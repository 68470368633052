import { type JSONApiUsersMap } from 'packages/grimoire'
import { type UserAttributes as AllUserAttributes } from 'packages/grimoire/src/user'

export * from 'packages/grimoire/src/user'

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export enum UsersActionTypes {
  FETCH_CURRENT_USER = 'USERS/API/FETCH_CURRENT_USER',
  FETCH_CURRENT_USER_FAILURE = 'USERS/FETCH_CURRENT_USER_FAILURE',
  FETCH_CURRENT_USER_SUCCESS = 'USERS/FETCH_CURRENT_USER_SUCCESS',

  SEARCH_USERS = 'USERS/API/SEARCH_USERS',
  SEARCH_USERS_FAILURE = 'USERS/SEARCH_USERS_FAILURE',
  SEARCH_USERS_SUCCESS = 'USERS/SEARCH_USERS_SUCCESS',

  SET_ACTIVE_USER = 'USERS/SET_ACTIVE_USER',
  SET_MAY_IMPERSONATE = 'USERS/MAY_IMPERSONATE',
}

export interface UsersState {
  /**
   * The active user who may differ from the authorized user during
   * impersonation
   */
  activeUserId: string
  /**
   * The authorized user (the person who signed in to IDP)
   */
  authUserId: string
  /**
   * User properties keyed to their IDs — for example, for both the *active* and
   * *authorized* user
   */
  data: JSONApiUsersMap
  /**
   * Whether the authorized user has permission to impersonate other users. This
   * will be true if they are flagged `isSuperuser` or if they have the IDP
   * scope `fieldops-guestworks-admin`.
   *
   * @see https://vacasait.atlassian.net/browse/VGW-159
   */
  mayImpersonate: boolean
  searchResults: JSONApiUsersMap
}

export type User = {
  id: string
} & UserAttributes

export type UserAttributes = AllUserAttributes
