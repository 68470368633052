import { produce } from 'immer'
import { type ActionType, getType } from 'typesafe-actions'

import { getIdAndDataTypeFromAction } from 'packages/utils/store'

import { fetchUnitsAction } from '../units/actions/fetchUnits'
import {
  createGwUnitStaffersAction,
  deleteGwUnitStafferAction,
  updateGwUnitStafferAction,
} from './actions'
import { fetchGwUnitStafferByIdAction } from './actions/fetchGwUnitStafferById'
import {
  type GwUnitStaffersState,
  type RawGwUnitStaffer,
} from './gwUnitStaffers.types'
import { emptyNormalizedGwUnitStaffersData } from './gwUnitStaffers.utils'

export const initialState: GwUnitStaffersState = Object.freeze({
  data: {},
})

const actions = {
  createGwUnitStaffersAction,
  deleteGwUnitStafferAction,
  fetchGwUnitStafferByIdAction,
  fetchUnitsAction,
  updateGwUnitStafferAction,
}

type GwUnitStaffersActionsTypes = ActionType<typeof actions>

export const gwUnitStaffersReducer = (
  state = initialState,
  action: GwUnitStaffersActionsTypes,
): GwUnitStaffersState =>
  produce(state, (draft: GwUnitStaffersState) => {
    switch (action.type) {
      case getType(fetchGwUnitStafferByIdAction.success): {
        const [id, gwUnitStaffer] =
          getIdAndDataTypeFromAction<RawGwUnitStaffer>(action, 'gwUnitStaffer')

        draft.data[id] = gwUnitStaffer

        return
      }

      case getType(fetchUnitsAction.success): {
        const normalized =
          action?.payload?.normalized || emptyNormalizedGwUnitStaffersData

        draft.data = {
          ...state.data,
          ...normalized.gwUnitStaffer,
        }

        return
      }

      case getType(createGwUnitStaffersAction.success):
      case getType(updateGwUnitStafferAction.success): {
        const normalized =
          action?.payload?.normalized || emptyNormalizedGwUnitStaffersData

        draft.data = {
          ...state.data,
          ...normalized.gwUnitStaffer,
        }

        return
      }

      case getType(deleteGwUnitStafferAction.success): {
        const { id } = action.payload
        delete draft.data[id]
      }
    }
  })
