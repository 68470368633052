// These auth code gen fns are all specifically for use in generateCodeChallenge().
// They were expertly borrowed from auth0's SDK, primarily starting here:
// https://github.com/auth0/auth0-spa-js/blob/master/src/utils.ts#L142

export const CACHE_KEY_CODE_VERIFIER = 'codeVerifier'

/* eslint-disable @typescript-eslint/no-explicit-any */
const getCryptoSubtle = () => {
  const crypto = window.crypto
  // safari 10.x uses webkitSubtle
  return crypto.subtle || (crypto as any).webkitSubtle
}

const createRandomString = () => {
  const charset =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-_~.'
  let random = ''
  const randomValues = Array.from(
    window.crypto.getRandomValues(new Uint8Array(43)),
  )
  randomValues.forEach(v => (random += charset[v % charset.length]))
  return random
}

const sha256 = async (s: string) => {
  const digestOp: any = getCryptoSubtle().digest(
    { name: 'SHA-256' },
    new TextEncoder().encode(s),
  )

  return await digestOp
}

const urlEncodeB64 = (input: string) => {
  const b64Chars: Record<string, string> = { '+': '-', '/': '_', '=': '' }
  return input.replace(/[+/=]/g, (m: string) => b64Chars[m])
}

const bufferToBase64UrlEncoded = (input: number[] | Uint8Array) => {
  const ie11SafeInput = new Uint8Array(input)
  return urlEncodeB64(
    window.btoa(String.fromCharCode(...Array.from(ie11SafeInput))),
  )
}
/* eslint-enable @typescript-eslint/no-explicit-any */

type AuthCodeKeys = {
  [key in 'codeChallenge' | 'codeVerifier']: string
}

/**
 * Generates a return a code challenge + verifier for the first 2 steps
 * of the auth code w/ PKCE process.
 *
 * https://developers.onelogin.com/openid-connect/api/authorization-code
 * https://developers.onelogin.com/openid-connect/api/authorization-code-grant
 */
export const generateCodeChallenge = async (): Promise<AuthCodeKeys> => {
  const codeVerifier = createRandomString()
  const codeChallengeBuffer = await sha256(codeVerifier)
  const codeChallenge = bufferToBase64UrlEncoded(codeChallengeBuffer)

  return {
    codeChallenge,
    codeVerifier,
  }
}
