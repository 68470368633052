import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Loader } from 'packages/common'
import { splitSearchQuery } from 'packages/utils/misc'

import { type ApplicationState } from 'app/store/store'
import { fetchTicketById } from 'app/store/tickets/actions'
import { getTicketById } from 'app/store/tickets/selectors'

import { SHARED_TICKET_INVALID_URL } from '../../schedule/schedule.utils'
import { TicketDetail } from '../TicketDetail/TicketDetail'
import { type UnknownAction } from '@reduxjs/toolkit'

export const SharedTicketPageContainer: React.FC = React.memo(() => {
  const dispatch = useDispatch()

  const { selectedTicket = '' } = splitSearchQuery(window.location.search)

  const fetchTicketState = useAsync(async () => {
    if (!selectedTicket) return
    await dispatch(fetchTicketById(selectedTicket) as unknown as UnknownAction)
  }, [selectedTicket])

  const ticket = useSelector((state: ApplicationState) =>
    getTicketById(state, selectedTicket),
  )

  if (fetchTicketState.loading) {
    return <Loader />
  }

  if (!ticket) {
    return <Navigate to={SHARED_TICKET_INVALID_URL} />
  }

  return <TicketDetail ticket={ticket} />
})
