import styled from '@emotion/styled'
import React from 'react'

import { text } from 'packages/styles'

import { MapsLink } from 'app/components/core'
import type { Unit } from 'app/store/units'
import { gwText } from 'app/styles'

const St = {
  Text: styled.p`
    ${text.bodyRegularSmall};
    margin: 0;
  `,
  UnitName: styled.h3`
    ${gwText.bodySmallBold};
  `,
}

export interface ResUnitInfoProps {
  unit: Unit
}

export const ResUnitInfo: React.FC<ResUnitInfoProps> = React.memo(
  ({ unit }) => {
    const unitAddressString = `${unit.address}, ${unit.city}, ${unit.state}`

    return (
      <div>
        <St.UnitName>{unit.name}</St.UnitName>
        <St.Text>{unitAddressString}</St.Text>
        <MapsLink {...unit} />
      </div>
    )
  },
)
