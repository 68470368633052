import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Loader } from 'packages/common'

import { getAppInitState } from 'app/store/app/selectors'

import { AccessPage } from './AccessPage'

export const AccessPageContainer: React.FC = React.memo(() => {
  const navigate = useNavigate()

  const appInitState = useSelector(getAppInitState)
  const appIsLoading = !['ready', 'error'].includes(appInitState)

  const handleHeaderClick = () => {
    navigate('/settings')
  }

  return appIsLoading ? (
    <Loader />
  ) : (
    <AccessPage onHeaderClick={handleHeaderClick} />
  )
})
