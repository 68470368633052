import React from 'react'

import { type ToastConfig, useToast } from 'packages/common'

interface UseGwToast {
  showGwToast: (config: ToastConfig) => void
}

export const useGwToast = (): UseGwToast => {
  const { showToast } = useToast()

  const showGwToast = React.useCallback(
    (config: ToastConfig) => {
      showToast({
        ...config,
        position: 'top-right',
      })
    },
    [showToast],
  )

  return { showGwToast }
}
