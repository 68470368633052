import { produce } from 'immer'
import { merge } from 'lodash/fp'
import { type ActionType, getType } from 'typesafe-actions'

import {
  fetchCleanByIdAction,
  fetchCleansAction,
  fetchPreviousCleansAction,
} from '../cleans/actions'
import { setActiveUserAction } from '../users/actions'
import {
  fetchReservationsAction,
  fetchPreviousReservationsAction,
} from './actions'
import { type ReservationsState } from './reservations.types'
import { emptyNormalizedReservationsData } from './reservations.utils'

export const initialState: ReservationsState = {
  data: {},
}

const actions = {
  fetchCleanByIdAction,
  fetchCleansAction,
  fetchPreviousCleansAction,
  fetchPreviousReservationsAction,
  fetchReservationsAction,
  setActiveUserAction,
}

type Actions = ActionType<typeof actions>

export const reservationsReducer = (
  state = initialState,
  action: Actions,
): ReservationsState =>
  produce(state, (draft: ReservationsState) => {
    switch (action.type) {
      case getType(fetchCleanByIdAction.success):
      case getType(fetchCleansAction.success):
      case getType(fetchPreviousCleansAction.success):
      case getType(fetchPreviousReservationsAction.success):
      case getType(fetchReservationsAction.success): {
        const normalized =
          action.payload?.normalized || emptyNormalizedReservationsData

        Object.values(normalized?.reservation || {}).forEach(incomingRes => {
          const existingRes = state.data[incomingRes.id] || {}
          const mergedRes = merge(existingRes, incomingRes)
          draft.data[incomingRes.id] = mergedRes
        })

        return
      }

      case getType(setActiveUserAction): {
        // clear all existing data when switching users
        // this is so that we as admins only see an impersonated user's reservations,
        // because fetching reservations and admin includes a lot more than expected
        draft.data = {}
      }
    }
  })
