import React from 'react'

import type { BadgeType } from 'app/components/core'
import { Badge } from 'app/components/core'
import { Slugs, useI18n } from 'app/i18n'

import { isTicketCompleted } from '../tickets.helpers'

type StatusBadgeType = Extract<BadgeType, 'aspen' | 'yellow'>

const badgeChildrenMap: Record<StatusBadgeType, string> = {
  aspen: Slugs.bookingStatusCompleted,
  yellow: Slugs.bookingStatusNotFinished,
}

const getBadgeType = (status): StatusBadgeType => {
  if (isTicketCompleted(status)) return 'aspen'
  return 'yellow'
}

export interface TicketStatusBadgeProps {
  status: string
}

export const TicketStatusBadge: React.FC<TicketStatusBadgeProps> = React.memo(
  ({ status }) => {
    const { t } = useI18n()
    const badgeType: StatusBadgeType = getBadgeType(status)

    return <Badge badgeType={badgeType}>{t(badgeChildrenMap[badgeType])}</Badge>
  },
)
