import React from 'react'

import { MissingDataError } from 'packages/utils/errors'
import { useSelectorWithErrorSuppression } from 'packages/utils/hooks/useSelectorWithErrorSuppression'

import { type ApplicationState } from 'app/store/store'
import { type Ticket } from 'app/store/tickets'
import { getTicketById } from 'app/store/tickets/selectors'

export const useSafeGetTicketById = (ticketId?: string): Ticket | undefined => {
  const safeGetTicketById = React.useCallback(
    (reduxState: ApplicationState): Ticket | undefined =>
      ticketId ? getTicketById(reduxState, ticketId) : undefined,
    [ticketId],
  )

  const ticket = useSelectorWithErrorSuppression<
    Ticket | undefined,
    ApplicationState
  >(safeGetTicketById, [MissingDataError])

  return ticket
}
