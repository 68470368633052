import { upperFirst } from 'lodash'
import React from 'react'

import { IconName } from 'packages/iconic'
import { DateFormat, format } from 'packages/utils/dateHelpers'
import { useShareApi } from 'packages/utils/hooks'

import { Button } from 'app/components/core'
import { Slugs, useI18n } from 'app/i18n'

import { type AdHocLockCodeResponse } from '../LockCodeForm/LockCodeForm.helpers'
import { getCodeDurationText } from './LockCodeFormSuccess.helpers'
import * as St from './LockCodeFormSuccess.styles'

export const CLIPBOARD_RESET_TIMEOUT = 1500

enum CopyState {
  Idle,
  Copied,
  Failed,
}

const slugsByCopyState: Record<CopyState, string> = {
  [CopyState.Idle]: Slugs.copyLink,
  [CopyState.Copied]: Slugs.copied,
  [CopyState.Failed]: Slugs.copyFailed,
}

interface useTranslationsParams {
  address: string
  copyState: CopyState
  endDate: string
  lockCode: string
  startDate: string
}

const useTranslations = (params: useTranslationsParams) => {
  const { t } = useI18n()

  const { copyState, address, endDate, startDate, lockCode } = params

  return {
    codeDuration: t(Slugs.codeDuration),
    contact: t(Slugs.contact),
    copyLinkText: t(slugsByCopyState[copyState]),
    days: t(Slugs.days),
    endTime: t(Slugs.endTime),
    hours: upperFirst(t(Slugs.hours)),
    itIsValidFrom: t(Slugs.itIsValidFrom, { address, endDate, startDate }),
    lockCode: t(Slugs.lockCode),
    lockCodeCreated: t(Slugs.lockCodeCreated),
    lockCodeFormSuccessBody: t(Slugs.lockCodeFormSuccessBody),
    lockCodeStartTime: t(Slugs.lockCodeStartTime),
    questions: t(Slugs.questions),
    shareVia: t(Slugs.shareVia),
    thankYou: t(Slugs.thankYou),
    yourLockCodeIs: t(Slugs.yourLockCodeIs, { lockCode }),
  }
}

export enum LockCodeFormSuccessTestIds {
  accessCode = 'LockCodeFormSuccess__accessCode',
  cardBody = 'LockCodeFormSuccess__cardBody',
  endDateTime = 'LockCodeFormSuccess__endDateTime',
  heading = 'LockCodeFormSuccess__heading',
  shareViaButton = 'LockCodeFormSuccess__shareViaButton',
  startDateTime = 'LockCodeFormSuccess__startDateTime',
}

export interface LockCodeFormSuccessProps {
  lockCodeFormResponse: AdHocLockCodeResponse
  unitAddress: string
}

export const LockCodeFormSuccess: React.FC<LockCodeFormSuccessProps> =
  React.memo(({ lockCodeFormResponse, unitAddress }) => {
    const { share, canShare } = useShareApi()
    const { accessCode, startDateTime, endDateTime } = lockCodeFormResponse

    const [copyState, setCopyState] = React.useState<CopyState>(CopyState.Idle)
    const strings = useTranslations({
      address: unitAddress,
      copyState,
      endDate: format(endDateTime, DateFormat.ShareText),
      lockCode: accessCode,
      startDate: format(startDateTime, DateFormat.ShareText),
    })

    const handleCopyLink = React.useCallback(async text => {
      try {
        await navigator.clipboard.writeText(text)
        setCopyState(CopyState.Copied)
        setTimeout(() => {
          setCopyState(CopyState.Idle)
        }, CLIPBOARD_RESET_TIMEOUT)
      } catch (err) {
        setCopyState(CopyState.Failed)
      }
    }, [])

    const handleShareButtonClick = () => {
      // NOTE: be very careful if tweaking the formatting/layout here, as it will affect the final "share" text
      const text = `${strings.yourLockCodeIs}

${strings.itIsValidFrom}

${strings.questions} ${strings.contact} 512-829-3039
${strings.thankYou}
`
      if (canShare) share({ text })
      else handleCopyLink(text)
    }

    return (
      <>
        <St.LockCodeFormSection>
          <St.HeadingContainer>
            <St.SuccessIconBackground>
              <St.SuccessIcon icon={IconName.checkCircle} size={24} />
            </St.SuccessIconBackground>
            <St.Heading data-testid={LockCodeFormSuccessTestIds.heading}>
              {strings.lockCodeCreated}
            </St.Heading>
          </St.HeadingContainer>
          <St.CardBody data-testid={LockCodeFormSuccessTestIds.cardBody}>
            {strings.lockCodeFormSuccessBody}
          </St.CardBody>
        </St.LockCodeFormSection>

        <St.LockCodeFormSection>
          <St.LockCodeDetailContainer>
            <St.LockCodeDetail>{strings.lockCode}</St.LockCodeDetail>
            <St.LockCodeDetailValue
              data-testid={LockCodeFormSuccessTestIds.accessCode}
            >
              {accessCode}
            </St.LockCodeDetailValue>
          </St.LockCodeDetailContainer>
          <St.LockCodeDetailContainer>
            <St.LockCodeDetail>{strings.lockCodeStartTime}</St.LockCodeDetail>
            <St.LockCodeDetailValue
              data-testid={LockCodeFormSuccessTestIds.startDateTime}
            >
              {format(startDateTime, DateFormat.SlashesWithTime)}
            </St.LockCodeDetailValue>
          </St.LockCodeDetailContainer>
          <St.LockCodeDetailContainer>
            <St.LockCodeDetail>{strings.endTime}</St.LockCodeDetail>
            <St.LockCodeDetailValue
              data-testid={LockCodeFormSuccessTestIds.endDateTime}
            >
              {format(endDateTime, DateFormat.SlashesWithTime)}
            </St.LockCodeDetailValue>
          </St.LockCodeDetailContainer>
          <St.LockCodeDetailContainer>
            <St.LockCodeDetail>{strings.codeDuration}</St.LockCodeDetail>
            <St.LockCodeDetailValue>
              {getCodeDurationText({
                daysSlug: strings.days,
                endDateTime,
                hoursSlug: strings.hours,
                startDateTime,
              })}
            </St.LockCodeDetailValue>
          </St.LockCodeDetailContainer>
        </St.LockCodeFormSection>

        <St.LockCodeFormSection
          data-testid={LockCodeFormSuccessTestIds.shareViaButton}
        >
          <Button block={true} onPress={handleShareButtonClick}>
            {canShare ? strings.shareVia : strings.copyLinkText}
          </Button>
        </St.LockCodeFormSection>
      </>
    )
  })
