import styled from '@emotion/styled'
import React from 'react'

import { IconName } from 'packages/iconic'

import { Slugs, useI18n } from 'app/i18n'
import { type Lockbox } from 'app/store/lockboxes/lockboxes.types'
import { type Unit } from 'app/store/units/units.types'

import {
  CleanDetailBody,
  CleanDetailIcon,
  CleanDetailSection,
  CleanDetailSubheader,
} from '../../CleanDetail.styles'

const St = {
  AccessCodes: CleanDetailSection,
  Details: CleanDetailBody,
  Icon: CleanDetailIcon,
  SectionLabel: styled.span`
    font-weight: 600;
  `,
  Subheader: CleanDetailSubheader,
}

export enum AccessCodesTestIds {
  container = 'AccessCodes__container',
}

export interface AccessCodesProps {
  lockbox?: Lockbox
  unit: Unit
}

export const AccessCodes: React.FC<AccessCodesProps> = React.memo(
  ({ lockbox, unit }) => {
    const { t } = useI18n()

    const hasValidLockbox =
      lockbox && (lockbox.primaryLock || lockbox.backupLock)

    if (!lockbox) {
      return null
    }

    return (
      <St.AccessCodes data-testid={AccessCodesTestIds.container}>
        <St.Icon icon={IconName.lock} size={14} />

        <St.Details>
          <strong>{t(Slugs.access)}</strong>

          {!!unit.gateCode && (
            <div>
              <St.Subheader>{t(Slugs.gateCode)}</St.Subheader>: {unit.gateCode}
            </div>
          )}

          {!!unit.alarmCode && (
            <div>
              <St.Subheader>{t(Slugs.alarmCode)}</St.Subheader>:{' '}
              {unit.alarmCode}
            </div>
          )}

          {lockbox && (
            <div data-testid="lockbox-section">
              {hasValidLockbox ? (
                <>
                  <div>
                    <St.Subheader>{t(Slugs.lockbox)}</St.Subheader>:{' '}
                    {lockbox.primaryLock || '-'}
                  </div>
                  <div>
                    <St.Subheader>{t(Slugs.backup)}</St.Subheader>:{' '}
                    {lockbox.backupLock || '-'}
                  </div>
                  <div>
                    <St.Subheader>{t(Slugs.notes)}</St.Subheader>:{' '}
                    {lockbox.notes || '-'}
                  </div>
                </>
              ) : (
                <> - </>
              )}
            </div>
          )}
        </St.Details>
      </St.AccessCodes>
    )
  },
)
