import { css } from '@emotion/react'

// typography styles here:
// https://www.figma.com/file/ihoeWx4RJ3mNvU0xsiIoKI/Polygon-%2BEnterprise?node-id=1%3A2&t=RhYtY6pRfnd0X7pk-4
export const text = {
  bodyBold: css`
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  `,
  bodyBoldSmall: css`
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  `,
  bodyBoldTiny: css`
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
  `,
  bodyRegularDefault: css`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  `,
  bodyRegularSmall: css`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  `,
  bodyRegularTiny: css`
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  `,
  bodyTiny: css`
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
  `,
  fieldLabelMobile: css`
    font-size: 14px;
    font-weight: 800;
    letter-spacing: 1.5px;
    line-height: 16px;
    text-transform: uppercase;
  `,
  headingCard: css`
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.3px;
    line-height: 29px;
  `,
  headingCardAlt: css`
    font-size: 21px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 0.2px;
  `,
  headingCardSmall: css`
    font-size: 21px;
    font-weight: 700;
    line-height: 1.5;
  `,
  headingDefault: css`
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
  `,
  headingLabel: css`
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    text-transform: uppercase;
  `,
  headingMediumCaps: css`
    font-size: 16px;
    font-weight: 800;
    letter-spacing: 2px;
    line-height: 21px;
    text-transform: uppercase;
  `,
  headingMicroCaps: css`
    font-size: 8px;
    font-weight: 800;
    letter-spacing: 1.5px;
    line-height: 12px;
    text-transform: uppercase;
  `,
  headingSCaps: css`
    font-size: 14px;
    font-weight: 800;
    letter-spacing: 2px;
    line-height: 0.875;
    text-transform: uppercase;
  `,
  headingTiny: css`
    font-size: 10px;
    font-weight: 800;
    letter-spacing: 1.5px;
    line-height: 14px;
  `,
  headingTinyCaps: css`
    font-size: 10px;
    font-weight: 800;
    letter-spacing: 1.5px;
    line-height: 14px;
    text-transform: uppercase;
  `,
  headingXsCaps: css`
    font-size: 12px;
    font-weight: 800;
    letter-spacing: 2px;
    line-height: 14px;
    text-transform: uppercase;
  `,
}
