import styled from '@emotion/styled'
import React from 'react'
import { useEffectOnce } from 'react-use'

import { Chip } from 'packages/common/src/Chip'
import { IconName, SvgIcon } from 'packages/iconic'
import { centerWithFlex } from 'packages/styles'

import { Slugs, useI18n } from 'app/i18n'

const OfflineNotifierContainer = styled.div`
  ${centerWithFlex};

  bottom: 10px;
  left: 50%;
  position: fixed;
  transform: translateX(-50%);
`

const textStyles: React.CSSProperties = {
  marginLeft: '.5rem',
  paddingTop: 1,
}

export enum OfflineNotifierTestIds {
  offline = 'OfflineNotifier__offline',
  online = 'OfflineNotifier__online',
}

const OfflineChip: React.FC = () => {
  const { t } = useI18n()

  return (
    <Chip testId={OfflineNotifierTestIds.offline}>
      <SvgIcon
        centerItems={true}
        icon={IconName.cloudOff}
        size={16}
        text={t(Slugs.youreOffline)}
        textFontSize={14}
        textStyleOverrides={textStyles}
      />
    </Chip>
  )
}

const OnlineChip: React.FC = () => {
  const { t } = useI18n()
  const [showing, setShowing] = React.useState(true)

  // auto-hide this chip after 5 seconds
  useEffectOnce(() => {
    const timeout = setTimeout(() => {
      setShowing(false)
    }, 5000)

    return () => {
      clearTimeout(timeout)
    }
  })

  return showing ? (
    <Chip testId={OfflineNotifierTestIds.online}>
      <SvgIcon
        centerItems={true}
        icon={IconName.cloud}
        size={16}
        text={t(Slugs.youreBackOnline)}
        textFontSize={14}
        textStyleOverrides={textStyles}
      />
    </Chip>
  ) : null
}

export type OnlineStatus = 'online' | 'offline' | 'backOnline'

export interface OfflineNotifierProps {
  onlineStatus: OnlineStatus
}

export const OfflineNotifier: React.FC<OfflineNotifierProps> = React.memo(
  ({ onlineStatus }) => {
    if (onlineStatus === 'offline') {
      return (
        <OfflineNotifierContainer>
          <OfflineChip />
        </OfflineNotifierContainer>
      )
    }

    if (onlineStatus === 'backOnline') {
      return (
        <OfflineNotifierContainer>
          <OnlineChip />
        </OfflineNotifierContainer>
      )
    }

    return null
  },
)
