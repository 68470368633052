import styled from '@emotion/styled'
import React from 'react'

import {
  cleanIsB2b,
  cleanIsPotentialB2b,
  cleanIsStandardService,
} from 'packages/grimoire'

import type { BadgeType } from 'app/components/core'
import { Badge } from 'app/components/core'
import { Slugs, useI18n } from 'app/i18n'
import type { Clean } from 'app/store/cleans'

const St = {
  ServiceTypeBadge: styled(Badge)`
    margin-left: 12px;
  `,
}

type ServiceBadgeType = Extract<BadgeType, 'midnight' | 'white'>

const getBadgeChildren = (clean: Clean): string => {
  if (cleanIsPotentialB2b(clean) || cleanIsB2b(clean)) return Slugs.badgeB2b
  return Slugs.badgeLate
}

export interface ServiceTypeBadgeProps {
  clean: Clean
}

export const ServiceTypeBadge: React.FC<ServiceTypeBadgeProps> = React.memo(
  ({ clean }) => {
    const { t } = useI18n()
    const badgeType: ServiceBadgeType = cleanIsPotentialB2b(clean)
      ? 'white'
      : 'midnight'

    return cleanIsStandardService(clean) ? null : (
      <St.ServiceTypeBadge badgeType={badgeType}>
        {t(getBadgeChildren(clean))}
      </St.ServiceTypeBadge>
    )
  },
)
