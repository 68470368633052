import { createSelector } from 'reselect'

import { type ApplicationState } from '../../store'
import { type AssignmentToCleanMap, type RawClean } from '../cleans.types'
import { hydrateRawClean } from '../cleans.utils'

const getFullState = (state: ApplicationState): ApplicationState => state
const getAssignmentIds = (_state: ApplicationState, ids: string[]): string[] =>
  ids

/**
 * Returns a map of cleans keyed by the assignment IDs passed in, where a given
 * clean is associated with a given assignment.
 *
 * If for any reason no clean is found for a given assignment ID, it will simply
 * be excluded from the map (rather than inserting a null placeholder).
 */
export const getCleansByAssignmentIds = createSelector(
  getFullState,
  getAssignmentIds,
  (state, assignmentIds): AssignmentToCleanMap => {
    const hydrator = hydrateRawClean(state)

    return assignmentIds.reduce<AssignmentToCleanMap>(
      (acc, assignmentId): AssignmentToCleanMap => {
        const rawClean = Object.values(state.cleans.data).find(
          (rawClean: RawClean) => {
            const assignments = rawClean.relationships.activeAssignments.data
            for (let i = 0; i < assignments.length; i += 1) {
              if (assignments[i].id === assignmentId) {
                return true
              }
            }

            return false
          },
        )
        if (rawClean) {
          acc[assignmentId] = hydrator(rawClean)
        }

        return acc
      },
      {},
    )
  },
)
