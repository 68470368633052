import { createSelector } from 'reselect'

import { type ApplicationState } from '../../store'
import { type UnitsState, type Unit } from '../units.types'
import { hydrateRawUnit } from '../units.utils'

const getUnitsState = (state: ApplicationState): UnitsState => state.units

export const getSelectedUnit = createSelector(
  getUnitsState,
  (unitsState): Unit | undefined => {
    const unitId = unitsState.selectedUnitId || ''
    const rawUnit = unitsState.data?.[unitId]
    return rawUnit && hydrateRawUnit(rawUnit)
  },
)
