import styled from '@emotion/styled'
import React from 'react'

import { colors } from 'packages/styles'

const St = {
  Card: styled.div`
    background-color: ${colors.white};
    border-radius: 16px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
    padding: 24px;
  `,
}

export interface CardProps {
  className?: string
  dataTestId?: string
  onClick?: () => void
  children: React.ReactNode
}

export const Card: React.FC<CardProps> = React.memo(
  ({ children, className = '', dataTestId, onClick }) => {
    return (
      <St.Card className={className} data-testid={dataTestId} onClick={onClick}>
        {children}
      </St.Card>
    )
  },
)
