import { type AxiosResponse } from 'axios'
import { difference, snakeCase } from 'lodash/fp'

import {
  AllReservationAttributeNames,
  type AllReservationAttributes,
} from 'packages/grimoire'
import {
  type JSONApiObjectWithRelationships,
  type JSONApiObjectWithRelationshipsMap,
  type NormalizedJSONApiResponse,
  type ToOneRelationship,
} from 'packages/grimoire/src/utils'

import { type JSONApiUnitsMap } from '../units'

export * from 'packages/grimoire/src/reservation'

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export interface ReservationsState {
  data: JSONApiReservationsMap
}

export enum ReservationsActionTypes {
  FETCH_PREVIOUS_RESERVATIONS = 'RESERVATIONS/API/FETCH_PREVIOUS_RESERVATIONS',
  FETCH_PREVIOUS_RESERVATIONS_FAILURE = 'RESERVATIONS/FETCH_PREVIOUS_RESERVATIONS_FAILURE',
  FETCH_PREVIOUS_RESERVATIONS_SUCCESS = 'RESERVATIONS/FETCH_PREVIOUS_RESERVATIONS_SUCCESS',

  FETCH_RESERVATIONS = 'RESERVATIONS/API/FETCH_RESERVATIONS',
  FETCH_RESERVATIONS_FAILURE = 'RESERVATIONS/FETCH_RESERVATIONS_FAILURE',
  FETCH_RESERVATIONS_SUCCESS = 'RESERVATIONS/FETCH_RESERVATIONS_SUCCESS',
}

/**********************************************************
 * SERVICE TYPES
 *********************************************************/
export interface ReservationsResponse {
  reservation: JSONApiReservationsMap
  unit: JSONApiUnitsMap
}

export type NormalizedReservationsApiResponse =
  NormalizedJSONApiResponse<ReservationsResponse>

export type ReservationsApiResponse =
  AxiosResponse<NormalizedReservationsApiResponse>

export type ReservationsServiceResponse =
  Promise<NormalizedReservationsApiResponse>

/**********************************************************
 * RESERVATION
 *********************************************************/
export type Reservation = {
  id: string
  unitId: string
} & ReservationAttributes

export interface ReservationRelationships {
  unit: ToOneRelationship
}

export type ReservationAttributes = AllReservationAttributes & {
  creationDate: string
  guestLockCode?: string
}

export const ReservationAttributeNames = difference(
  AllReservationAttributeNames.concat(['creationDate', 'guestLockCode']),
)([])

export const ReservationApiFields: string[] =
  ReservationAttributeNames.map(snakeCase)

export type RawReservation = JSONApiObjectWithRelationships<
  ReservationAttributes,
  ReservationRelationships
>

export type JSONApiReservationsMap = JSONApiObjectWithRelationshipsMap<
  ReservationAttributes,
  ReservationRelationships
>

export const CleanReservationApiFields = difference(ReservationApiFields)([
  'guest_lock_code',
])
