import React from 'react'
import { useDispatch } from 'react-redux'

import {
  createDateString,
  isAfter,
  subMinutes,
} from 'packages/utils/dateHelpers'
import { splitSearchQuery } from 'packages/utils/misc'
import { logInfo } from 'packages/wiretap/logging'

import { type AuthTokens } from '../auth.types'
import {
  type AuthOverrideParams,
  getRefreshTokenRequest,
  getTokenRequest,
  redirectForAuthentication,
} from '../utils/authCodeWithPkce.utils'
import { CACHE_KEY_CODE_VERIFIER } from '../utils/generateCodeChallenge'
import { type UnknownAction } from '@reduxjs/toolkit'

interface validateAuthRedirectsProps {
  authClientId: string
  authUrl: string
  overrideParams?: AuthOverrideParams
}

/*
  validateAuthRedirects returns recordNewAuthRedirect, which we use to record a timestamp in session storage,
  and hasExceededMaxRedirects, which returns a boolean depending on whether we have reached the max num of auth retries.
  If something is really off with code/token exchange and we start looping endlessly validateAuthRedirects
  will allow us to eject out of the loop and log it to DataDog.
*/
const validateAuthRedirects = ({
  authClientId,
  authUrl,
  overrideParams,
}: validateAuthRedirectsProps) => {
  const SESSION_KEY = 'full_auth_redirect_timestamps'
  const MAX_REDIRECT_COUNT = 5

  // check if previous session storage data exists and if not default to empty array
  const sessionData = sessionStorage.getItem(SESSION_KEY)
  const parsedSessionData: string[] = sessionData ? JSON.parse(sessionData) : []

  const oneMinuteAgo = subMinutes(createDateString(), 1)

  // filter out any timestamps that are older than one minute
  const isValidTimeStamps = parsedSessionData.filter(timestamp =>
    isAfter(timestamp, oneMinuteAgo),
  )
  const hasExceededMaxRedirects = isValidTimeStamps.length >= MAX_REDIRECT_COUNT

  const recordNewAuthRedirect = () => {
    sessionStorage.setItem(
      SESSION_KEY,
      JSON.stringify([...isValidTimeStamps, createDateString()]),
    )
  }

  if (hasExceededMaxRedirects) {
    logInfo('Error with code/token exchange. Exceeded max retries', {
      authClientId,
      authUrl,
    })
  }

  return {
    hasExceededMaxRedirects,
    recordNewAuthRedirect,
  }
}

export interface UseIdpAuthProps {
  accessToken: string | undefined
  authClientId: string
  authUrl: string
  isImpersonated: boolean
  needsFullAuth: boolean
  needsSilentRefresh: boolean
  /**
   * Method called when auth values have changed
   *
   * @param scopes Optionally pass IDP scopes for processing by the calling method
   */
  onAuthInitialized: (scopes?: string[]) => void
  /**
   * Optional custom OAuth `audience` and `scope` values. These may be set as
   * environment variables, e.g. `REACT_APP_IDP_SCOPE`.
   */
  overrideParams?: AuthOverrideParams
  refreshToken: string | undefined
  /**
   * Method to update state indicating whether the current user should be
   * redirected to sign in again
   */
  setNeedsFullAuthRedirect: (value: boolean) => void
  setTokens: (tokens: AuthTokens) => void
  tokenUrl: string
}

/** Compare to `AuthCodeWithPkce` */
export const useIdpAuth = ({
  authClientId,
  accessToken,
  authUrl,
  isImpersonated,
  needsFullAuth,
  needsSilentRefresh,
  onAuthInitialized,
  overrideParams = {},
  refreshToken,
  setNeedsFullAuthRedirect,
  setTokens,
  tokenUrl,
}: UseIdpAuthProps): void => {
  const dispatch = useDispatch()

  const [code, setCode] = React.useState('')

  /**
   * Handles the initial authentication request.
   * - If we already have a "code" from the server, it will be saved locally so we can exchange it for a token.
   * - Otherwise, a full auth redirect is triggered
   */
  React.useEffect(() => {
    if (!navigator.onLine) {
      setTokens({
        accessToken: undefined,
        idToken: undefined,
        refreshToken: undefined,
      })

      onAuthInitialized()
      return
    }

    if (needsFullAuth) {
      const params = splitSearchQuery(window.location.search)
      const { code } = params

      const { hasExceededMaxRedirects, recordNewAuthRedirect } =
        validateAuthRedirects({
          authClientId,
          authUrl,
          overrideParams,
        })

      if (code) {
        setCode(code)
      } else {
        recordNewAuthRedirect()

        // if there are not 5 or more timestamps that are less than a minute old, then do redirect
        if (!hasExceededMaxRedirects) {
          redirectForAuthentication(authUrl, authClientId, overrideParams)
        }
      }
    }
  }, [
    authClientId,
    authUrl,
    needsFullAuth,
    onAuthInitialized,
    overrideParams,
    setTokens,
  ])

  /**
   * Handles the "code-to-token exchange" flow.
   * - When we have a "code" from the auth server, will attempt to exchange that code for an auth token
   * - If any errors occur, we will simply trigger a full auth redirect
   */
  React.useEffect(() => {
    const fetchAuthTokens = async () => {
      try {
        const request = getTokenRequest(code, authClientId)
        const res = await fetch(tokenUrl, request)
        const data = await res.json()
        const {
          access_token: accessToken,
          id_token: idToken,
          refresh_token: refreshToken,
        } = data

        dispatch(
          setTokens({
            accessToken,
            idToken,
            refreshToken,
          }) as unknown as UnknownAction,
        )

        const scopes = data.scope ? data.scope.split(' ') : undefined
        onAuthInitialized(scopes)
      } catch (err) {
        // manually setting our token to an impersonated user's token will attempt to trigger this,
        // so we need to manually stop it when this is present
        if (!isImpersonated) {
          dispatch(setNeedsFullAuthRedirect(true) as unknown as UnknownAction)
        }
      } finally {
        sessionStorage.removeItem(CACHE_KEY_CODE_VERIFIER)
      }
    }

    if (code && !accessToken) {
      fetchAuthTokens()
    }
  }, [
    authClientId,
    accessToken,
    code,
    dispatch,
    isImpersonated,
    onAuthInitialized,
    setNeedsFullAuthRedirect,
    setTokens,
    tokenUrl,
  ])

  /**
   * Handles the "refresh token" flow.
   * - When 'needsSilentRefresh' is true, will attempt to request a new token using our existing refresh token
   * - If any errors occur, we will simply trigger a full auth redirect
   */
  React.useEffect(() => {
    const refreshAuthTokens = async () => {
      try {
        if (!refreshToken) {
          throw Error(
            'No refresh token found; full authentication redirect required.',
          )
        }

        const request = getRefreshTokenRequest(refreshToken, authClientId)
        const res = await fetch(tokenUrl, request)
        const data = await res.json()
        const {
          access_token: accessToken,
          id_token: idToken,
          refresh_token: newRefreshToken,
        } = data

        if (idToken && newRefreshToken) {
          dispatch(
            setTokens({
              accessToken,
              idToken,
              refreshToken: newRefreshToken,
            }) as unknown as UnknownAction,
          )
        } else {
          throw Error(
            'No valid tokens found; full authentication redirect required.',
          )
        }
      } catch (err) {
        dispatch(setNeedsFullAuthRedirect(true) as unknown as UnknownAction)
      }
    }

    if (needsSilentRefresh) {
      refreshAuthTokens()
    }
  }, [
    authClientId,
    code,
    dispatch,
    needsSilentRefresh,
    refreshToken,
    setNeedsFullAuthRedirect,
    setTokens,
    tokenUrl,
  ])
}
