import { deprecated } from 'typesafe-actions'

import { UsersActionTypes } from 'app/store/users'
const { createStandardAction } = deprecated

export const setMayImpersonateUserAction = createStandardAction(
  UsersActionTypes.SET_MAY_IMPERSONATE,
)<boolean>()

/**
 * Indicate whether the authorized user has permission to impersonate other
 * users. This should only be true if `isSuperuser == true` or the user has
 * the IDP scope `fieldops-guestworks-admin`.
 */
export const setMayImpersonateUser = (allowed: boolean) => async dispatch => {
  dispatch(setMayImpersonateUserAction(allowed))
}
