import { produce } from 'immer'
import { type ActionType, getType } from 'typesafe-actions'

import { getIdAndDataTypeFromAction } from 'packages/utils/store'

import { fetchUnitsAction } from '../units/actions'
import {
  createGwLocalHostAction,
  deleteGwLocalHostAction,
  fetchGwLocalHostByIdAction,
} from './actions'
import {
  type GwLocalHostsState,
  type RawGwLocalHost,
} from './gwLocalhosts.types'
import { emptyNormalizedGwLocalHostsData } from './gwLocalhosts.utils'

export const initialState: GwLocalHostsState = Object.freeze({
  data: {},
})

const actions = {
  createGwLocalHostAction,
  deleteGwLocalHostAction,
  fetchGwLocalHostByIdAction,
  fetchUnitsAction,
}

type GwLocalHostsActionsTypes = ActionType<typeof actions>

export const gwLocalHostsReducer = (
  state = initialState,
  action: GwLocalHostsActionsTypes,
): GwLocalHostsState =>
  produce(state, (draft: GwLocalHostsState) => {
    switch (action.type) {
      case getType(fetchGwLocalHostByIdAction.success): {
        const [id, gwLocalHost] = getIdAndDataTypeFromAction<RawGwLocalHost>(
          action,
          'gwLocalHost',
        )

        draft.data[id] = gwLocalHost

        return
      }

      case getType(createGwLocalHostAction.success):
      case getType(fetchUnitsAction.success): {
        const normalized =
          action?.payload?.normalized || emptyNormalizedGwLocalHostsData

        draft.data = {
          ...state.data,
          ...normalized.gwLocalHost,
        }

        return
      }

      case getType(deleteGwLocalHostAction.success): {
        const id = action?.payload?.id
        if (id) {
          delete draft.data[id]
        }
      }
    }
  })
