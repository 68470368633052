import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { isIOS } from 'mobile-device-detect'

import { SvgIcon } from 'packages/iconic'
import { colors, truncateTextWithEllipsis } from 'packages/styles'

import { Card } from 'app/components/core'
import { type Reservation } from 'app/store/reservations'
import { gwText } from 'app/styles'

/* eslint-disable @typescript-eslint/naming-convention */
const bgColorMap: Record<Reservation['bookingType'], string> = {
  guest_reservation: '#5a2458',
  owner_hold: '#8c9711',
  unknown: colors.yellow,
  vacasa_hold: colors.yellow,
}
/* eslint-enable @typescript-eslint/naming-convention */

const cardSizingFix = isIOS
  ? css`
      word-break: break-word;
    `
  : css`
      overflow-wrap: anywhere;
    `

export const Container = styled(Card)`
  ${cardSizingFix};
  align-items: center;
  border-radius: 8px;
  box-shadow: 1px 1px 20px rgba(112, 117, 121, 0.2);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 16px;
`

export const CleanIcon = styled(SvgIcon)`
  &,
  span {
    ${truncateTextWithEllipsis};
    ${gwText.headingSmallCaps}
    color: ${colors.dusk};
  }

  svg {
    color: ${colors.dusk};
    flex-shrink: 0;
  }
`

export const DueDate = styled.div`
  ${gwText.bodyXs};
`

export const Date = styled.div<{ res?: Reservation }>`
  background-color: ${({ res }) =>
    res ? bgColorMap[res.bookingType] : `${colors.aspen50}`};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 40px;
  margin-right: 18px;
  width: 40px;
`

export const DetailsContainer = styled.div`
  align-items: center;
  display: flex;
`

export const Day = styled.div<{ res?: Reservation }>`
  color: ${({ res }) => (res ? `${colors.white}` : `${colors.midnight}`)};
  font-size: 21px;
  font-weight: 800;
  line-height: 16px;
  position: relative;
  text-align: center;
  top: 2px;
`

export const Month = styled.div<{ res?: Reservation }>`
  ${gwText.headingTinyCaps};
  color: ${({ res }) => (res ? `${colors.white}` : `${colors.midnight}`)};
  position: relative;
  text-align: center;
  top: 2px;
`

export const ChevronIcon = styled(SvgIcon)`
  color: ${colors.dusk};
`

export const DueDateBadgeContainer = styled.div`
  display: flex;
  margin-top: 4px;
`
