import styled from '@emotion/styled'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Drawer } from 'packages/common'
import { CloseButton } from 'packages/common/src/modals/components'
import { centerWithFlex } from 'packages/styles'

import { getReservationById } from 'app/store/reservations/selectors'
import { type ApplicationState } from 'app/store/store'
import { UnknownUnit } from 'app/store/units'
import { getUnitById } from 'app/store/units/selectors/getUnitById'

import { ResDrawerContent } from './ResDrawerContent'

const St = {
  CloseButton: styled(CloseButton)`
    position: static;
  `,
  Drawer: styled(Drawer)`
    width: min(480px, 100%);

    & > button:first-of-type {
      // ensure the absolute-position button stays on top of relative-positioned tab content
      z-index: 2;
    }
  `,
  HeaderControls: styled.div`
    ${centerWithFlex};
    border-bottom: 1px solid #ced8de;
    display: flex;
    height: 40px;
    justify-content: flex-end;
    margin-bottom: 16px;
    margin-right: auto;
    padding: 0 16px;
    width: 100%;
  `,
}

export interface ResDrawerContainerProps {
  reservationId: string
}

export const ResDrawerContainer: React.FC<ResDrawerContainerProps> = React.memo(
  ({ reservationId }) => {
    if (!reservationId) return null
    const navigate = useNavigate()

    const res = useSelector((state: ApplicationState) =>
      getReservationById(state, reservationId),
    )

    // if we have a res ID but no res is found, we are here by mistake; go back to main cleans page
    React.useEffect(() => {
      if (reservationId && !res) {
        navigate('/cleans')
      }
    }, [navigate, res, reservationId])

    if (!res) return null

    const unit = useSelector((state: ApplicationState) =>
      getUnitById(state, res.unitId),
    )

    const [isDrawerForceClosed, setIsDrawerForceClosed] = React.useState(false)
    const forceCloseDrawer = () => {
      setIsDrawerForceClosed(true)
    }

    const handleAfterExit = React.useCallback(() => {
      setIsDrawerForceClosed(false)
      navigate('/cleans')
    }, [navigate])

    return (
      <St.Drawer
        afterExit={handleAfterExit}
        forceClose={isDrawerForceClosed}
        hideCloseButton={true}
        isOpen={!!res}
      >
        <St.HeaderControls>
          <St.CloseButton onClick={forceCloseDrawer} />
        </St.HeaderControls>
        <ResDrawerContent res={res} unit={unit || UnknownUnit} />
      </St.Drawer>
    )
  },
)
