import { noop } from 'lodash/fp'
import React from 'react'
import { useSelector } from 'react-redux'

import { useFormFns } from 'packages/common'
import { IconName } from 'packages/iconic'
import { createDateObject, startOfDay } from 'packages/utils/dateHelpers'
import { useAsyncFnWithReset } from 'packages/utils/hooks'

import { Slugs, useI18n } from 'app/i18n'
import { UnknownUnit } from 'app/store/units'
import { getSelectedUnit } from 'app/store/units/selectors/getSelectedUnit'

import { LockCodeFormSuccess } from '../LockCodeFormSuccess'
import {
  LockCodeForm,
  type LockCodeFormProps,
  type LockCodeFormValues,
} from './LockCodeForm'
import * as St from './LockCodeForm.container.styles'
import { fetchAdHocLockCode } from './LockCodeForm.helpers'

const useTranslations = () => {
  const { t } = useI18n()

  return {
    lockCodeFormBody: t(Slugs.lockCodeFormBody),
    lockCodeGenerator: t(Slugs.lockCodeGenerator),
  }
}

export interface LockCodeFormContainerProps {
  accessToken: string
}

const initialFormValues: LockCodeFormValues = {
  endDate: undefined,
  endTime: undefined,
  startDate: startOfDay(createDateObject()),
  startTime: undefined,
}

export const LockCodeFormContainer: React.FC<LockCodeFormContainerProps> =
  React.memo(({ accessToken }) => {
    const strings = useTranslations()

    const selectedUnit = useSelector(getSelectedUnit) || UnknownUnit

    const { id: unitId, address: unitAddress } = selectedUnit

    const { formValues, setValueByKey } =
      useFormFns<LockCodeFormValues>(initialFormValues)

    const [getAdHocLockCodeState, getAdHocLockCodeFn] =
      useAsyncFnWithReset(async () => {
        const { endDate, endTime, startDate, startTime } = formValues

        // this ensures no undefined form values are passed to fetchAdHocLockCode
        // endTime and startTime need explicit checks since a possible value for them is zero (falsey)
        // to get around this we need to directly check if endTime or startTime are undefined
        if (
          !endDate ||
          typeof endTime === 'undefined' ||
          !startDate ||
          typeof startTime === 'undefined'
        ) {
          return
        }

        return await fetchAdHocLockCode({
          accessToken,
          endDate,
          endTime,
          startDate,
          startTime,
          unitId,
        })
      }, [accessToken, unitId, formValues])

    const formState: LockCodeFormProps['formState'] = React.useMemo(
      () => ({
        canSubmit: Object.values(formValues).every(Boolean),
        values: formValues,
      }),
      [formValues],
    )

    const handleSubmit: React.FormEventHandler = React.useCallback(
      event => {
        event.preventDefault()
        getAdHocLockCodeFn()
      },
      [getAdHocLockCodeFn],
    )

    const handlers: LockCodeFormProps['handlers'] = React.useMemo(
      () => ({
        cancel: noop,
        change: noop,
        onDateChange: setValueByKey,
        onTimeChange: setValueByKey,
        submit: handleSubmit,
      }),
      [handleSubmit, setValueByKey],
    )

    return getAdHocLockCodeState.value?.accessCode ? (
      <LockCodeFormSuccess
        lockCodeFormResponse={getAdHocLockCodeState.value}
        unitAddress={unitAddress}
      />
    ) : (
      <>
        <St.LockCodeCardSection>
          <St.LockCodeIconBackground>
            <St.LockCodeIcon icon={IconName.lock} size={24} />
          </St.LockCodeIconBackground>
          <St.CardHeading>{strings.lockCodeGenerator}</St.CardHeading>
        </St.LockCodeCardSection>

        <St.LockCodeCardSection>
          <St.CardBody>{strings.lockCodeFormBody}</St.CardBody>
        </St.LockCodeCardSection>
        <LockCodeForm
          formState={formState}
          handlers={handlers}
          requestState={getAdHocLockCodeState}
        />
      </>
    )
  })
