import styled from '@emotion/styled'
import React from 'react'
import type { AriaButtonProps } from 'react-aria'
import { useButton } from 'react-aria'
import { useSelector } from 'react-redux'

import { IconName, SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

import { getSelectedUnit } from 'app/store/units/selectors/getSelectedUnit'
import { gwText } from 'app/styles'

import { UnitImageThumbnail } from '../UnitImageThumbnail'

export type UnitSelectButtonProps = AriaButtonProps & {
  dataTestId?: string
  children?: React.ReactNode
}

const St = {
  Button: styled.button<{ selectedUnit }>`
    align-items: center;
    background-color: ${colors.white};
    border: 1px solid ${colors.midnight10};
    border-radius: 40px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    width: ${({ selectedUnit }) => (selectedUnit ? '100%' : '90px')};
    padding: 0;
  `,
  ChevronIcon: styled(SvgIcon)`
    color: ${colors.midnight70};
    position: relative;
    transform: rotate(90deg);
    top: 1px;
  `,
  Children: styled.div`
    ${gwText.bodySmallBold};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  LeftIconContainer: styled.div`
    margin: 8px 10px 8px 10px;
  `,
  RightIconContainer: styled.div`
    align-items: center;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    margin: 8px 10px 8px 10px;
    min-height: 24px;
    min-width: 24px;
  `,
}

export const UnitSelectButton: React.FC<UnitSelectButtonProps> = React.memo(
  props => {
    const { children, dataTestId } = props

    const selectedUnit = useSelector(getSelectedUnit)
    const imageUrl = selectedUnit?.primaryImageId
      ? `https://vacasa-units.imgix.net/${selectedUnit?.primaryImageId}.jpg?w=50`
      : ''

    const ref = React.useRef(null)
    const { buttonProps } = useButton(props, ref)

    return (
      <St.Button
        {...buttonProps}
        data-testid={dataTestId}
        selectedUnit={selectedUnit}
      >
        <St.LeftIconContainer>
          <UnitImageThumbnail imageUrl={imageUrl} />
        </St.LeftIconContainer>
        <St.Children>{children}</St.Children>
        <St.RightIconContainer>
          <St.ChevronIcon icon={IconName.chevronRight} size={20} />
        </St.RightIconContainer>
      </St.Button>
    )
  },
)
