import React from 'react'

import { type ModalChildrenProps, useModalContext } from 'packages/common'

import { DatePicker, type DatePickerProps } from './DatePicker'

export type DatePickerModalProps = DatePickerProps

interface UseDatePickerModal {
  showDatePickerModal: () => void
}

export const useDatePickerModal = (
  props: DatePickerModalProps,
): UseDatePickerModal => {
  const { disableAfterDate, disableBeforeDate, onDateChange, selectedDate } =
    props
  const { showModal } = useModalContext()

  // in addition to firing the 'onDateChange' prop, we also need to close the modal
  const handleDateChange = React.useCallback(
    (date: Date | undefined, beginClose: ModalChildrenProps['beginClose']) => {
      onDateChange(date)
      beginClose()
    },
    [onDateChange],
  )

  const showDatePickerModal = React.useCallback(() => {
    showModal({
      childRenderer: ({ beginClose }) => (
        <DatePicker
          disableAfterDate={disableAfterDate}
          disableBeforeDate={disableBeforeDate}
          onDateChange={date => {
            handleDateChange(date, beginClose)
          }}
          selectedDate={selectedDate}
        />
      ),
    })
  }, [
    handleDateChange,
    selectedDate,
    showModal,
    disableAfterDate,
    disableBeforeDate,
  ])

  return {
    showDatePickerModal,
  }
}
