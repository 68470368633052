import React, { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useMount } from 'react-use'

import { Loader } from 'packages/common'
import { useAsyncFnWithReset } from 'packages/utils/hooks'

import { useGwToast } from 'app/components/core/hooks'
import { Slugs, useI18n } from 'app/i18n'
import { getAppInitState } from 'app/store/app/selectors'
import { fetchTickets } from 'app/store/tickets/actions'
import {
  getLastFetch,
  getCompletedTickets,
  getNonCompletedTickets,
} from 'app/store/tickets/selectors'
import { getSelectedUnit } from 'app/store/units/selectors/getSelectedUnit'
import { useActiveUser } from 'app/utils/hooks'

import { TicketsPage } from './TicketsPage'
import { type UnknownAction } from '@reduxjs/toolkit'

export const TicketsPageContainer: React.FC = React.memo(() => {
  const { t } = useI18n()
  const dispatch = useDispatch()
  const { showGwToast } = useGwToast()
  const { user } = useActiveUser()

  const selectedUnit = useSelector(getSelectedUnit)

  const appInitState = useSelector(getAppInitState)
  const appIsLoading = !['ready', 'error'].includes(appInitState)

  const completedTickets = useSelector(getCompletedTickets)
  const nonCompletedTickets = useSelector(getNonCompletedTickets)
  const tickets = useMemo(
    () =>
      [...nonCompletedTickets, ...completedTickets].filter(
        ticket => ticket.unit.id === selectedUnit?.id,
      ),
    [nonCompletedTickets, completedTickets, selectedUnit?.id],
  )

  const lastFetch = useSelector(getLastFetch)

  const [fetchTicketsState, fetchTicketsFn] = useAsyncFnWithReset(
    async () => {
      if (!user || user?.isSuperuser) return
      return dispatch(fetchTickets() as unknown as UnknownAction)
    },
    [dispatch, user],
    { loading: true },
  )

  useMount(fetchTicketsFn)

  // show an error toast for any errors from re-fetching tickets
  React.useEffect(() => {
    if (fetchTicketsState.error) {
      showGwToast({
        message: t(Slugs.anErrorOccurredLong),
        toastType: 'danger',
      })
    }
  }, [fetchTicketsState.error, showGwToast, t])

  const isLoading = appIsLoading || fetchTicketsState.loading

  return isLoading ? (
    <Loader />
  ) : (
    <TicketsPage
      lastFetch={lastFetch}
      onRefetch={fetchTicketsFn}
      tickets={tickets}
      unit={selectedUnit}
    />
  )
})
