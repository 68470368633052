import { type RequestOptions } from 'packages/utils/store/jsonapi.types'

import { api } from '../axiosInstance'
import {
  type GwUnitStaffersApiResponse,
  type GwUnitStaffersServiceResponse,
} from './gwUnitStaffers.types'
import { emptyGwUnitStaffersResponse } from './gwUnitStaffers.utils'

const URL = '/gw_unit_staffers'

export const gwUnitStaffersService = {
  async createGwUnitStaffer(
    data: RequestOptions = {},
  ): GwUnitStaffersServiceResponse {
    const response: GwUnitStaffersApiResponse = await api.post(URL, data)
    return response?.data || emptyGwUnitStaffersResponse
  },

  async deleteGwUnitStaffer(id: string): GwUnitStaffersServiceResponse {
    const response: GwUnitStaffersApiResponse = await api.delete(`${URL}/${id}`)
    return response?.data || emptyGwUnitStaffersResponse
  },

  async fetchGwUnitStafferById(
    stafferId: string,
    params: RequestOptions = {},
  ): GwUnitStaffersServiceResponse {
    const response: GwUnitStaffersApiResponse = await api.get(
      `${URL}/${stafferId}`,
      {
        params,
      },
    )
    return response?.data || emptyGwUnitStaffersResponse
  },

  async updateGwUnitStaffer(
    id: string,
    requestOptions: RequestOptions,
  ): GwUnitStaffersServiceResponse {
    const response: GwUnitStaffersApiResponse = await api.patch(
      `${URL}/${id}`,
      requestOptions,
    )
    return response?.data || emptyGwUnitStaffersResponse
  },
}
