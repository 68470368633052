import { filter, groupBy, map, pipe, values } from 'lodash/fp'
import { createSelector } from 'reselect'

import {
  DateFormat,
  format,
  isAfter,
  isBefore,
} from 'packages/utils/dateHelpers'

import { type ApplicationState } from '../../store'
import { type RawReservation, type Reservation } from '../reservations.types'
import { hydrateRawRes } from '../reservations.utils'

const dateKey = (res: Reservation) => {
  return format(res?.checkIn, DateFormat.YearFull)
}

const filterResByDateRange = (
  rawRes: RawReservation,
  startDate: string | Date,
  endDate: string | Date,
  selectedUnitId: string,
) => {
  return (
    rawRes?.relationships?.unit?.data?.id === selectedUnitId &&
    !isBefore(rawRes?.attributes?.checkIn, startDate) &&
    !isAfter(rawRes?.attributes?.checkIn, endDate)
  )
}

export const getReservationsBuckets = createSelector(
  (state: ApplicationState) => state.reservations,
  (_state, startDate: string | Date) => startDate,
  (_state, _startDate, endDate: string | Date) => endDate,
  (_state, _startDate, _endDate, selectedUnitId: string) => selectedUnitId,
  (
    reservationsState,
    startDate,
    endDate,
    selectedUnitId,
  ): Record<string, Reservation[]> => {
    return pipe(
      values,
      filter((rawRes: RawReservation) =>
        filterResByDateRange(rawRes, startDate, endDate, selectedUnitId),
      ),
      map(hydrateRawRes),
      groupBy(dateKey),
    )(reservationsState?.data)
  },
)
