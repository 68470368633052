import classNames from 'classnames'
import * as React from 'react'

import { type IconName } from '../../icons/iconMap'

import styles from './SvgIcon.module.scss'

const DEFAULT_ICON_SIZE = 12
const DEFAULT_TEXT_SIZE = 12
const DEFAULT_STYLES: React.CSSProperties = {}

export enum IconTextPosition {
  Left = 'iconTextLeft',
  Right = 'iconTextRight',
}

export interface SvgIconProps {
  /**
   * (Optional) Whether the icon/text should be center-aligned, Flex-wise.
   * If false or unspecified, `baseline` will be used for alignment.
   */
  centerItems?: boolean
  /**
   * (Optional) Additional class (name) to apply to the icon's main wrapper element.
   */
  className?: string
  /** (Optional) String to apply as `data-testid` attribute */
  dataTestId?: string
  height?: number
  /**
   * The name of the icon to display. Use the `IconName` enum to set this value.
   */
  icon: IconName
  /**
   * (Optional) Callback for clicking on the icon. Note that, if a value is supplied here,
   * the icon will automatically receive `cursor: pointer` style, so you do not need to specify it.
   */
  onClick?: () => void
  /**
   * The size of the icon to be displayed. This translates directly to the `height/width` settings for the `svg` element
   */
  size?: number
  /**
   * (Optional) Raw style object to directly apply additional styles. Note that this gets applied
   * _directly to the `<svg>` element itself_, and not the wrapper. If you need to apply classes to the
   * wrapper, you can do so with the `className` prop.
   */
  style?: React.CSSProperties
  /**
   * (Optional) Text to be displayed next to the icon. Note the additional props that allow some customization of this.
   */
  text?: string
  /**
   * (Optional) Font size value for any text have specified to be rendered with the icon.
   * Can be a number, which will be used as a `px` value, or a string, if you wish
   * to pass a value like `.75rem`. (Default: `12px`)
   */
  textFontSize?: number | string
  /**
   * (Optional) When rendering text with an icon, you can use this setting to specify if
   * it should be on the left or right side of the icon. Use the `IconTextPosition` enum
   * to set this value. (Default: `IconTextPosition.Right`)
   */
  textPosition?: IconTextPosition
  /**
   * (Optional) Override styles to apply directly to the optional text element.
   *
   * Note that the `textFontSize` prop will always take precedence over a `font-size` property
   * when it is defined, so for the sake of clarity, it is generally recommended to only one
   * of these props at a time.
   */
  textStyleOverrides?: React.CSSProperties
  width?: number
}

const SvgIcon: React.FunctionComponent<SvgIconProps> = ({
  centerItems = false,
  className = '',
  dataTestId,
  height,
  icon,
  onClick,
  size = DEFAULT_ICON_SIZE,
  style = DEFAULT_STYLES,
  text,
  textFontSize,
  textPosition = IconTextPosition.Right,
  textStyleOverrides = DEFAULT_STYLES,
  width,
}) => {
  const textStyles = React.useMemo(() => {
    // directly setting "textFontSize" will take top precedence, then style overrides object, then fallback/default
    const fontSize =
      textFontSize || textStyleOverrides.fontSize || DEFAULT_TEXT_SIZE

    return { ...textStyleOverrides, fontSize }
  }, [textFontSize, textStyleOverrides])

  return (
    <div
      className={classNames(className, styles.icon, {
        [styles.centered]: centerItems,
        [styles.clickable]: !!onClick,
        [styles[textPosition]]: !!text,
      })}
      onClick={onClick}
      data-testid={dataTestId}
    >
      <svg height={height ?? size} style={style} width={width ?? size}>
        <use href={`#${icon}`} />
      </svg>

      {text && (
        <span className={classNames(styles.text)} style={textStyles}>
          {text}
        </span>
      )}
    </div>
  )
}

/**
 * The primary rendering component for use with icons generated by Iconic. To use,
 * you _must_ mount the main `Iconic` component in the app's top-level component, as
 * this exposes all of the icons as SVG Symbols.
 *
 * Then, you can render them anywhere you need to by using this component and specifying
 * the icon it should render with the `icon` prop.
 */
export default React.memo(SvgIcon)
