import { upperFirst } from 'lodash/fp'
import React from 'react'

import { IconName } from 'packages/iconic'
import { DateFormat, formatLocalized } from 'packages/utils/dateHelpers'

import { Slugs, useI18n } from 'app/i18n'
import { type Clean } from 'app/store/cleans'

import { ServiceTypeBadge } from '../CleanStatusBadges/ServiceTypeBadge'
import * as St from './NewCleanCard.styles'

export enum NewCleanCardTestIds {
  container = 'NewCleanCard__container',
}

export interface NewCleanCardProps {
  clean: Clean
  onClick: (cleanId: string) => void
}

export const NewCleanCard: React.FC<NewCleanCardProps> = React.memo(
  ({ clean, onClick }) => {
    const { t } = useI18n()

    const day = formatLocalized(clean.effectiveDate, DateFormat.DayOfMonthShort)
    const month = formatLocalized(
      clean.effectiveDate,
      DateFormat.MonthNameShort,
    )
    const dueDateString = formatLocalized(
      clean.dueDate,
      DateFormat.TimeWithAmPm,
    )

    const handleClick = React.useCallback(() => {
      onClick(clean.id)
    }, [clean.id, onClick])

    return (
      <St.Container
        dataTestId={NewCleanCardTestIds.container}
        onClick={handleClick}
      >
        <St.DetailsContainer>
          <St.Date>
            <St.Day>{day}</St.Day>
            <St.Month>{month}</St.Month>
          </St.Date>
          <div>
            <St.CleanIcon
              centerItems={true}
              icon={IconName.doorClose}
              text={t(Slugs.postStayClean)}
              textFontSize={'inherit'}
              textStyleOverrides={{ marginLeft: 12 }}
              size={16}
            />

            <St.DueDateBadgeContainer>
              <St.DueDate>
                {upperFirst(t(Slugs.due))}: {dueDateString}
              </St.DueDate>
              <ServiceTypeBadge clean={clean} />
            </St.DueDateBadgeContainer>
          </div>
        </St.DetailsContainer>
        <St.ChevronIcon icon={IconName.chevronRight} size={22} />
      </St.Container>
    )
  },
)
