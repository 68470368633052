interface WithId {
  id: string
}

/**
 * Default comparator to use when none is provided.
 * Simply compares the IDs of the two objects.
 *
 * @param a
 * @param b
 */
export const defaultValueComparator = (a: WithId, b: WithId): boolean => {
  if (!a.id || !b.id) {
    throw new Error(
      'Values do not have ID fields, and thus cannot use the default comparator. Please provide a custom comparator.',
    )
  }

  return a.id === b.id
}
