import { type AxiosResponse } from 'axios'
import { map, pipe, reject, snakeCase } from 'lodash/fp'

import { type OfflinePatchData } from 'packages/offline'
import { flippedIncludes } from 'packages/utils/misc/array.helpers'

import {
  type JSONApiObjectMap,
  type JSONApiObject,
  type NormalizedJSONApiResponse,
  type RequestConfig,
} from '../utils'

/**********************************************************
 * SERVICE TYPES
 *********************************************************/
export interface OtherTimersResponse {
  // TODO: rename to other timer when API ready
  taskTime: JSONApiOtherTimerMap
}

export interface OtherTimersPostData {
  isFinalized?: boolean
  notes?: string
  startedAt: Date
  stoppedAt?: Date
  subTimerType?: OtherTimerSubType
  timerType: OtherTimerType
  userId: string
}

export interface OtherTimerPostApiAttributes {
  is_finalized?: boolean
  notes?: string
  started_at: string
  stopped_at?: string
  // TODO: rename to other timer when API ready
  sub_task_type?: OtherTimerSubType
  task_type: OtherTimerType
}

export type OtherTimePatchData = {
  id: string
} & OtherTimePatchAttributes

interface StaticOtherTimePatchData {
  isFinalized?: boolean
  mileage?: number
  notes?: string
  startedOffline?: boolean
}

export type OtherTimePatchAttributes = {
  startedAt?: Date | null
  stoppedAt?: Date | null
} & StaticOtherTimePatchData

export type OtherTimeOfflinePatchData = {
  startedAt?: string | null
  stoppedAt?: string | null
} & StaticOtherTimePatchData

export interface OtherTimePatchApiAttributes {
  is_finalized?: boolean
  mileage?: number
  notes?: string
  started_at?: string | null
  stopped_at?: string | null
}

export type NormalizedOtherTimersApiResponse =
  NormalizedJSONApiResponse<OtherTimersResponse>
export type OtherTimeRequestConfig =
  RequestConfig<NormalizedOtherTimersApiResponse>
export type OtherTimersServiceResponse =
  Promise<NormalizedOtherTimersApiResponse>
export type OtherTimersApiResponse =
  AxiosResponse<NormalizedOtherTimersApiResponse>

/**********************************************************
 * OTHER TIME
 *********************************************************/
export type OtherTimer = {
  id: string
} & OtherTimeAttributes

export enum OtherTimerType {
  DRIVE = 'drive',
  LAUNDRY = 'laundry',
  OTHER = 'other',
  TRAINING = 'training',
}

type ExtractSubMenuType<A> = A extends
  | OtherTimerType.DRIVE
  | OtherTimerType.OTHER
  ? A
  : never

export type SubMenuType = ExtractSubMenuType<OtherTimerType>

export type OtherTimerSubTypes =
  | 'admin'
  | 'scheduling'
  | 'office_clean'
  | 'employee_meeting'

export type DriveTimerSubTypes =
  | 'clean'
  | 'maintenance'
  | 'supplies'
  | 'laundry'
  | 'inspection'

export type OtherTimerSubType = OtherTimerSubTypes | DriveTimerSubTypes

export interface OtherTimeAttributes {
  isFinalized: boolean
  notes?: string
  startedAt: string
  startedOffline?: boolean
  stoppedAt: string | null
  subTimerType?: OtherTimerSubType
  timerType: OtherTimerType
}

export const OtherTimerAttributeNames = [
  'isFinalized',
  'notes',
  'startedAt',
  'stoppedAt',
  'subTaskType',
  'taskType',
  'startedOffline',
]

/**
Attributes that we don't or can't use in requests that we remove below:
- API does not accept "notes" as a field request, but we do still need it 
for offline timers storage
- StartedOffline is an attribute that only exists locally
*/
const localAttributes = ['notes', 'startedOffline']

export const OtherTimeApiFields = pipe(
  reject(flippedIncludes<string>(localAttributes)),
  map(snakeCase),
)(OtherTimerAttributeNames)

export type OfflineOtherTimer = OfflinePatchData<OtherTimeOfflinePatchData>
export type RawOtherTime = JSONApiObject<OtherTimeAttributes>

export type JSONApiOtherTimerMap = JSONApiObjectMap<OtherTimeAttributes>
