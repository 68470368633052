/* eslint-disable no-console */
import { handleMissingSegmentAnalytics } from '../errorReporting'
import { type AnalyticsPayload } from '../track'

// https://segment.com/docs/connections/spec/common/#context
interface SegmentAppContext {
  build: string
  name: string
  version: string
}

// add app name and version to Segment's "context" block
// this is normally added automatically by Segment, but it apparently doesn't work for the web/JS version
const version = process.env.REACT_APP_RELEASE ?? 'unavailable'
const name = 'guestworks'
const appContext: SegmentAppContext = {
  build: version,
  name,
  version,
}

export function getAnalytics(): SegmentAnalytics.AnalyticsJS {
  const analytics = window.analytics
  if (!analytics) {
    console.error('Segment has not been instantiated')
  }

  return analytics
}

export function trackOnSegment(
  event: string,
  payload: AnalyticsPayload,
  debug = false,
): void {
  if (debug) {
    console.info('Sending Segment event with payload:')
    console.info({ event, payload })
  }

  const analytics = getAnalytics()

  if (analytics) {
    analytics.track(event, payload, {
      context: {
        app: appContext,
      },
    })
  } else {
    handleMissingSegmentAnalytics(event, payload)
  }
}
