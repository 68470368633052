import { noop } from 'lodash/fp'
import { createAsyncAction } from 'typesafe-actions'

import { type ReduxActionCallbacks } from 'packages/grimoire/src/utils'
import {
  type RequestConfig,
  type RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { gwUnitStaffersService } from '../gwUnitStaffers.service'
import { trackGwUnitStafferUpdated } from '../gwUnitStaffers.trackers'
import {
  type GwUnitStafferPatchAttributes,
  type GwUnitStafferPatchData,
  GwUnitStaffersActionTypes,
  type NormalizedGwUnitStaffersApiResponse,
} from '../gwUnitStaffers.types'
import { fetchGwUnitStafferById } from './fetchGwUnitStafferById'

export const updateGwUnitStafferAction = createAsyncAction(
  GwUnitStaffersActionTypes.UPDATE_GW_UNIT_STAFFER,
  GwUnitStaffersActionTypes.UPDATE_GW_UNIT_STAFFER_SUCCESS,
  GwUnitStaffersActionTypes.UPDATE_GW_UNIT_STAFFER_FAILURE,
)<
  RequestConfig<NormalizedGwUnitStaffersApiResponse>,
  NormalizedGwUnitStaffersApiResponse,
  Error
>()

export const buildRequestData = (
  patchData: GwUnitStafferPatchData,
): RequestOptions => {
  const { email, name, phone } = patchData
  const attributes: Partial<GwUnitStafferPatchAttributes> = {
    email,
    name,
    phone,
  }

  return {
    data: {
      attributes,
      id: patchData.id,
      type: 'gw_unit_staffer',
    },
  }
}

export const updateGwUnitStaffer =
  (patchData: GwUnitStafferPatchData, callbacks: ReduxActionCallbacks = {}) =>
  async (dispatch, getState) => {
    const { onError = noop, onSuccess = noop } = callbacks
    try {
      const gwUnitStafferId = patchData.id
      const requestData = buildRequestData(patchData)
      const request = gwUnitStaffersService.updateGwUnitStaffer.bind(
        null,
        gwUnitStafferId,
        requestData,
      )
      const result = await dispatch(
        updateGwUnitStafferAction.request({ request }),
      )
      dispatch(updateGwUnitStafferAction.success(result))
      trackGwUnitStafferUpdated(patchData, getState)

      await dispatch(fetchGwUnitStafferById(gwUnitStafferId))

      onSuccess()
      return result.normalized
    } catch (error) {
      dispatch(updateGwUnitStafferAction.failure(error))
      onError()
      throw error
    }
  }
