import { configureStore } from '@reduxjs/toolkit'
import { type StateType } from 'typesafe-actions'

import { getIdpAuthMiddleware } from 'packages/auth'
import { sentryReduxEnhancer } from 'packages/common'

import { setNeedsFullAuthRedirect, setNeedsSilentRefresh } from './auth/actions'
import { getAccessToken, getNeedsSilentRefresh } from './auth/selectors'
import { setRequestHeader } from './axiosInstance'
import { rootReducer } from './reducers'

export type ApplicationState = StateType<typeof rootReducer>

const authMiddleware = getIdpAuthMiddleware({
  getAccessToken,
  getNeedsSilentRefresh,
  setNeedsFullAuthRedirect,
  setNeedsSilentRefresh,
  setRequestHeader,
})

/* eslint-disable sort-keys */
export default configureStore({
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ['payload.request'],
      },
    }).concat(authMiddleware),
  enhancers: getDefaultEnhancers =>
    getDefaultEnhancers().concat(sentryReduxEnhancer),
  reducer: rootReducer,
})
/* eslint-enable sort-keys */
