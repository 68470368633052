import { createAsyncAction } from 'typesafe-actions'

import { DateFormat, format } from 'packages/utils/dateHelpers'
import {
  type RequestConfig,
  type RequestOptions,
} from 'packages/utils/store/jsonapi.types'
import { logInfo } from 'packages/wiretap/logging'

import { FETCH_CLEANS_RESERVATIONS_MAX_SIZE } from 'app/Guestworks.constants'
import { type ApplicationState } from 'app/store/store'

import { HousekeeperApiFields } from '../../housekeepers'
import { LockboxApiFields } from '../../lockboxes'
import { CleanReservationApiFields } from '../../reservations'
import { UserApiFields } from '../../users'
import { cleansService } from '../cleans.service'
import {
  CleanApiFields,
  CleansActionTypes,
  type NormalizedCleansApiResponse,
} from '../cleans.types'

const logErrorToSentry = (getState: () => ApplicationState): void => {
  const { activeUserId = 'unknown', authUserId = 'unknown' } =
    getState().users || {}

  logInfo('"Fetch Cleans" call exceeded max page size', {
    requestData: {
      activeUserId,
      maxPageSize: FETCH_CLEANS_RESERVATIONS_MAX_SIZE,
      userId: authUserId,
    },
  })
}

export const fetchCleansAction = createAsyncAction(
  CleansActionTypes.FETCH_CLEANS,
  CleansActionTypes.FETCH_CLEANS_SUCCESS,
  CleansActionTypes.FETCH_CLEANS_FAILURE,
)<
  RequestConfig<NormalizedCleansApiResponse>,
  NormalizedCleansApiResponse,
  Error
>()

export const getDateRange = (startDate: string, endDate?: string) =>
  endDate
    ? {
        range: [
          format(startDate, DateFormat.ApiUtcShort),
          format(endDate, DateFormat.ApiUtcShort),
        ],
      }
    : format(startDate, DateFormat.ApiUtcShort)

export const getParams = (
  dateRange: string | { range: string[] },
): RequestOptions => {
  return {
    fields: {
      assignment: [],
      clean: CleanApiFields,
      housekeeper: HousekeeperApiFields,
      lock_box: LockboxApiFields,
      reservation: CleanReservationApiFields,
      unit: [],
      user: UserApiFields,
    },
    filter: {
      effective_date: dateRange,
      is_active: true,
    },
    include: [
      'active_assignments',
      'active_assignments.housekeeper',
      'active_assignments.housekeeper.user',
      'reservation',
      'reservation.unit',
      'unit',
      'unit.lock_box',
    ],
    page: { size: FETCH_CLEANS_RESERVATIONS_MAX_SIZE },
    sort: ['effective_date'],
  }
}

export const fetchCleans =
  (startDate: string, endDate?: string) =>
  async (dispatch, getState: () => ApplicationState) => {
    try {
      const dateRange = getDateRange(startDate, endDate)
      const params = getParams(dateRange)
      const request = cleansService.fetchCleans.bind(null, params)
      const result: NormalizedCleansApiResponse = await dispatch(
        fetchCleansAction.request({ request }),
      )

      // if we fetched too many cleans, log it to Sentry
      const resultSize = Object.keys(result?.normalized?.clean || {}).length
      if (resultSize >= FETCH_CLEANS_RESERVATIONS_MAX_SIZE) {
        logErrorToSentry(getState)
      }

      dispatch(fetchCleansAction.success(result))
      return result.normalized
    } catch (error) {
      dispatch(fetchCleansAction.failure(error))
      throw error
    }
  }
