import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider as ReduxProvider } from 'react-redux'

import {
  initializeSentry,
  SentryContextProvider,
  SentryErrorBoundary,
} from 'packages/common/src/Sentry'
import { Iconic } from 'packages/iconic'
import { CssReset } from 'packages/styles'

import { Guestworks } from './Guestworks'
import store from './store/store'

import 'react-toastify/dist/ReactToastify.css'
import { initializeDatadogRum } from './initializeDatadogRum'

// import reportWebVitals from '../../reportWebVitals'

const sentryIsActive = initializeSentry()
initializeDatadogRum()

const root = ReactDOM.createRoot(document.getElementById('root')!)
root.render(
  <React.StrictMode>
    <SentryContextProvider value={{ sentryIsActive }}>
      <SentryErrorBoundary boundary="Guestworks__root">
        <Iconic />
        <CssReset />
        <ReduxProvider store={store}>
          <Guestworks />
        </ReduxProvider>
      </SentryErrorBoundary>
    </SentryContextProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
