import { type RequestOptions } from 'packages/utils/store/jsonapi.types'

import { api } from '../axiosInstance'
import {
  type DispositionApiResponse,
  type DispositionServiceResponse,
  type TicketsApiResponse,
  type TicketsServiceResponse,
} from './tickets.types'
import { emptyDispositionResponse, emptyTicketsResponse } from './tickets.utils'

const URL = '/tickets'
const URL_DISPOSITIONS = '/dispositions'

export async function fetchTickets(
  params: RequestOptions = {},
): TicketsServiceResponse {
  const response: TicketsApiResponse = await api.get(URL, {
    params,
  })

  return response?.data || emptyTicketsResponse
}

export async function fetchTicketById(
  id: string,
  params: RequestOptions = {},
): TicketsServiceResponse {
  const response: TicketsApiResponse = await api.get(`${URL}/${id}`, {
    params,
  })
  return response?.data || emptyTicketsResponse
}

export async function updateTicket(
  ticketId: string,
  requestOptions: RequestOptions,
): TicketsServiceResponse {
  const response: TicketsApiResponse = await api.patch(
    `${URL}/${ticketId}`,
    requestOptions,
  )

  return response?.data || emptyTicketsResponse
}

export async function fetchTicketingMaintenanceDispositions(
  params: RequestOptions = {},
): DispositionServiceResponse {
  const response: DispositionApiResponse = await api.get(URL_DISPOSITIONS, {
    params,
  })

  return response?.data || emptyDispositionResponse
}

export async function createTicket(
  data: RequestOptions,
): TicketsServiceResponse {
  const response: TicketsApiResponse = await api.post(URL, data)

  return response?.data || emptyTicketsResponse
}
