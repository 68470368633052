import styled from '@emotion/styled'
import React from 'react'
import type { AriaTextFieldOptions } from 'react-aria'
import { useTextField } from 'react-aria'

import { colors } from 'packages/styles'

import { gwShadows, gwText } from 'app/styles'

interface WithError {
  hasError?: boolean
}

const BottomLabel = styled.div`
  ${gwText.bodySmallSemibold};
  padding-left: 1px;
  margin-top: 8px;
  transition: color 100ms;
`

const St = {
  Description: styled(BottomLabel)`
    color: ${colors.dusk60};
  `,
  Error: styled(BottomLabel)`
    color: ${colors.alert};
  `,
  Label: styled.label<WithError>`
    ${gwText.headingXsCaps};
    color: ${({ hasError }) => (hasError ? colors.alert : colors.dusk)};
    display: flex;
    padding-left: 1px;
    transition: color 200ms;
  `,
  TextArea: styled.textarea<WithError>`
    ${gwText.bodyDefault};
    border-color: ${({ hasError }) =>
      hasError ? colors.alert : colors.midnight10};
    border-style: solid;
    border-radius: 8px;
    border-width: ${({ hasError }) => (hasError ? 2 : 1)}px;
    color: ${colors.dusk};
    padding: 16px 24px;
    transition: outline 50ms;
    width: 100%;

    &::placeholder {
      color: ${colors.dusk60};
      opacity: 0.5;
    }

    &:focus,
    &:active {
      box-shadow: ${({ hasError }) =>
        hasError ? gwShadows.alert : gwShadows.focus};
      outline: ${({ hasError }) =>
        hasError ? 0 : `4px solid ${colors.lake40}`};
    }
  `,
  TextAreaWrapper: styled.div`
    border: 0;
    display: flex;
    position: relative;
    width: 100%;

    label + & {
      margin-top: 8px;
    }
  `,
}

export enum TextAreaTestIds {
  description = 'TextArea__description',
  error = 'TextArea__error',
}

type RequiredAriaProps = Required<
  Pick<AriaTextFieldOptions<'textarea'>, 'label' | 'onChange'>
>

export type TextAreaProps = AriaTextFieldOptions<'input'> &
  RequiredAriaProps & {
    rowCount?: number
  }

/**
 * TextArea based primarily around react-aria's useTextField().
 * If you have questions, take a peek as these docs, as they cover a lot of implementation details:
 * https://react-spectrum.adobe.com/react-aria/useTextField.html
 */
export const TextArea: React.FC<TextAreaProps> = React.memo(props => {
  const { description, errorMessage, label, rowCount } = props

  const textAreaRef = React.useRef<HTMLTextAreaElement>(null)
  const { descriptionProps, errorMessageProps, inputProps, labelProps } =
    useTextField(
      {
        ...props,
        inputElementType: 'textarea',
        validationState: errorMessage ? 'invalid' : 'valid',
      },
      textAreaRef,
    )

  return (
    <div>
      <St.Label {...labelProps} hasError={!!errorMessage}>
        {label}
      </St.Label>

      <St.TextAreaWrapper>
        <St.TextArea
          {...inputProps}
          hasError={!!errorMessage}
          ref={textAreaRef}
          rows={rowCount}
        />
      </St.TextAreaWrapper>

      {!errorMessage && !!description && (
        <St.Description
          {...descriptionProps}
          data-testid={TextAreaTestIds.description}
        >
          {description}
        </St.Description>
      )}

      {!!errorMessage && (
        <St.Error {...errorMessageProps} data-testid={TextAreaTestIds.error}>
          {errorMessage as any}
        </St.Error>
      )}
    </div>
  )
})
