import styled from '@emotion/styled'
import { type Ref } from 'react'

import { layers, shadows } from 'packages/styles'
import { isInOpenPhase } from 'packages/utils/hooks'

import { isHidden, isRenderable, isRenderableAndShowing } from '../modals.utils'
import { type ModalPortalProps } from './ModalPortal'

type PickedModalPortalProps = Pick<
  ModalPortalProps,
  'duration' | 'transitionPhase'
>

type StyledModalProps = PickedModalPortalProps & {
  elementHeight: number
  elementWidth: number
  ref: Ref<HTMLDivElement>
}

export const StyledModal = styled.div<StyledModalProps>`
  background: white;
  border-radius: 4px;
  box-shadow: ${shadows.shadow4};
  box-sizing: border-box;
  left: 50%;
  max-height: 95vh;
  max-width: 95vw;
  min-width: min(90%, 320px);
  overflow: auto;
  padding: 32px;
  position: fixed;
  top: 0;
  will-change: opacity, transform;
  z-index: ${layers.modal};

  display: ${props => (isHidden(props) ? 'none' : 'inherit')};
  opacity: ${props => (isRenderableAndShowing(props) ? 1 : 0)};
  transition: ${({ duration }) =>
    `opacity ${duration}ms ease, transform ${duration}ms ease`};
  visibility: ${props => (isRenderable(props) ? 'visible' : 'hidden')};

  transform: ${({ elementHeight, transitionPhase }) => {
    if (elementHeight && isInOpenPhase(transitionPhase)) {
      return `translate(-50%, 64px)`
    }

    return `translate(-50%, 0)`
  }};
`

/**************************************************
 * Helper styling classes for Modal content
 **************************************************/

const ModalSection = styled.div`
  max-width: 320px;
`

export const ModalTitle = styled(ModalSection)`
  font-size: 18px;
  font-weight: 600;
  margin: 16px 0;
`

export const ModalContent = styled(ModalSection)`
  display: flex;
  flex-direction: column;
  font-size: 15px;
  height: 100%;
`

export const ModalButtons = styled(ModalSection)`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
`
