import { createAsyncAction } from 'typesafe-actions'

import { DateFormat, format } from 'packages/utils/dateHelpers'
import {
  type RequestConfig,
  type RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { FETCH_CLEANS_RESERVATIONS_MAX_SIZE } from 'app/Guestworks.constants'

import { reservationsService } from '../reservations.service'
import {
  type NormalizedReservationsApiResponse,
  ReservationApiFields,
  ReservationsActionTypes,
} from '../reservations.types'

export const fetchReservationsAction = createAsyncAction(
  ReservationsActionTypes.FETCH_RESERVATIONS,
  ReservationsActionTypes.FETCH_RESERVATIONS_SUCCESS,
  ReservationsActionTypes.FETCH_RESERVATIONS_FAILURE,
)<
  RequestConfig<NormalizedReservationsApiResponse>,
  NormalizedReservationsApiResponse,
  Error
>()

export const getParams = (
  startDate: string,
  endDate: string,
): RequestOptions => {
  return {
    fields: {
      reservation: ReservationApiFields,
      unit: [],
    },
    filter: {
      check_in: { gte: format(startDate, DateFormat.ApiUtcShort) },
      check_out: { lte: format(endDate, DateFormat.ApiUtcShort) },
      is_cancelled: false,
    },
    include: ['unit'],
    page: { size: FETCH_CLEANS_RESERVATIONS_MAX_SIZE },
  }
}

export const fetchReservations =
  (startDate: string, endDate: string) => async dispatch => {
    try {
      const params = getParams(startDate, endDate)
      const request = reservationsService.fetchReservations.bind(null, params)
      const result: NormalizedReservationsApiResponse = await dispatch(
        fetchReservationsAction.request({ request }),
      )

      dispatch(fetchReservationsAction.success(result))
      return result.normalized
    } catch (error) {
      dispatch(fetchReservationsAction.failure(error))
      throw error
    }
  }
