import { createSelector } from 'reselect'

import { type ApplicationState } from '../../store'
import { type Reservation } from '../reservations.types'
import { hydrateRawRes } from '../reservations.utils'

export const getReservationById = createSelector(
  (state: ApplicationState) => state.reservations,
  (_, id: string) => id,
  (reservationsState, id): Reservation | undefined => {
    const rawRes = reservationsState.data[id]
    return rawRes && hydrateRawRes(rawRes)
  },
)
