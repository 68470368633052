import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Loader, useConfirmModal } from 'packages/common'
import { useAsyncFnWithReset } from 'packages/utils/hooks'

import { useGwToast } from 'app/components/core/hooks'
import { useI18n, Slugs } from 'app/i18n'
import { getAppInitState } from 'app/store/app/selectors'
import { getGwLocalHostsByIds } from 'app/store/gwLocalhosts/selectors/getGwLocalhostsByIds'
import { deleteGwUnitStaffer } from 'app/store/gwUnitStaffers/actions'
import { getGwUnitStaffersByIds } from 'app/store/gwUnitStaffers/selectors/getGwUnitStaffersByIds'
import { type ApplicationState } from 'app/store/store'
import { UnknownUnit } from 'app/store/units'
import { getSelectedUnit } from 'app/store/units/selectors/getSelectedUnit'

import { MyUnitsPage } from './MyUnitsPage'
import { getStafferByType } from './MyUnitsPage.helpers'
import { type UnknownAction } from '@reduxjs/toolkit'

const useTranslations = () => {
  const { t } = useI18n()

  return {
    anErrorOccurred: t(Slugs.anErrorOccurred),
    primaryHousekeeperDeleteModalBody: t(Slugs.primaryHkDeleteModalBody),
    primaryHousekeeperDeleteModalTitle: t(Slugs.primaryHkDeleteModalTitle),
    primaryHousekeeperInfoDeleted: t(Slugs.primaryHkDeleted),
  }
}

export const MyUnitsPageContainer: React.FC = React.memo(() => {
  const strings = useTranslations()
  const { showGwToast } = useGwToast()
  const dispatch = useDispatch()

  const appInitState = useSelector(getAppInitState)
  const appIsLoading = !['ready', 'error'].includes(appInitState)
  const selectedUnit = useSelector(getSelectedUnit) || UnknownUnit

  const gwUnitStaffersIds = selectedUnit?.gwUnitStaffersIds || []
  const gwUnitStaffers = useSelector((state: ApplicationState) =>
    getGwUnitStaffersByIds(state, gwUnitStaffersIds),
  )

  const primaryHousekeeperStaffer = getStafferByType(
    gwUnitStaffers,
    'primary_housekeeper',
  )

  const gwLocalHostsIds = selectedUnit?.gwLocalHostsIds || []
  const gwLocalHosts = useSelector((state: ApplicationState) =>
    getGwLocalHostsByIds(state, gwLocalHostsIds),
  )

  const [deletePrimaryHkState, deletePrimaryHkFn] =
    useAsyncFnWithReset(async () => {
      if (!primaryHousekeeperStaffer) return

      return dispatch(
        deleteGwUnitStaffer(primaryHousekeeperStaffer.id, {
          onError: () => {
            showGwToast({
              message: strings.anErrorOccurred,
              toastType: 'danger',
            })
          },
          onSuccess: () => {
            showGwToast({
              message: strings.primaryHousekeeperInfoDeleted,
            })
          },
        }) as unknown as UnknownAction,
      )
    }, [
      dispatch,
      showGwToast,
      strings.anErrorOccurred,
      strings.primaryHousekeeperInfoDeleted,
      primaryHousekeeperStaffer,
    ])

  const { showModal } = useConfirmModal({
    onConfirm: deletePrimaryHkFn,
    slugs: {
      message: strings.primaryHousekeeperDeleteModalBody,
      title: strings.primaryHousekeeperDeleteModalTitle,
    },
  })

  const onDelete = React.useCallback(() => {
    showModal()
  }, [showModal])

  const { loading } = deletePrimaryHkState

  return appIsLoading ? (
    <Loader />
  ) : (
    <MyUnitsPage
      gwUnitStaffers={gwUnitStaffers}
      gwLocalHosts={gwLocalHosts}
      onDelete={onDelete}
      loading={loading}
    />
  )
})
