import * as Sentry from '@sentry/react'
import type * as React from 'react'

import { getAuthUserId } from 'packages/utils/misc'

export interface SentryOptions {
  /**
   * Optional "boundary" tag to add to the request.
   * In general, this is more useful when using Sentry as part of a React ErrorBoundary,
   * so we can specify which page/section the error came from.
   * In Sentry, this simply shows up as a "tag," and it is not used for any grouping.
   */
  boundary?: string
  customData?: Record<string, Record<string, unknown>>
  /**
   * Optional React ErrorBoundary data. This is specific to using Sentry as part of an ErrorBoundary.
   */
  errorInfo?: React.ErrorInfo
  /**
   * Optional grouping fingerprint. This can be used to override Sentry's default grouping of errors,
   * but in general, should only be used when we are 100% sure we want to force a given set of errors to be grouped together.
   */
  fingerprint?: string[]
  /**
   * Optional Sentry severity override setting. Only applies to messages (not exceptions), and it defaults to "info"
   * which will probably be appropriate in most cases.
   *
   * Available options can be found using the Sentry.Severity enum.
   */
  severity?: Sentry.SeverityLevel
}

type SentryRequestConfig = {
  data: Error | string
} & SentryOptions

/**
 * Generic helper function for sending data to Sentry.
 *
 * Note that this function is not exported outside of the package, as we should favor
 * using the more abstracted type-specific versions in the neighboring 'helpers' files.
 * @param options
 */
export const sendToSentry = (options: SentryRequestConfig): void => {
  const {
    boundary,
    data,
    errorInfo,
    fingerprint,
    severity = 'info',
    customData,
  } = options

  const authUserId = getAuthUserId()

  Sentry.withScope((scope: Sentry.Scope) => {
    // add online status tag for all events ("unknown" for safe legacy support)
    scope.setTag('online', `${navigator?.onLine ?? 'unknown'}`)
    scope.setUser({ id: authUserId || undefined })

    if (boundary) {
      scope.setTag('boundary', boundary)
    }

    if (errorInfo) {
      scope.setExtras({ errorInfo })
    }

    if (fingerprint) {
      scope.setFingerprint(fingerprint)
    }

    if (customData) {
      scope.setContext(Object.keys(customData)[0], Object.values(customData)[0])
    }

    if (typeof data === 'string') {
      Sentry.captureMessage(data, severity)
    } else if (data instanceof Error) {
      Sentry.captureException(data)
    }
  })
}
