import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { getReservationById } from 'app/store/reservations/selectors'
import { type ApplicationState } from 'app/store/store'

import { NewResCard } from './NewResCard'

export interface NewResCardContainerProps {
  reservationId: string
}

export const NewResCardContainer: React.FC<NewResCardContainerProps> =
  React.memo(({ reservationId }) => {
    const navigate = useNavigate()

    const res = useSelector((state: ApplicationState) =>
      getReservationById(state, reservationId),
    )

    const handleClick = React.useCallback((): void => {
      navigate(`/cleans?selectedRes=${reservationId}`)
    }, [navigate, reservationId])

    return res ? <NewResCard onClick={handleClick} res={res} /> : null
  })
