import { createSelector } from 'reselect'

import { type ApplicationState } from '../../store'
import { type Unit } from '../units.types'
import { hydrateRawUnit } from '../units.utils'
import { MissingDataError } from 'packages/utils/errors'

export const getUnitById = createSelector(
  (state: ApplicationState) => state.units,
  (_, unitId: string) => unitId,
  (unitsState, unitId): Unit => {
    const rawUnit = unitsState.data[unitId]
    if (!rawUnit) {
      throw new MissingDataError('unit', unitId)
    }
    return hydrateRawUnit(rawUnit)
  },
)
