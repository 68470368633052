import { filter, groupBy, map, pipe, values } from 'lodash/fp'
import { createSelector } from 'reselect'

import {
  DateFormat,
  format,
  isAfter,
  isBefore,
} from 'packages/utils/dateHelpers'

import { type ApplicationState } from '../../store'
import { type Clean, type RawClean } from '../cleans.types'
import { hydrateRawClean } from '../cleans.utils'

const dateKey = (clean: Clean) => {
  return format(clean?.effectiveDate, DateFormat.YearFull)
}

const filterCleans = (
  rawClean: RawClean,
  startDate: string | Date,
  endDate: string | Date,
  selectedUnitId: string,
) => {
  return (
    rawClean?.relationships?.unit?.data?.id === selectedUnitId &&
    !isBefore(rawClean?.attributes?.effectiveDate, startDate) &&
    !isAfter(rawClean?.attributes?.effectiveDate, endDate)
  )
}

export const getCleansBuckets = createSelector(
  (state: ApplicationState) => state,
  (_state, startDate: string | Date) => startDate,
  (_state, _startDate, endDate: string | Date) => endDate,
  (_state, _startDate, _endDate, selectedUnitId: string) => selectedUnitId,
  (state, startDate, endDate, selectedUnitId): Record<string, Clean[]> => {
    const hydrator = hydrateRawClean(state)

    return pipe(
      values,
      filter((rawClean: RawClean) =>
        filterCleans(rawClean, startDate, endDate, selectedUnitId),
      ),
      map(hydrator),
      groupBy(dateKey),
    )(state?.cleans?.data)
  },
)
