import { xor } from 'lodash/fp'

import {
  AllTaskAttributeNames,
  type AllTaskAttributes,
  type ComputedTaskJobType,
  computedTaskJobTypeValues,
  type TaskJobType,
} from '../task'

export type VisitJobType = Exclude<TaskJobType, 'standard' | 'deep_clean'>

export const computedVisitJobTypeValues = xor(
  ['deep', 'post'],
  computedTaskJobTypeValues,
) as Array<Exclude<ComputedTaskJobType, 'deep' | 'post'>>

export type ComputedVisitJobType = (typeof computedVisitJobTypeValues)[number]

/**********************************************************
 * VISIT
 *********************************************************/
/**
 * All keys here are task attribute keys, but the values are more specific to Visits.
 * All keys in this type should be omitted from `AllTaskAttributes` in the `AllVisitAttributes` definition
 */
interface FilteredVisitAttributes {
  jobType: VisitJobType[]
}

export type AllVisitAttributes = Omit<AllTaskAttributes, 'jobType'> &
  FilteredVisitAttributes

export const AllVisitAttributeNames = AllTaskAttributeNames
