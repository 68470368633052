import { type AxiosResponse } from 'axios'
import { difference, snakeCase } from 'lodash/fp'

import {
  AllCleanAttributeNames,
  type AllCleanAttributes,
  type CleanJobType,
  type JSONApiHousekeepersMap,
  type JSONApiUsersMap,
} from 'packages/grimoire'
import {
  type JSONApiObjectWithRelationships,
  type JSONApiObjectWithRelationshipsMap,
  type NormalizedJSONApiResponse,
  type StringKeyToObjectMap,
  type ToManyRelationship,
  type ToOneRelationship,
} from 'packages/grimoire/src/utils'

import { type Assignment, type JSONApiAssignmentsMap } from '../assignments'
import { type JSONApiGwUnitStafferMap } from '../gwUnitStaffers'
import { type JSONApiLockboxesMap } from '../lockboxes'
import { type JSONApiReservationsMap, type Reservation } from '../reservations'
import { type JSONApiUnitsMap, type Unit } from '../units'

export * from 'packages/grimoire/src/clean/clean.types'

/**********************************************************
 * UTILITY TYPES
 *********************************************************/
export type BucketedCleans = Record<string, Record<string, Clean[]>>

export type AssignmentToCleanMap = StringKeyToObjectMap<Clean>

/**********************************************************
 * SERVICE TYPES
 *********************************************************/
export interface CleansResponse {
  assignment: JSONApiAssignmentsMap
  clean: JSONApiCleanMap
  gwUnitStaffer: JSONApiGwUnitStafferMap
  housekeeper: JSONApiHousekeepersMap
  lockBox: JSONApiLockboxesMap
  reservation: JSONApiReservationsMap
  unit: JSONApiUnitsMap
  user: JSONApiUsersMap
}

export interface CleanPatchApiAttributes {
  completed_at: string
  job_type: string[]
  started_at: string
}

export interface CleanPatchAttributes {
  completedAt: Date
  jobType: CleanJobType[]
  startedAt: Date
}

export type CleanPatchData = {
  id: string
} & Partial<CleanPatchAttributes>

export type NormalizedCleansApiResponse =
  NormalizedJSONApiResponse<CleansResponse>

export type CleansServiceResponse = Promise<NormalizedCleansApiResponse>

export type CleansApiResponse = AxiosResponse<NormalizedCleansApiResponse>

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export enum CleansActionTypes {
  FETCH_CLEAN = 'CLEANS/API/FETCH_CLEAN',
  FETCH_CLEANS = 'CLEANS/API/FETCH_CLEANS',
  FETCH_CLEANS_FAILURE = 'CLEANS/FETCH_CLEANS_FAILURE',

  FETCH_CLEANS_SUCCESS = 'CLEANS/FETCH_CLEANS_SUCCESS',
  FETCH_CLEAN_FAILURE = 'CLEANS/FETCH_CLEAN_FAILURE',
  FETCH_CLEAN_SUCCESS = 'CLEANS/FETCH_CLEAN_SUCCESS',

  FETCH_PREVIOUS_CLEANS = 'CLEANS/API/FETCH_PREVIOUS_CLEANS',
  FETCH_PREVIOUS_CLEANS_FAILURE = 'CLEANS/FETCH_PREVIOUS_CLEANS_FAILURE',
  FETCH_PREVIOUS_CLEANS_SUCCESS = 'CLEANS/FETCH_PREVIOUS_CLEANS_SUCCESS',

  UPDATE_CLEAN = 'CLEANS/API/UPDATE_CLEAN',
  UPDATE_CLEAN_FAILURE = 'CLEANS/UPDATE_CLEAN_FAILURE',
  UPDATE_CLEAN_SUCCESS = 'CLEANS/UPDATE_CLEAN_SUCCESS',
}

export interface CleansState {
  data: JSONApiCleanMap
  error?: Error
  lastFetch: number
  lastFetchPrevious: number
  loading: boolean
}

/**********************************************************
 * CLEAN
 *********************************************************/
export type Clean = {
  assignments: Assignment[]
  id: string
  reservation: Reservation
  unit: Unit
} & CleanAttributes

export type CleanAttributes = Omit<
  AllCleanAttributes,
  | 'cleanPhotos'
  | 'lastCleanCompletedAt'
  | 'realtimeStatus'
  | 'hasCleanPhotos'
  | 'inspectionChecklist'
  | 'inspectionCompletedAt'
  | 'inspectionNotes'
  | 'ticketAttachedCount'
  | 'timersSubmitted'
>

export const CleanAttributeNames = difference(AllCleanAttributeNames)([
  'lastCleanCompletedAt',
  'realtimeStatus',
  'hasCleanPhotos',
  'inspectionChecklist',
  'inspectionCompletedAt',
  'inspectionNotes',
  'ticketAttachedCount',
  'timersSubmitted',
])

export const CleanApiFields: string[] = CleanAttributeNames.map(snakeCase)

export interface CleanRelationships {
  activeAssignments: ToManyRelationship
  reservation: ToOneRelationship
  unit: ToOneRelationship
}

export type RawClean = JSONApiObjectWithRelationships<
  CleanAttributes,
  CleanRelationships
>

export type JSONApiCleanMap = JSONApiObjectWithRelationshipsMap<
  CleanAttributes,
  CleanRelationships
>
