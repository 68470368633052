import React from 'react'

import { IconName } from 'packages/iconic'

import { Slugs, useI18n } from 'app/i18n'
import { type Unit } from 'app/store/units'

import { UnitSelectButton } from '../../core'
import * as St from './SupportPage.styles'

export enum SupportPageTestIds {
  button = 'supportPage__button',
  container = 'supportPage__container',
  headingIcon = 'supportPage__headingIcon',
}

export interface SupportPageProps {
  onHeadingIconClick: () => void
  onShowUnitPickerModal: () => void
  selectedUnit: Unit | undefined
}

const useTranslations = () => {
  const { t } = useI18n()

  return {
    homeownerAccountSupport: t(Slugs.homeownerAccountSupport),
    sevenDaysAWeek: t(Slugs.sevenDaysAWeek),
    sixAmToSixPm: t(Slugs.sixAmToSixPm),
    support: t(Slugs.support),
    supportCardBody: t(Slugs.supportCardBody),
  }
}

export const SupportPage: React.FC<SupportPageProps> = ({
  selectedUnit,
  onHeadingIconClick,
  onShowUnitPickerModal,
}) => {
  const strings = useTranslations()

  return (
    <section data-testid={SupportPageTestIds.container}>
      <St.HeadingContainer>
        <St.HeadingIconContainer
          onClick={onHeadingIconClick}
          data-testid={SupportPageTestIds.headingIcon}
        >
          <St.HeadingIcon icon={IconName.leftArrow} size={16} />
        </St.HeadingIconContainer>
        <St.Heading>{strings.support}</St.Heading>
      </St.HeadingContainer>

      <St.ButtonWrapper>
        <UnitSelectButton
          dataTestId={SupportPageTestIds.button}
          onPress={onShowUnitPickerModal}
        >
          {selectedUnit?.name}
        </UnitSelectButton>
      </St.ButtonWrapper>

      <St.SupportCard>
        <St.SupportSection>
          <St.VacasaIconBackGround>
            <St.VacasaIcon icon={IconName.vacasa2} size={24} />
          </St.VacasaIconBackGround>
          <St.CardHeading>{strings.homeownerAccountSupport}</St.CardHeading>
        </St.SupportSection>

        <St.CardBodyText>{strings.supportCardBody}</St.CardBodyText>

        <St.SupportSection>
          <St.ClockIcon icon={IconName.clock} size={16} />
          <St.CardTimeText>{`${strings.sixAmToSixPm} · ${strings.sevenDaysAWeek}`}</St.CardTimeText>
        </St.SupportSection>

        <St.SupportSection>
          <St.ContactInfoIconBackground>
            <St.ContactInfoIcon icon={IconName.phone} size={16} />
          </St.ContactInfoIconBackground>
          <St.ContactInfo href="tel:18557830040">
            +1 855 783 0040
          </St.ContactInfo>
        </St.SupportSection>

        <St.SupportSection>
          <St.ContactInfoIconBackground>
            <St.ContactInfoIcon icon={IconName.mail} size={16} />
          </St.ContactInfoIconBackground>
          <St.ContactInfo href="mailto:guestworks@vacasa.com">
            guestworks@vacasa.com
          </St.ContactInfo>
        </St.SupportSection>
      </St.SupportCard>
    </section>
  )
}
