import { differenceInDays, differenceInHours } from 'packages/utils/dateHelpers'

export interface getCodeDurationTextArgs {
  daysSlug: string
  endDateTime: Date
  hoursSlug: string
  startDateTime: Date
}

export const getCodeDurationText = ({
  endDateTime,
  startDateTime,
  daysSlug,
  hoursSlug,
}: getCodeDurationTextArgs): string => {
  const days = differenceInDays(endDateTime, startDateTime)
  const hours = differenceInHours(endDateTime, startDateTime)

  return days === 0 ? `${hours} ${hoursSlug}` : `${days} ${daysSlug}`
}
