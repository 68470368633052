import styled from '@emotion/styled'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Loader } from 'packages/common'
import { colors, layers } from 'packages/styles'
import { useAsyncFnWithReset } from 'packages/utils/hooks'
import { logInfo } from 'packages/wiretap/logging'

import { useGwToast } from 'app/components/core/hooks'
import { type AppInitState } from 'app/store/app/app.types'
import { setTokens } from 'app/store/auth/actions'
import { getAccessToken } from 'app/store/auth/selectors'
import { fullName, type User } from 'app/store/users'

import AsyncUserSelector from '../AsyncUserSelector/AsyncUserSelector'
import { type UnknownAction } from '@reduxjs/toolkit'

const SERVICE_URL = process.env.REACT_APP_SERVICE_HOST
const TOKEN_URL = `${SERVICE_URL}/impersonation_token`

/**
 * Sends a request to fetch an "impersonation token" and assuming it succeeds, returns said token.
 * This allows us to make requests as another user. Note that this manually hijacks our regular token
 * handling and manually sets this in the store over our own auth tokens, so it may be a little flaky
 * in some ways when compared to our regular auth system.
 *
 * - Impersonation tokens will not persist when refreshing, so you will have to re-select the user every time
 * - Refresh tokens will not work for an impersonated user, so again, you may be forced to refresh and then re-select the user if the token expiresA..
 * @param idToken The actual ID token (i.e. the admin user's ID Token)
 * @param userId The ID of the user we want to impersonate
 */
const fetchImpersonationToken = async ({
  idToken,
  userId,
}): Promise<string> => {
  const result = await fetch(TOKEN_URL, {
    body: `user_id=${userId}`,

    headers: {
      Authorization: `Bearer ${idToken}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },

    method: 'POST',
  }).then(async res => {
    if (res.ok) return await res.json()

    res.json().then(json => {
      logInfo('Error fetching impersonation token', {
        error: json?.error || 'unknown',
      })
    })

    throw new Error('Error fetching impersonation token')
  })

  return result.access_token
}

const St = {
  AdminPanel: styled.div`
    background: ${colors.midnight4};
    border: 1px solid ${colors.midnight20};
    display: flex;
    padding: 8px;
    position: relative;
  `,
  UserName: styled.div`
    color: ${colors.midnight50};
    text-align: right;
    width: 100%;
  `,
  UserSelector: styled(AsyncUserSelector)`
    background: ${colors.midnight2};
    border: 1px solid ${colors.midnight20};
    border-radius: 4px;
    width: 100%;
    z-index: ${layers.zoneSelector};
  `,
}

export enum AdminPanelTestIds {
  container = 'AdminPanel__container',
}

export interface AdminPanelActions {
  searchUsers: (search: string) => Promise<void>
  setActiveUser: (user: User) => void
  setAppInitState: (nextState: AppInitState) => void
}

export interface AdminPanelSelectors {
  getActiveUser: () => User | undefined
  getUsersSearchResults: () => User[]
}

export type AdminPanelProps = AdminPanelActions & AdminPanelSelectors

export const AdminPanel: React.FC<AdminPanelProps> = React.memo(
  ({
    getActiveUser,
    getUsersSearchResults,
    searchUsers,
    setActiveUser,
    setAppInitState,
  }) => {
    const dispatch = useDispatch()
    const { showGwToast } = useGwToast()

    const activeUser = getActiveUser()
    const idToken = useSelector(getAccessToken)
    const [selectedUser, setSelectedUser] = React.useState<User | null>(null)

    const [fetchTokenState, fetchTokenFn] = useAsyncFnWithReset(
      async (userId: string) =>
        await fetchImpersonationToken({
          idToken,
          userId,
        }),
      [idToken],
    )

    const handleUserSelectionChange = React.useCallback(
      async (user: User) => {
        setSelectedUser(user)
        fetchTokenFn(user.id)
      },
      [fetchTokenFn],
    )

    // if successful store the token and the selected user as the app's "active user"
    React.useEffect(() => {
      if (selectedUser && fetchTokenState.value) {
        setActiveUser(selectedUser)
        dispatch(
          setTokens({
            impersonationToken: fetchTokenState.value,
          }) as unknown as UnknownAction,
        )

        // bootstrapping triggers active user data load so wait for active user to be set
        setAppInitState('bootstrapping')
      }
    }, [
      dispatch,
      fetchTokenState.value,
      selectedUser,
      setActiveUser,
      setAppInitState,
    ])

    // if error, clear the selected user and show a simple error message
    React.useEffect(() => {
      if (fetchTokenState.error) {
        setSelectedUser(null)
        showGwToast({
          message: 'Error fetching impersonation token',
          toastType: 'danger',
        })
      }
    }, [fetchTokenState.error, showGwToast])

    return (
      <St.AdminPanel data-testid={AdminPanelTestIds.container}>
        {selectedUser ? (
          <St.UserName>
            Viewing <strong>{fullName(selectedUser)}</strong>
          </St.UserName>
        ) : (
          <St.UserSelector
            getUsersResults={getUsersSearchResults}
            onUserSelectionChange={handleUserSelectionChange}
            searchUsers={searchUsers}
            selectedUser={activeUser}
          />
        )}

        {fetchTokenState.loading && <Loader size={14} />}
      </St.AdminPanel>
    )
  },
)
