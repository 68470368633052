import { createDateObject, isBefore, isAfter } from 'packages/utils/dateHelpers'
import {
  findRelationshipId,
  transformNormalizedToTyped,
} from 'packages/utils/store'

import { getLockboxById } from '../lockboxes/selectors'
import { type ApplicationState } from '../store'
import { getUnitById } from '../units/selectors'
import {
  type Ticket,
  TicketAttributeNames,
  TicketSeverity,
  TicketStatus,
  type RawTicket,
  type TicketsResponse,
  type NormalizedTicketsApiResponse,
  type NormalizedDispositionApiResponse,
  type DispositionResponse,
  type RawDisposition,
  DispositionAttributeNames,
  type Disposition,
} from './tickets.types'

export const emptyNormalizedTicketsData: TicketsResponse = Object.freeze({
  lockBox: {},
  ticket: {},
  unit: {},
})

export const emptyTicketsResponse: NormalizedTicketsApiResponse = Object.freeze(
  {
    normalized: emptyNormalizedTicketsData,
    raw: { data: [] },
  },
)

export const emptyNormalizedDispositionData: DispositionResponse =
  Object.freeze({
    disposition: {},
  })

export const emptyDispositionResponse: NormalizedDispositionApiResponse =
  Object.freeze({
    normalized: emptyNormalizedDispositionData,
    raw: { data: [] },
  })

export const hydrateRawDisposition = (
  disposition: RawDisposition,
): Disposition =>
  transformNormalizedToTyped<Disposition>(
    disposition,
    DispositionAttributeNames,
  )

export const hydrateRawTicket =
  (state: ApplicationState) =>
  (rawTicket: RawTicket): Ticket => {
    const unitId = findRelationshipId(rawTicket, 'unit')
    const lockboxId = findRelationshipId(rawTicket, 'lockBox')

    if (!unitId) {
      throw Error(`No unit relationship found on ticket: ${rawTicket.id}`)
    }

    if (!lockboxId) {
      throw new Error(
        `No lockbox relationship found on ticket: ${rawTicket.id}`,
      )
    }

    const ticket = transformNormalizedToTyped<Ticket>(
      rawTicket,
      TicketAttributeNames,
    )

    return {
      ...ticket,
      lockbox: getLockboxById(state, lockboxId),
      unit: getUnitById(state, unitId),
    }
  }

const severityRanking = {
  [TicketSeverity.URGENT]: 1,
  [TicketSeverity.TODAY]: 2,
  [TicketSeverity.PRIOR_TO_NEXT_GUEST]: 3,
  [TicketSeverity.MEDIUM]: 4,
  [TicketSeverity.LOW]: 5,
  [TicketSeverity.PLANNED]: 6,
}

export function compareSeverity(a: Ticket, b: Ticket): number {
  const aSevRank = severityRanking[a.severity]
  const bSevRank = severityRanking[b.severity]

  if (aSevRank < bSevRank) {
    return -1
  }

  if (aSevRank < bSevRank) {
    return 1
  }

  return 0
}

export function compareCreationDate(a: Ticket, b: Ticket): number {
  const dateA = createDateObject(a.createdAt)
  const dateB = createDateObject(b.createdAt)

  if (isBefore(dateA, dateB)) {
    return 1
  }

  if (isAfter(dateA, dateB)) {
    return -1
  }

  return 0
}

export function compareCompletedDate(a: Ticket, b: Ticket): number {
  const dateA = createDateObject(a.completedAt)
  const dateB = createDateObject(b.completedAt)

  if (isBefore(dateA, dateB)) {
    return 1
  }

  if (isAfter(dateA, dateB)) {
    return -1
  }

  return 0
}

const completedStatuses = [
  TicketStatus.RESOLVED_COMPLETED,
  TicketStatus.RESOLVED_DISMISSED,
  TicketStatus.RESOLVED_DUPLICATE,
]

export function isCompleted(ticket: Ticket): boolean {
  return completedStatuses.includes(ticket.status)
}
