/* eslint-disable @typescript-eslint/naming-convention */
import styled from '@emotion/styled'
import React from 'react'

import { IconName, SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

const Link = styled.a`
  align-items: center;
  color: ${colors.lake};
  display: inline-flex;
  font-size: 14px;
  font-weight: 600;
`

const StyledSvgIcon = styled(SvgIcon)`
  margin-left: 5px;
`

export interface ExternalLinkProps {
  className?: string
  text: string
  url: string
}

export const ExternalLink: React.FC<ExternalLinkProps> = ({
  className,
  text,
  url,
}) => (
  <Link
    aria-label={text}
    className={className}
    href={url}
    rel="noopener noreferrer"
    target="_blank"
  >
    {text}
    <StyledSvgIcon centerItems={true} icon={IconName.externalLink} size={15} />
  </Link>
)
