import { produce } from 'immer'
import { isEmpty, merge } from 'lodash/fp'
import { type ActionType, getType } from 'typesafe-actions'

import { fetchCleanByIdAction } from '../cleans/actions'
import { fetchTicketByIdAction } from '../tickets/actions'
import { fetchUnitByIdAction } from './actions/fetchUnitById'
import { fetchUnitsAction } from './actions/fetchUnits'
import { updateSelectedUnitAction } from './actions/updateSelectedUnit'
import { type UnitsState } from './units.types'
import { emptyNormalizedUnitsData } from './units.utils'

export const initialState: UnitsState = {
  data: {},
  selectedUnitId: '',
}

const actions = {
  fetchCleanByIdAction,
  fetchTicketByIdAction,
  fetchUnitByIdAction,
  fetchUnitsAction,
  updateSelectedUnitAction,
}

type Actions = ActionType<typeof actions>

export const unitsReducer = (
  state = initialState,
  action: Actions,
): UnitsState =>
  produce(state, (draft: UnitsState) => {
    switch (action.type) {
      // we are purposefully ignoring fetchCleansAction and fetchTicketsAction to avoid race conditions that cause invalid data
      case getType(fetchTicketByIdAction.success):
      case getType(fetchCleanByIdAction.success):
      case getType(fetchUnitByIdAction.success):
      case getType(fetchUnitsAction.success): {
        const normalized =
          action.payload?.normalized || emptyNormalizedUnitsData

        // edge case: for users with no units, there is nothing to do here expect clear localStorage
        if (!normalized?.unit) {
          localStorage.removeItem('selectedUnitId')
          return
        }

        Object.values(normalized.unit).forEach(incomingUnit => {
          const existingUnit = state.data[incomingUnit.id] || {}
          const mergedUnit = merge(existingUnit, incomingUnit)
          draft.data[incomingUnit.id] = mergedUnit
        })

        // attempt to re-load cached unit ID, and validate that it is a real unit
        const cachedSelectedUnitId =
          localStorage.getItem('selectedUnitId') || ''

        const isValidUnit =
          !!cachedSelectedUnitId &&
          (!!state.data[cachedSelectedUnitId] ||
            !!normalized.unit[cachedSelectedUnitId])

        if (!isValidUnit) {
          // if we have an invalid unit, just grab the first one from the data and use that instead
          const selectedUnitId = !isEmpty(normalized?.unit)
            ? Object.values(normalized?.unit)[0]?.id
            : ''

          draft.selectedUnitId = selectedUnitId
          localStorage.setItem('selectedUnitId', selectedUnitId)
        } else {
          draft.selectedUnitId = cachedSelectedUnitId
        }

        return
      }

      case getType(updateSelectedUnitAction): {
        const selectedUnitId = action?.payload || ''

        localStorage.setItem('selectedUnitId', selectedUnitId)
        draft.selectedUnitId = selectedUnitId
      }
    }
  })
