import styled from '@emotion/styled'
import { startCase, upperFirst } from 'lodash/fp'
import React from 'react'

import { IconName } from 'packages/iconic'
import { colors } from 'packages/styles'

import {
  isViewingSharedClean,
  isViewingSharedTicket,
} from 'app/components/schedule/schedule.utils'
import { Slugs, useI18n } from 'app/i18n'
import { gwLayers } from 'app/styles'

import { NavTab, type NavTabConfig } from './NavTab'

const St = {
  NavTabWrapper: styled.nav`
    background: ${colors.midnight};
    bottom: 0;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    height: 70px;
    justify-content: center;
    left: 0;
    position: sticky;
    width: 100%;
    z-index: ${gwLayers.navTabs};
  `,
}

export const NavTabWrapper: React.FC = React.memo(() => {
  const { t } = useI18n()

  const tabConfigs: NavTabConfig[] = React.useMemo(
    () => [
      {
        icon: IconName.calendar,
        route: '/cleans',
        text: startCase(t(Slugs.schedule)),
      },
      {
        icon: IconName.tag,
        route: '/tickets',
        text: upperFirst(t(Slugs.tickets)),
      },
      {
        icon: IconName.gear,
        route: '/settings',
        text: upperFirst(t(Slugs.settings)),
      },
    ],
    [t],
  )

  const shouldRender = !(isViewingSharedClean() || isViewingSharedTicket())

  return shouldRender ? (
    <St.NavTabWrapper>
      {tabConfigs.map(config => (
        <NavTab config={config} key={config.route} />
      ))}
    </St.NavTabWrapper>
  ) : null
})
