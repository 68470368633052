import styled from '@emotion/styled'
import React from 'react'

import { colors } from 'packages/styles'

const St = {
  Card: styled.div<Pick<CardProps, 'onClick'>>`
    background-color: white;
    border-radius: 4px;
    border: 1px solid ${colors.midnight20};
    box-shadow: 0 1px 4px 0 rgba(216, 216, 216, 0.5);
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};
  `,
}

export interface CardProps {
  children?: React.ReactNode
  className?: string
  dataTestId?: string
  onClick?: () => void
}

export const Card: React.FC<CardProps> = ({
  children,
  className = '',
  dataTestId,
  onClick,
}) => (
  <St.Card className={className} data-testid={dataTestId} onClick={onClick}>
    {children}
  </St.Card>
)
