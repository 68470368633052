import { createAsyncAction } from 'typesafe-actions'

import {
  type RequestConfig,
  type RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { FETCH_UNITS_MAX_SIZE } from 'app/Guestworks.constants'

import { GwLocalHostApiFields } from '../../gwLocalhosts/gwLocalhosts.types'
import { GwUnitStafferApiFields } from '../../gwUnitStaffers'
import { unitsService } from '../units.service'
import {
  type NormalizedUnitsApiResponse,
  UnitApiFields,
  UnitsActionTypes,
} from '../units.types'

export const fetchUnitsAction = createAsyncAction(
  UnitsActionTypes.FETCH_UNITS,
  UnitsActionTypes.FETCH_UNITS_SUCCESS,
  UnitsActionTypes.FETCH_UNITS_FAILURE,
)<
  RequestConfig<NormalizedUnitsApiResponse>,
  NormalizedUnitsApiResponse,
  Error
>()

export const getParams = (): RequestOptions => ({
  fields: {
    gw_local_host: GwLocalHostApiFields,
    gw_unit_staffer: GwUnitStafferApiFields,
    unit: UnitApiFields,
  },
  include: ['gw_unit_staffers', 'gw_local_hosts'],
  page: { size: FETCH_UNITS_MAX_SIZE },
})

export const fetchUnits = () => async dispatch => {
  try {
    const params = getParams()
    const request = unitsService.fetchUnits.bind(null, params)
    const result = await dispatch(fetchUnitsAction.request({ request }))
    dispatch(fetchUnitsAction.success(result))
    return result.normalized
  } catch (error) {
    dispatch(fetchUnitsAction.failure(error))
    throw error
  }
}
