import styled from '@emotion/styled'

import { SvgIcon } from 'packages/iconic'
import {
  centerWithFlex,
  colors,
  truncateTextWithEllipsis,
} from 'packages/styles/jss'

import { type Clean } from 'app/store/cleans'

export const BaseCleanHeader = styled.div<{ clean: Clean }>`
  align-items: center;
  background-color: ${colors.midnight10};
  display: flex;
  font-size: 12px;
  font-weight: bold;
  height: 40px;
  justify-content: flex-start;
  padding: 0 8px;
  text-transform: uppercase;

  /* remove right pad when owner badge is present */
  padding-right: ${({ clean }) => (clean.isNextStayOwner ? 0 : '8px')};
`

export const CleanTypeIcon = styled(SvgIcon)`
  &,
  span {
    ${truncateTextWithEllipsis};
  }

  svg {
    flex-shrink: 0;
  }
`

export const OwnerBadge = styled.span`
  ${centerWithFlex};

  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 6px;
  color: ${colors.dusk};
  font-size: inherit;
  font-weight: 700;
  height: 24px;
  margin-left: auto;
  margin-right: 8px;
  padding: 0 8px;
`
