import { type AxiosResponse } from 'axios'
import { difference, snakeCase } from 'lodash/fp'

import {
  AllUnitAttributeNames,
  type AllUnitAttributes,
} from 'packages/grimoire'
import {
  type JSONApiObjectWithRelationships,
  type JSONApiObjectWithRelationshipsMap,
  type NormalizedJSONApiResponse,
  type ToManyRelationship,
  type ToOneRelationship,
} from 'packages/utils/store'

import { type JSONApiGwLocalHostMap } from '../gwLocalhosts/gwLocalhosts.types'
import { type JSONApiGwUnitStafferMap } from '../gwUnitStaffers'

export type { Amenities } from 'packages/grimoire/src/unit'
export {
  BathAmenities,
  BedAmenities,
  HotTubAmenities,
  NetworkAmenities,
  OrderedBathAmenities,
  OrderedBedAmenities,
} from 'packages/grimoire/src/unit'

/**********************************************************
 * SERVICE TYPES
 *********************************************************/

export interface UnitsResponse {
  gwLocalHost: JSONApiGwLocalHostMap
  gwUnitStaffer: JSONApiGwUnitStafferMap
  unit: JSONApiUnitsMap
}

export type NormalizedUnitsApiResponse =
  NormalizedJSONApiResponse<UnitsResponse>

export type UnitsApiResponse = AxiosResponse<NormalizedUnitsApiResponse>
export type UnitsServiceResponse = Promise<NormalizedUnitsApiResponse>

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export enum UnitsActionTypes {
  FETCH_UNIT = 'UNITS/API/FETCH_UNIT',
  FETCH_UNITS = 'UNITS/API/FETCH_UNITS',
  FETCH_UNITS_FAILURE = 'UNITS/FETCH_UNITS_FAILURE',

  FETCH_UNITS_SUCCESS = 'UNITS/FETCH_UNITS_SUCCESS',
  FETCH_UNIT_FAILURE = 'UNITS/FETCH_UNIT_FAILURE',
  FETCH_UNIT_SUCCESS = 'UNITS/FETCH_UNIT_SUCCESS',

  UPDATE_SELECTED_UNIT = 'UNITS/UPDATE_SELECTED_UNIT',
}

export interface UnitsState {
  data: JSONApiUnitsMap
  selectedUnitId: string
}

/**********************************************************
 * UNIT
 *********************************************************/
export type Unit = {
  gwLocalHostsIds: string[]
  gwUnitStaffersIds: string[]
  id: string
} & UnitAttributes

export type UnitAttributes = { primaryImageId: string } & Omit<
  AllUnitAttributes,
  'isActive' | 'nextDeepCleanDueAt' | 'realtimeStatus'
>

export const AllGwUnitAttributeNames = [
  ...AllUnitAttributeNames,
  'primaryImageId',
]

export const UnitAttributeNames = difference(AllGwUnitAttributeNames)([
  'isActive',
  'nextDeepCleanDueAt',
  'rateContractorDeepClean',
  'rateContractorPostStay',
  'realtimeStatus',
])

export const UnitApiFields: string[] = UnitAttributeNames.map(snakeCase)

export interface UnitRelationships {
  gwLocalHosts: ToManyRelationship
  gwUnitStaffers: ToManyRelationship
  lockBox: ToOneRelationship
}

export type RawUnit = JSONApiObjectWithRelationships<
  UnitAttributes,
  UnitRelationships
>

export type JSONApiUnitsMap = JSONApiObjectWithRelationshipsMap<
  UnitAttributes,
  UnitRelationships
>
