import { produce } from 'immer'
import { type ActionType, getType } from 'typesafe-actions'

import { fetchSmartLocksByUnitIdAction } from './actions'
import { type SmartLocksState } from './smartLocks.types'
import { emptyNormalizedSmartLocksData } from './smartLocks.utils'

const initialState: SmartLocksState = {
  data: {},
}

const actions = {
  fetchSmartLocksByUnitIdAction,
}

type SmartLockActionsTypes = ActionType<typeof actions>

export const smartLocksReducer = (
  state = initialState,
  action: SmartLockActionsTypes,
): SmartLocksState =>
  produce(state, (draft: SmartLocksState) => {
    switch (action.type) {
      case getType(fetchSmartLocksByUnitIdAction.success): {
        const normalized =
          action.payload?.normalized || emptyNormalizedSmartLocksData

        draft.data = normalized.smartLock || {}
      }
    }
  })
