import { produce } from 'immer'

import { transformNormalizedToTyped } from 'packages/utils/store'

import {
  type NormalizedSmartLocksApiResponse,
  type RawSmartLock,
  type SmartLock,
  SmartLockAttributeNames,
  type SmartLocksResponse,
} from './smartLocks.types'

export const emptyNormalizedSmartLocksData = Object.freeze<SmartLocksResponse>({
  smartLock: {},
  unit: {},
  user: {},
})

export const emptySmartLocksResponse: NormalizedSmartLocksApiResponse =
  Object.freeze({
    normalized: emptyNormalizedSmartLocksData,
    raw: { data: [] },
  })

export const transformRawSmartLock = (
  rawSmartLock: RawSmartLock,
): SmartLock => {
  const baseSmartLock = transformNormalizedToTyped<SmartLock>(
    rawSmartLock,
    SmartLockAttributeNames,
  )

  return produce(baseSmartLock, draft => {
    draft.unitId = rawSmartLock?.relationships?.unit?.data?.id || ''
    draft.userId = rawSmartLock?.relationships?.generatedBy?.data?.id || ''
  })
}
