import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Loader } from 'packages/common'

import { getAppInitState } from 'app/store/app/selectors'

import { useUnitPickerModal } from '../../core/hooks/useUnitPickerModal/useUnitPickerModal'
import { SupportPage } from './SupportPage'

export const SupportPageContainer: React.FC = () => {
  const navigate = useNavigate()
  const appInitState = useSelector(getAppInitState)
  const appIsLoading = !['ready', 'error'].includes(appInitState)

  const { selectedUnit, showUnitPickerModal } = useUnitPickerModal()

  const handleHeadingIconClick = () => {
    navigate('/settings')
  }

  return appIsLoading ? (
    <Loader />
  ) : (
    <SupportPage
      selectedUnit={selectedUnit}
      onHeadingIconClick={handleHeadingIconClick}
      onShowUnitPickerModal={showUnitPickerModal}
    />
  )
}
