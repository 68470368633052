import {
  type GwUnitStaffer,
  type GwUnitStafferTypes,
} from 'app/store/gwUnitStaffers'

export const getStafferByType = (
  gwUnitStaffers: GwUnitStaffer[],
  stafferType: GwUnitStafferTypes,
): GwUnitStaffer | undefined => {
  return gwUnitStaffers.find(
    stafferObj => stafferObj.stafferType === stafferType,
  )
}
