import React from 'react'
import { useSelector } from 'react-redux'

import { getAccessToken } from 'app/store/auth/selectors'
import { type Ticket } from 'app/store/tickets'

import { ShareTicketButton } from './ShareTicketButton'

export interface ShareTicketButtonContainerProps {
  ticket: Ticket
}

export const ShareTicketButtonContainer: React.FC<ShareTicketButtonContainerProps> =
  React.memo(({ ticket }) => {
    const accessToken = useSelector(getAccessToken)
    if (!accessToken) return null

    return <ShareTicketButton accessToken={accessToken} ticket={ticket} />
  })
