import { noop } from 'lodash/fp'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  type AppAuthState,
  type AuthOverrideParams,
  useAppAuth,
  useIdpAuth,
  type UseIdpAuthProps,
} from 'packages/auth'
import { splitSearchQuery } from 'packages/utils/misc'

import { setNeedsFullAuthRedirect, setTokens } from 'app/store/auth/actions'

import {
  isViewingSharedClean,
  isViewingSharedTicket,
} from './components/schedule/schedule.utils'
import { IMPERSONATION_IDP_SCOPE } from './Guestworks.constants'
import { initI18n } from './i18n'
import {
  getAccessToken,
  getImpersonationToken,
  getNeedsFullAuthRedirect,
  getNeedsSilentRefresh,
  getRefreshToken,
} from './store/auth/selectors'
import { setMayImpersonateUser } from './store/users/actions'
import { type UnknownAction } from '@reduxjs/toolkit'

const {
  REACT_APP_IDP_BASE_URL: authRoot = '',
  REACT_APP_IDP_CLIENT_ID: authClientId = '',
  REACT_APP_IDP_SCOPE: scope = '',
  REACT_APP_IDP_AUDIENCE: audience = '',
} = process.env

const urls = {
  auth: authRoot + '/authorize',
  token: authRoot + '/token',
}

const overrideParams: AuthOverrideParams = { audience, scope }

interface UseGuestworksAuth {
  authState: AppAuthState
}

export const useGuestworksAuth = (): UseGuestworksAuth => {
  const dispatch = useDispatch()
  const accessToken = useSelector(getAccessToken)
  const impersonationToken = useSelector(getImpersonationToken)
  const refreshToken = useSelector(getRefreshToken)
  const needsFullAuth = useSelector(getNeedsFullAuthRedirect)
  const needsSilentRefresh = useSelector(getNeedsSilentRefresh)

  const { authState, onAppAuthInitialized } = useAppAuth()
  /**
   * If current user is a delegate viewing a shared clean or ticket then the
   * authentication bootstrapping process is skipped in favor of directly using
   * their delegate token
   */
  const canSkipAuthFlow = isViewingSharedClean() || isViewingSharedTicket()

  const handleAuthInitialized = React.useCallback(
    async (scopes?: string[]) => {
      await initI18n()
      await onAppAuthInitialized()

      if (scopes?.includes(IMPERSONATION_IDP_SCOPE)) {
        dispatch(setMayImpersonateUser(true) as unknown as UnknownAction)
      }
    },
    [onAppAuthInitialized, dispatch],
  )

  // provide empty auth config for shared cleans/tickets so that the auth hook does not attempt to contact IDP
  const authConfig: UseIdpAuthProps = canSkipAuthFlow
    ? {
        accessToken: '',
        authClientId: '',
        authUrl: '',
        isImpersonated: !!impersonationToken,
        needsFullAuth: false,
        needsSilentRefresh: false,
        onAuthInitialized: noop,
        overrideParams,
        refreshToken: '',
        setNeedsFullAuthRedirect: noop,
        setTokens: noop,
        tokenUrl: '',
      }
    : {
        accessToken,
        authClientId,
        authUrl: urls.auth,
        isImpersonated: !!impersonationToken,
        needsFullAuth,
        needsSilentRefresh,
        onAuthInitialized: handleAuthInitialized,
        overrideParams,
        refreshToken,
        setNeedsFullAuthRedirect,
        setTokens,
        tokenUrl: urls.token,
      }

  useIdpAuth(authConfig)

  /*
   * If the current user is a delegate token user viewing a shared clean/ticket, we must skip the entire
   * authentication bootstrapping process, and just directly use the delegate token provided in the URL params instead.
   * In this case, we will not render any of the authentication handling components.
   */
  if (canSkipAuthFlow) {
    const {
      b: body = '',
      h: header = '',
      s: sig = '',
    } = splitSearchQuery(window.location.search)

    // ensure we have all necessary components to make the token; otherwise we might end up with
    // a token like ".." after joining, which we obviously do not want
    const canCombineToken = !!body && !!header && !!sig
    const delegateToken = canCombineToken ? [header, body, sig].join('.') : ''

    dispatch(
      setTokens({
        accessToken: undefined,
        delegateToken,
        idToken: undefined,
        refreshToken: undefined,
      }) as unknown as UnknownAction,
    )

    handleAuthInitialized()
  }

  return { authState }
}
