import {
  addDays,
  DateFormat,
  format,
  subDays,
  startOfToday,
} from 'packages/utils/dateHelpers'

import {
  CLEANS_TIMELINE_DATE_RANGE,
  CLEANS_TIMELINE_PREV_DAYS,
} from '../Guestworks.constants'

export const getUpcomingTimelineDateRange = (): [string, string] => {
  const today = startOfToday()

  return [
    format(today, DateFormat.ApiUtcShort),
    format(
      addDays(today, CLEANS_TIMELINE_DATE_RANGE - 1),
      DateFormat.ApiUtcShort,
    ),
  ]
}

export const getPreviousTimelineDateRange = (): [string, string] => {
  const today = startOfToday()

  return [
    format(subDays(today, CLEANS_TIMELINE_PREV_DAYS), DateFormat.ApiUtcShort),
    format(subDays(today, 1), DateFormat.ApiUtcShort),
  ]
}
