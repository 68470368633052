import React from 'react'
import { useSelector } from 'react-redux'

import { getAccessToken } from 'app/store/auth/selectors'

import { LockCodeCard } from './LockCodeCard'

export const LockCodeCardContainer: React.FC = React.memo(() => {
  const accessToken = useSelector(getAccessToken)
  if (!accessToken) return null

  return <LockCodeCard accessToken={accessToken} />
})
