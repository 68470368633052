import { produce } from 'immer'
import { every } from 'lodash/fp'

import { type Disposition, type SeverityAttributes } from 'app/store/tickets'

import { type CreateTicketAction } from './createTicket.actions'

export interface CreateTicketValuesState {
  description: string
  disposition: Disposition | null
  severity: SeverityAttributes | null
  title: string
}

export interface CreateTicketState {
  uiState: {
    descriptionOpen: boolean
    dispositionsLoading: boolean
    error: boolean
    loading: boolean
    readyToSubmit: boolean
  }
  values: CreateTicketValuesState
}

export const initialCreateTicketState: CreateTicketState = {
  uiState: {
    descriptionOpen: false,
    dispositionsLoading: true,
    error: false,
    loading: false,
    readyToSubmit: false,
  },
  values: {
    description: '',
    disposition: null,
    severity: null,
    title: '',
  },
}

const validateValues = (state: CreateTicketState) => {
  const { title, disposition, severity } = state.values

  return every(Boolean)([title, disposition, severity])
}

export const createTicketReducer = (
  state: CreateTicketState,
  action: CreateTicketAction,
): CreateTicketState =>
  produce(state, draft => {
    switch (action.type) {
      case 'setValue':
        Object.entries(action.payload).forEach(([section, value]) => {
          if (section in draft.values) {
            draft.values[section] = value
          }
        })

        break

      case 'toggleDetailsOpen':
        draft.uiState.descriptionOpen = !state.uiState.descriptionOpen
        break

      case 'setError':
        draft.uiState.error = action.payload
        break

      case 'setLoading':
        draft.uiState.loading = action.payload
        break
    }

    draft.uiState.readyToSubmit = validateValues(draft)
  })
