import React from 'react'

import { type ModalChildrenProps, useModalContext } from 'packages/common'

import { TimePicker, type TimePickerProps } from './TimePicker'

export type TimePickerModalProps = TimePickerProps

interface UseTimePickerModal {
  showTimePickerModal: () => void
}

export const useTimePickerModal = (
  props: TimePickerModalProps,
): UseTimePickerModal => {
  const { onTimeChange, selectedTime } = props
  const { showModal } = useModalContext()

  const handleTimeChange = React.useCallback(
    (time: number, beginClose: ModalChildrenProps['beginClose']) => {
      onTimeChange(time)
      beginClose()
    },
    [onTimeChange],
  )

  const showTimePickerModal = React.useCallback(() => {
    showModal({
      childRenderer: ({ beginClose }) => (
        <TimePicker
          onTimeChange={time => {
            handleTimeChange(time, beginClose)
          }}
          selectedTime={selectedTime}
        />
      ),
    })
  }, [handleTimeChange, selectedTime, showModal])

  return {
    showTimePickerModal,
  }
}
