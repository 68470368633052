import React, { lazy, Suspense } from 'react'
import { v4 as uuid } from 'uuid'

import { type AriaFormProps } from 'packages/common'

import { Slugs, useI18n } from 'app/i18n'

import { Button, InputField } from '../../core'
import { St } from './NotificationForm.styles'
import 'react-phone-number-input/style.css'

const PhoneInput = lazy(async () => await import('react-phone-number-input'))

export enum NotificationFormTestIds {
  container = 'NotificationForm__container',
  phoneDescription = 'NotificationForm__phoneDescription',
}

export interface NotificationFormValues {
  email: string
  name: string
  phone: string
}

// @ts-expect-error
export type NotificationFormProps = AriaFormProps<NotificationFormValues>

const useTranslations = () => {
  const { t } = useI18n()

  return {
    cancel: t(Slugs.cancel),
    emailAddress: t(Slugs.emailAddress),
    emailDescription: t(Slugs.emailDescription),
    emailPlaceholder: t(Slugs.emailPlaceholder),
    name: t(Slugs.name),
    namePlaceholder: t(Slugs.firstAndLastName),
    phone: t(Slugs.phoneNumber),
    phoneDescription: t(Slugs.phoneDescription),
    phonePlaceholder: t(Slugs.phonePlaceholder),
    save: t(Slugs.save),
  }
}

/**
 * See the [README][1] rather than the [page][2] for complete
 * `phone-number-input` documentation
 *
 * [1]: https://gitlab.com/catamphetamine/react-phone-number-input
 * [2]: https://catamphetamine.gitlab.io/react-phone-number-input/
 */
export const NotificationForm: React.FC<NotificationFormProps> = React.memo(
  ({ formState, handlers, requestState }) => {
    const strings = useTranslations()
    const phoneInputId = `phone-${uuid()}`
    const isDisabled =
      !formState.values?.name ||
      !formState.values?.email ||
      !formState.values?.phone

    return (
      <St.Form
        data-testid={NotificationFormTestIds.container}
        onSubmit={handlers.submit}
      >
        <InputField
          errorMessage={formState.errors?.name}
          id="name"
          isClearable={true}
          placeholder={strings.namePlaceholder}
          label={strings.name}
          name="name"
          onChange={handlers.setValueByKey('name')}
          value={formState.values.name}
        />

        <St.Section>
          <St.Label hasError={!!formState.errors?.phone} htmlFor={phoneInputId}>
            {strings.phone}
          </St.Label>

          <Suspense fallback={<div></div>}>
            <PhoneInput
              id={phoneInputId}
              defaultCountry="US"
              placeholder={strings.phonePlaceholder}
              value={formState.values.phone}
              onChange={handlers.setValueByKey('phone')}
            />
          </Suspense>

          <St.Description
            data-testid={NotificationFormTestIds.phoneDescription}
          >
            {strings.phoneDescription}
          </St.Description>
        </St.Section>

        <St.Section>
          <InputField
            description={strings.emailDescription}
            errorMessage={formState.errors?.email}
            id="email"
            isClearable={true}
            placeholder={strings.emailPlaceholder}
            label={strings.emailAddress}
            name="email"
            onChange={handlers.setValueByKey('email')}
            value={formState.values.email}
          />
        </St.Section>

        <St.ButtonContainer>
          <Button
            buttonSize={'small'}
            buttonType={'text'}
            onPress={handlers.cancel}
            type={'button'}
          >
            {strings.cancel}
          </Button>
          <Button
            buttonSize={'small'}
            buttonType={'primary'}
            isLoading={requestState.loading}
            isDisabled={isDisabled}
            type={'submit'}
          >
            {strings.save}
          </Button>
        </St.ButtonContainer>
      </St.Form>
    )
  },
)

export default NotificationForm
