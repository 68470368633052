import React from 'react'
import { createPortal } from 'react-dom'
import RiLightbox, { type ILightBoxProps } from 'react-image-lightbox'

import 'react-image-lightbox/style.css'

// top-level class for the Lightbox renderer—use this to run a
// querySelector to find the Lightbox, e.g. for "click away" cancelling
export const LIGHTBOX_CLASS_SELECTOR = '.ReactModalPortal'

type PickedProps = Pick<
  ILightBoxProps,
  'imageTitle' | 'onCloseRequest' | 'onMoveNextRequest' | 'onMovePrevRequest'
>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LightboxLibComp = RiLightbox as any

export type LightboxProps = PickedProps & {
  isOpen: boolean
  renderContainer?: Element
  sourceIndex: number
  sources: string[]
}

// most of this implementation is taken directly from the docs example:
// https://github.com/frontend-collective/react-image-lightbox#example
export const Lightbox: React.FC<LightboxProps> = React.memo(
  ({
    imageTitle,
    isOpen,
    onCloseRequest,
    onMoveNextRequest,
    onMovePrevRequest,
    renderContainer,
    sources,
    sourceIndex,
  }) => {
    return isOpen
      ? createPortal(
          <LightboxLibComp
            imageTitle={imageTitle}
            mainSrc={sources[sourceIndex]}
            nextSrc={sources[(sourceIndex + 1) % sources.length]}
            onCloseRequest={onCloseRequest}
            onMoveNextRequest={onMoveNextRequest}
            onMovePrevRequest={onMovePrevRequest}
            prevSrc={
              sources[(sourceIndex + sources.length - 1) % sources.length]
            }
          />,
          renderContainer || document.body,
        )
      : null
  },
)
