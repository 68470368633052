import { noop } from 'lodash/fp'
import { createAsyncAction } from 'typesafe-actions'

import { type ReduxActionCallbacks } from 'packages/grimoire/src/utils'
import {
  type RequestConfig,
  type RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import * as ticketsService from '../tickets.service'
import { trackTicketCompleted } from '../tickets.trackers'
import {
  type NormalizedTicketsApiResponse,
  type TicketPatchData,
  TicketsActionTypes,
} from '../tickets.types'

export const updateTicketAction = createAsyncAction(
  TicketsActionTypes.UPDATE_TICKET,
  TicketsActionTypes.UPDATE_TICKET_SUCCESS,
  TicketsActionTypes.UPDATE_TICKET_FAILURE,
)<
  RequestConfig<NormalizedTicketsApiResponse>,
  NormalizedTicketsApiResponse,
  Error
>()

export const updateTicket =
  (patchData: TicketPatchData, callbacks: ReduxActionCallbacks = {}) =>
  async (dispatch, getState) => {
    const { id, ...attributes } = patchData
    const { onSuccess = noop } = callbacks
    const requestData: RequestOptions = {
      data: {
        attributes,
        id,
        type: 'ticket',
      },
    }

    const request = ticketsService.updateTicket.bind(null, id, requestData)

    try {
      const result = await dispatch(updateTicketAction.request({ request }))

      dispatch(updateTicketAction.success(result))

      trackTicketCompleted(patchData, getState)
      onSuccess()
      return result
    } catch (error) {
      dispatch(updateTicketAction.failure(error))
      throw error
    }
  }
