import styled from '@emotion/styled'

import { SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

import { gwText } from 'app/styles'

export { LockCodeFormSection } from '../LockCodeForm/LockCodeForm.styles'

export const SuccessIconBackground = styled.div`
  background-color: ${colors.midnight4};
  border-radius: 50%;
  padding: 10px;
`

export const SuccessIcon = styled(SvgIcon)`
  color: ${colors.success};
`

export const Heading = styled.h2`
  ${gwText.headingSmall};
  color: ${colors.midnight70};
  margin: 0 0 0 12px;
`

export const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
`

export const CardBody = styled.p`
  ${gwText.bodyDefault};
  color: ${colors.dusk60};
  margin: 16px 0 0 0;
`

export const LockCodeDetailContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const LockCodeDetail = styled.p`
  ${gwText.bodySmallSemibold};
  color: ${colors.dusk60};
  margin: 0;
`

export const LockCodeDetailValue = styled.p`
  ${gwText.bodySmall};
  color: ${colors.dusk60};
  margin: 0;
`
