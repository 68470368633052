import * as Sentry from '@sentry/react'

import { type AnalyticsPayload } from './track'

/**********************************************************
 * Segment
 *********************************************************/

/**
 * Warn us if the segment initialization fails so that we know we're missing data
 */
export const handleMissingSegmentAnalytics = (
  event: string,
  payload?: AnalyticsPayload,
): void => {
  Sentry.getCurrentScope()
    .setLevel('info')
    .setTag('Segment', 'analytics() undefined')
    .setExtra('Event Type', event)

  if (payload) {
    Sentry.getCurrentScope()
      .setExtra('Event Payload', payload)
      .setFingerprint(['Segment undefined'])
  }

  Sentry.captureMessage('Segment undefined')
}

export const handleSegmentUserIsNotAFunction = (): void => {
  Sentry.getCurrentScope()
    .setLevel('info')
    .setTag('Segment', 'analytics() undefined')
    .setFingerprint(['Segment user is not a function'])

  Sentry.captureMessage('Segment analytics.user is not a function')
}
