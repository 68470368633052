import styled from '@emotion/styled'
import { noop } from 'lodash/fp'
import React from 'react'

import {
  BannerTestIds,
  type DrawerContainerProps,
  LIGHTBOX_CLASS_SELECTOR,
  Drawer,
} from 'packages/common'

import { type Clean } from 'app/store/cleans'

import { type CleanDetailProps } from '../CleanDetail'
import { CleanDetailWrapper } from '../CleanDetailWrapper'
import { CREATE_TICKET_DRAWER_ID } from '../CreateTicket/CreateTicketDrawer'
import { CleanDetailDrawerId } from './CleanDetailDrawer.utils'

const St = {
  Drawer: styled(Drawer)`
    width: min(480px, 100%);

    & > button:first-of-type {
      // ensure the absolute-position button stays on top of relative-positioned tab content
      z-index: 2;
    }
  `,
}

type PickedDrawerProps = Pick<
  DrawerContainerProps,
  'afterExit' | 'afterOpen' | 'isOpen'
>
type PickedCleanDetailProps = Pick<CleanDetailProps, 'lockbox'>

export type CleanDetailDrawerProps = PickedDrawerProps &
  PickedCleanDetailProps & {
    clean: Clean | undefined
  }

export const CleanDetailDrawer: React.FC<CleanDetailDrawerProps> = React.memo(
  ({ afterExit = noop, afterOpen = noop, clean, isOpen, lockbox }) => {
    const handleClickAway = React.useCallback(event => {
      // prevent closing the drawer if the outside click was on the Lightbox
      const lightbox = document.querySelector(LIGHTBOX_CLASS_SELECTOR)
      const banner = document.querySelector(
        `div[data-testid="${BannerTestIds.container}"]`,
      )

      // prevent closing the drawer if the outside click was on the Create Ticket Drawer
      const createTicketDrawer = document.querySelector(
        `#${CREATE_TICKET_DRAWER_ID}`,
      )

      const createTicketDrawerBackdrop = document.querySelector(
        `#${CREATE_TICKET_DRAWER_ID}__backdrop`,
      )

      return (
        lightbox?.contains(event.target) ||
        banner?.contains(event.target) ||
        createTicketDrawer?.contains(event.target) ||
        createTicketDrawerBackdrop?.contains(event.target) ||
        false
      )
    }, [])

    const handleAfterExit = React.useCallback(() => {
      setIsDrawerForceClosed(false)
      afterExit()
    }, [afterExit])

    const [isDrawerForceClosed, setIsDrawerForceClosed] = React.useState(false)
    const forceCloseDrawer = () => {
      setIsDrawerForceClosed(true)
    }

    if (!clean) return null

    return (
      <St.Drawer
        afterExit={handleAfterExit}
        afterOpen={afterOpen}
        forceClose={isDrawerForceClosed}
        hideCloseButton={true}
        id={CleanDetailDrawerId}
        isOpen={isOpen}
        onClickAway={handleClickAway}
      >
        <CleanDetailWrapper
          clean={clean}
          onCloseDrawerClick={forceCloseDrawer}
          lockbox={lockbox}
        />
      </St.Drawer>
    )
  },
)
