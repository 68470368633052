import React from 'react'

import type { GwLocalHost } from 'app/store/gwLocalhosts'

import { GwLocalHostRowContainer } from '../GwLocalHostRow'

export interface GwLocalHostsListProps {
  editing: boolean
  gwLocalHosts: GwLocalHost[]
}

export const GwLocalHostsList: React.FC<GwLocalHostsListProps> = React.memo(
  ({ editing, gwLocalHosts }) => {
    return (
      <>
        {gwLocalHosts.map(lh => (
          <GwLocalHostRowContainer
            editing={editing}
            key={lh.id}
            gwLocalHost={lh}
          />
        ))}
      </>
    )
  },
)
