import styled from '@emotion/styled'

import { SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

import { gwColors, gwText } from 'app/styles'

import { Card } from '../../core'

export {
  ButtonWrapper,
  HeadingContainer,
  HeadingIconContainer,
  HeadingIcon,
  Heading,
} from '../Settings.styles'

export const SupportCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 24px;
`

export const CardBodyText = styled.p`
  ${gwText.bodySmall};
  color: ${colors.dusk60};
  margin-bottom: 16px;
`

export const SupportSection = styled.div`
  align-items: center;
  display: flex;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`

export const CardHeading = styled.h3`
  ${gwText.headingSmallCaps};
  color: ${colors.midnight70};
  margin: 0 0 0 12px;
`

export const CardTimeText = styled.p`
  ${gwText.bodySmall};
  color: ${colors.midnight70};
  margin: 0 0 0 10px;
`

export const ClockIcon = styled(SvgIcon)`
  color: ${colors.midnight70};
`

export const ContactInfo = styled.a`
  ${gwText.bodySmallSemibold};
  color: ${gwColors.links};
  margin: 0 0 0 16px;
`

export const ContactInfoIcon = styled(SvgIcon)`
  color: ${colors.midnight70};
`

export const ContactInfoIconBackground = styled.div`
  background-color: ${colors.midnight4};
  border-radius: 50%;
  padding: 8px;
`

export const VacasaIcon = styled(SvgIcon)`
  color: ${colors.white};
  position: relative;
  bottom: 1px;
`

export const VacasaIconBackGround = styled.div`
  background-color: ${colors.midnight};
  border-radius: 50%;
  padding: 10px;
`
