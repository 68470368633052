// The "source of truth" for these color definitions should be here:
// https://www.figma.com/file/DsLtETsedvI8spfexMWNLb/%E2%AC%A0-Polygon-Design-System?node-id=4%3A3

/* eslint-disable sort-keys */

export const colors = {
  alert: '#D62E4F',
  alert70: '#E97175',
  alert40: '#EFABB9',
  alert10: '#FCEBEB',

  aspen: '#99a98a',
  aspen50: '#ccd4c4',
  aspen40: '#d6ddd0',
  aspen10: '#f5f6f3',

  awareness: '#FAB60F',
  awareness70: '#EDD28F',
  awareness40: '#EDD28F',
  awareness10: '#FFF8E7',

  dusk: '#2c3439',
  dusk80: '#51585c',
  dusk70: '#4c5357',
  dusk60: '#707579',
  dusk40: '#969a9c',

  gulf: '#007F90',
  gulf20: '#CCE5E9',

  keyWest: '#FFD000',

  laguna: '#ef7831',

  lake: '#0078ab',
  lake70: '#4CA0C4',
  lake40: '#7FBBD5',
  lake20: '#cce5f4',
  lake10: '#E5F1F6',

  midnight: '#003349',
  midnight90: '#1A475B',
  midnight80: '#335c6d',
  midnight70: '#4d7080',
  midnight60: '#668592',
  midnight50: '#8099A4',
  midnight40: '#99adb6',
  midnight30: '#b2c2c8',
  midnight20: '#ccd6db',
  midnight10: '#e5ebed',
  midnight4: '#f5f7f8',
  midnight2: '#fbfbfc',

  sand: '#E6E3D6',

  success: '#6ba342',
  success70: '#97BE7A',
  success40: '#C4DAB3',
  success10: '#F0F5EC',

  tetons30: '#E2E8E8',

  white: '#ffffff',

  yellow: '#ddad49',
}
