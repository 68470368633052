import { createAsyncAction } from 'typesafe-actions'

import { UserApiFieldsWithoutPII } from 'packages/grimoire'
import {
  type RequestConfig,
  type RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { HousekeeperApiFields } from '../../housekeepers'
import { LockboxApiFields } from '../../lockboxes'
import { ReservationApiFields } from '../../reservations'
import { UnitApiFields } from '../../units'
import { cleansService } from '../cleans.service'
import {
  CleanApiFields,
  CleansActionTypes,
  type NormalizedCleansApiResponse,
} from '../cleans.types'

export const fetchSharedCleanByIdAction = createAsyncAction(
  CleansActionTypes.FETCH_CLEAN,
  CleansActionTypes.FETCH_CLEAN_SUCCESS,
  CleansActionTypes.FETCH_CLEAN_FAILURE,
)<
  RequestConfig<NormalizedCleansApiResponse>,
  NormalizedCleansApiResponse,
  Error
>()

export const getParams = (): RequestOptions => ({
  fields: {
    assignment: [],
    clean: CleanApiFields,
    housekeeper: HousekeeperApiFields,
    lock_box: LockboxApiFields,
    reservation: ReservationApiFields,
    unit: UnitApiFields,
    user: UserApiFieldsWithoutPII,
  },
  include: [
    'active_assignments',
    'active_assignments.housekeeper',
    'active_assignments.housekeeper.user',
    'reservation',
    'unit',
    'unit.lock_box',
  ],
  page: { size: 100 },
})

/**
 * This is nearly identical to fetchCleanById, but adds some additional includes that simplify
 * the process of fetching all of the data we need for a clean. (e.g. lockboxes, smartLocks)
 * These were not originally supported when we built the other action, and in the interest of minimizing
 * regression risk, we will keep them separate actions for now.
 */
export const fetchSharedCleanById = (cleanId: string) => async dispatch => {
  try {
    const params = getParams()
    const request = cleansService.fetchCleanById.bind(null, cleanId, params)
    const result = await dispatch(
      fetchSharedCleanByIdAction.request({ request }),
    )
    dispatch(fetchSharedCleanByIdAction.success(result))
    return result.normalized
  } catch (error) {
    dispatch(fetchSharedCleanByIdAction.failure(error))
    throw error
  }
}
