import { Events, track } from 'packages/wiretap'

import { getDelegateUserId } from '../auth/selectors'
import { type ApplicationState } from '../store'
import { getActiveUser } from '../users/selectors'
import { type CleanPatchData } from './cleans.types'

export const trackCleanCompleted = (
  patchData: CleanPatchData,
  getState: () => ApplicationState,
): void => {
  const { completedAt, id } = patchData
  if (!completedAt) return

  const state = getState()
  const activeUser = getActiveUser(state)
  const delegateUserId = getDelegateUserId(state)

  const payload = {
    clean_id: id,
    is_delegate: !!delegateUserId,
    user_id: activeUser?.id || delegateUserId || 'unknown',
  }

  track(Events.guestworksCleanCompleted, payload)
}
