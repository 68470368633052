import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAsyncFn } from 'react-use'

import { useOnlineStatus } from 'packages/utils/hooks'
import { logInfo } from 'packages/wiretap/logging'

import { fetchCleanById } from 'app/store/cleans/actions'
import { getCleanById } from 'app/store/cleans/selectors'
import { getIncludedLockboxByUnitId } from 'app/store/lockboxes/selectors'
import { type ApplicationState } from 'app/store/store'

import { CleanDetailDrawer } from './CleanDetailDrawer'
import { type UnknownAction } from '@reduxjs/toolkit'

export interface CleanDetailDrawerContainerProps {
  cleanId: string | undefined
}

export const CleanDetailDrawerContainer: React.FC<
  CleanDetailDrawerContainerProps
> = ({ cleanId = '' }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isOnline = useOnlineStatus().isOnline()

  // re-fetch the full clean details whenever the drawer is opened
  const [_, fetchClean] = useAsyncFn(async () => {
    if (!cleanId) return

    try {
      await dispatch(fetchCleanById(cleanId) as unknown as UnknownAction)
    } catch (err) {
      if (isOnline) {
        logInfo('Error fetching clean (drawer open)', {
          details: {
            cleanId,
            error: err?.message || 'unknown',
          },
        })
      }
    }
  }, [cleanId, dispatch, navigate, isOnline])

  const clean = useSelector((state: ApplicationState) =>
    getCleanById(state, cleanId),
  )

  const lockbox = useSelector((state: ApplicationState) =>
    getIncludedLockboxByUnitId(state, clean?.unit.id || ''),
  )

  const afterExit = React.useCallback(() => {
    navigate('/cleans')
  }, [navigate])

  // if we have a clean ID but no clean is found, we are here by mistake; go back to main cleans page
  React.useEffect(() => {
    if (cleanId && !clean) {
      navigate('/cleans')
    }
  }, [clean, cleanId, navigate])

  return (
    <CleanDetailDrawer
      afterExit={afterExit}
      afterOpen={fetchClean}
      clean={clean}
      isOpen={!!clean}
      lockbox={lockbox}
    />
  )
}
