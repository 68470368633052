import styled from '@emotion/styled'

import { SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

import { gwColors } from 'app/styles'

export const TicketsListControls = styled.div`
  padding: 16px 8px 0 8px;
  border-top: 1px solid ${gwColors.uiDivider};
`

export const TicketsList = styled.div`
  padding: 20px 8px;
`

export const NoResults = styled.div`
  align-items: center;
  color: ${colors.dusk};
  display: flex;
  flex-direction: column;
  font-weight: bold;
  line-height: 24px;
  padding: 26px;
  text-align: center;
`

export const NoResultsIcon = styled(SvgIcon)`
  color: ${colors.midnight60};
  margin-bottom: 16px;
`
