import { upperFirst } from 'lodash/fp'
import React from 'react'

import { Alert, ClipboardText, ExternalLink } from 'packages/common'
import { isWithinFreezeWindow } from 'packages/grimoire'
import { IconName, SvgIcon } from 'packages/iconic'
import { type AsyncState } from 'packages/utils/hooks'
import { getVacasaViewUnitURL } from 'packages/utils/links'

import {
  Button,
  LockCodeButtonContainer,
  LockCodeRequestType,
  isCleanLockCodeCreationDisabled,
} from 'app/components/core'
import { Slugs, useI18n } from 'app/i18n'
import { type Clean } from 'app/store/cleans'
import { type Lockbox } from 'app/store/lockboxes/lockboxes.types'
import { OrderedBathAmenities, OrderedBedAmenities } from 'app/store/units'
import { useActiveUser } from 'app/utils/hooks/useActiveUser'

import { CleanStatusBadges } from '../CleanStatusBadges'
import {
  composeOrderedAmenityString,
  formatGuestName,
  hasGuestDetails,
} from './CleanDetail.helpers'
import {
  CleanDetailBody,
  CleanDetailContainer,
  CleanDetailIcon,
  CleanDetailMainSection,
  CleanDetailSection,
  CleanDetailSubheader,
  EcdButtonWrapper,
  HkNote,
  LockCodeBody,
  PrivacyNoticeSection,
  CleanDetailHeader,
  CleanDetailCreateTicketDrawer,
} from './CleanDetail.styles'
import { AccessCodes, AddressInfo, DateInfo } from './components'
import { CleanNotesDisplay } from './components/CleanNotesDisplay'

const URL =
  'https://www.vacasa.com/policies/privacy-notice-employees-and-contractors'

const St = {
  CleanDetailBody,
  CleanDetailContainer,
  CleanDetailCreateTicketDrawer,
  CleanDetailHeader,
  CleanDetailIcon,
  CleanDetailMainSection,
  CleanDetailSection,
  EcdButtonWrapper,
  HkNote,
  LockCodeBody,
  PrivacyNoticeSection,
  Subheader: CleanDetailSubheader,
}

const useTranslations = ({ clean, lockCode, unit, reservation }) => {
  const { t, tp } = useI18n()

  const getLockCodeBody = () => {
    if (isCleanLockCodeCreationDisabled(clean)) {
      return Slugs.disabledLockCodeBody
    }

    return lockCode
      ? Slugs.enabledLockCodeBodyWithCode
      : Slugs.enabledLockCodeBody
  }

  return {
    additionalInstructions: t(Slugs.additionalInstructions),
    bathrooms: tp(Slugs.bath, unit?.bathrooms || 0),
    bathTypesString: composeOrderedAmenityString(
      OrderedBathAmenities,
      unit.amenities,
      tp,
    ),
    beds: tp(Slugs.bed, unit?.beds || 0),
    bedTypesString: composeOrderedAmenityString(
      OrderedBedAmenities,
      unit.amenities,
      tp,
    ),
    buttonText: t(
      clean.completedAt ? upperFirst(Slugs.completed) : Slugs.markAsComplete,
    ),
    cleanId: t(Slugs.cleanId),
    cleanIdForUkg: t(Slugs.cleanIdForUkg),
    editType: t(Slugs.editType),
    guestDetails: t(Slugs.guestDetails),
    homeInfo: t(Slugs.homeInfo),
    hotTub: t(Slugs.hotTub),
    lastResString: `${tp(Slugs.guest, reservation.guests)}, ${tp(Slugs.dog, reservation.dogs)}`,
    lockCodeBody: t(getLockCodeBody(), { lockCode }),
    lockCodes: t(Slugs.lockCodes, { lockCode }),
    maxOccupancy: t(Slugs.maxOccupancy),
    oopsChangesNotSaved: `${t(Slugs.oopsChangesNotSaved)}`,
    priorReservation: t(Slugs.priorReservation),
    privacyNotice: t(Slugs.privacyNotice),
    rescheduleAClean: t(Slugs.rescheduleAClean),
    viewHome: t(Slugs.viewHome),
  }
}

export enum CleanDetailTestIds {
  container = 'CleanDetail__container',
  ecdButton = 'CleanDetail__ecdButton',
  guestDetails = 'CleanDetail__guestDetails',
  guestEmail = 'CleanDetail__guestEmail',
  guestName = 'CleanDetail__guestName',
  guestPhone = 'CleanDetail__guestPhone',
  hotTubInfo = 'CleanDetail__hotTubInfo',
  lockCodes = 'CleanDetail__lockCodes',
}

export interface CleanDetailProps {
  clean: Clean
  lockbox?: Lockbox
  requestState: AsyncState<unknown>
  showEcdModal: () => void
  showModal: () => void
}

export const CleanDetail: React.FC<CleanDetailProps> = React.memo(
  ({ clean, lockbox, requestState, showEcdModal, showModal }) => {
    const { isEmployee } = useActiveUser()

    const { reservation, unit } = clean
    const { guestEmail, guestPhone } = reservation
    const { amenities, housekeeperNotes, id: unitId, hasSmartLock } = unit

    const isGuestDetails = hasGuestDetails(reservation)
    const guestName = formatGuestName(reservation)
    const [lockCode, setLockCode] = React.useState('')
    const strings = useTranslations({ clean, lockCode, reservation, unit })

    const frozen = isWithinFreezeWindow(clean.effectiveDate)
    const hottubAvailable = !!amenities.hotTub && amenities.hotTub > 0

    return (
      <St.CleanDetailContainer data-testid={CleanDetailTestIds.container}>
        <St.CleanDetailHeader clean={clean} />

        <div>
          <CleanStatusBadges clean={clean} />
          <AddressInfo clean={clean} />

          <St.CleanDetailMainSection>
            {requestState.error && (
              <Alert alertType="danger">{strings.oopsChangesNotSaved}</Alert>
            )}
            <Button
              block={true}
              buttonType={'secondary'}
              isDisabled={!!clean.completedAt || requestState.loading}
              isLoading={requestState.loading}
              onPress={showModal}
            >
              {strings.buttonText}
            </Button>
          </St.CleanDetailMainSection>

          <DateInfo clean={clean} />

          <St.CleanDetailMainSection>
            <CleanNotesDisplay notes={clean.notes || ''} />

            {/* "Rescheduling a clean" + ECD info modal */}
            <St.EcdButtonWrapper>
              <Button
                buttonType="text"
                dataTestId={CleanDetailTestIds.ecdButton}
                onPress={showEcdModal}
              >
                {strings.rescheduleAClean}
                <SvgIcon icon={IconName.info} size={14} />
              </Button>
            </St.EcdButtonWrapper>

            {/* Lock Codes */}
            {hasSmartLock && (
              <St.CleanDetailSection data-testid={CleanDetailTestIds.lockCodes}>
                <St.CleanDetailIcon icon={IconName.lock} size={14} />
                <St.CleanDetailBody>
                  <div>
                    <strong>{strings.lockCodes}</strong>
                  </div>
                  <St.LockCodeBody>{strings.lockCodeBody}</St.LockCodeBody>
                  <LockCodeButtonContainer
                    itemId={clean.id}
                    type={LockCodeRequestType.clean}
                    onSetLockCode={setLockCode}
                    isCreationDisabled={() =>
                      isCleanLockCodeCreationDisabled(clean)
                    }
                  />
                </St.CleanDetailBody>
              </St.CleanDetailSection>
            )}

            {/* Prior Reservation */}
            <St.CleanDetailSection>
              <St.CleanDetailIcon icon={IconName.users} size={14} />
              <St.CleanDetailBody>
                <div>
                  <strong>{strings.priorReservation}</strong>
                </div>
                <div>{strings.lastResString}</div>
              </St.CleanDetailBody>
            </St.CleanDetailSection>

            {frozen && <AccessCodes lockbox={lockbox} unit={unit} />}

            {/* Home Info */}
            <St.CleanDetailSection>
              <St.CleanDetailIcon icon={IconName.home} size={14} />
              <St.CleanDetailBody>
                <div>
                  <strong>{strings.homeInfo}</strong>
                </div>

                <div>
                  <St.Subheader>{strings.beds}</St.Subheader>
                  {strings.bedTypesString && ` (${strings.bedTypesString})`}
                </div>

                <div>
                  <St.Subheader>{strings.bathrooms}</St.Subheader>
                  {strings.bathTypesString && ` (${strings.bathTypesString})`}
                </div>
                <div>
                  <St.Subheader>{strings.maxOccupancy}</St.Subheader>
                  {` ${unit.maxOccupancy}`}
                </div>

                {hottubAvailable && (
                  <div>
                    <St.Subheader data-testid={CleanDetailTestIds.hotTubInfo}>
                      {strings.hotTub}
                    </St.Subheader>
                  </div>
                )}

                <ExternalLink
                  text={strings.viewHome}
                  url={getVacasaViewUnitURL(unitId)}
                />
              </St.CleanDetailBody>
            </St.CleanDetailSection>

            {/* HK Notes */}
            {!!housekeeperNotes && (
              <St.CleanDetailSection>
                <St.CleanDetailIcon icon={IconName.info} size={14} />
                <St.CleanDetailBody>
                  <div>
                    <strong>{strings.additionalInstructions}</strong>
                  </div>

                  <div>
                    {housekeeperNotes.split('\n').map((paragraph, i) => (
                      <St.HkNote key={i}>{paragraph}</St.HkNote>
                    ))}
                  </div>
                </St.CleanDetailBody>
              </St.CleanDetailSection>
            )}

            {/* Guest Details */}
            {isGuestDetails && (
              <St.CleanDetailSection
                data-testid={CleanDetailTestIds.guestDetails}
              >
                <St.CleanDetailIcon icon={IconName.user2} size={14} />
                <St.CleanDetailBody>
                  <strong>{strings.guestDetails}</strong>
                  {!!guestName && (
                    <div data-testid={CleanDetailTestIds.guestName}>
                      {guestName}
                    </div>
                  )}
                  {!!guestPhone && (
                    <div data-testid={CleanDetailTestIds.guestPhone}>
                      {guestPhone}
                    </div>
                  )}
                  {!!guestEmail && (
                    <div data-testid={CleanDetailTestIds.guestEmail}>
                      {guestEmail}
                    </div>
                  )}
                </St.CleanDetailBody>
              </St.CleanDetailSection>
            )}

            {isEmployee && (
              <St.CleanDetailSection>
                <St.CleanDetailIcon icon={IconName.watch} size={14} />
                <St.CleanDetailBody>
                  <div>
                    <strong>{strings.cleanId}</strong>
                  </div>
                  <div>
                    {strings.cleanIdForUkg}
                    <em> ClockIt! with UKG</em>
                  </div>
                  <ClipboardText>{clean.id}</ClipboardText>
                </St.CleanDetailBody>
              </St.CleanDetailSection>
            )}

            <St.CleanDetailCreateTicketDrawer unit={unit} />

            <St.PrivacyNoticeSection>
              <ExternalLink text={strings.privacyNotice} url={URL} />
            </St.PrivacyNoticeSection>
          </St.CleanDetailMainSection>
        </div>
      </St.CleanDetailContainer>
    )
  },
)
