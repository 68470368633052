import { createSelector } from 'reselect'

import { MissingDataError } from 'packages/utils/errors'
import { transformNormalizedToTyped } from 'packages/utils/store/store.utils'

import { type ApplicationState } from '../../store'
import {
  type Lockbox,
  type LockboxesState,
  LockboxAttributeNames,
} from '../lockboxes.types'

const getLockboxState = (state: ApplicationState): LockboxesState =>
  state.lockboxes

const getLockboxId = (_state: ApplicationState, id: string) => id

export const getLockboxById = createSelector(
  getLockboxState,
  getLockboxId,
  (lockboxState, lockboxId): Lockbox => {
    const lockbox = lockboxState.data[lockboxId]

    if (!lockbox) {
      throw new MissingDataError('lockbox', lockboxId)
    }

    return transformNormalizedToTyped<Lockbox>(lockbox, LockboxAttributeNames)
  },
)
