import { Events, track } from 'packages/wiretap'

import { getDelegateUserId } from '../auth/selectors'
import { type ApplicationState } from '../store'
import { getActiveUser } from '../users/selectors'
import { type TicketPatchData, type TicketPostData } from './tickets.types'

export const trackTicketCompleted = (
  patchData: TicketPatchData,
  getState: () => ApplicationState,
): void => {
  const { id } = patchData
  const state = getState()
  const activeUser = getActiveUser(state)
  const delegateUserId = getDelegateUserId(state)

  const payload = {
    completed_by: activeUser?.id || delegateUserId || 'unknown',
    completed_ticket_id: id,
    is_delegate: !!delegateUserId,
  }

  track(Events.guestworksTicketCompleted, payload)
}

export const trackTicketCreated = (
  postData: TicketPostData,
  getState: () => ApplicationState,
): void => {
  const { unitId } = postData
  const state = getState()
  const activeUser = getActiveUser(state)
  const delegateUserId = getDelegateUserId(state)

  const payload = {
    associated_unit_id: unitId || 'unknown',
    created_by: activeUser?.id || delegateUserId || 'unknown',
    is_delegate: !!delegateUserId,
  }

  track(Events.guestworksTicketCreated, payload)
}
