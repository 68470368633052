import {
  findRelationshipId,
  transformNormalizedToTyped,
} from 'packages/utils/store'

import { getAssignmentById } from '../assignments/selectors'
import { getReservationById } from '../reservations/selectors'
import { type ApplicationState } from '../store'
import { getUnitById } from '../units/selectors'
import {
  type Clean,
  CleanAttributeNames,
  type CleansResponse,
  type NormalizedCleansApiResponse,
  type RawClean,
} from './cleans.types'

/**
 * Empty data in the shape of the Cleans service's `normalized` payload.
 * Use this as a fallback when there are issues with the API data being undefined or malformed.
 */
export const emptyNormalizedCleansData: CleansResponse = Object.freeze({
  assignment: {},
  clean: {},
  gwUnitStaffer: {},
  housekeeper: {},
  lockBox: {},
  reservation: {},
  unit: {},
  user: {},
})

/**
 * Empty data to use as a "safety net" any time an API response is undefined for any reason
 */
export const emptyCleansResponse: NormalizedCleansApiResponse = Object.freeze({
  normalized: emptyNormalizedCleansData,
  raw: { data: [] },
})

export const hydrateRawClean =
  (state: ApplicationState) =>
  (rawClean: RawClean): Clean => {
    const assignments = rawClean.relationships.activeAssignments.data.map(ass =>
      getAssignmentById(state, ass.id),
    )

    // populate reservation (required)
    const reservationId = findRelationshipId(rawClean, 'reservation')
    const reservation = getReservationById(state, reservationId || '')
    if (!reservation) {
      throw Error(`A reservation could not be found with ID: ${reservationId}`)
    }

    // populate unit (required)
    const unitId = findRelationshipId(rawClean, 'unit')
    if (!unitId) {
      throw Error(`A unit could not be found with ID: ${unitId}`)
    }

    const unit = getUnitById(state, unitId)

    const clean = transformNormalizedToTyped<Clean>(
      rawClean,
      CleanAttributeNames,
    )

    return {
      ...clean,
      assignments,
      reservation,
      unit,
    }
  }
