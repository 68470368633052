import classNames from 'classnames'
import * as React from 'react'
import BeatLoader from 'react-spinners/BeatLoader'
import ClipLoader from 'react-spinners/ClipLoader'

import { colors } from 'packages/styles'

import styles from './Loader.module.scss'

export const LOADER_BASE_COLOR = colors.lake

export enum LoaderAlignment {
  center = 'centerAligned',
  left = 'leftAligned',
  right = 'rightAligned',
}

export enum LoaderType {
  Beat = 'beat',
  Clip = 'clip',
}

const loaderMap = {
  [LoaderType.Beat]: <BeatLoader />,
  [LoaderType.Clip]: <ClipLoader />,
}

export enum LoaderTestIds {
  container = 'Loader__container',
}

export interface LoaderProps {
  /**
   * (Optional) The alignment position of the loader display.
   * Default: center
   **/
  alignment?: LoaderAlignment
  /**
   * (Optional) The color for the animated part of the loader. Will default to a blue-ish color.
   **/
  color?: string | null
  /**
   * (Optional) The size of the loader element. As a frame of reference, the values used here are
   * roughly the same as what you would generally use for `font-size`.
   */
  size?: number
  /**
   * (Optional) Whether the backdrop for the loader should have a transparent background.
   * Useful when using the Loader as a full-page loader.
   */
  transparent?: boolean
  /** (Optional)  Specify which loading icon to display within the button */
  type?: LoaderType
}

/**
 * A component to show loading state on top of any component.
 * This component is currently using the [react-spinners](https://github.com/davidhu2000/react-spinners)
 * library for rendering the loading UI.
 *
 * Note that the Loader uses `position: absolute`, and fills the parent container. Thus, if the parent
 * uses `position: relative`, it will simply fill the parent; otherwise, it will expand to fill the page.
 * This is useful for making "full page" loaders, although you will probably want to ensure that you set the
 * prop `transparent={true}` when using it in this manner.
 */
export const Loader: React.FC<LoaderProps> = React.memo(
  ({
    alignment = LoaderAlignment.center,
    color = LOADER_BASE_COLOR,
    size = 16,
    transparent = false,
    type = LoaderType.Beat,
  }) => {
    return (
      <div className={styles[type]} data-testid={LoaderTestIds.container}>
        <div
          className={classNames(styles.backdrop, {
            [styles.transparent]: transparent,
          })}
        />

        {React.cloneElement(loaderMap[type], {
          className: classNames(styles.loader, styles[alignment]),
          color,
          size,
        })}
      </div>
    )
  },
)
