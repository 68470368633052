import styled from '@emotion/styled'
import React from 'react'

import { Drawer, type DrawerContainerProps } from 'packages/common'
import { CloseButton } from 'packages/common/src/modals/components'
import { centerWithFlex, colors } from 'packages/styles'

import { CREATE_TICKET_DRAWER_ID } from 'app/components/schedule/components/CleanDetail/CreateTicket/CreateTicketDrawer'
import { type Ticket } from 'app/store/tickets'

import { ShareTicketButtonContainer } from '../../ShareTicketButton'
import { TicketDetail } from '../TicketDetail'

const St = {
  CloseButton: styled(CloseButton)`
    position: static;
  `,

  Drawer: styled(Drawer)`
    width: min(480px, 100%);
  `,

  Header: styled.div`
    align-items: center;
    background-color: ${colors.midnight4};
    box-shadow: 0 5px 11px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: sticky;
    top: 0;
    z-index: 1;
  `,

  HeaderControls: styled.div`
    ${centerWithFlex};
    align-items: center;
    display: flex;
    height: 72px;
    justify-content: space-between;
    margin-right: auto;
    padding: 0 16px;
    width: 100%;
  `,
}

type PickedDrawerProps = Pick<DrawerContainerProps, 'afterExit' | 'isOpen'>
export type TicketDetailDrawerProps = PickedDrawerProps & {
  ticket: Ticket | undefined
  userId: string
}

export const TicketDetailDrawer: React.FC<TicketDetailDrawerProps> = ({
  afterExit,
  isOpen,
  ticket,
}) => {
  const handleClickAway = React.useCallback(event => {
    // prevent closing the drawer if the outside click was on the Create Ticket Drawer
    const createTicketDrawer = document.querySelector(
      `#${CREATE_TICKET_DRAWER_ID}`,
    )

    const createTicketDrawerBackdrop = document.querySelector(
      `#${CREATE_TICKET_DRAWER_ID}__backdrop`,
    )

    return (
      createTicketDrawer?.contains(event.target) ||
      createTicketDrawerBackdrop?.contains(event.target) ||
      false
    )
  }, [])

  const handleAfterExit = React.useCallback(() => {
    setIsDrawerForceClosed(false)
    afterExit()
  }, [afterExit])

  const [isDrawerForceClosed, setIsDrawerForceClosed] = React.useState(false)
  const forceCloseDrawer = () => {
    setIsDrawerForceClosed(true)
  }

  if (!ticket) return null

  return (
    <St.Drawer
      afterExit={handleAfterExit}
      isOpen={isOpen}
      forceClose={isDrawerForceClosed}
      onClickAway={handleClickAway}
    >
      <St.Header>
        <St.HeaderControls>
          <ShareTicketButtonContainer ticket={ticket} />
          <St.CloseButton onClick={forceCloseDrawer}></St.CloseButton>
        </St.HeaderControls>
      </St.Header>

      <TicketDetail ticket={ticket} />
    </St.Drawer>
  )
}
