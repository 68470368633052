import React from 'react'
import { useDispatch } from 'react-redux'

import { useConfirmModal } from 'packages/common'
import { type ReduxActionCallbacks } from 'packages/grimoire/src/utils'
import { useAsyncFnWithReset, type AsyncState } from 'packages/utils/hooks'

import { useGwToast } from 'app/components/core/hooks'
import { Slugs, useI18n } from 'app/i18n'
import {
  type Ticket,
  type TicketPatchData,
  TicketStatus,
} from 'app/store/tickets'
import { updateTicket } from 'app/store/tickets/actions'
import { type UnknownAction } from '@reduxjs/toolkit'

interface UseUpdateTicket {
  showModal: () => void
  updateTicketState: AsyncState<unknown>
}

export const useUpdateTicket = (ticket: Ticket): UseUpdateTicket => {
  const { t } = useI18n()
  const dispatch = useDispatch()
  const { showGwToast } = useGwToast()

  const [updateTicketState, updateTicketFn] = useAsyncFnWithReset(
    async (patchData: TicketPatchData, callbacks: ReduxActionCallbacks = {}) =>
      dispatch(updateTicket(patchData, callbacks) as unknown as UnknownAction),
    [],
  )

  const handleUpdateTicket = React.useCallback(() => {
    updateTicketFn(
      {
        id: ticket.id,
        status: TicketStatus.RESOLVED_COMPLETED,
      },
      {
        onSuccess: () => {
          showGwToast({ message: t(Slugs.ticketUpdateSuccess) })
        },
      },
    )
  }, [updateTicketFn, showGwToast, t, ticket.id])

  const { showModal } = useConfirmModal({
    onConfirm: handleUpdateTicket,
    slugs: {
      message: t(Slugs.ticketCompleteModalBody),
      title: t(Slugs.areYouSure),
    },
  })

  return {
    showModal,
    updateTicketState,
  }
}
