import styled from '@emotion/styled'
import * as React from 'react'

import { UnitRealtimeStatus } from 'packages/grimoire'
import { SvgIcon, IconName } from 'packages/iconic'
import { colors } from 'packages/styles'

import { StatusOrbType } from '../../StatusOrb'
import { UnitRealtimeStatusIndicatorTestIds } from './UnitRealtimeStatusIndicator'

const CompleteStatusIcon = styled(SvgIcon)`
  color: ${colors.success70};
`

const OffsetIcon = styled(SvgIcon)`
  padding-bottom: 3px;
`

interface UnitRealtimeStatusIconProps {
  dataTestId: string
}

const CheckMark: React.FC<UnitRealtimeStatusIconProps> = ({ dataTestId }) => (
  <CompleteStatusIcon
    icon={IconName.check}
    dataTestId={dataTestId ?? UnitRealtimeStatusIndicatorTestIds.check}
    size={14}
  />
)

const VacasaHome: React.FC<UnitRealtimeStatusIconProps> = ({ dataTestId }) => (
  <OffsetIcon
    icon={IconName.vacasaHold}
    dataTestId={dataTestId ?? UnitRealtimeStatusIndicatorTestIds.vacasaHold}
    size={20}
  />
)

const OwnerHold: React.FC<UnitRealtimeStatusIconProps> = ({ dataTestId }) => (
  <OffsetIcon
    icon={IconName.crown}
    dataTestId={dataTestId ?? UnitRealtimeStatusIndicatorTestIds.ownerHold}
    size={22}
  />
)

const Guest: React.FC<UnitRealtimeStatusIconProps> = ({ dataTestId }) => (
  <OffsetIcon
    icon={IconName.user2}
    dataTestId={dataTestId ?? UnitRealtimeStatusIndicatorTestIds.guestOccupied}
    size={18}
  />
)

export const unitRealtimeStatusIndicatorMap = {
  [UnitRealtimeStatus.CLEAN_IN_PROGRESS_OVERDUE]: StatusOrbType.RED,
  [UnitRealtimeStatus.CLEAN_IN_PROGRESS]: StatusOrbType.BLUE,
  [UnitRealtimeStatus.CLEAN_READY_OVERDUE]: StatusOrbType.RED,
  [UnitRealtimeStatus.CLEAN_READY]: StatusOrbType.YELLOW,
}

const animatedStatuses = [
  UnitRealtimeStatus.CLEAN_IN_PROGRESS,
  UnitRealtimeStatus.CLEAN_IN_PROGRESS_OVERDUE,
]

export const shouldAnimate = (status: UnitRealtimeStatus): boolean =>
  animatedStatuses.includes(status)

const noIconUnitRealtimeStatuses = [
  UnitRealtimeStatus.UNKNOWN,
  UnitRealtimeStatus.VACANT_NO_VACASA_CLEAN,
]

export const hasIconForStatus = (status: UnitRealtimeStatus): boolean =>
  !noIconUnitRealtimeStatuses.includes(status)

export const iconRenderMap = {
  [UnitRealtimeStatus.CLEAN_COMPLETED]: CheckMark,
  [UnitRealtimeStatus.OCCUPIED_GUEST]: Guest,
  [UnitRealtimeStatus.OWNER_HOLD]: OwnerHold,
  [UnitRealtimeStatus.VACASA_HOLD]: VacasaHome,
}

export const rendersIcon = (status: UnitRealtimeStatus): boolean =>
  Object.keys(iconRenderMap).includes(status)
