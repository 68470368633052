import { upperFirst } from 'lodash/fp'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { IconName, SvgIcon } from 'packages/iconic'

import { Slugs, useI18n } from 'app/i18n'
import { type Ticket } from 'app/store/tickets'

import { Button } from '../../core'
import { useTranslateTickets } from '../hooks'
import { useUpdateTicket } from '../hooks/useUpdateTicket'
import { isTicketCompleted } from '../tickets.helpers'
import { TicketStatusBadge } from '../TicketStatusBadge'
import * as St from './TicketCard.styles'

const iconTextStyles: React.CSSProperties = {
  fontWeight: 'bold',
  lineHeight: '16px',
  textTransform: 'uppercase',
}

const useTranslations = (ticket: Ticket) => {
  const { t } = useI18n()

  return {
    buttonText: t(
      isTicketCompleted(ticket.status)
        ? upperFirst(Slugs.completed)
        : Slugs.markAsComplete,
    ),
  }
}

export enum TicketCardTestIds {
  button = 'TicketCard__button',
  container = 'TicketCard__container',
  statusBadgeContainer = 'TicketCard__statusBadgeContainer',
}

export interface TicketCardProps {
  ticket: Ticket
}

export const TicketCard: React.FC<TicketCardProps> = ({ ticket }) => {
  const navigate = useNavigate()
  const strings = useTranslations(ticket)

  const {
    id,
    severity,
    status,
    title,
    unit: { address, city, state },
  } = ticket

  const { showModal, updateTicketState } = useUpdateTicket(ticket)

  const { translateSeverity } = useTranslateTickets()

  const handleClick = React.useCallback(() => {
    navigate(`/tickets?selectedTicket=${id}`)
  }, [navigate, id])

  return (
    <St.StyledTicketCard
      dataTestId={TicketCardTestIds.container}
      onClick={handleClick}
    >
      <St.StatusBar>
        <SvgIcon
          centerItems={true}
          icon={IconName.tag}
          text={translateSeverity(severity)}
          textFontSize={12}
          textStyleOverrides={iconTextStyles}
        />
      </St.StatusBar>

      <St.InfoContainer>
        <St.StatusBadgeContainer
          data-testid={TicketCardTestIds.statusBadgeContainer}
        >
          <TicketStatusBadge status={status} />
        </St.StatusBadgeContainer>

        <St.Title>{title}</St.Title>
        <St.Address>
          {address}, {city}, {state}
        </St.Address>
        <Button
          buttonType={'secondary'}
          block={true}
          dataTestId={TicketCardTestIds.button}
          isDisabled={isTicketCompleted(status)}
          isLoading={updateTicketState.loading}
          onPress={showModal}
        >
          {strings.buttonText}
        </Button>
      </St.InfoContainer>
    </St.StyledTicketCard>
  )
}
