import { produce } from 'immer'

import { transformNormalizedToTyped } from 'packages/utils/store'

import {
  type NormalizedReservationsApiResponse,
  type RawReservation,
  type Reservation,
  ReservationAttributeNames,
  type ReservationsResponse,
} from './reservations.types'

export const emptyNormalizedReservationsData =
  Object.freeze<ReservationsResponse>({
    reservation: {},
    unit: {},
  })

export const emptyReservationsResponse: NormalizedReservationsApiResponse =
  Object.freeze({
    normalized: emptyNormalizedReservationsData,
    raw: { data: [] },
  })

export function hydrateRawRes(rawRes: RawReservation): Reservation {
  const baseRes = transformNormalizedToTyped<Reservation>(
    rawRes,
    ReservationAttributeNames,
  )

  return produce(baseRes, draft => {
    draft.unitId = rawRes.relationships?.unit?.data?.id || ''
  })
}
