import { filter, map, pipe, prop, propEq, values } from 'lodash/fp'

import { type ApplicationState } from '../../store'
import { type Disposition } from '../tickets.types'
import { hydrateRawDisposition } from '../tickets.utils'

export const getAllTierThreeMaintenanceDispositions: (
  state: ApplicationState,
) => Disposition[] = pipe(
  prop('tickets.dispositions'),
  values,
  filter(propEq('attributes.tierOne', 'maintenance')),
  map(hydrateRawDisposition),
)
