import styled from '@emotion/styled'

import { SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

import { gwText } from 'app/styles'

export const StyledDateTimeButton = styled.button`
  align-items: center;
  background-color: ${colors.white};
  border: 1px solid ${colors.midnight10};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const Children = styled.span`
  ${gwText.bodyDefault}
  color: ${colors.dusk60};
  padding: 12px 20px 12px 12px;
`
export const RightIcon = styled(SvgIcon)`
  padding: 0 12px 0 0;
`
