import styled from '@emotion/styled'
import React from 'react'

import { Button } from 'packages/common'
import { IconName, SvgIcon } from 'packages/iconic'

import { useDelegateToken } from 'app/components/core/hooks/useDelegateToken'
import { type Ticket } from 'app/store/tickets'

const St = {
  Icon: styled(SvgIcon)`
    margin-right: 11px;
    padding-bottom: 2px;
  `,
}

export interface ShareTicketButtonProps {
  accessToken: string
  ticket: Ticket
}

export const ShareTicketButton: React.FC<ShareTicketButtonProps> = React.memo(
  ({ accessToken, ticket }) => {
    const ticketId = ticket?.id
    const unitAddress = ticket?.unit?.address

    const {
      buttonText,
      canShare,
      copyLinkText,
      disabled,
      fetchTokenState,
      onClick,
    } = useDelegateToken(accessToken, ticketId, 'tickets', unitAddress)

    return (
      <Button
        disabled={disabled}
        isLoading={fetchTokenState.loading}
        onClick={onClick}
        buttonType={'secondary'}
      >
        {canShare ? (
          <>
            <St.Icon icon={IconName.share2} size={24} />
            {buttonText}
          </>
        ) : (
          <>
            <St.Icon icon={IconName.copy} size={22} />
            {copyLinkText}
          </>
        )}
      </Button>
    )
  },
)
