import React from 'react'

import { Card } from 'app/components/core'

import { LockCodeFormContainer } from '../LockCodeForm'

export enum LockCodeCardTestIds {
  container = 'LockCodeCard__container',
}

export interface LockCodeCardProps {
  accessToken: string
}

export const LockCodeCard: React.FC<LockCodeCardProps> = React.memo(
  ({ accessToken }) => {
    return (
      <Card dataTestId={LockCodeCardTestIds.container}>
        <LockCodeFormContainer accessToken={accessToken} />
      </Card>
    )
  },
)
