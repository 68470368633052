import styled from '@emotion/styled'

import { SvgIcon } from 'packages/iconic'
import { centerWithFlex, colors } from 'packages/styles'

export const Icon = styled(SvgIcon)`
  color: ${colors.midnight70};
`

export const IconBackground = styled.div`
  ${centerWithFlex};
  background-color: ${colors.midnight10};
  border-radius: 50%;
  height: 24px;
  width: 24px;
  flex-shrink: 0;
`

export const Image = styled.img`
  height: 24px;
  object-fit: cover;
`

export const ImageContainer = styled.div`
  ${centerWithFlex};
  border-radius: 50%;
  max-height: 24px;
  max-width: 24px;
  overflow: hidden;
`
