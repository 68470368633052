import { type AxiosResponse } from 'axios'
import { snakeCase, xor } from 'lodash/fp'

import {
  type HkEmployeeType,
  type JSONApiHousekeepersMap,
} from 'packages/grimoire'

import {
  type JSONApiObjectWithRelationships,
  type JSONApiObjectWithRelationshipsMap,
  type NormalizedJSONApiResponse,
  type ToOneRelationship,
} from '../utils'

/**********************************************************
 * SERVICE TYPES
 *********************************************************/
export interface UsersResponse {
  housekeeper: JSONApiHousekeepersMap
  user: JSONApiUsersMap
}

export type NormalizedUsersApiResponse =
  NormalizedJSONApiResponse<UsersResponse>

export type UsersApiResponse = AxiosResponse<NormalizedUsersApiResponse>
export type UsersServiceResponse = Promise<NormalizedUsersApiResponse>

/**********************************************************
 * USER
 *********************************************************/
export type User = {
  housekeeperId?: string
  id: string
} & UserAttributes

export interface UserAttributes {
  address: string
  employeeType: HkEmployeeType | null
  firstName: string
  isHomeowner: boolean
  isHousekeepingManager: boolean
  isSuperuser: boolean
  lastName: string
  mobilePhone: string
  monolithUserId: string | null
}

export interface UserRelationships {
  housekeeper: ToOneRelationship
}

export const UserAttributeNames = [
  'firstName',
  'isHomeowner',
  'isHousekeepingManager',
  'isSuperuser',
  'lastName',
  'address',
  'employeeType',
  'mobilePhone',
  'monolithUserId',
]

export const UserApiFields: string[] = UserAttributeNames.map(snakeCase)

export const UserApiFieldsWithoutPII = xor(UserApiFields, [
  'address',
  'mobile_phone',
])

export type RawUser = JSONApiObjectWithRelationships<
  UserAttributes,
  UserRelationships
>

export type JSONApiUsersMap = JSONApiObjectWithRelationshipsMap<
  UserAttributes,
  UserRelationships
>
