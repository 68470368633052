import React from 'react'

import { AsyncSelector } from 'packages/common'

import { type User } from 'app/store/users'

interface AsyncUserSelectorProps {
  className?: string
  defaultOptions?: User[]
  getUsersResults: () => User[]
  isDisabled?: boolean
  onUserSelectionChange: (value: User) => void
  searchUsers: (input: string) => Promise<any> // eslint-disable-line
  selectedUser?: User
}

const AsyncUserSelector: React.FC<AsyncUserSelectorProps> = ({
  className,
  getUsersResults,
  isDisabled = false,
  onUserSelectionChange,
  defaultOptions = [],
  searchUsers,
  selectedUser,
}) => {
  // The following line is used to refer to the current version of the getResults selector within load options
  const getResultsRef = React.useRef(getUsersResults)
  getResultsRef.current = getUsersResults

  const loadOptions = async (input, callback) => {
    if (input.length >= 3) {
      await searchUsers(input)
      const searchResultsInSelect = getResultsRef.current()
      callback(searchResultsInSelect)
    }
  }

  const noOptionsMessage = React.useCallback(
    ({ inputValue }) => (inputValue === '' ? 'Type to search' : 'No results'),
    [],
  )

  return (
    <AsyncSelector
      className={className}
      defaultOptions={defaultOptions}
      isDisabled={isDisabled}
      getOptionLabel={user => `${user.firstName} ${user.lastName}`}
      getOptionValue={user => user.id}
      loadingMessage={() => 'Loading...'}
      loadOptions={loadOptions}
      noOptionsMessage={noOptionsMessage}
      onChange={onUserSelectionChange}
      placeholder={'Select...'}
      value={selectedUser}
    />
  )
}

export default AsyncUserSelector
