import { sendToSentry, type SentryOptions } from './sendToSentry'

/**
 * Send an Error event to Sentry.
 *
 * This will show up as a standard "Error" event in Sentry, and these do have the potential
 * to trigger alarms if they occur frequently enough.
 *
 * This should generally be used for any bug/error/etc that is user-impacting and must be fixed.
 */
export const sendErrorToSentry = (
  error: Error,
  options: SentryOptions = {},
): void => {
  sendToSentry({ data: error, ...options })
}

/**
 * Send a message/log to Sentry.
 *
 * This will show up as an "Info" type event in Sentry, so it is useful for data that
 * we want to capture, but which should not be able to trigger any alarms.
 *
 * Note that, for clarity on the Sentry-side, all messages sent through here will be prepended with "LOG:".
 */
export const sendMessageToSentry = (
  _message: string,
  _options: SentryOptions = {},
): void => {
  // we have a kaizen ticket to remove this completely, but for now we need to silence it because it is too noisy on Sentry
  // sendToSentry({ data: `LOG: ${message}`, ...options })
}
