import styled from '@emotion/styled'
import { isIOS } from 'mobile-device-detect'
import { type Ref } from 'react'

import { colors, layers, shadows } from 'packages/styles'
import { isInOpenPhase } from 'packages/utils/hooks'

import { isHidden, isRenderable } from '../modals.utils'
import { type DrawerRendererProps } from './Drawer.types'

type StyledDrawerProps = DrawerRendererProps & {
  elementHeight: number
  elementWidth: number
  ref: Ref<HTMLDivElement>
}

const BasicDrawer = styled.div<StyledDrawerProps>`
  background: white;
  box-shadow: ${shadows.shadow4};
  box-sizing: border-box;
  max-height: 100vh;
  max-width: 100vw;
  overflow: auto;
  position: fixed;
  will-change: transform;
  z-index: ${layers.drawer};

  display: ${props => (isHidden(props) ? 'none' : 'inherit')};
  transition: ${({ duration }) => `transform ${duration}ms ease`};
  visibility: ${props => (isRenderable(props) ? 'visible' : 'hidden')};

  // after the element has been rendered and measured, we can lock that value in for "width"
  // this prevents child elements from re-sizing it unexpectedly from its original size
  // (e.g. a long string of text)
  width: ${({ elementWidth }) =>
    elementWidth ? `${elementWidth}px` : undefined};

  @media only screen and (max-width: 768px) {
    overflow-x: hidden;
    // add extra padding to the drawer on iOS to ensure we get around the control bars on versions <= 14
    padding-bottom: ${isIOS ? '100px' : 0};
    width: 100vw;
  }
`

//= ==================================================
// Vertical Drawers
// Full width; height based on children
//= =================================================
const YDrawer = styled(BasicDrawer)<StyledDrawerProps>`
  left: 0;
  right: 0;
  width: 100vw;
`

export const TopDrawer = styled(YDrawer)<StyledDrawerProps>`
  border-bottom: 1px solid ${colors.midnight20};

  top: ${({ elementHeight }) => (elementHeight ? `-${elementHeight}px` : 0)};
  transform: ${({ elementHeight, transitionPhase }) => {
    if (isInOpenPhase(transitionPhase)) {
      return `translateY(${elementHeight}px)`
    }

    return 'none'
  }};
`

export const BottomDrawer = styled(YDrawer)<StyledDrawerProps>`
  border-top: 1px solid ${colors.midnight20};

  bottom: ${({ elementHeight }) => (elementHeight ? `-${elementHeight}px` : 0)};
  transform: ${({ elementHeight, transitionPhase }) => {
    if (isInOpenPhase(transitionPhase)) {
      return `translateY(-${elementHeight}px)`
    }

    return 'none'
  }};
`

//= ==================================================
// Horizontal Drawers
// Full height; width based on children
//= =================================================
const XDrawer = styled(BasicDrawer)<StyledDrawerProps>`
  bottom: 0;
  height: 100vh;
  top: 0;
`

export const LeftDrawer = styled(XDrawer)<StyledDrawerProps>`
  border-right: 1px solid ${colors.midnight20};

  left: ${({ elementWidth }) => (elementWidth ? `-${elementWidth}px` : 0)};
  transform: ${({ transitionPhase, elementWidth }) => {
    if (isInOpenPhase(transitionPhase)) {
      return `translateX(${elementWidth}px)`
    }

    return 'none'
  }};
`

export const RightDrawer = styled(XDrawer)<StyledDrawerProps>`
  right: ${({ elementWidth }) => (elementWidth ? `-${elementWidth}px` : 0)};
  transform: ${({ transitionPhase, elementWidth }) => {
    if (isInOpenPhase(transitionPhase)) {
      return `translateX(-${elementWidth}px)`
    }

    return 'none'
  }};
`
