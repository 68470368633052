import { upperFirst } from 'lodash/fp'
import React from 'react'

import { cleanIsMidstay } from 'packages/grimoire'
import { type TranslatorFn, useI18n, Slugs } from 'packages/i18n'
import { IconName, SvgIcon } from 'packages/iconic'

import { type Clean } from 'app/store/cleans'

import {
  BaseCleanHeader,
  CleanTypeIcon,
  OwnerBadge,
} from './CleanHeader.styles'

interface CleanHeaderConfig {
  icon: IconName
  text: string
}

const getConfig = (clean: Clean, t: TranslatorFn): CleanHeaderConfig => {
  if (cleanIsMidstay(clean)) {
    return {
      icon: IconName.midstayClean,
      text: t(Slugs.midStay),
    }
  }

  return {
    icon: IconName.doorClose,
    text: t(Slugs.postStay),
  }
}

export enum CleanHeaderTestIds {
  ownerBadge = 'CleanHeader__ownerBadge',
}

export interface CleanHeaderProps {
  clean: Clean
  children?: React.ReactNode
}

export const CleanHeader: React.FC<CleanHeaderProps> = React.memo(props => {
  const { t } = useI18n()

  const { clean, children } = props
  const { icon, text } = getConfig(clean, t)

  return (
    <BaseCleanHeader {...props}>
      <CleanTypeIcon
        centerItems={true}
        icon={icon}
        text={text}
        textFontSize={'inherit'}
        size={16}
      />
      {children}
      {clean.isNextStayOwner && (
        <OwnerBadge data-testid={CleanHeaderTestIds.ownerBadge}>
          <SvgIcon
            icon={IconName.crown}
            text={upperFirst(t(Slugs.owner))}
            centerItems={true}
          />
        </OwnerBadge>
      )}
    </BaseCleanHeader>
  )
})
