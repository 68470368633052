import snakeCase from 'lodash/snakeCase'

import {
  type JSONApiObjectWithRelationships,
  type JSONApiObjectWithRelationshipsMap,
  type ToOneRelationship,
} from 'packages/utils/store/jsonapi.types'

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export interface LockboxesState {
  data: JSONApiLockboxesMap
  error?: Error
}

/**********************************************************
 * LOCKBOX TYPES
 *********************************************************/
export interface LockboxAttributes {
  backupLock: string
  notes: string
  primaryLock: string
}

export const LockboxAttributeNames = ['backupLock', 'notes', 'primaryLock']

export const LockboxApiFields = LockboxAttributeNames.map<string>(snakeCase)

export interface LockboxRelationships {
  unit: ToOneRelationship
}

export type RawLockbox = JSONApiObjectWithRelationships<
  LockboxAttributes,
  LockboxRelationships
>

export type JSONApiLockboxesMap = JSONApiObjectWithRelationshipsMap<
  LockboxAttributes,
  LockboxRelationships
>

export type Lockbox = {
  id: string
} & LockboxAttributes
