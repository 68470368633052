import { createSelector } from 'reselect'

import { type ApplicationState } from '../../store'
import { type Unit, type UnitsState } from '../units.types'
import { hydrateRawUnit } from '../units.utils'

const getUnitsState = (state: ApplicationState): UnitsState => state.units

export const getUnits = createSelector(getUnitsState, (unitsState): Unit[] => {
  return Object.values(unitsState.data).map(unit => hydrateRawUnit(unit))
})
