import { createAsyncAction } from 'typesafe-actions'

import {
  type RequestConfig,
  type RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import {
  type NormalizedUsersApiResponse,
  UsersActionTypes,
  UserApiFieldsWithoutPII,
  usersService,
} from 'app/store/users'

export const fetchCurrentUserAction = createAsyncAction(
  UsersActionTypes.FETCH_CURRENT_USER,
  UsersActionTypes.FETCH_CURRENT_USER_SUCCESS,
  UsersActionTypes.FETCH_CURRENT_USER_FAILURE,
)<
  RequestConfig<NormalizedUsersApiResponse>,
  NormalizedUsersApiResponse,
  Error
>()

export const getParams = (): RequestOptions => ({
  fields: { user: UserApiFieldsWithoutPII },
})

/**
 * Sends a GET request to the server to fetch the currently logged-in User.
 */
export const fetchCurrentUser = () => async dispatch => {
  try {
    const request = usersService.fetchCurrentUser.bind(null, getParams())
    const result = await dispatch(fetchCurrentUserAction.request({ request }))
    dispatch(fetchCurrentUserAction.success(result))
    return result.normalized
  } catch (error) {
    dispatch(fetchCurrentUserAction.failure(error))
    throw error
  }
}
