/**********************************************************
 * TICKET
 *********************************************************/
export enum TicketStatus {
  ACCEPTED = 'Accepted',
  ASSIGNED = 'Assigned',
  AWAITING = 'Awaiting',
  RESOLUTION_PENDING = 'Resolution Pending',
  RESOLVED_COMPLETED = 'Resolved - Completed',
  RESOLVED_DISMISSED = 'Resolved - Dismissed',
  RESOLVED_DUPLICATE = 'Resolved - Duplicate',
  UNASSIGNED = 'Unassigned',
}

export enum TicketSeverity {
  LOW = 'Low',
  MEDIUM = 'Medium',
  PLANNED = 'Planned',
  PRIOR_TO_NEXT_GUEST = 'Prior to Next Guest',
  TODAY = 'Today',
  URGENT = 'Urgent',
}

export enum TicketReasonForAssignment {
  COVERAGE_PARTNER = 'coverage_partner',
  CROSS_COVERAGE = 'cross_coverage',
  MANAGER = 'manager',
  MANUAL = 'manual',
  NON_URGENT_UNIT_MANAGER_DEFAULT = 'non_urgent_unit_manager_default',
  NON_URGENT_ZONE_DISPOSITION_DEFAULT = 'non_urgent_zone_disposition_default',
  NO_UNIT_MANAGER = 'no_unit_manager',
  STANDARD_AVAILABILITY = 'standard_availability',
  ZONE_DISPOSITION_DEFAULT = 'zone_disposition_default',
}

export interface AllTicketAttributes {
  completedAt?: string
  createdAt: string
  description?: string
  dueDate?: string
  reasonForAssignment: TicketReasonForAssignment | null
  requestedBy: string
  severity: TicketSeverity
  status: TicketStatus
  title: string
}

export const AllTicketAttributeNames = [
  'completedAt',
  'createdAt',
  'description',
  'dueDate',
  'reasonForAssignment',
  'requestedBy',
  'severity',
  'status',
  'title',
]
