import { addHours, DateFormat, format } from 'packages/utils/dateHelpers'
import { logInfo } from 'packages/wiretap/logging'

const AD_HOC_LOCK_CODE_URL = `${process.env.REACT_APP_SERVICE_HOST}/access_code/get_adhoc_code`

interface AdHocLockCodeArgs {
  accessToken: string
  endDate: Date
  endTime: number
  startDate: Date
  startTime: number
  unitId: string
}

export interface AdHocLockCodeResponse {
  accessCode: string
  endDateTime: Date
  startDateTime: Date
}

export const fetchAdHocLockCode = async ({
  accessToken,
  unitId,
  startDate,
  startTime,
  endDate,
  endTime,
}: AdHocLockCodeArgs): Promise<AdHocLockCodeResponse> => {
  const validFrom = encodeURIComponent(
    format(
      addHours(startDate, Number(startTime)),
      DateFormat.ApiUtcWithSeconds,
    ),
  )

  const validTo = encodeURIComponent(
    format(addHours(endDate, Number(endTime)), DateFormat.ApiUtcWithSeconds),
  )

  const result = await fetch(AD_HOC_LOCK_CODE_URL, {
    body: `unit_id=${unitId}&valid_from=${validFrom}&valid_to=${validTo}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
  }).then(async res => {
    if (res.ok) {
      return await res.json()
    }

    // errors here will not throw from fetch, so we need to manually parse/await and re-throw
    res.json().then(json => {
      logInfo('Error fetching lock codes', {
        error: json?.error || 'unknown',
      })
    })

    throw new Error('Error fetching lock code')
  })
  const {
    access_code: accessCode,
    valid_from: startDateTime,
    valid_to: endDateTime,
  } = result

  return { accessCode, endDateTime, startDateTime }
}
