import styled from '@emotion/styled'

import { ExternalLink as CoreExternalLink } from 'packages/common'
import { SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

export const StatusBar = styled.div`
  background: ${colors.success40};
  padding: 6px 16px;
`

export const Section = styled.section`
  font-size: 14px;
  padding: 16px 24px;

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.midnight20};
  }
`

export const Container = styled.div`
  background: ${colors.white};
  overflow-y: auto;
  padding-top: 16px;
  padding-bottom: 64px;

  pre {
    white-space: pre-line;
  }
`

export const Title = styled.div`
  color: black;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
`

export const SubTitle = styled.div`
  color: ${colors.dusk70};
  font-size: 14px;
  line-height: 20px;
  margin-top: 2px;
`

export const Description = styled.div`
  margin-bottom: 16px;
`

export const ExternalLink = styled(CoreExternalLink)`
  margin-top: 16px;
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const StatusBadgeContainer = styled.div`
  margin-bottom: 16px;
`

// equivalent to `CleansDetailBody`
export const TicketDetailBody = styled.div`
  // padding-left: 16px;
  flex: 1;
`

export const TicketDetailIcon = styled(SvgIcon)`
  flex: 0;
  margin-top: 1px;
  padding-right: 16px;
`
export const LockCodeRow = styled.div`
  display: flex;

  :not(:last-child) {
    margin-bottom: 20px;
  }
`

export const LockCodeBody = styled.p`
  margin: 8px 0 8px 0;
`
