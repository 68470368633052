import get from 'lodash/get' // eslint-disable-line

import { type RequestOptions } from 'packages/utils/store/jsonapi.types'

import { api } from '../axiosInstance'
import {
  type CleansServiceResponse,
  type CleansApiResponse,
} from './cleans.types'
import { emptyCleansResponse } from './cleans.utils'

const URL = '/cleans'

export const cleansService = {
  /**
   * Sends a GET request to the server to fetch cleans by the specified params
   * @param params
   */
  async fetchCleans(params: RequestOptions = {}): CleansServiceResponse {
    const response: CleansApiResponse = await api.get(URL, {
      params,
    })
    return get(response, 'data', emptyCleansResponse)
  },

  /**
   * Sends a GET request to the server for a Clean with the specified ID
   * @param id
   * @param params
   */
  async fetchCleanById(
    id: string,
    params: RequestOptions = {},
  ): CleansServiceResponse {
    const response: CleansApiResponse = await api.get(`${URL}/${id}`, {
      params,
    })
    return get(response, 'data', emptyCleansResponse)
  },

  /**
   * Sends a PATCH request to update the associated clean
   * @param cleanId
   * @param requestOptions
   */
  async updateClean(
    cleanId: string,
    requestOptions: RequestOptions,
  ): CleansServiceResponse {
    const response: CleansApiResponse = await api.patch(
      `${URL}/${cleanId}`,
      requestOptions,
    )
    return get(response, 'data', emptyCleansResponse)
  },
}
