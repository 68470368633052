import React from 'react'
import type { AriaButtonProps } from 'react-aria'
import { useButton } from 'react-aria'

import { type IconName } from 'packages/iconic'

import * as St from './DateTimeButton.styles'

export type DateTimeButtonProps = AriaButtonProps & {
  dataTestId?: string
  iconName?: IconName
  iconSize?: number
}

export const DateTimeButton: React.FC<DateTimeButtonProps> = React.memo(
  props => {
    const { children, dataTestId, iconName, iconSize = 22 } = props

    const ref = React.useRef(null)
    const { buttonProps } = useButton(props, ref)

    return (
      <St.StyledDateTimeButton {...buttonProps} data-testid={dataTestId}>
        <St.Children>{children}</St.Children>
        {iconName != null && <St.RightIcon icon={iconName} size={iconSize} />}
      </St.StyledDateTimeButton>
    )
  },
)
