import { type AxiosResponse } from 'axios'
import { snakeCase } from 'lodash/fp'

import {
  type JSONApiObjectWithRelationships,
  type JSONApiObjectWithRelationshipsMap,
  type NormalizedJSONApiResponse,
  type ToOneRelationship,
} from 'packages/grimoire/src/utils'

import { type JSONApiUnitsMap } from '../units'

/**********************************************************
 * SERVICE TYPES
 *********************************************************/

export interface GwUnitStafferPostAttributes {
  email: string
  name: string
  phone: string
  stafferType: GwUnitStafferTypes
  unitId: string
}

export interface GwUnitStafferPostApiAttributes {
  email: string
  name: string
  phone: string
  staffer_type: GwUnitStafferTypes
}

export interface GwUnitStafferPostApiRelationships {
  unit: ToOneRelationship
}

export type NormalizedGwUnitStaffersApiResponse =
  NormalizedJSONApiResponse<GwUnitStaffersResponse>

export interface GwUnitStafferPatchApiAttributes {
  email: string
  name: string
  phone: string
  staffer_type: GwUnitStafferTypes
}

export interface GwUnitStafferPatchAttributes {
  email: string
  name: string
  phone: string
  stafferType: GwUnitStafferTypes
}

export type GwUnitStafferPatchData = {
  id: string
} & Partial<GwUnitStafferPatchAttributes>

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export enum GwUnitStaffersActionTypes {
  CREATE_GW_UNIT_STAFFER = 'GW_UNIT_STAFFERS/API/CREATE_GW_UNIT_STAFFER',
  CREATE_GW_UNIT_STAFFER_FAILURE = 'GW_UNIT_STAFFERS/CREATE_GW_UNIT_STAFFER_FAILURE',
  CREATE_GW_UNIT_STAFFER_SUCCESS = 'GW_UNIT_STAFFERS/CREATE_GW_UNIT_STAFFER_SUCCESS',

  DELETE_GW_UNIT_STAFFER = 'GW_UNIT_STAFFERS/API/DELETE_GW_UNIT_STAFFER',
  DELETE_GW_UNIT_STAFFER_FAILURE = 'GW_UNIT_STAFFERS/DELETE_GW_UNIT_STAFFER_FAILURE',
  DELETE_GW_UNIT_STAFFER_SUCCESS = 'GW_UNIT_STAFFERS/DELETE_GW_UNIT_STAFFER_SUCCESS',

  FETCH_GW_UNIT_STAFFER = 'GW_UNIT_STAFFERS/API/FETCH_GW_UNIT_STAFFER',
  FETCH_GW_UNIT_STAFFER_FAILURE = 'GW_UNIT_STAFFERS/FETCH_GW_UNIT_STAFFER_FAILURE',
  FETCH_GW_UNIT_STAFFER_SUCCESS = 'GW_UNIT_STAFFERS/FETCH_GW_UNIT_STAFFER_SUCCESS',

  UPDATE_GW_UNIT_STAFFER = 'GW_UNIT_STAFFERS/API/UPDATE_GW_UNIT_STAFFER',
  UPDATE_GW_UNIT_STAFFER_FAILURE = 'GW_UNIT_STAFFERS/UPDATE_GW_UNIT_STAFFER_FAILURE',
  UPDATE_GW_UNIT_STAFFER_SUCCESS = 'GW_UNIT_STAFFERS/UPDATE_GW_UNIT_STAFFER_SUCCESS',
}

export interface GwUnitStaffersState {
  data: JSONApiGwUnitStafferMap
}

export interface GwUnitStafferRelationships {
  unit: ToOneRelationship
}

export interface GwUnitStaffersResponse {
  gwUnitStaffer: JSONApiGwUnitStafferMap
  unit: JSONApiUnitsMap
}

export type GwUnitStaffer = {
  id: string
  unitId: string
} & GwUnitStafferAttributes

export interface GwUnitStafferAttributes {
  email: string
  id: string
  name: string
  phone: string
  stafferType?: GwUnitStafferTypes
}

export type GwUnitStaffersServiceResponse =
  Promise<NormalizedGwUnitStaffersApiResponse>

export type GwUnitStaffersApiResponse =
  AxiosResponse<NormalizedGwUnitStaffersApiResponse>

export const GwUnitStafferAttributeNames = [
  'email',
  'name',
  'phone',
  'stafferType',
]

export const GwUnitStafferApiFields: string[] =
  GwUnitStafferAttributeNames.map(snakeCase)

export type RawGwUnitStaffer = JSONApiObjectWithRelationships<
  GwUnitStafferAttributes,
  GwUnitStafferRelationships
>

export type JSONApiGwUnitStafferMap = JSONApiObjectWithRelationshipsMap<
  GwUnitStafferAttributes,
  GwUnitStafferRelationships
>

export type GwUnitStafferTypes = 'cx_contact' | 'primary_housekeeper'
