import { useSelector } from 'react-redux'

import { HkEmployeeType, type User } from 'packages/grimoire'

import { getDelegateUserId } from 'app/store/auth/selectors'
import { getHkByUserId } from 'app/store/housekeepers/selectors'
import { type ApplicationState } from 'app/store/store'
import { getActiveUser } from 'app/store/users/selectors'

interface UseActiveUser {
  delegateUserId: string | undefined
  employeeType: string | undefined
  isAgency: boolean
  isContractor: boolean
  isEmployee: boolean
  user: User | undefined
}

export const useActiveUser = (): UseActiveUser => {
  const user = useSelector(getActiveUser)
  const delegateUserId = useSelector(getDelegateUserId)

  const hk = useSelector((storeState: ApplicationState) =>
    getHkByUserId(storeState, user?.id),
  )

  const isEmployee: boolean = hk?.employeeType === HkEmployeeType.employee
  const isContractor: boolean = hk?.employeeType === HkEmployeeType.contractor
  const isAgency: boolean = hk?.employeeType === HkEmployeeType.agency
  const employeeType: string | undefined = hk?.employeeType

  return {
    delegateUserId,
    employeeType,
    isAgency,
    isContractor,
    isEmployee,
    user,
  }
}
