import { Events, track } from 'packages/wiretap'

import { type ApplicationState } from '../store'
import { getActiveUser } from '../users/selectors'
import {
  type GwUnitStafferPatchData,
  type GwUnitStafferPostAttributes,
} from './gwUnitStaffers.types'

export const trackGwUnitStafferUpdated = (
  patchData: GwUnitStafferPatchData,
  getState: () => ApplicationState,
): void => {
  const { id, stafferType } = patchData
  const state = getState()
  const activeUser = getActiveUser(state)

  const payload = {
    staffer_type: stafferType,
    updated_by: activeUser?.id || 'unknown',
    updated_staffer_id: id,
  }

  if (stafferType === 'primary_housekeeper') {
    track(Events.guestworksPrimaryHousekeeperUpdated, payload)
  }

  if (stafferType === 'cx_contact') {
    track(Events.guestworksLocalHostUpdated, payload)
  }
}

export const trackGwUnitStafferCreated = (
  postData: GwUnitStafferPostAttributes,
  getState: () => ApplicationState,
): void => {
  const { stafferType, unitId } = postData
  const state = getState()
  const activeUser = getActiveUser(state)

  const payload = {
    associated_unit_id: unitId || 'unknown',
    created_by: activeUser?.id || 'unknown',
    staffer_type: stafferType,
  }

  if (stafferType === 'primary_housekeeper') {
    track(Events.guestworksPrimaryHousekeeperCreated, payload)
  }

  if (stafferType === 'cx_contact') {
    track(Events.guestworksLocalHostCreated, payload)
  }
}

// This is only going to track Primary HK deletion, as currently that's what the app allows

export const trackGwUnitStafferDeleted = (
  id: string,
  getState: () => ApplicationState,
): void => {
  const state = getState()
  const activeUser = getActiveUser(state)

  const payload = {
    deleted_by: activeUser?.id || 'unknown',
    deleted_staffer_id: id || 'unknown',
  }

  track(Events.guestworksPrimaryHousekeeperDeleted, payload)
}
