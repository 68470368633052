import styled from '@emotion/styled'
import { noop } from 'lodash/fp'
import { isIOS } from 'mobile-device-detect'
import React from 'react'

import {
  Alert,
  Loader,
  type ModalConfig,
  Select,
  useModalContext,
} from 'packages/common'
import { IconName } from 'packages/iconic'
import { colors } from 'packages/styles'

import { Button, InputField, TextArea } from 'app/components/core'
import { TicketSeverityExamplesModal } from 'app/components/tickets/TicketSeverityExamplesModal'
import { type Disposition, type SeverityAttributes } from 'app/store/tickets'
import type { Unit } from 'app/store/units'
import { gwText, gwSelect } from 'app/styles'

import { type CreateTicketValuesState } from '../createTicketState/createTicket.reducer'
import {
  type CreateTicketFormTranslations,
  DEFAULT_DISPOSITION_DATA,
} from './CreateTicketForm.container'

const St = {
  Alert: styled(Alert)`
    margin: 16px 0 0 0;
  `,
  ButtonContainer: styled.div`
    grid-gap: 16px;
    padding: 32px 24px 0px 24px;
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  `,
  CreateTicketBody: styled.p`
    ${gwText.bodySmall}
    color: ${colors.dusk80};
    font-style: italic;
    margin-bottom: 0;
  `,
  ErrorMessage: styled.div`
    color: ${colors.alert};
  `,
  Form: styled.form`
    min-height: 100vh;
    padding-bottom: 128px;
  `,
  Header: styled.div`
    ${gwText.headingMedium}
    background: ${colors.midnight10};
    color: ${colors.midnight};
    padding: 32px 16px 16px 24px;
    text-align: start;
  `,
  Label: styled.label`
    ${gwText.headingXsCaps};
    color: ${colors.dusk};
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;

    nav {
      color: ${colors.lake};
      cursor: pointer;
      text-transform: none;
      letter-spacing: normal;
      font-weight: normal;
    }
  `,
  LoaderWrapper: styled.div`
    height: 32px;
    position: relative;
    margin-top: 32px;
  `,
  Section: styled.section`
    padding: 32px 24px 0px 24px;

    &:first-of-type {
      padding-top: 28px;
    }
  `,
  SeverityLabel: styled.div`
    font-weight: bold;
    line-height: 1em; // otherwise label+note are cut-off
    aside {
      font-weight: normal;
    }
  `,
}

export enum CreateTicketFormTestIds {
  cancel = 'CreateTicketForm__cancel',
  container = 'CreateTicketForm__container',
  dispositionLoader = 'CreateTicketForm__dispositionLoader',
  error = 'CreateTicketForm__error',
  submit = 'CreateTicketForm__submit',
  toggleAdditionalDetails = 'CreateTicketForm__toggleAdditionalDetails',
}

export interface CreateTicketFormProps {
  eventHandlers: {
    cancel: () => void
    clearError: () => void
    onInputChange: (
      section: keyof CreateTicketValuesState,
    ) => (val: string) => void
    submit: (e: React.FormEvent<HTMLFormElement>) => void
    toggleDetails: () => void
  }
  state: {
    canSubmit: boolean
    disposition: Disposition | null
    dispositions: Disposition[] | null | 'error'
    error: boolean
    isDetailsOpen: boolean
    isLoading: boolean
    severity: SeverityAttributes | null
  }
  strings: CreateTicketFormTranslations
  unit: Unit
}

export const CreateTicketForm: React.FC<CreateTicketFormProps> = React.memo(
  ({ eventHandlers, state, strings, unit }) => {
    // this fixes some strange behavior on iOS where we would not be able to scroll internally
    // in inputs that support it when the form is scrolled all the way down (textarea, Select)
    // this just scrolls the container up 1px, which seems to fix the issue. Just iOS things.
    const iosScrollFix = React.useCallback(() => {
      if (!isIOS) return

      const formEl = document.querySelector('#CreateTicketDrawer')
      if (formEl) {
        // iOS does not support 'window.innerHeight' properly, so we have to use this less common measurement for it
        if (window.screen.height + formEl.scrollTop >= formEl.scrollHeight) {
          formEl.scrollBy(0, -1)
        }
      }
    }, [])

    const { showModal } = useModalContext()
    const showSeverityExamples = () => {
      showModal(modalConfig)
    }

    const modalConfig: ModalConfig = {
      childRenderer: () => <TicketSeverityExamplesModal />,
      modalProps: {
        disableCloseMethods: [],
        hideCloseButton: false,
      },
    }

    return (
      <St.Form
        data-testid={CreateTicketFormTestIds.container}
        onSubmit={eventHandlers.submit}
      >
        <St.Header>{strings.headerText}</St.Header>
        <St.Section>
          <InputField
            defaultValue={unit.name}
            id="unit"
            isDisabled={true}
            isReadOnly={true}
            label={strings.unit}
            onChange={noop}
          />
        </St.Section>

        <St.Section>
          <InputField
            id="title"
            label={strings.titleLabel}
            onChange={eventHandlers.onInputChange('title')}
          />
        </St.Section>

        <St.Section>
          {state.isDetailsOpen ? (
            <TextArea
              label={strings.descriptionLabel}
              onChange={eventHandlers.onInputChange('description')}
              rowCount={6}
            />
          ) : (
            <Button
              buttonSize={'xSmall'}
              buttonType={'secondary'}
              dataTestId={CreateTicketFormTestIds.toggleAdditionalDetails}
              onPress={eventHandlers.toggleDetails}
              iconName={IconName.plusCircle}
              iconSize={14}
            >
              {strings.additionLabelsToggle}
            </Button>
          )}
        </St.Section>

        <St.Section>
          <St.Label htmlFor="dispositionId">
            {strings.dispositionIdLabel}
          </St.Label>

          {state.dispositions ? (
            <>
              <Select
                disabled={state.dispositions === 'error'}
                id="dispositionId"
                onChange={eventHandlers.onInputChange('disposition')}
                getOptionLabel={(disposition: Disposition) =>
                  disposition.tier3Display
                }
                maxMenuHeight={200}
                onFocus={iosScrollFix}
                placeholder={
                  state.dispositions === 'error'
                    ? strings.defaultDispositionDisplayValue
                    : undefined
                }
                options={
                  state.dispositions === 'error'
                    ? [DEFAULT_DISPOSITION_DATA]
                    : Object.values(state.dispositions)
                }
                selectedValue={state.disposition}
                style={gwSelect}
              />
              {state.dispositions === 'error' && (
                <St.ErrorMessage>
                  {strings.dispositionsFailToLoadMessage}
                </St.ErrorMessage>
              )}
            </>
          ) : (
            <St.LoaderWrapper
              data-testid={CreateTicketFormTestIds.dispositionLoader}
            >
              <Loader />
            </St.LoaderWrapper>
          )}
        </St.Section>

        <St.Section>
          <St.Label htmlFor="severity">
            {strings.severityLabel}
            <nav onClick={showSeverityExamples}>{strings.seeExamples}</nav>
          </St.Label>

          <Select
            id="severity"
            onChange={eventHandlers.onInputChange('severity')}
            getOptionLabel={(severity: SeverityAttributes) => (
              <St.SeverityLabel>
                {severity.label}
                <aside>{severity.note}</aside>
              </St.SeverityLabel>
            )}
            maxMenuHeight={200}
            onFocus={iosScrollFix}
            options={strings.severityLevels as any}
            selectedValue={state.severity}
            style={gwSelect}
          />
        </St.Section>

        {state.error && (
          <St.Alert
            alertType={'danger'}
            dataTestId={CreateTicketFormTestIds.error}
            onClose={eventHandlers.clearError}
          >
            {strings.failureMessage}
          </St.Alert>
        )}
        <St.Section>
          <St.CreateTicketBody>{strings.createTicketBody}</St.CreateTicketBody>
        </St.Section>
        <St.ButtonContainer>
          <Button
            dataTestId={CreateTicketFormTestIds.cancel}
            onPress={eventHandlers.cancel}
            buttonType={'secondary'}
          >
            {strings.cancel}
          </Button>
          <Button
            dataTestId={CreateTicketFormTestIds.submit}
            isDisabled={!state.canSubmit}
            isLoading={state.isLoading}
            buttonType={'primary'}
            type="submit"
          >
            {strings.submit}
          </Button>
        </St.ButtonContainer>
      </St.Form>
    )
  },
)
