import styled from '@emotion/styled'

import { colors } from 'packages/styles'

export const St = {
  ButtonContainer: styled.div`
    align-items: center;
    display: flex;
    grid-gap: 12px;
    justify-content: flex-end;
    width: 100%;
  `,
  Form: styled.form`
    background-color: ${colors.midnight4};
    border-radius: 8px;
    padding: 16px;
  `,
  Section: styled.section`
    margin: 24px 0px;
  `,
}
