import styled from '@emotion/styled'
import React from 'react'

import { colors } from 'packages/styles'

import { Slugs, useI18n } from 'app/i18n'
import { gwText } from 'app/styles'

interface Styles {
  color: string
}

const St = {
  Color: styled.div<Styles>`
    background-color: ${({ color }) => color};
    border-radius: 100px;
    height: 10px;
    width: 18px;
  `,
  Container: styled.div`
    background-color: ${colors.midnight4};
    border-radius: 8px;
    padding: 16px;
  `,
  ContentContainer: styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
  `,
  Key: styled.div`
    align-items: center;
    display: flex;
  `,
  Text: styled.p`
    ${gwText.headingXsCaps};
    margin: 0 0 0 8px;
  `,
}

const colorTextArr = [
  {
    color: '#8c9711',
    text: Slugs.owner,
  },
  {
    color: '#5a2458',
    text: Slugs.guest,
  },
  {
    color: colors.yellow,
    text: Slugs.vacasa,
  },
  {
    color: colors.aspen50,
    text: Slugs.clean,
  },
]

export const ScheduleColorKey: React.FC = React.memo(() => {
  const { t } = useI18n()

  return (
    <St.Container>
      <St.ContentContainer>
        {colorTextArr.map(({ color, text }) => {
          return (
            <St.Key key={text}>
              <St.Color color={color} />
              <St.Text>{t(text)}</St.Text>
            </St.Key>
          )
        })}
      </St.ContentContainer>
    </St.Container>
  )
})
