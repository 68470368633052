import { transformNormalizedToTyped } from 'packages/utils/store'

import {
  type Lockbox,
  LockboxAttributeNames,
  type RawLockbox,
} from './lockboxes.types'

/**
 * Empty data in the shape of the Lockboxes service's `normalized` payload.
 * Use this as a fallback when there are issues with the API data being undefined or malformed.
 */
export const emptyNormalizedLockboxesData = Object.freeze({
  lockBox: {},
})

export const transformRawLockbox = (rawLockbox: RawLockbox): Lockbox =>
  transformNormalizedToTyped<Lockbox>(rawLockbox, LockboxAttributeNames)
