import { css, type SerializedStyles } from '@emotion/react'
import { createBreakpoint } from 'react-use'

const Breakpoints = {
  LG: 769,
  SM: 768,
}

export type Breakpoint = 'SM' | 'LG'
export interface WithBreakpoint {
  breakpoint: Breakpoint
}
type UseBreakpoint = () => Breakpoint

export const useBreakpoint: UseBreakpoint = createBreakpoint(
  Breakpoints,
) as UseBreakpoint

export const equalOrLarger = (
  size: number,
  content: SerializedStyles,
): SerializedStyles => css`
  @media screen and (min-width: ${size}px) {
    ${content};
  }
`

export const equalOrSmaller = (
  size: number,
  content: SerializedStyles,
): SerializedStyles => css`
  @media screen and (max-width: ${size}px) {
    ${content};
  }
`

export const largeScreen = (content: SerializedStyles): SerializedStyles => css`
  @media screen and (min-width: ${Breakpoints.LG}px) {
    ${content};
  }
`

export const smallScreen = (content: SerializedStyles): SerializedStyles => css`
  @media screen and (max-width: ${Breakpoints.SM}px) {
    ${content};
  }
`
