import { noop } from 'lodash/fp'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useFormFns } from 'packages/common'
import { useI18n } from 'packages/i18n'
import { useAsyncFnWithReset } from 'packages/utils/hooks'

import { useGwToast } from 'app/components/core/hooks'
import { Slugs } from 'app/i18n'
import { createGwLocalHost } from 'app/store/gwLocalhosts/actions'
import { getSelectedUnit } from 'app/store/units/selectors/getSelectedUnit'

import {
  AccessForm,
  type AccessFormProps,
  type AccessFormValues,
} from './AccessForm'
import { type UnknownAction } from '@reduxjs/toolkit'

interface AccessFormContainerProps {
  onCancel?: () => void
  onSuccess?: () => void
}

const useTranslations = () => {
  const { t } = useI18n()

  return {
    inviteSent: t(Slugs.inviteSent),
    pleaseVerify: t(Slugs.pleaseVerify),
  }
}

export const AccessFormContainer: React.FC<AccessFormContainerProps> =
  React.memo(({ onCancel = noop, onSuccess = noop }) => {
    const { showGwToast } = useGwToast()
    const dispatch = useDispatch()
    const strings = useTranslations()

    const unitId = useSelector(getSelectedUnit)?.id || ''

    const initialFormValues: AccessFormValues = {
      email: '',
      firstName: '',
      lastName: '',
    }

    const { formValues, setValueByKey } =
      useFormFns<AccessFormValues>(initialFormValues)

    const [createGwLocalHostState, createGwLocalHostFn] =
      useAsyncFnWithReset(async () => {
        return dispatch(
          createGwLocalHost(
            {
              email: formValues.email,
              firstName: formValues.firstName,
              lastName: formValues.lastName,
              unitId,
            },
            {
              onError: () => {
                showGwToast({
                  message: strings.pleaseVerify,
                  toastType: 'danger',
                })
              },

              onSuccess: () => {
                showGwToast({
                  message: strings.inviteSent,
                  toastType: 'success',
                })

                onSuccess()
              },
            },
          ) as unknown as UnknownAction,
        )
      }, [
        dispatch,
        formValues,
        unitId,
        onSuccess,
        showGwToast,
        strings.inviteSent,
        strings.pleaseVerify,
      ])

    const formState: AccessFormProps['formState'] = React.useMemo(
      () => ({
        canSubmit: Object.values(formValues).every(Boolean),
        values: formValues,
      }),
      [formValues],
    )

    const handleSubmit: React.FormEventHandler = React.useCallback(
      event => {
        event.preventDefault()
        createGwLocalHostFn()
      },
      [createGwLocalHostFn],
    )

    const handlers: AccessFormProps['handlers'] = React.useMemo(
      () => ({
        cancel: onCancel,
        setValueByKey,
        submit: handleSubmit,
      }),
      [handleSubmit, onCancel, setValueByKey],
    )

    return (
      <>
        <AccessForm
          formState={formState}
          handlers={handlers}
          requestState={createGwLocalHostState}
        />
      </>
    )
  })
