import styled from '@emotion/styled'
import React from 'react'

import { Slugs, useI18n } from 'packages/i18n'
import { colors } from 'packages/styles'

export const CLIPBOARD_RESET_TIMEOUT = 1500

enum CopyState {
  Idle,
  Copied,
  Failed,
}

interface ClipboardTextProps {
  children: string
}

const Container = styled.div`
  align-items: center;
  background: #f6f6f6;
  display: flex;
  justify-content: space-between;
  padding: 10px 16px;
`

const CopyButton = styled.button<{ copyState: CopyState }>`
  color: ${p => {
    if (p.copyState === CopyState.Failed) return colors.alert
    if (p.copyState === CopyState.Copied) return colors.dusk
    return colors.lake
  }};
  font-weight: 600;
  background: none;
  border: none;
  cursor: pointer;
`

export const ClipboardText: React.FC<ClipboardTextProps> = ({
  children: text,
}) => {
  const { ut } = useI18n()

  const [copyState, setCopyState] = React.useState<CopyState>(CopyState.Idle)

  const handleCopy = React.useCallback(async () => {
    try {
      await navigator.clipboard.writeText(text)
      setCopyState(CopyState.Copied)
      setTimeout(() => {
        setCopyState(CopyState.Idle)
      }, CLIPBOARD_RESET_TIMEOUT)
    } catch (err) {
      setCopyState(CopyState.Failed)
    }
  }, [text])

  let buttonText = ut(Slugs.copy)

  if (copyState === CopyState.Failed) {
    buttonText = `${ut(Slugs.copyFailed)}!`
  } else if (copyState === CopyState.Copied) {
    buttonText = `${ut(Slugs.copied)}!`
  }

  return (
    <Container>
      <span>{text}</span>

      <CopyButton
        onClick={handleCopy}
        disabled={copyState !== CopyState.Idle}
        copyState={copyState}
      >
        {buttonText}
      </CopyButton>
    </Container>
  )
}
