import styled from '@emotion/styled'
import { prop } from 'lodash/fp'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { centerWithFlex, truncateTextWithEllipsis } from 'packages/styles'

import { Slugs, useI18n } from 'app/i18n'
import { type Unit } from 'app/store/units'
import { updateSelectedUnit } from 'app/store/units/actions/updateSelectedUnit'
import { getSelectedUnit } from 'app/store/units/selectors/getSelectedUnit'
import { getUnits } from 'app/store/units/selectors/getUnits'

import { UnitImageThumbnail } from '../../components'
import { useRadioButtonsModal } from '../../components/RadioButtons/useRadioButtonsModal'
import { type UnknownAction } from '@reduxjs/toolkit'

const St = {
  Option: styled.div`
    ${centerWithFlex};
  `,
  OptionText: styled.span`
    ${truncateTextWithEllipsis};
    width: 100%;
    margin-left: 10px;
  `,
}

interface UseUnitPickerModal {
  selectedUnit: Unit | undefined
  showUnitPickerModal: () => void
}

export const useUnitPickerModal = (): UseUnitPickerModal => {
  const { t } = useI18n()
  const dispatch = useDispatch()

  const units = useSelector(getUnits)
  const selectedUnit = useSelector(getSelectedUnit)
  const [selected, setSelected] = React.useState(selectedUnit)

  const getOptionLabel = React.useCallback((unit: Unit) => {
    const imageUrl = unit?.primaryImageId
      ? `https://vacasa-units.imgix.net/${unit?.primaryImageId}.jpg?w=50`
      : ''

    return (
      <St.Option>
        <div>
          <UnitImageThumbnail imageUrl={imageUrl} />
        </div>
        <St.OptionText>{unit?.name}</St.OptionText>
      </St.Option>
    )
  }, [])

  const handleChange = React.useCallback(
    id => {
      const newSelection = units.find(opt => opt.id === id)
      if (newSelection) {
        setSelected(newSelection)
        dispatch(
          updateSelectedUnit(newSelection.id) as unknown as UnknownAction,
        )
      }
    },
    [dispatch, units],
  )

  const { showRadioButtonsModal } = useRadioButtonsModal({
    getOptionLabel,
    getOptionValue: prop('id'),
    labelPosition: 'left',
    noOptionsText: t(Slugs.noUnits),
    onChange: handleChange,
    options: units,
    selectedValue: selected,
  })

  return {
    selectedUnit,
    showUnitPickerModal: showRadioButtonsModal,
  }
}
