import { isInOpenPhase, TransitionPhaseType } from 'packages/utils/hooks'

interface WithTransitionPhase {
  transitionPhase: TransitionPhaseType
}

type WithDimensions = WithTransitionPhase & {
  elementHeight: number
  elementWidth: number
}

/**
 * Checks whether the current transition state is "hidden," indicating that
 * all transitions have finished, and thus it is safe to remove the element from the DOM
 */
export const isHidden = (props: WithTransitionPhase): boolean =>
  props.transitionPhase === TransitionPhaseType.hidden

/**
 * Checks whether the element is in a renderable state. Sometimes elements will enter the
 * DOM before the contents have been sized, and this case, we want to defer rendering them.
 * (i.e. Wait until the elements has valid dimensions)
 */
export const isRenderable = (props: WithDimensions): boolean =>
  !!props.elementHeight && !!props.elementWidth && !isHidden(props)

export const isRenderableAndShowing = (props: WithDimensions): boolean =>
  isRenderable(props) && isInOpenPhase(props.transitionPhase)
