import { xor } from 'lodash/fp'

import {
  type ComputedTaskJobType,
  computedTaskJobTypeValues,
  TaskServiceType,
  TaskRealtimeStatus,
  type AllTaskAttributes,
  AllTaskAttributeNames,
  type TaskJobType,
} from '../task/task.types'

export { TaskRealtimeStatus as CleanRealtimeStatus }
export { TaskServiceType as CleanServiceType }

export type CleanJobType = Exclude<TaskJobType, 'inspection'>

export const computedCleanJobTypeValues = xor(
  ['visit'],
  computedTaskJobTypeValues,
) as Array<Exclude<ComputedTaskJobType, 'visit'>>

export type ComputedCleanJobType = (typeof computedCleanJobTypeValues)[number]

/**********************************************************
 * CLEAN
 *********************************************************/
/**
 * All keys here are task attribute keys, but the values are more specific to Cleans.
 * All keys in this type should be omitted from `AllTaskAttributes` in the `AllCleanAttributes` definition
 */
interface FilteredCleanAttributes {
  jobType: CleanJobType[]
}

export type AllCleanAttributes = Omit<
  AllTaskAttributes,
  'jobType' | 'ticketAttachedCount'
> &
  FilteredCleanAttributes

export const AllCleanAttributeNames = AllTaskAttributeNames
