import { createSelector } from 'reselect'

import { type ApplicationState } from 'app/store/store'
import { hydrateRawUser, type User } from 'app/store/users'

export const getUserById = createSelector(
  (state: ApplicationState) => state.users,
  (_, userId: string) => userId,
  (usersState, userId): User | undefined => {
    const rawUser = usersState.data[userId]
    return rawUser ? hydrateRawUser(rawUser) : undefined
  },
)
