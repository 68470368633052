import styled from '@emotion/styled'
import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { type IconName, SvgIcon } from 'packages/iconic'
import { centerWithFlex } from 'packages/styles'

import { gwText } from 'app/styles'

const St = {
  Highlight: styled.div<{ visible: boolean }>`
    background-color: white;
    height: 2px;
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    position: absolute;
    top: 0;
    transition: opacity 350ms ease;
    width: 100%;
  `,
  NavTab: styled.div`
    ${centerWithFlex};
    ${gwText.headingMicroCaps};
    background-color: transparent;
    color: white;
    cursor: pointer;
    flex-direction: column;
    min-width: 108px;
    padding-top: 4px;
    position: relative;
    transition: background-color 200ms ease;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  `,
  Text: styled.div`
    padding-top: 4px;
  `,
}

export enum NavTabTestIds {
  container = 'NavTab__container',
}

export interface NavTabConfig {
  icon: IconName
  route: string
  text: string
}

export interface NavTabProps {
  config: NavTabConfig
}

export const NavTab: React.FC<NavTabProps> = React.memo(({ config }) => {
  const navigate = useNavigate()
  const current = useLocation().pathname

  const { icon, text } = config

  const handleClick = React.useCallback(() => {
    navigate(config.route)
  }, [config.route, navigate])

  /**
   * Determines if this tab's route is the current route.
   * Note that this is a shallow check which only considers
   * the first section of the path, so e.g.
   * `/cleans` would be considered equal to `/cleans/{id}`
   */
  const isCurrentRoute = React.useMemo((): boolean => {
    const pathRoot = (path: string) => path.split('/')[1]
    return pathRoot(current) === pathRoot(config.route)
  }, [current, config.route])

  return (
    <St.NavTab data-testid={NavTabTestIds.container} onClick={handleClick}>
      <St.Highlight visible={isCurrentRoute} />
      <SvgIcon icon={icon} size={22} />
      <St.Text>{text}</St.Text>
    </St.NavTab>
  )
})
