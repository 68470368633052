import { createSelector } from 'reselect'

import { type ApplicationState } from 'app/store/store'

const getMayImpersonateUserState = (state: ApplicationState): boolean =>
  state.users.mayImpersonate

export const getMayImpersonateUser = createSelector(
  getMayImpersonateUserState,
  (allowed): boolean => allowed,
)
