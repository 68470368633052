import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { always, noop } from 'lodash/fp'
import React from 'react'
import { useClickAway, useKey, usePrevious } from 'react-use'

import { Keys } from 'packages/utils/constants'
import {
  TransitionPhaseType,
  useTransition,
} from 'packages/utils/hooks/useTransition/useTransition'

import { ToastTestIds } from '../../Toast'
import { ModalBackdropTestIds } from '../components'
import { ModalTestIds } from '../Modal'
import { defaultOptionalProps, type DrawerContainerProps } from './Drawer.types'
import { DrawerPortal } from './DrawerPortal'

// Adds selectors here to prevent "outside clicks" on them from closing the drawer
const IGNORED_CLICK_SELECTORS = [
  `div[data-testid="${ToastTestIds.container}"]`, // Toasts
  `div[data-testid="${ModalTestIds.container}"]`, // Modal contents
  `div[data-testid="${ModalBackdropTestIds.container}"]`, // Modal backdrop
]

const alwaysFalse = always(false)

export enum DrawerTestIds {
  backdrop = 'Drawer__backdrop',
}

export const Drawer: React.FC<DrawerContainerProps> = props => {
  // React strict mode complains about props with 'on...' names being passed to DOM,
  // so pulling them off here can avoid some harmless but annoying console errors
  const {
    afterOpen = noop,
    ignoreClickEventsOn = [],
    onClickAway = alwaysFalse,
    ...passThruProps
  } = props
  const { afterExit, duration, forceClose, isOpen, pinned } = passThruProps

  const prevForceClose = usePrevious(forceClose)
  const prevIsOpen = usePrevious(isOpen)
  const drawerRef = React.useRef<HTMLDivElement>(null)

  const { beginTransitionIn, beginTransitionOut, transitionPhase } =
    useTransition({
      afterExit,
      transitionTime: duration,
    })

  const cleanupAndClose = React.useCallback(() => {
    if (drawerRef.current) {
      enableBodyScroll(drawerRef.current)
    }

    beginTransitionOut()
  }, [beginTransitionOut])

  const handleClickAway = React.useCallback(
    event => {
      if (pinned) return
      if (onClickAway(event)) return

      const clickIsIgnored =
        // ignore clicks on default/global ignored elements
        IGNORED_CLICK_SELECTORS.some(sel => {
          const el = document.querySelector(sel)
          return el?.contains(event.target)
        }) ||
        // ignore clicks on elements specified by props
        ignoreClickEventsOn.some(sel => {
          const el = document.querySelector(sel)
          return el?.contains(event.target)
        })

      if (clickIsIgnored) return

      cleanupAndClose()
    },
    [cleanupAndClose, ignoreClickEventsOn, onClickAway, pinned],
  )

  // when the "forceClose" prop changes to true, begin closing the drawer
  React.useEffect(() => {
    if (forceClose && forceClose !== prevForceClose) {
      cleanupAndClose()
    }
  }, [cleanupAndClose, forceClose, prevForceClose])

  // when we first open, disable body scrolling
  React.useEffect(() => {
    if (isOpen && !!drawerRef.current) {
      disableBodyScroll(drawerRef.current)
    }
  }, [isOpen])

  React.useEffect(() => {
    if (prevIsOpen === isOpen) return

    if (isOpen) {
      beginTransitionIn()
    }
  }, [beginTransitionIn, isOpen, prevIsOpen])

  const prevTransitionPhase = usePrevious(transitionPhase)

  React.useEffect(() => {
    const drawerBecameActive =
      transitionPhase === TransitionPhaseType.enterActive &&
      transitionPhase !== prevTransitionPhase

    if (drawerBecameActive) {
      afterOpen()
    }
  }, [prevTransitionPhase, transitionPhase, afterOpen])

  // this handler is a "safety net" to ensure body scrolling always gets re-enabled,
  // even if the Drawer is forcefully removed (e.g. if the user clicks browser back in a "drawer route")
  React.useEffect(() => {
    const ref = drawerRef?.current
    return () => {
      if (ref) {
        enableBodyScroll(ref)
      }
    }
  }, [])

  useClickAway(drawerRef, handleClickAway) // close drawer on outside click
  useKey(Keys.Esc, cleanupAndClose, {}, [cleanupAndClose]) // close drawer on ESC key

  return (
    <DrawerPortal
      {...defaultOptionalProps}
      {...passThruProps}
      beginClose={cleanupAndClose}
      ref={drawerRef}
      renderContainer={props.renderContainer}
      transitionPhase={transitionPhase}
    />
  )
}
