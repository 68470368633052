import { produce } from 'immer'
import get from 'lodash/get' // eslint-disable-line
import { type ActionType, getType } from 'typesafe-actions'

import { setAuthUser } from 'packages/utils/misc'
import { setLoggingUser } from 'packages/wiretap/logging'

import {
  fetchCleanByIdAction,
  fetchCleansAction,
  fetchPreviousCleansAction,
} from 'app/store/cleans/actions'

import { fetchSmartLocksByUnitIdAction } from '../smartLocks/actions'
import { fetchTicketByIdAction } from '../tickets/actions'
import { fetchUnitByIdAction } from '../units/actions'
import {
  fetchCurrentUserAction,
  searchUsersAction,
  setActiveUserAction,
  setMayImpersonateUserAction,
} from './actions'
import { type RawUser, type UsersState } from './users.types'
import { emptyNormalizedUsersData } from './users.utils'

const initialState: UsersState = {
  activeUserId: '',
  authUserId: '',
  data: {},
  mayImpersonate: false,
  searchResults: {},
}

const actions = {
  fetchCleanByIdAction,
  fetchCleansAction,
  fetchPreviousCleansAction,
  fetchCurrentUserAction,
  fetchSmartLocksByUnitIdAction,
  fetchTicketByIdAction,
  fetchUnitByIdAction,
  searchUsersAction,
  setActiveUserAction,
  setMayImpersonateUserAction,
}

type UsersActionsTypes = ActionType<typeof actions>

export const usersReducer = (
  state = initialState,
  action: UsersActionsTypes,
): UsersState =>
  produce(state, (draft: UsersState) => {
    switch (action.type) {
      // save users included with HK data when fetching cleans
      case getType(fetchCleanByIdAction.success):
      case getType(fetchPreviousCleansAction.success):
      case getType(fetchCleansAction.success): {
        const normalized =
          action.payload?.normalized || emptyNormalizedUsersData

        draft.data = {
          ...state.data,
          ...normalized.user,
        }

        return
      }

      case getType(fetchCurrentUserAction.success): {
        const normalized = get(
          action,
          'payload.normalized',
          emptyNormalizedUsersData,
        )

        const authUser = Object.values(normalized.user)[0] as RawUser
        draft.activeUserId = authUser.id
        draft.authUserId = authUser.id
        draft.data = normalized.user

        setAuthUser(authUser)
        setLoggingUser(draft.authUserId, draft.activeUserId)

        if (authUser.attributes.isSuperuser) {
          // do not set false since that's already the default and the value
          // could be true based on an IDP scope, which shouldn't be overridden
          draft.mayImpersonate = true
        }

        // add the authenticated user ID to Google Tag Manager's variables
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const dataLayer = (window as any).dataLayer
        if (dataLayer) {
          dataLayer.push({ userId: authUser.id })
        }

        return
      }

      // users included with smartLocks (as "generated_by")
      case getType(fetchSmartLocksByUnitIdAction.success): {
        const normalized =
          action.payload?.normalized || emptyNormalizedUsersData

        Object.values(normalized?.user || {}).forEach(user => {
          draft.data[user.id] = user
        })

        return
      }

      case getType(searchUsersAction.success): {
        const normalized = get(
          action,
          'payload.normalized',
          emptyNormalizedUsersData,
        )

        draft.searchResults = normalized.user || {}
        return
      }

      case getType(setActiveUserAction): {
        const user = action.payload
        draft.activeUserId = user.id
        setLoggingUser(state.authUserId, draft.activeUserId)

        // if this is a user we have selected from a search, we need to copy
        // said user over to "data" so it does not get cleared on the next search
        const userFromSearch = state.searchResults[user.id]
        if (!state.data[user.id] && userFromSearch) {
          draft.data[user.id] = userFromSearch
        }

        return
      }

      case getType(setMayImpersonateUserAction): {
        draft.mayImpersonate = action.payload
      }
    }
  })
