import { createSelector } from 'reselect'

import { type ApplicationState } from '../../store'
import { type GwUnitStaffer } from '../gwUnitStaffers.types'
import { hydrateRawGwUnitStaffer } from '../gwUnitStaffers.utils'

/**
 * Returns an array of gwUnitStaffers based on all ID provided.
 */

export const getGwUnitStaffersByIds = createSelector(
  (state: ApplicationState) => state.gwUnitStaffers,
  (_, gwUnitStafferIds: string[]) => gwUnitStafferIds,
  (gwUnitStaffersState, gwUnitStafferIds): GwUnitStaffer[] =>
    gwUnitStafferIds?.reduce((acc, id) => {
      const rawGwUnitStaffer = gwUnitStaffersState.data[id]
      if (rawGwUnitStaffer) {
        acc.push(hydrateRawGwUnitStaffer(rawGwUnitStaffer))
      }

      return acc
    }, [] as GwUnitStaffer[]),
)
