import styled from '@emotion/styled'
import React from 'react'
import { Link } from 'react-router-dom'

import { Loader } from 'packages/common'
import { IconName } from 'packages/iconic'
import { colors } from 'packages/styles'
import { type AsyncState } from 'packages/utils/hooks'

import { Slugs, useI18n } from 'app/i18n'
import { type SmartLock } from 'app/store/smartLocks'
import { gwText } from 'app/styles'

import { UnitSelectButton, useUnitPickerModal } from '../../core'
import {
  ButtonWrapper,
  HeadingContainer,
  HeadingIconContainer,
  HeadingIcon,
  Heading,
} from '../Settings.styles'
import { AccessLogCardContainer } from './AccessLogCard.container'

const St = {
  BlurbTitle: styled.div`
    ${gwText.headingSmall};
    color: ${colors.midnight70};
    margin-bottom: 16px;
    position: relative;
  `,
  Body: styled.div`
    color: ${colors.dusk60};
    padding: 32px;
    padding-top: 0;
  `,
  ButtonWrapper,
  Heading,
  HeadingContainer,
  HeadingIcon,
  HeadingIconContainer,
  LoaderWrapper: styled.div`
    height: 16px;
    position: relative;
  `,
}

const useTranslations = () => {
  const { t } = useI18n()

  return {
    blurbBody: t(Slugs.historicalLogBody),
    blurbTitle: t(Slugs.historicalLog),
    noAccessCodes: t(Slugs.noAccessCodes),
    title: t(Slugs.accessLog),
  }
}

interface AccessHistoryPageProps {
  requestState: AsyncState<unknown>
  smartLocks: SmartLock[]
}

export const AccessHistoryPage: React.FC<AccessHistoryPageProps> = React.memo(
  ({ requestState, smartLocks }) => {
    const strings = useTranslations()
    const { selectedUnit, showUnitPickerModal } = useUnitPickerModal()

    return (
      <section>
        <St.HeadingContainer>
          <St.HeadingIconContainer>
            <Link to={'/settings/access'}>
              <St.HeadingIcon icon={IconName.leftArrow} size={16} />
            </Link>
          </St.HeadingIconContainer>
          <St.Heading>{strings.title}</St.Heading>
        </St.HeadingContainer>

        <St.ButtonWrapper>
          <UnitSelectButton onPress={showUnitPickerModal}>
            {selectedUnit?.name}
          </UnitSelectButton>
        </St.ButtonWrapper>

        <St.Body>
          <St.BlurbTitle>
            {strings.blurbTitle}
            {requestState.loading && <Loader size={6} transparent={true} />}
          </St.BlurbTitle>
          <p>{strings.blurbBody}</p>

          {smartLocks.length > 0 ? (
            smartLocks.map(smartLock => (
              <AccessLogCardContainer
                key={smartLock.id}
                smartLock={smartLock}
              />
            ))
          ) : (
            <div>{strings.noAccessCodes}</div>
          )}
        </St.Body>
      </section>
    )
  },
)
