import { noop } from 'lodash/fp'
import { createAsyncAction } from 'typesafe-actions'

import { type ReduxActionCallbacks } from 'packages/grimoire/src/utils'
import { type RequestConfig } from 'packages/utils/store'

import { gwUnitStaffersService } from '../gwUnitStaffers.service'
import { trackGwUnitStafferDeleted } from '../gwUnitStaffers.trackers'
import {
  GwUnitStaffersActionTypes,
  type NormalizedGwUnitStaffersApiResponse,
} from '../gwUnitStaffers.types'

export const deleteGwUnitStafferAction = createAsyncAction(
  GwUnitStaffersActionTypes.DELETE_GW_UNIT_STAFFER,
  GwUnitStaffersActionTypes.DELETE_GW_UNIT_STAFFER_SUCCESS,
  GwUnitStaffersActionTypes.DELETE_GW_UNIT_STAFFER_FAILURE,
)<RequestConfig<NormalizedGwUnitStaffersApiResponse>, { id: string }, Error>()

export const deleteGwUnitStaffer =
  (id: string, callbacks: ReduxActionCallbacks = {}) =>
  async (dispatch, getState) => {
    const { onError = noop, onSuccess = noop } = callbacks
    try {
      const request = gwUnitStaffersService.deleteGwUnitStaffer.bind(null, id)
      const result = await dispatch(
        deleteGwUnitStafferAction.request({ request }),
      )

      dispatch(deleteGwUnitStafferAction.success({ id }))

      trackGwUnitStafferDeleted(id, getState)

      onSuccess()

      return result.normalized
    } catch (error) {
      dispatch(deleteGwUnitStafferAction.failure(error))
      onError()
      throw error
    }
  }
