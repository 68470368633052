import {
  type JSONApiObjectWithRelationships,
  type JSONApiObjectWithRelationshipsMap,
  type ToOneRelationship,
} from 'packages/grimoire/src/utils'

import { type Housekeeper } from '../housekeepers'

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export interface AssignmentsState {
  data: JSONApiAssignmentsMap
}

/**********************************************************
 * ASSIGNMENT
 *********************************************************/
export type Assignment = {
  housekeeper: Housekeeper
  id: string
} & AssignmentAttributes

export interface AssignmentAttributes {}

export const AssignmentAttributeNames: string[] = []

export interface AssignmentRelationships {
  clean: ToOneRelationship
  housekeeper: ToOneRelationship
}

export type RawAssignment = JSONApiObjectWithRelationships<
  AssignmentAttributes,
  AssignmentRelationships
>

export type JSONApiAssignmentsMap = JSONApiObjectWithRelationshipsMap<
  AssignmentAttributes,
  AssignmentRelationships
>
