import styled from '@emotion/styled'
import React from 'react'

import { Loader, Switch } from 'packages/common'
import { IconName, SvgIcon } from 'packages/iconic'
import {
  centerWithFlex,
  colors,
  truncateTextWithEllipsis,
} from 'packages/styles'
import { type AsyncState } from 'packages/utils/hooks'

import { Badge, Button } from 'app/components/core'
import { Slugs, useI18n } from 'app/i18n'
import type { GwLocalHost } from 'app/store/gwLocalhosts'
import { gwText } from 'app/styles'

const St = {
  Email: styled.div`
    ${truncateTextWithEllipsis};
  `,
  Info: styled.div`
    ${centerWithFlex};
    flex-shrink: 0;
  `,
  LocalHost: styled.div`
    ${gwText.headingMediumLight};
    align-items: center;
    color: ${colors.midnight70};
    display: flex;
    height: 32px; /* this ensures a uniform height between all different render states */
    grid-gap: 8px;
    justify-content: space-between;
    margin-bottom: 16px;
    position: relative;
  `,
  TrashIcon: styled(SvgIcon)`
    color: ${colors.alert};
  `,
}

const useTranslations = () => {
  const { t } = useI18n()
  return {
    inviteSent: t(Slugs.inviteSent),
  }
}

export enum GwLocalHostRowTestIds {
  container = 'SettingsPage__container',
  trash = 'trash',
}

export interface GwLocalHostRowProps {
  deleteRequestState: AsyncState<unknown>
  editing: boolean
  gwLocalHost: GwLocalHost
  onDeleteClick: () => void
  onToggle: (enabled: boolean) => void
  updateRequestState: AsyncState<unknown>
}

export const GwLocalHostRow: React.FC<GwLocalHostRowProps> = React.memo(
  ({
    deleteRequestState,
    editing,
    gwLocalHost,
    onDeleteClick,
    onToggle,
    updateRequestState,
  }) => {
    const strings = useTranslations()
    const isLoading = deleteRequestState?.loading || updateRequestState?.loading

    return (
      <St.LocalHost>
        <St.Email>{gwLocalHost.email}</St.Email>

        <St.Info>
          <>
            {editing ? (
              <Button
                dataTestId="trash"
                buttonType={'iconSubtle'}
                onPress={onDeleteClick}
              >
                <St.TrashIcon icon={IconName.trash} size={22} />
              </Button>
            ) : gwLocalHost.hasIdpPassword ? (
              <Switch checked={gwLocalHost.isActive} onToggle={onToggle} />
            ) : (
              <Badge badgeType={'yellow'}>{strings.inviteSent}</Badge>
            )}
          </>
        </St.Info>

        {isLoading && <Loader />}
      </St.LocalHost>
    )
  },
)
