import React from 'react'
import { useSelector } from 'react-redux'

import { getAccessToken } from 'app/store/auth/selectors'
import { type Clean } from 'app/store/cleans'

import { ShareCleanButton } from './ShareCleanButton'

export interface ShareCleanButtonContainerProps {
  clean: Clean
}

export const ShareCleanButtonContainer: React.FC<ShareCleanButtonContainerProps> =
  React.memo(({ clean }) => {
    const accessToken = useSelector(getAccessToken)
    if (!accessToken) return null

    return <ShareCleanButton accessToken={accessToken} clean={clean} />
  })
