import { createSelector } from 'reselect'

import { type Clean } from 'app/store/cleans/cleans.types'
import { getFrozenCleans } from 'app/store/cleans/selectors'
import { type ApplicationState } from 'app/store/store'

import { getActiveUserAssignmentByClean } from './getActiveUserAssignmentByClean'

/**
 * Returns a sorted list of all assignment IDs for the current user that are
 * tied to cleans within the freeze window.
 *
 * Note that the returned list needs to be sorted to ensure a stable
 * cache key for the ServiceWorker cache.
 */
export const getFrozenAssignmentIds = createSelector(
  (state: ApplicationState): ApplicationState => state,
  (state): string[] => {
    const findAssignmentForUser = (acc: string[], clean: Clean) => {
      const assignment = getActiveUserAssignmentByClean(state, clean)
      if (assignment) {
        acc.push(assignment.id)
      }

      return acc
    }

    const frozenCleans = getFrozenCleans(state)
    return frozenCleans.reduce(findAssignmentForUser, []).sort()
  },
)
