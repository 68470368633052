import { type RequestOptions } from 'packages/utils/store/jsonapi.types'

import { api } from '../axiosInstance'
import {
  type SmartLocksApiResponse,
  type SmartLocksServiceResponse,
} from './smartLocks.types'
import { emptySmartLocksResponse } from './smartLocks.utils'

const URL = '/smart_locks'

export const smartLocksService = {
  async fetchSmartLocks(
    params: RequestOptions = {},
  ): SmartLocksServiceResponse {
    const response: SmartLocksApiResponse = await api.get(URL, { params })
    return response?.data || emptySmartLocksResponse
  },
}
