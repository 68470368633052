import styled from '@emotion/styled'

import { SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

import { gwText } from 'app/styles'

export const LockCodeCardSection = styled.div`
  align-items: center;
  display: flex;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
`

export const LockCodeIconBackground = styled.div`
  background-color: ${colors.midnight10};
  border-radius: 50%;
  padding: 10px;
`

export const LockCodeIcon = styled(SvgIcon)`
  bottom: 1px;
  color: ${colors.midnight70};
  position: relative;
`

export const CardHeading = styled.h2`
  ${gwText.headingSmall};
  color: ${colors.midnight70};
  margin: 0 0 0 12px;
`

export const CardBody = styled.p`
  ${gwText.bodyDefault};
  color: ${colors.dusk60};
  margin: 0;
  width: 100%;
`
