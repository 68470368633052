import React from 'react'
import { useSelector } from 'react-redux'

import { type SmartLock } from 'app/store/smartLocks'
import { type ApplicationState } from 'app/store/store'
import { getUserById } from 'app/store/users/selectors'

import { AccessLogCard } from './AccessLogCard'

interface AccessLogCardContainerProps {
  smartLock: SmartLock
}

export const AccessLogCardContainer: React.FC<AccessLogCardContainerProps> =
  React.memo(({ smartLock }) => {
    const user = useSelector((state: ApplicationState) =>
      getUserById(state, smartLock?.userId),
    )

    if (!smartLock || !user) {
      return null
    }

    return <AccessLogCard smartLock={smartLock} user={user} />
  })
