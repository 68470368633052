import styled from '@emotion/styled'
import React from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { Loader } from 'packages/common'

import { OfflineNotifierContainer } from 'app/components/core'

import { getAppInitState } from '../store/app/selectors'
import { getMayImpersonateUser } from '../store/users/selectors/getMayImpersonateUser'
import { ConnectedAdminPanel } from './admin/AdminContainer'
import { NavTabWrapper, useInitializeGuestworks } from './core'
import { GuestworksRouter } from './Guestworks.router'

interface GuestworksRootProps {
  initialUrl: string
}

const St = {
  Main: styled.main`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-bottom: 30px;
  `,
}

export const GuestworksRoot: React.FC<GuestworksRootProps> = React.memo(
  ({ initialUrl }) => {
    useInitializeGuestworks()

    const mayImpersonate = useSelector(getMayImpersonateUser)
    const appInitState = useSelector(getAppInitState)
    const isInitializing = appInitState === 'initial'

    return isInitializing ? (
      <Loader />
    ) : (
      <BrowserRouter>
        <St.Main>
          {mayImpersonate && <ConnectedAdminPanel />}
          <GuestworksRouter initialUrl={initialUrl} />
          <OfflineNotifierContainer />
        </St.Main>
        <NavTabWrapper />
      </BrowserRouter>
    )
  },
)
