const ADMIN_BASE_URL = process.env.REACT_APP_ADMIN_BASE_URL

export const ADMIN_TICKETS_URL = `${ADMIN_BASE_URL}/admin/dashboard/tickets`
export const ADMIN_UNITS_URL = `${ADMIN_BASE_URL}/admin/dashboard/units`
export const CREATE_TICKET_URL = `${ADMIN_TICKETS_URL}/editMaintenance`

export enum RequestedBy {
  Employee = '3',
  Guest = '2',
  Owner = '1',
}

interface AdminCreateTicketParams {
  requestedBy?: RequestedBy
  reservationId?: string
  unitId: string
}

/**
 * Generates a Vacasa Admin link to create a new ticket for the given Unit.
 * (Params passed in are used to pre-populate some fields in the form.)
 */
export const getAdminCreateTicketURL = ({
  requestedBy = RequestedBy.Employee,
  reservationId,
  unitId,
}: AdminCreateTicketParams): string => {
  const params = new URLSearchParams()

  params.set('UnitID', unitId)
  params.set('RequestedBy', requestedBy)

  if (reservationId) {
    params.set('ReservationID', reservationId)
  }

  return `${CREATE_TICKET_URL}?${params.toString()}`
}

/**
 * Generates a link to an individual ticket in Vacasa Admin
 */
export const getAdminViewTicketURL = (id: string): string =>
  `${ADMIN_TICKETS_URL}/view?TicketID=${id}&Maintenance=1`

/**
 * Generates a Vacasa Admin link to view all tickets associated with a Unit
 */
export const getAdminViewUnitTicketsURL = (id: string): string =>
  `${ADMIN_TICKETS_URL}/units?UnitID=${id}`

/**
 * Generates an Admin link to edit the (optional) HK Notes on a given Unit.
 */
export const getAdminEditUnitHkNotesUrl = (unitId: string): string =>
  `${ADMIN_UNITS_URL}/housekeeping?UnitID=${unitId}`

/**
 * Generates a link to view a unit's details on the public website
 */
export const getVacasaViewUnitURL = (id: string): string =>
  `https://www.vacasa.com/unit.php?UnitID=${id}`

const ADD_DAYS_BASE = `${ADMIN_BASE_URL}/admin/dashboard/reservation/finance`

export const getAddDaysToResUrl = (resId: string): string =>
  `${ADD_DAYS_BASE}/beginAdjustment?reservationId=${resId}&adjustmentType=extraCleaningDays`
