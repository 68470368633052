import styled from '@emotion/styled'
import React from 'react'

import { IconName, SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

import { Slugs, useI18n } from 'app/i18n'

const St = {
  BadDelegateLink: styled.main`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 16px;
    padding-top: 64px; // this is an arbitrary value, but it looks good at all sizes
    text-align: center;
  `,

  Body: styled.p`
    font-size: 16px;
    margin-top: 16px;
  `,

  Header: styled.h1`
    font-size: 38px;
    font-weight: bold;
  `,

  Icon: styled(SvgIcon)`
    color: ${colors.laguna};
    margin-bottom: 32px;
  `,
}

const useTranslations = () => {
  const { ut } = useI18n()

  return {
    body: ut(Slugs.sharedCleanExpiredBody),
    header: ut(Slugs.sharedCleanExpiredTitle),
  }
}

export const BadDelegateLink: React.FC = React.memo(() => {
  const strings = useTranslations()

  return (
    <St.BadDelegateLink>
      <St.Icon icon={IconName.linkBroken} size={110} />
      <St.Header>{strings.header}</St.Header>
      <St.Body>{strings.body}</St.Body>
    </St.BadDelegateLink>
  )
})
