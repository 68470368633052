import styled from '@emotion/styled'
import React from 'react'

import { CloseButton } from 'packages/common/src/modals/components'
import { centerWithFlex, colors } from 'packages/styles'

import { isViewingSharedClean } from 'app/components/schedule/schedule.utils'
import { Slugs, useI18n } from 'app/i18n'

import {
  CleanDetailContainer,
  type CleanDetailContainerProps,
} from '../CleanDetail.container'
import { ShareCleanButtonContainer } from '../components/ShareCleanButton'

const HEADER_CONTROLS_HEIGHT = 72
const HEADER_TAB_HEIGHT = 44

export const St = {
  CloseButton: styled(CloseButton)`
    position: static;
  `,

  Header: styled.div`
    align-items: center;
    background-color: ${colors.midnight4};
    box-shadow: 0 5px 11px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: sticky;
    top: 0;
    z-index: 1;
  `,

  HeaderControls: styled.div`
    ${centerWithFlex};
    align-items: center;
    display: flex;
    height: ${HEADER_CONTROLS_HEIGHT}px;
    justify-content: space-between;
    margin-right: auto;
    padding: 0 16px;
    width: 100%;
  `,

  HeaderText: styled.div`
    flex-grow: 1;
    font-size: 16px;
    font-weight: 700;
    height: ${HEADER_TAB_HEIGHT}px;
    line-height: ${HEADER_TAB_HEIGHT}px;
    text-align: center;
  `,

  HeaderTextContainer: styled.div`
    border-top: 1px solid ${colors.midnight20};
    margin-bottom: 0;
    width: 100%;
  `,
}

const useTranslations = () => {
  const { t } = useI18n()

  return {
    unitInfo: t(Slugs.unitInfo),
  }
}

type PickedCleanDetailProps = Pick<
  CleanDetailContainerProps,
  'clean' | 'lockbox'
>

export type CleanDetailWrapperProps = PickedCleanDetailProps & {
  onCloseDrawerClick: () => void
}

export const CleanDetailWrapper: React.FC<CleanDetailWrapperProps> = React.memo(
  ({ clean, lockbox, onCloseDrawerClick }) => {
    const strings = useTranslations()
    const shouldRenderHeaderSection = !isViewingSharedClean()

    return (
      <>
        <St.Header>
          {shouldRenderHeaderSection && (
            <St.HeaderControls>
              <ShareCleanButtonContainer clean={clean} />
              <St.CloseButton onClick={onCloseDrawerClick} />
            </St.HeaderControls>
          )}

          <St.HeaderTextContainer>
            <St.HeaderText>{strings.unitInfo}</St.HeaderText>
          </St.HeaderTextContainer>
        </St.Header>

        <CleanDetailContainer clean={clean} lockbox={lockbox} />
      </>
    )
  },
)
