import React from 'react'

import { useEcdModal, useUpdateClean } from 'app/components/schedule/hooks'
import { type Clean } from 'app/store/cleans'
import { type Lockbox } from 'app/store/lockboxes/lockboxes.types'

import { CleanDetail } from './CleanDetail'

export interface CleanDetailContainerProps {
  clean: Clean
  lockbox?: Lockbox
}

export const CleanDetailContainer: React.FC<CleanDetailContainerProps> =
  React.memo(({ clean, lockbox }) => {
    const { updateState, showModal } = useUpdateClean(clean)
    const { showEcdModal } = useEcdModal()

    return (
      <CleanDetail
        clean={clean}
        lockbox={lockbox}
        requestState={updateState}
        showEcdModal={showEcdModal}
        showModal={showModal}
      />
    )
  })
