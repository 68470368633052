import styled from '@emotion/styled'
import React from 'react'

import { colors } from 'packages/styles'

import { Slugs, useI18n } from 'app/i18n'
import type { Reservation } from 'app/store/reservations'
import { gwText } from 'app/styles'

const St = {
  ResHeader: styled.div<{ res: Reservation }>`
    ${gwText.headingSmallCaps};
    align-items: center;
    background-color: ${({ res }) => bgColorMap[res.bookingType]};
    color: white;
    display: flex;
    height: 40px;
    justify-content: flex-start;
    padding: 0;
    padding-left: 16px;
  `,
}

/* eslint-disable @typescript-eslint/naming-convention */
const bgColorMap: Record<Reservation['bookingType'], string> = {
  guest_reservation: '#5a2458',
  owner_hold: '#8c9711',
  unknown: colors.yellow,
  vacasa_hold: colors.yellow,
}

const textMap: Record<Reservation['bookingType'], string> = {
  guest_reservation: Slugs.guestStay,
  owner_hold: Slugs.ownerHold,
  unknown: Slugs.unknown,
  vacasa_hold: Slugs.vacasaHold,
}
/* eslint-enable @typescript-eslint/naming-convention */

export interface ResHeaderProps {
  res: Reservation
}

export const ResHeader: React.FC<ResHeaderProps> = React.memo(({ res }) => {
  const { t } = useI18n()
  return <St.ResHeader res={res}>{t(textMap[res.bookingType])}</St.ResHeader>
})
