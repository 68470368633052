import { type AxiosResponse } from 'axios'
import { snakeCase } from 'lodash/fp'

import {
  type JSONApiObjectWithRelationships,
  type JSONApiObjectWithRelationshipsMap,
  type NormalizedJSONApiResponse,
  type ToOneRelationship,
} from 'packages/utils/store/jsonapi.types'

import { type JSONApiUnitsMap } from '../units'
import { type JSONApiUsersMap } from '../users'

/**********************************************************
 * SERVICE TYPES
 *********************************************************/
export interface SmartLocksResponse {
  smartLock: JSONApiSmartLocksMap
  unit: JSONApiUnitsMap
  user: JSONApiUsersMap
}

export type NormalizedSmartLocksApiResponse =
  NormalizedJSONApiResponse<SmartLocksResponse>

export type SmartLocksServiceResponse = Promise<NormalizedSmartLocksApiResponse>

export type SmartLocksApiResponse =
  AxiosResponse<NormalizedSmartLocksApiResponse>

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export enum SmartLocksActionTypes {
  FETCH_SMART_LOCKS_BY_UNIT_ID = 'SMART_LOCKS/API/FETCH_SMART_LOCKS_BY_UNIT_ID',
  FETCH_SMART_LOCKS_BY_UNIT_ID_FAILURE = 'SMART_LOCKS/FETCH_SMART_LOCKS_BY_UNIT_ID_FAILURE',
  FETCH_SMART_LOCKS_BY_UNIT_ID_SUCCESS = 'SMART_LOCKS/FETCH_SMART_LOCKS_BY_UNIT_ID_SUCCESS',
}

export interface SmartLocksState {
  data: JSONApiSmartLocksMap
}

/**********************************************************
 * SMART LOCKS
 *********************************************************/
export interface SmartLockAttributes {
  accessCode: string
  expiredAt: string
  validAt: string
}

export type SmartLock = {
  id: string
  unitId: string
  userId: string
} & SmartLockAttributes

export const SmartLockAttributeNames = ['accessCode', 'expiredAt', 'validAt']

export const SmartLockApiFields = SmartLockAttributeNames.map<string>(snakeCase)

export interface SmartLockRelationships {
  generatedBy: ToOneRelationship
  unit: ToOneRelationship
}

export type RawSmartLock = JSONApiObjectWithRelationships<
  SmartLockAttributes,
  SmartLockRelationships
>

export type JSONApiSmartLocksMap = JSONApiObjectWithRelationshipsMap<
  SmartLockAttributes,
  SmartLockRelationships
>
