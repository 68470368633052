import React from 'react'

import { IconName } from 'packages/iconic'

import { Slugs, useI18n } from 'app/i18n'

import { UnitSelectButton } from '../../core/components/UnitSelectButton'
import { useUnitPickerModal } from '../../core/hooks/useUnitPickerModal'
import { LockCodeCardContainer } from '../components/LockCodeCard'
import * as St from './AccessPage.styles'

export enum AccessPageTestIds {
  container = 'AccessPage__container',
  headingIcon = 'AccessPage__headingIcon',
  lockCodeFormContainer = 'AccessPage__lockCodeFormContainer',
  noSmartLockCard = 'AccessPage__noSmartLockCard',
  unitName = 'AccessPage__unitName',
}

export interface AccessPageProps {
  onHeaderClick: () => void
}

const useTranslations = () => {
  const { t } = useI18n()

  return {
    link: t(Slugs.viewHistoricalLog),
    lockCodes: t(Slugs.lockCodes),
    lockCodeSupportText: t(Slugs.lockCodeSupportText),
    noLockCodeText: t(Slugs.noSmartLockCardText),
  }
}

export const AccessPage: React.FC<AccessPageProps> = React.memo(
  ({ onHeaderClick }) => {
    const strings = useTranslations()

    const { selectedUnit, showUnitPickerModal } = useUnitPickerModal()

    const hasSmartLock = selectedUnit?.hasSmartLock

    return (
      <section data-testid={AccessPageTestIds.container}>
        <St.HeadingContainer>
          <St.HeadingIconContainer
            data-testid={AccessPageTestIds.headingIcon}
            onClick={onHeaderClick}
          >
            <St.HeadingIcon icon={IconName.leftArrow} size={16} />
          </St.HeadingIconContainer>
          <St.Heading>{strings.lockCodes}</St.Heading>
        </St.HeadingContainer>

        <St.ButtonWrapper>
          <UnitSelectButton onPress={showUnitPickerModal}>
            {selectedUnit?.name}
          </UnitSelectButton>
        </St.ButtonWrapper>

        {hasSmartLock ? (
          <>
            <St.LockCodeFormContainer
              data-testid={AccessPageTestIds.lockCodeFormContainer}
            >
              <LockCodeCardContainer />
            </St.LockCodeFormContainer>

            <St.LinkWrapper>
              <St.Link to={'/settings/access/log'}>{strings.link}</St.Link>
            </St.LinkWrapper>
          </>
        ) : (
          <St.NoSmartLockCard dataTestId={AccessPageTestIds.noSmartLockCard}>
            <St.AlertTriangleIcon icon={IconName.alertTriangle} size={56} />
            <St.NoSmartLockCardText>
              {strings.noLockCodeText}
            </St.NoSmartLockCardText>
          </St.NoSmartLockCard>
        )}
      </section>
    )
  },
)
