import styled from '@emotion/styled'

import { Card } from 'packages/common'

import { gwText } from 'app/styles'

export const StyledTicketCard = styled(Card)`
  margin-bottom: 8px;
`

export const StatusBar = styled.div`
  background: #c4dab3;
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  padding: 6px 8px;
`

export const InfoContainer = styled.div`
  padding: 16px;
`

export const Title = styled.div`
  ${gwText.headingSmall}
  margin-bottom: 8px;
`

export const Code = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`

export const Address = styled.div`
  ${gwText.bodySmall}
  margin-bottom: 16px;
`

export const StatusBadgeContainer = styled.div`
  margin-bottom: 16px;
`
