import styled from '@emotion/styled'
import upperFirst from 'lodash/upperFirst'
import React from 'react'
import { useSelector } from 'react-redux'

import { colors } from 'packages/styles'
import { distanceToNowLocalized } from 'packages/utils/dateHelpers'
import { useOnlineStatus } from 'packages/utils/hooks/useOnlineStatus'

import { Slugs, useI18n } from 'app/i18n'
import { getCoreDataIsLoading } from 'app/store/utils/selectors'

// the interval at which we will update the "last updated" string
// we probably want this to be just under a minute, so we can ensure we have a fairly accurate readout
const FETCH_STRING_UPDATE_INTERVAL = 55 * 1000

const St = {
  LastFetchBanner: styled.div`
    color: ${colors.dusk60};
    font-size: 12px;
  `,
  Link: styled.span`
    color: ${colors.lake};
    cursor: pointer;
    margin-left: 8px;
  `,
}

export interface LastFetchBannerProps {
  className?: string
  /**
   * The timestamp of the last successful fetch.
   * This will be used to parse the "last updated xxx ago" string.
   * If this value is falsey, the component will assume there has not been a successful
   * fetch, and thus will render nothing at all.
   */
  lastFetch: number
  onRefetch: () => void
  type?: string
}

export const LastFetchBanner: React.FC<LastFetchBannerProps> = React.memo(
  ({ className, lastFetch, onRefetch }) => {
    const { t, ut } = useI18n()
    const isOnline = useOnlineStatus().isOnline()

    const isLoading = useSelector(getCoreDataIsLoading)

    const [lastFetchString, setLastFetchString] = React.useState('')
    const [lastFetchTimestamp, setLastFetchTimestamp] =
      React.useState(lastFetch)

    // this will be used to keep track of the setInterval instance, so we can remove it in useEffect
    const updateLastFetchIntervalRef = React.useRef<any>(0) // eslint-disable-line

    React.useEffect(() => {
      if (lastFetch && lastFetch !== lastFetchTimestamp) {
        setLastFetchTimestamp(lastFetch)
      }
    }, [lastFetch, lastFetchTimestamp])

    // when our local "last fetch" data changes, parse a new string from it
    React.useEffect(() => {
      const updateLastFetchString = () => {
        if (lastFetchTimestamp === 0) return

        const newLastFetchString = distanceToNowLocalized(lastFetchTimestamp)
        if (newLastFetchString !== lastFetchString) {
          setLastFetchString(newLastFetchString)
        }
      }

      // call the update function once immediately, then set up the interval
      updateLastFetchString()
      updateLastFetchIntervalRef.current = setInterval(
        updateLastFetchString,
        FETCH_STRING_UPDATE_INTERVAL,
      )

      return () => {
        clearInterval(updateLastFetchIntervalRef.current)
      }
    }, [lastFetchString, lastFetchTimestamp])

    const shouldRender = isLoading || lastFetchString
    const updatingText = ut(Slugs.updating)
    const lastUpdatedText = `${t(Slugs.lastUpdated)} ${lastFetchString}`

    return shouldRender ? (
      <St.LastFetchBanner className={className}>
        {isLoading ? updatingText : lastUpdatedText}
        {!isLoading && isOnline && (
          <St.Link onClick={onRefetch}>{upperFirst(t(Slugs.update))}</St.Link>
        )}
      </St.LastFetchBanner>
    ) : null
  },
)
