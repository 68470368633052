import { transformNormalizedToTyped } from 'packages/utils/store'

import {
  type Housekeeper,
  HousekeeperAttributeNames,
  type RawHousekeeper,
} from './housekeepers.types'

export const emptyNormalizedHousekeepersData = Object.freeze({
  housekeeper: {},
})

export const hydrateRawHousekeeper = (
  rawAssignment: RawHousekeeper,
): Housekeeper => {
  const housekeeper = transformNormalizedToTyped<Housekeeper>(
    rawAssignment,
    HousekeeperAttributeNames,
  )

  return {
    ...housekeeper,
  }
}
