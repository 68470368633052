import { type AxiosResponse } from 'axios'
import { snakeCase } from 'lodash/fp'

import {
  type JSONApiObjectWithRelationships,
  type JSONApiObjectWithRelationshipsMap,
  type NormalizedJSONApiResponse,
  type ToOneRelationship,
} from 'packages/grimoire/src/utils'

import { type JSONApiUnitsMap } from '../units'

/**********************************************************
 * SERVICE TYPES
 *********************************************************/

export interface GwLocalHostPostAttributes {
  email: string
  firstName: string
  lastName: string
  unitId: string
}

export interface GwLocalHostPostApiAttributes {
  email: string
  first_name: string
  last_name: string
}

export interface GwLocalHostPostApiRelationships {
  unit: ToOneRelationship
}

export type NormalizedGwLocalHostsApiResponse =
  NormalizedJSONApiResponse<GwLocalHostsResponse>

export interface GwLocalHostPatchApiAttributes {
  is_active: boolean
}

export interface GwLocalHostPatchAttributes {
  isActive: boolean
}

export type GwLocalHostPatchData = {
  id: string
} & Partial<GwLocalHostPatchAttributes>

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export enum GwLocalHostsActionTypes {
  CREATE_GW_LOCAL_HOST = 'GW_LOCAL_HOSTS/API/CREATE_GW_LOCAL_HOST',
  CREATE_GW_LOCAL_HOST_FAILURE = 'GW_LOCAL_HOSTS/CREATE_GW_LOCAL_HOST_FAILURE',
  CREATE_GW_LOCAL_HOST_SUCCESS = 'GW_LOCAL_HOSTS/CREATE_GW_LOCAL_HOST_SUCCESS',

  DELETE_GW_LOCAL_HOST = 'GW_LOCAL_HOSTS/API/DELETE_GW_LOCAL_HOST',
  DELETE_GW_LOCAL_HOST_FAILURE = 'GW_LOCAL_HOSTS/DELETE_GW_LOCAL_HOST_FAILURE',
  DELETE_GW_LOCAL_HOST_SUCCESS = 'GW_LOCAL_HOSTS/DELETE_GW_LOCAL_HOST_SUCCESS',

  FETCH_GW_LOCAL_HOST = 'GW_LOCAL_HOSTS/API/FETCH_GW_LOCAL_HOST',
  FETCH_GW_LOCAL_HOST_FAILURE = 'GW_LOCAL_HOSTS/FETCH_GW_LOCAL_HOST_FAILURE',
  FETCH_GW_LOCAL_HOST_SUCCESS = 'GW_LOCAL_HOSTS/FETCH_GW_LOCAL_HOST_SUCCESS',

  UPDATE_GW_LOCAL_HOST = 'GW_LOCAL_HOSTS/API/UPDATE_GW_LOCAL_HOST',
  UPDATE_GW_LOCAL_HOST_FAILURE = 'GW_LOCAL_HOSTS/UPDATE_GW_LOCAL_HOST_FAILURE',
  UPDATE_GW_LOCAL_HOST_SUCCESS = 'GW_LOCAL_HOSTS/UPDATE_GW_LOCAL_HOST_SUCCESS',
}

export interface GwLocalHostsState {
  data: JSONApiGwLocalHostMap
}

export interface GwLocalHostRelationships {
  unit: ToOneRelationship
}

export interface GwLocalHostsResponse {
  gwLocalHost: JSONApiGwLocalHostMap
  unit: JSONApiUnitsMap
}

export type GwLocalHost = {
  id: string
  unitId: string
} & GwLocalHostAttributes

export interface GwLocalHostAttributes {
  email: string
  firstName: string
  hasIdpPassword: boolean
  isActive: boolean
  lastName: string
}

export type GwLocalHostsServiceResponse =
  Promise<NormalizedGwLocalHostsApiResponse>

export type GwLocalHostsApiResponse =
  AxiosResponse<NormalizedGwLocalHostsApiResponse>

export const GwLocalHostAttributeNames = [
  'email',
  'firstName',
  'hasIdpPassword',
  'isActive',
  'lastName',
]

export const GwLocalHostApiFields: string[] =
  GwLocalHostAttributeNames.map(snakeCase)

export type RawGwLocalHost = JSONApiObjectWithRelationships<
  GwLocalHostAttributes,
  GwLocalHostRelationships
>

export type JSONApiGwLocalHostMap = JSONApiObjectWithRelationshipsMap<
  GwLocalHostAttributes,
  GwLocalHostRelationships
>
