import { createAsyncAction } from 'typesafe-actions'

import { DateFormat, format } from 'packages/utils/dateHelpers'
import {
  type RequestConfig,
  type RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { FETCH_CLEANS_RESERVATIONS_MAX_SIZE } from 'app/Guestworks.constants'

import { HousekeeperApiFields } from '../../housekeepers'
import { LockboxApiFields } from '../../lockboxes'
import { ReservationApiFields } from '../../reservations'
import { UserApiFields } from '../../users'
import { cleansService } from '../cleans.service'
import {
  CleanApiFields,
  CleansActionTypes,
  type NormalizedCleansApiResponse,
} from '../cleans.types'

export const fetchPreviousCleansAction = createAsyncAction(
  CleansActionTypes.FETCH_PREVIOUS_CLEANS,
  CleansActionTypes.FETCH_PREVIOUS_CLEANS_SUCCESS,
  CleansActionTypes.FETCH_PREVIOUS_CLEANS_FAILURE,
)<
  RequestConfig<NormalizedCleansApiResponse>,
  NormalizedCleansApiResponse,
  Error
>()

export const getDateRange = (startDate: string, endDate?: string) =>
  endDate
    ? {
        range: [
          format(startDate, DateFormat.ApiUtcShort),
          format(endDate, DateFormat.ApiUtcShort),
        ],
      }
    : format(startDate, DateFormat.ApiUtcShort)

export const getParams = (
  dateRange: string | { range: string[] },
): RequestOptions => {
  return {
    fields: {
      assignment: [],
      clean: CleanApiFields,
      housekeeper: HousekeeperApiFields,
      lock_box: LockboxApiFields,
      reservation: ReservationApiFields,
      unit: [],
      user: UserApiFields,
    },
    filter: {
      effective_date: dateRange,
      is_active: true,
    },
    include: [
      'active_assignments',
      'active_assignments.housekeeper',
      'active_assignments.housekeeper.user',
      'reservation',
      'reservation.unit',
      'unit',
      'unit.lock_box',
    ],
    page: { size: FETCH_CLEANS_RESERVATIONS_MAX_SIZE },
    sort: ['effective_date'],
  }
}

export const fetchPreviousCleans =
  (startDate: string, endDate?: string) => async dispatch => {
    try {
      const dateRange = getDateRange(startDate, endDate)
      const params = getParams(dateRange)
      const request = cleansService.fetchCleans.bind(null, params)
      const result: NormalizedCleansApiResponse = await dispatch(
        fetchPreviousCleansAction.request({ request }),
      )

      dispatch(fetchPreviousCleansAction.success(result))
      return result.normalized
    } catch (error) {
      dispatch(fetchPreviousCleansAction.failure(error))
      throw error
    }
  }
