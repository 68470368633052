import styled from '@emotion/styled'
import React from 'react'

import { Button } from 'packages/common'
import { IconName, SvgIcon } from 'packages/iconic'

import { useDelegateToken } from 'app/components/core/hooks/useDelegateToken'
import { type Clean } from 'app/store/cleans'

const St = {
  Icon: styled(SvgIcon)`
    margin-right: 12px;
    padding-bottom: 2px;
  `,
}

export interface ShareCleanButtonProps {
  accessToken: string
  clean: Clean
}

export const ShareCleanButton: React.FC<ShareCleanButtonProps> = React.memo(
  ({ accessToken, clean }) => {
    const cleanId = clean?.id
    const unitAddress = clean?.unit?.address

    const {
      buttonText,
      canShare,
      copyLinkText,
      disabled,
      fetchTokenState,
      onClick,
    } = useDelegateToken(accessToken, cleanId, 'cleans', unitAddress)

    return (
      <Button
        disabled={disabled}
        isLoading={fetchTokenState.loading}
        onClick={onClick}
        buttonType={'secondary'}
      >
        {canShare ? (
          <>
            <St.Icon icon={IconName.share2} size={24} />
            {buttonText}
          </>
        ) : (
          <>
            <St.Icon icon={IconName.copy} size={22} />
            {copyLinkText}
          </>
        )}
      </Button>
    )
  },
)
