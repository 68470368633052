import upperFirst from 'lodash/upperFirst'

import { cleanIsMidstay } from 'packages/grimoire'
import { type TranslatorFn } from 'packages/i18n'
import {
  DateFormat,
  format,
  MIDSTAY_CLEAN_DUE_TIME_STRING,
  MIDSTAY_CLEAN_START_TIME_STRING,
} from 'packages/utils/dateHelpers'
import {
  unitCodeAndNameString,
  unitFullAddressString,
} from 'packages/utils/hkStringHelpers'

import { Slugs } from 'app/i18n'
import { type Clean } from 'app/store/cleans'
import { type Reservation } from 'app/store/reservations'
import {
  type Amenities,
  BathAmenities,
  BedAmenities,
  HotTubAmenities,
  OrderedBathAmenities,
  OrderedBedAmenities,
} from 'app/store/units'
import {
  cleanCheckoutStringForShare,
  cleanDueDateStringForShare,
} from 'app/utils'

export const UNIT_DETAIL_URL_BASE = 'https://www.vacasa.com/unit.php?UnitID='

const formatDateTime = (date: string, time: string): string =>
  `${date}, ${time}`

export const getStartDateTimeString = (clean: Clean): string => {
  if (cleanIsMidstay(clean)) {
    return formatDateTime(
      format(clean.effectiveDate, DateFormat.ShareTextDateOnly),
      MIDSTAY_CLEAN_START_TIME_STRING,
    )
  }

  return cleanCheckoutStringForShare(clean)
}

export const getDueDateTimeString = (clean: Clean): string => {
  if (cleanIsMidstay(clean)) {
    return formatDateTime(
      format(clean.dueDate, DateFormat.ShareTextDateOnly),
      MIDSTAY_CLEAN_DUE_TIME_STRING,
    )
  }

  return cleanDueDateStringForShare(clean)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getAmenityString = (key: string, value: number, tp): string => {
  switch (key) {
    case BedAmenities.kingBeds:
      return tp(Slugs.king, value)
    case BedAmenities.queenBeds:
      return tp(Slugs.queen, value)
    case BedAmenities.doubleBeds:
      return tp(Slugs.double, value)
    case BedAmenities.twinBeds:
      return tp(Slugs.twin, value)
    case BedAmenities.sofabed:
      return tp(Slugs.sofaBed, value)
    case BedAmenities.futon:
      return tp(Slugs.futon, value)
    case BathAmenities.fullBaths:
      return tp(Slugs.full, value)
    case BathAmenities.halfBaths:
      return tp(Slugs.half, value)
    default:
      return tp(key, value)
  }
}

/**
 * Takes in an array of ordered amenities and reduces it to a single string
 * containing the quantity of each amenity within that unit. The results
 * are then formated to remove ", " at the beginning of the new composed string.
 * EX: Results => "3 King, 3 Queen, 5 Double, 2 Twin, 4 Futon, 2 Sofa Bed"
 * @param orderedAmenityList
 * @param amenities
 * @param tp
 */
export const composeOrderedAmenityString = (
  orderedAmenityList: string[],
  amenities: Amenities,
  tp, // eslint-disable-line
): string => {
  return orderedAmenityList
    .reduce((acc, key) => {
      let accRef = acc
      if (amenities?.[key]) {
        const value = amenities[key]
        accRef += `, ${getAmenityString(key, value, tp)}`
      }

      return accRef
    }, '')
    .slice(2)
}

/**
 * Takes a clean, and returns a formatted text block for use with the share API
 * @param clean
 * @param t
 * @param tp
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createShareText = (clean: Clean, t: TranslatorFn, tp): string => {
  const { unit, reservation } = clean

  const { guests, dogs } = reservation

  const { amenities, beds, bathrooms } = unit

  const dueDateString = getDueDateTimeString(clean)
  const startDateString = getStartDateTimeString(clean)

  const unitNameString = unitCodeAndNameString(unit)
  const addressString = unitFullAddressString(unit)
  const lastResString = `${tp(Slugs.guest, guests)}, ${tp(Slugs.dog, dogs)}`

  const bedTypeString = composeOrderedAmenityString(
    OrderedBedAmenities,
    amenities,
    tp,
  )
  const bathTypeString = composeOrderedAmenityString(
    OrderedBathAmenities,
    amenities,
    tp,
  )

  const bedString =
    tp(Slugs.bed, beds) + (bedTypeString ? ` (${bedTypeString})` : '')

  const bathString =
    tp(Slugs.bath, bathrooms) + (bathTypeString ? ` (${bathTypeString})` : '')

  const hotTubString = tp(Slugs.hotTub, amenities[HotTubAmenities.hotTub])

  const unitLocationInfoString = `${t(Slugs.unit)}: ${unitNameString}\n${t(
    Slugs.address,
  )}: ${addressString}`

  const cleanTimesString = `${upperFirst(
    t(Slugs.start),
  )}: ${startDateString}\n${upperFirst(t(Slugs.due))}: ${dueDateString}`

  const priorReservationString = `${t(Slugs.priorReservation)}: ${lastResString}`

  let unitAttributesString = `${bedString}\n${bathString}\n`
  unitAttributesString += amenities.hotTub ? `${hotTubString}\n` : ''

  unitAttributesString += `${t(Slugs.listing)}: ${UNIT_DETAIL_URL_BASE}${clean.unit.id}`

  const housekeeperNotesString = clean.unit.housekeeperNotes
    ? `${t(Slugs.additionalInstructions)}: ${clean.unit.housekeeperNotes}`
    : null

  const sections = [
    unitLocationInfoString,
    cleanTimesString,
    priorReservationString,
    unitAttributesString,
    housekeeperNotesString,
  ]

  // Join the strings, but filter out any strings that are null - HK Notes may or may not exist
  return sections.filter(s => !!s).join('\n\n')
}

// takes a reservation and returns true if any guest details exist
export const hasGuestDetails = (reservation: Reservation): boolean => {
  const { guestEmail, guestFirstName, guestLastName, guestPhone } = reservation
  return !!(guestEmail || guestFirstName || guestLastName || guestPhone)
}

// takes a reservation and decides whether to render nothing, part of the name, or the whole name
export const formatGuestName = (reservation: Reservation): string => {
  const { guestFirstName, guestLastName } = reservation

  if (!guestFirstName && !guestLastName) return ''

  if (!!guestFirstName && !guestLastName) {
    return `${guestFirstName}`
  }

  if (!guestFirstName && !!guestLastName) {
    return `${guestLastName}`
  }

  return `${guestFirstName} ${guestLastName}`
}
