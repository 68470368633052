import { createAsyncAction } from 'typesafe-actions'

import {
  type RequestConfig,
  type RequestOptions,
  JsonApiErrorCodes,
} from 'packages/utils/store/jsonapi.types'
import { hasErrorCode } from 'packages/utils/store/jsonapi.utils'

import { LockboxApiFields } from '../../lockboxes'
import * as ticketsService from '../tickets.service'
import {
  TicketsActionTypes,
  type NormalizedTicketsApiResponse,
  TicketApiFields,
  TicketStatus,
} from '../tickets.types'

export const fetchTicketsAction = createAsyncAction(
  TicketsActionTypes.FETCH_TICKETS,
  TicketsActionTypes.FETCH_TICKETS_SUCCESS,
  TicketsActionTypes.FETCH_TICKETS_FAILURE,
)<
  RequestConfig<NormalizedTicketsApiResponse>,
  NormalizedTicketsApiResponse,
  Error
>()

export const getTicketParams = (): RequestOptions => {
  return {
    fields: {
      lock_box: LockboxApiFields,
      ticket: TicketApiFields,
      unit: [],
    },
    filter: {
      status: {
        in: [
          TicketStatus.ASSIGNED,
          TicketStatus.ACCEPTED,
          TicketStatus.RESOLUTION_PENDING,
        ],
      },
    },
    include: ['unit', 'lock_box'],
    // legacy API max results limited to 100
    page: { size: 100 },
  }
}

export const fetchTickets = () => async dispatch => {
  try {
    const params = getTicketParams()
    const request = ticketsService.fetchTickets.bind(null, params)
    const result = await dispatch(fetchTicketsAction.request({ request }))

    dispatch(fetchTicketsAction.success(result))
    return result
  } catch (error) {
    dispatch(fetchTicketsAction.failure(error))

    if (hasErrorCode(JsonApiErrorCodes.MISSING_MONOLITH_USER_ID, error)) {
      return
    }

    throw error
  }
}
