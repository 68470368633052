import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Loader } from 'packages/common'
import { useAsyncFnWithReset } from 'packages/utils/hooks'

import { getAppInitState } from 'app/store/app/selectors'
import { fetchSmartLocksByUnitId } from 'app/store/smartLocks/actions'
import { getSmartLocksByUnitId } from 'app/store/smartLocks/selectors'
import { type ApplicationState } from 'app/store/store'
import { getSelectedUnit } from 'app/store/units/selectors'

import { AccessHistoryPage } from './AccessHistoryPage'
import { type UnknownAction } from '@reduxjs/toolkit'

export const AccessHistoryPageContainer: React.FC = React.memo(() => {
  const dispatch = useDispatch()

  const appInitState = useSelector(getAppInitState)
  const appIsLoading = !['ready', 'error'].includes(appInitState)

  const selectedUnit = useSelector(getSelectedUnit)
  const smartLocks = useSelector((state: ApplicationState) =>
    getSmartLocksByUnitId(state, selectedUnit?.id || ''),
  )

  const [fetchSmartLocksState, fetchSmartLocksFn] = useAsyncFnWithReset(
    async (unitId: string) => {
      return dispatch(
        fetchSmartLocksByUnitId(unitId) as unknown as UnknownAction,
      )
    },
    [dispatch],
  )

  React.useEffect(() => {
    if (!selectedUnit?.id) return
    fetchSmartLocksFn(selectedUnit.id)
  }, [dispatch, fetchSmartLocksFn, selectedUnit?.id])

  return appIsLoading ? (
    <Loader />
  ) : (
    <AccessHistoryPage
      requestState={fetchSmartLocksState}
      smartLocks={smartLocks}
    />
  )
})
