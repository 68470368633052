import React from 'react'
import { useSelector } from 'react-redux'

import { getAccessToken } from 'app/store/auth/selectors'

import { LockCodeButton } from './LockCodeButton'
import { type LockCodeRequestType } from './LockCodeButton.helpers'

export interface LockCodeButtonContainerProps {
  /** Method to indicate whether the creation of new lock codes is disabled */
  isCreationDisabled: () => boolean
  /** {@link Clean} or {@link Ticket} ID */
  itemId: string
  /** Method called after lock code has been generated */
  onSetLockCode: (lockCode: string) => void
  type: LockCodeRequestType
}

export const LockCodeButtonContainer: React.FC<LockCodeButtonContainerProps> =
  React.memo(({ itemId, type, onSetLockCode, isCreationDisabled }) => {
    const accessToken = useSelector(getAccessToken)
    if (!accessToken) return null

    return (
      <LockCodeButton
        isCreationDisabled={isCreationDisabled}
        onSetLockCode={onSetLockCode}
        itemId={itemId}
        type={type}
        accessToken={accessToken}
      />
    )
  })
