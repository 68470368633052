import styled from '@emotion/styled'

import {
  centerWithFlex,
  colors,
  truncateTextWithEllipsis,
} from 'packages/styles'

import { gwText } from 'app/styles'

export {
  Icon,
  IconBackground,
} from '../UnitImageThumbnail/UnitImageThumbnail.styles'

export const Radio = styled.input<{
  disabled: boolean
  labelPosition: 'left' | 'right'
}>`
  ${centerWithFlex};
  appearance: none;
  border: ${({ disabled }) =>
    disabled ? `2px solid ${colors.dusk40}` : `2px solid ${colors.midnight40}`};
  border-radius: 50%;
  cursor: pointer;
  margin: ${({ labelPosition }) =>
    labelPosition === 'left' ? 0 : '0 10px 0 0'};
  min-height: 24px;
  min-width: 24px;

  &:before {
    content: '';
    background-color: ${colors.lake};
    border-radius: 50%;
    height: 12px;
    transition: 150ms transform ease-in-out;
    transform: scale(0);
    width: 12px;
  }

  &:checked {
    border-color: ${colors.lake};

    &:before {
      transform: scale(1);
    }
  }
`

export const OptionWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`

export const Label = styled.label<{
  labelPosition: 'left' | 'right'
}>`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: ${({ labelPosition }) =>
    labelPosition === 'left' ? 'row' : 'row-reverse'};
  width: 100%;
`

export const LabelContents = styled.div<{
  disabled: boolean
  labelPosition: 'left' | 'right'
}>`
  ${gwText.bodySmallBold};
  ${truncateTextWithEllipsis};
  color: ${({ disabled }) => (disabled ? `${colors.dusk40}` : 'inherit')};
  margin: ${({ labelPosition }) =>
    labelPosition === 'left' ? '0 10px 0 0' : 0};
  width: 100%;
`
