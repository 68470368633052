import { produce } from 'immer'
import { jwtDecode } from 'jwt-decode'
import { type ActionType, getType } from 'typesafe-actions'

import {
  setNeedsFullAuthRedirectAction,
  setNeedsSilentRefreshAction,
  setTokensAction,
} from './actions'
import { type AuthState } from './auth.types'

export const initialState: AuthState = {
  accessToken: undefined,
  delegateToken: undefined,
  delegateUserId: '',
  idToken: undefined,
  impersonationToken: undefined,
  needsFullAuthRedirect: true,
  needsSilentRefresh: false,
  refreshToken: undefined,
}

const actions = {
  setNeedsFullAuthRedirectAction,
  setNeedsSilentRefreshAction,
  setTokensAction,
}

type AuthActionsTypes = ActionType<typeof actions>

export const authReducer = (
  state = initialState,
  action: AuthActionsTypes,
): AuthState =>
  produce(state, (draft: AuthState) => {
    switch (action.type) {
      case getType(setTokensAction): {
        draft.accessToken = action.payload.accessToken
        draft.error = undefined
        draft.needsFullAuthRedirect = false
        draft.needsSilentRefresh = false
        draft.refreshToken = action.payload.refreshToken
        draft.idToken = action.payload.idToken
        draft.impersonationToken = action.payload.impersonationToken

        const delegateToken = action.payload.delegateToken
        draft.delegateToken = delegateToken

        // assuming we have a valid token, attempt to pull the 'sub' from it,
        // as this is the ID of the user who requested the token; we will use to ID delegate users
        if (delegateToken) {
          try {
            const decoded = jwtDecode(delegateToken)
            draft.delegateUserId = decoded?.sub || ''
          } catch (err) {
            draft.delegateUserId = ''
            return
          }
        }

        return
      }

      case getType(setNeedsFullAuthRedirectAction): {
        draft.error = undefined
        draft.needsFullAuthRedirect = action.payload
        draft.needsSilentRefresh = false
        return
      }

      case getType(setNeedsSilentRefreshAction): {
        draft.error = undefined
        draft.needsFullAuthRedirect = false
        draft.needsSilentRefresh = action.payload
      }
    }
  })
