import type { CurriedFunction2 } from 'lodash'
import { curry } from 'lodash/fp'
import React from 'react'
import { usePrevious } from 'react-use'

import { logDevWarning } from 'packages/grimoire/src/utils'

import { type RadioButtonsProps } from '../RadioButtons'

type InputChangeEvent = React.ChangeEvent<
  HTMLInputElement | HTMLTextAreaElement
>

interface NameValuePair {
  name: string
  value: unknown
}

export interface UseFormFns<FormValuesType extends object> {
  formValues: FormValuesType
  handleChange: (event: InputChangeEvent) => void
  handleChangeRadio: RadioButtonsProps['onChange']
  resetFormValues: () => void
  setValueByKey: CurriedFunction2<string, string, void>
}

export function useFormFns<FormValuesType extends object>(
  initialValues: FormValuesType,
): UseFormFns<FormValuesType> {
  const [formValues, setFormValues] =
    React.useState<FormValuesType>(initialValues)

  const setValueByKey = React.useCallback(
    (key: string, value: unknown) => {
      if (key in formValues) {
        setFormValues(prev => ({
          ...prev,
          [key]: value,
        }))
      }
    },
    [formValues],
  )

  const resetFormValues = React.useCallback(() => {
    setFormValues(initialValues)
  }, [initialValues])

  const handleChange = React.useCallback(
    (event: InputChangeEvent) => {
      const { name = '', value = '' } = event.target
      setValueByKey(name, value)
    },
    [setValueByKey],
  )

  const handleChangeRadio = React.useCallback(
    (option: NameValuePair) => {
      const { name = '', value = '' } = option
      if (!name || !value) {
        const parsedKeys = Object.keys(option).join(' | ')
        logDevWarning(
          `Error in useFormFns.handleRadioChange():\n`,
          `Value pairs for radio buttons must have "name" and "value" keys.\n`,
          `Received keys: ${parsedKeys}`,
        )
      }

      setValueByKey(name, value)
    },
    [setValueByKey],
  )

  return {
    formValues,
    handleChange,
    handleChangeRadio,
    resetFormValues,
    setValueByKey: curry(setValueByKey),
  }
}

export function useAsyncFormFns<FormValuesType extends object>(
  initialValues: FormValuesType,
  isLoading: boolean,
): UseFormFns<FormValuesType> | null {
  const formFns = useFormFns(initialValues)
  const previousIsLoading = usePrevious(isLoading)

  React.useEffect(() => {
    if (!isLoading && previousIsLoading) {
      formFns.resetFormValues()
    }
  }, [isLoading, formFns, previousIsLoading])

  return isLoading ? null : formFns
}
