import { type RequestOptions } from 'packages/utils/store/jsonapi.types'

import { api } from '../axiosInstance'
import {
  type ReservationsApiResponse,
  type ReservationsServiceResponse,
} from './reservations.types'
import { emptyReservationsResponse } from './reservations.utils'

const URL = '/reservations'

export const reservationsService = {
  async fetchReservations(
    params: RequestOptions = {},
  ): ReservationsServiceResponse {
    const response: ReservationsApiResponse = await api.get(URL, { params })
    return response?.data || emptyReservationsResponse
  },
}
