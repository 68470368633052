import styled from '@emotion/styled'

import { LastFetchBanner } from 'app/components/core'
import { gwColors, gwText } from 'app/styles'

export const YearHeader = styled.div`
  ${gwText.headingSmallCaps};
  color: ${gwColors.grayMedium};
  margin: 14px 0;
`

export const ScheduleTimeline = styled.div`
  padding-bottom: 12px;
  margin: 0 24px;
`

export const CardContainer = styled.div`
  margin-bottom: 14px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const TimeStampBanner = styled(LastFetchBanner)`
  margin-bottom: 14px;
`
