import { deprecated } from 'typesafe-actions'

import { UnitsActionTypes } from '../units.types'
const { createStandardAction } = deprecated

export const updateSelectedUnitAction = createStandardAction(
  UnitsActionTypes.UPDATE_SELECTED_UNIT,
)<string>()

export const updateSelectedUnit = (selectedUnitId: string) => dispatch =>
  dispatch(updateSelectedUnitAction(selectedUnitId))
