import styled from '@emotion/styled'
import React from 'react'

import { colors } from 'packages/styles'
import {
  createDateString,
  DateFormat,
  format,
  isAfter,
  isBefore,
} from 'packages/utils/dateHelpers'

import { Slugs, useI18n } from 'app/i18n'
import { type SmartLock } from 'app/store/smartLocks'
import { fullName, type User } from 'app/store/users'
import { gwText } from 'app/styles'

const St = {
  ActiveDot: styled.div`
    background-color: ${colors.success70};
    border-radius: 100%;
    box-shadow: 0 0 3px 2px rgba(151, 190, 122, 0.5);
    height: 12px;
    width: 12px;
  `,
  ActiveIndicator: styled.div`
    ${gwText.bodySmallBold};
    align-items: center;
    color: ${colors.success};
    display: flex;
    grid-gap: 12px;
    margin-bottom: 8px;
  `,
  Container: styled.div`
    background-color: white;
    border-radius: 16px;
    box-shadow: 1px 1px 20px 0 rgba(112, 117, 121, 0.2);
    padding: 24px;

    &:not(:first-of-type) {
      margin-top: 24px;
    }
  `,
  DataRow: styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;

    &:not(:first-of-type) {
      margin-top: 2px;
    }
  `,
  DataRowTitle: styled.span`
    ${gwText.bodySmallBold};
  `,
  DataRowValue: styled.span`
    ${gwText.bodySmall};
  `,
}

const useTranslations = () => {
  const { ut } = useI18n()
  return {
    active: ut(Slugs.active),
    createdBy: ut(Slugs.createdBy),
    endTime: ut(Slugs.endTime),
    lockCode: ut(Slugs.lockCode),
    startTime: ut(Slugs.startTime),
    unknown: ut(Slugs.unknown),
  }
}

const DataRow = ({ title, value }: { title: string; value: string }) => {
  return (
    <St.DataRow>
      <St.DataRowTitle>{title}</St.DataRowTitle>
      <St.DataRowValue>{value}</St.DataRowValue>
    </St.DataRow>
  )
}

interface AccessLogCardProps {
  smartLock: SmartLock
  user: User
}

export const AccessLogCard: React.FC<AccessLogCardProps> = React.memo(
  ({ smartLock, user }) => {
    const strings = useTranslations()

    const now = createDateString()
    const startTime = format(smartLock.validAt, DateFormat.SlashesWithTime)
    const endTime = format(smartLock.expiredAt, DateFormat.SlashesWithTime)

    const isActive =
      isAfter(now, smartLock.validAt) && isBefore(now, smartLock.expiredAt)
    const canShowCode = isActive && smartLock.accessCode
    const userName = user ? fullName(user) : strings.unknown

    return (
      <St.Container>
        {isActive && (
          <St.ActiveIndicator>
            {strings.active}
            <St.ActiveDot />
          </St.ActiveIndicator>
        )}

        <DataRow title={strings.createdBy} value={userName} />

        {canShowCode && (
          <DataRow title={strings.lockCode} value={smartLock.accessCode} />
        )}

        <DataRow title={strings.startTime} value={startTime} />
        <DataRow title={strings.endTime} value={endTime} />
      </St.Container>
    )
  },
)
