import { createAsyncAction } from 'typesafe-actions'

import { type RequestConfig } from 'packages/utils/store/jsonapi.types'

import {
  type NormalizedUsersApiResponse,
  UserApiFieldsWithoutPII,
  UsersActionTypes,
  usersService,
} from 'app/store/users'

export const searchUsersAction = createAsyncAction(
  UsersActionTypes.SEARCH_USERS,
  UsersActionTypes.SEARCH_USERS_SUCCESS,
  UsersActionTypes.SEARCH_USERS_FAILURE,
)<
  RequestConfig<NormalizedUsersApiResponse>,
  NormalizedUsersApiResponse,
  Error
>()

export const getParams = (search: string) => ({
  fields: {
    user: UserApiFieldsWithoutPII,
  },
  filter: {
    guestworks_users: true,
    is_active: true,
    search,
  },
  page: { size: 25 },
})

export const searchUsers = (search: string) => async dispatch => {
  try {
    const params = getParams(search)
    const request = usersService.fetchUsers.bind(null, params)
    const result = await dispatch(searchUsersAction.request({ request }))
    dispatch(searchUsersAction.success(result))
    return result.normalized
  } catch (error) {
    dispatch(searchUsersAction.failure(error))
    throw error
  }
}
