import { logInfo } from 'packages/wiretap/logging'

const DELETE_TOKEN_URL = `${process.env.REACT_APP_SERVICE_HOST}/delegation_token`
const SERVICE_URL = process.env.REACT_APP_SERVICE_HOST

interface DeleteTokenArgs {
  accessToken: string
  resourcePath: string
}

export const fetchDelegateToken = async ({
  accessToken,
  resourcePath,
}: DeleteTokenArgs): Promise<string> => {
  const result = await fetch(DELETE_TOKEN_URL, {
    body:
      'grant_type=urn:ietf:params:oauth:grant-type:token-exchange&' +
      `resource=${SERVICE_URL}/${resourcePath}&` +
      'scope=fieldops-jobs:read fieldops-jobs:start&' +
      `subject_token=${accessToken}&` +
      'subject_token_type=urn:ietf:params:oauth:token-type:access_token',

    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },

    method: 'POST',
  }).then(async res => {
    if (res.ok) {
      return await res.json()
    }

    // errors here will not throw from fetch, so we need to manually parse/await and re-throw
    res.json().then(json => {
      logInfo('Error fetching delegate token', {
        error: json?.error || 'unknown',
      })
    })

    throw new Error('Error fetching delegate token')
  })

  const { access_token: delegateToken } = result
  return delegateToken
}
