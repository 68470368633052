import React from 'react'

import { IconName } from 'packages/iconic'
import {
  DateFormat,
  differenceInDays,
  formatLocalized,
  startOfDay,
} from 'packages/utils/dateHelpers'

import { Slugs, useI18n } from 'app/i18n'
import type { Reservation } from 'app/store/reservations'

import * as St from './NewResCard.styles'

/* eslint-disable @typescript-eslint/naming-convention */
const textMap: Record<Reservation['bookingType'], string> = {
  guest_reservation: Slugs.guestStay,
  owner_hold: Slugs.ownerHold,
  unknown: Slugs.unknown,
  vacasa_hold: Slugs.vacasaHold,
}
/* eslint-enable @typescript-eslint/naming-convention */

export enum NewResCardTestIds {
  container = 'NewResCard__container',
}

export interface NewResCardProps {
  onClick: () => void
  res: Reservation
}

export const NewResCard: React.FC<NewResCardProps> = React.memo(
  ({ res, onClick }) => {
    const { t, tp } = useI18n()

    const day = formatLocalized(res.checkIn, DateFormat.DayOfMonthShort)
    const month = formatLocalized(res.checkIn, DateFormat.MonthNameShort)

    const numberOfNights = differenceInDays(
      startOfDay(res.checkOut),
      startOfDay(res.checkIn),
    )
    const nightsText = tp(Slugs.night, numberOfNights)

    return (
      <St.Container dataTestId={NewResCardTestIds.container} onClick={onClick}>
        <St.DetailsContainer>
          <St.Date res={res}>
            <St.Day res={res}>{day}</St.Day>
            <St.Month res={res}>{month}</St.Month>
          </St.Date>
          <div>
            <St.ResHeader>{t(textMap[res.bookingType])}</St.ResHeader>
            <St.Nights>{nightsText}</St.Nights>
          </div>
        </St.DetailsContainer>
        <St.ChevronIcon icon={IconName.chevronRight} size={22} />
      </St.Container>
    )
  },
)
