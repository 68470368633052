import styled from '@emotion/styled'
import React from 'react'

import { centerWithFlex, colors } from 'packages/styles'

import { gwColors, gwText } from 'app/styles'

export const badgeTypeValues = ['aspen', 'midnight', 'white', 'yellow'] as const
export type BadgeType = (typeof badgeTypeValues)[number]
export type BadgeTypeMap<T> = { [k in BadgeType]: T }

const badgeStylesByType: BadgeTypeMap<{
  background: string
  border?: string
  color: string
}> = {
  aspen: {
    background: colors.aspen10,
    color: gwColors.evergreen,
  },
  midnight: {
    background: colors.midnight60,
    color: colors.midnight10,
  },
  white: {
    background: colors.midnight2,
    border: `solid 1px ${colors.midnight60}`,
    color: colors.midnight60,
  },
  yellow: {
    background: colors.yellow,
    color: colors.midnight,
  },
}

type WithBadgeType = Pick<BadgeProps, 'badgeType'>
const St = {
  Badge: styled.span<WithBadgeType>`
    ${centerWithFlex};
    border-radius: 20px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
    display: inline-flex;
    height: 20px;
    padding: 0 12px;

    background: ${props => badgeStylesByType[props.badgeType].background};
    border: ${props => badgeStylesByType[props.badgeType].border || 'none'};
    color: ${props => badgeStylesByType[props.badgeType].color};
  `,
  Text: styled.span`
    ${gwText.headingTinyCaps};
    padding-top: 2px; // text vertical alignment fix
  `,
}

export enum BadgeTestIds {
  container = 'Badge__container',
}

export interface BadgeProps {
  badgeType: BadgeType
  children: React.ReactNode
  className?: string
}

export const Badge: React.FC<BadgeProps> = React.memo(
  ({ badgeType, children, className }) => {
    return (
      <St.Badge
        badgeType={badgeType}
        className={className}
        data-testid={BadgeTestIds.container}
      >
        <St.Text>{children}</St.Text>
      </St.Badge>
    )
  },
)
