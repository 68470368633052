import { pick } from 'lodash/fp'
import { createAsyncAction } from 'typesafe-actions'

import {
  type RequestConfig,
  type RequestOptions,
} from 'packages/utils/store/jsonapi.types'
import { makeRelationship } from 'packages/utils/store/jsonapi.utils'

import {
  isViewingSharedClean,
  isViewingSharedTicket,
} from 'app/components/schedule/schedule.utils'

import * as ticketsService from '../tickets.service'
import { trackTicketCreated } from '../tickets.trackers'
import {
  TicketsActionTypes,
  type NormalizedTicketsApiResponse,
  type TicketPostData,
  type TicketPostAPIAttributes,
  type TicketPostAPIRelationships,
} from '../tickets.types'
import { fetchTicketById } from './fetchTicketById'

export const createTicketAction = createAsyncAction(
  TicketsActionTypes.CREATE_TICKET,
  TicketsActionTypes.CREATE_TICKET_SUCCESS,
  TicketsActionTypes.CREATE_TICKET_FAILURE,
)<
  RequestConfig<NormalizedTicketsApiResponse>,
  NormalizedTicketsApiResponse,
  Error
>()

export const buildRequestData = (postData: TicketPostData): RequestOptions => {
  const attributes: TicketPostAPIAttributes = pick([
    'title',
    'description',
    'severity',
  ])(postData) as TicketPostAPIAttributes

  const relationships: TicketPostAPIRelationships = {
    disposition: makeRelationship('disposition', postData.dispositionId),
    unit: makeRelationship('unit', postData.unitId),
  }

  return {
    data: {
      attributes,
      relationships,
      type: 'ticket',
    },
  }
}

export const createTicket =
  (postData: TicketPostData) => async (dispatch, getState) => {
    const requestData = buildRequestData(postData)

    const request = ticketsService.createTicket.bind(null, requestData)

    try {
      const result = await dispatch(createTicketAction.request({ request }))

      dispatch(createTicketAction.success(result))

      trackTicketCreated(postData, getState)

      const newTicketId = Object.keys(result.normalized.ticket)[0]

      const isViewingSharedCleanOrTicket =
        isViewingSharedClean() || isViewingSharedTicket()

      if (!isViewingSharedCleanOrTicket) {
        await dispatch(fetchTicketById(newTicketId))
      }

      return result
    } catch (error) {
      dispatch(createTicketAction.failure(error))

      throw error
    }
  }
