import React from 'react'
import type { AriaButtonProps } from 'react-aria'
import { useButton } from 'react-aria'

import { Loader, LoaderType } from 'packages/common'
import { type IconName, SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

import { St, StyledButton } from './Button.styles'

export type ButtonSize = 'default' | 'small' | 'xSmall'

export type ButtonType =
  | 'alertPrimary'
  | 'alertSecondary'
  | 'iconPrimary'
  | 'iconSecondary'
  | 'iconSubtle'
  | 'primary'
  | 'secondary'
  | 'subtle'
  | 'text'
  | 'white'

const loaderColorByType: Record<ButtonType, string> = {
  alertPrimary: 'white',
  alertSecondary: colors.alert,
  iconPrimary: colors.midnight90,
  iconSecondary: colors.midnight90,
  iconSubtle: colors.midnight90,
  primary: 'white',
  secondary: colors.midnight,
  subtle: colors.midnight,
  text: colors.midnight,
  white: colors.midnight,
}

const omitLoaderTypes: ButtonType[] = ['text']

export type ButtonProps = AriaButtonProps & {
  block?: boolean
  buttonSize?: ButtonSize
  buttonType?: ButtonType
  childStyles?: React.CSSProperties
  className?: string
  dataTestId?: string
  iconName?: IconName
  iconSize?: number
  isLoading?: boolean
}

export const Button: React.FC<ButtonProps> = React.memo(props => {
  const {
    block = false,
    buttonSize = 'default',
    buttonType = 'primary',
    children,
    childStyles = {},
    className = '',
    dataTestId,
    iconName,
    iconSize = 16,
    isLoading = false,
  } = props

  const ref = React.useRef(null)
  const { buttonProps } = useButton(props, ref)

  const ButtonComponent = StyledButton[buttonType]

  const shouldShowLoader = isLoading && !omitLoaderTypes.includes(buttonType)
  const shouldShowLoadingText =
    isLoading && omitLoaderTypes.includes(buttonType)

  return (
    <ButtonComponent
      {...buttonProps}
      block={block}
      buttonSize={buttonSize}
      className={className}
      data-testid={dataTestId}
      isLoading={isLoading}
      ref={ref}
    >
      <St.Children isLoading={isLoading} style={childStyles}>
        {iconName != null && <SvgIcon icon={iconName} size={iconSize} />}
        {children}
      </St.Children>

      {shouldShowLoader && (
        <Loader
          color={loaderColorByType[buttonType]}
          transparent={true}
          type={LoaderType.Clip}
        />
      )}
      {shouldShowLoadingText && <St.LoadingText>Loading...</St.LoadingText>}
    </ButtonComponent>
  )
})
