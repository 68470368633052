import { keyframes, css } from '@emotion/react'
import styled from '@emotion/styled'

import { colors } from 'packages/styles/jss'

import { type StatusOrbProps } from './StatusOrb'
import { StatusOrbType } from './StatusOrb.types'

const outerCircleDiameter = 12
const innerCircleDiameter = 8
const differenceInSize = outerCircleDiameter - innerCircleDiameter
const innerPaddingSize = differenceInSize / 2

const ping = keyframes`
  0% {
    opacity: 1;
    transform: scale(0.2);
  }

  80% {
    opacity: 0;
    transform: scale(2.2);
  }

  100% {
    opacity: 0;
    transform: scale(3.2);
  }
`

const cleanStatusColors = {
  [StatusOrbType.TRANSPARENT]: 'transparent',
  [StatusOrbType.YELLOW]: '#ddad49',
  [StatusOrbType.RED]: '#d62e4f',
  [StatusOrbType.BLUE]: colors.lake,
}

export const StatusCirclesContainer = styled.div`
  height: ${outerCircleDiameter}px;
  position: relative;
  width: ${outerCircleDiameter}px;
`

type StatusCircleProps = Pick<StatusOrbProps, 'statusType' | 'shouldAnimate'>

export const OuterCircle = styled.div<StatusCircleProps>`
  background-color: ${({ shouldAnimate, statusType }) =>
    shouldAnimate ? cleanStatusColors[statusType] : 'transparent'};
  border-radius: 100px;
  height: ${outerCircleDiameter}px;
  position: absolute;
  width: ${outerCircleDiameter}px;

  animation: ${({ shouldAnimate }) =>
    shouldAnimate
      ? css`
          ${ping} 1.5s ease-in-out infinite both
        `
      : 'none'};
`

export const InnerCircle = styled.div<StatusCircleProps>`
  background-color: ${({ statusType }) => cleanStatusColors[statusType]};
  border-radius: 100px;
  bottom: ${innerPaddingSize}px;
  height: ${innerCircleDiameter}px;
  left: ${innerPaddingSize}px;
  position: absolute;
  width: ${innerCircleDiameter}px;
  z-index: 2;
`
