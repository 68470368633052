import styled from '@emotion/styled'
import React from 'react'

import { Alert, Drawer, useManageDrawerState } from 'packages/common'
import { useI18n } from 'packages/i18n'
import { IconName } from 'packages/iconic'
import { useOnlineStatus } from 'packages/utils/hooks'

import { Button } from 'app/components/core'
import { Slugs } from 'app/i18n'
import { type Unit } from 'app/store/units'

import { CreateTicketFormContainer } from '../CreateTicketForm/CreateTicketForm.container'

export const CREATE_TICKET_DRAWER_ID = 'CreateTicketDrawer'

export enum CreateTicketDrawerButtonTestIds {
  alert = 'CreateTicketDrawer__alert',
  button = 'CreateTicketDrawer__button',
}

const St = {
  Alert: styled(Alert)`
    margin-bottom: 0;
    margin-top: 12px;
  `,
  ButtonContainer: styled.div`
    margin-top: 12px;
  `,
  Drawer: styled(Drawer)`
    max-width: 480px;
    min-width: min(360px, 100%);
  `,
}

export interface CreateTicketDrawerButtonProps {
  unit?: Unit
}

export const CreateTicketDrawerButton: React.FC<
  CreateTicketDrawerButtonProps
> = ({ unit }) => {
  const { ut } = useI18n()
  const isOnline = useOnlineStatus().isOnline()

  const { completeDrawerClose, closeDrawer, drawerState, openDrawer } =
    useManageDrawerState()

  return (
    <>
      {isOnline ? (
        <St.ButtonContainer>
          <Button
            dataTestId={CreateTicketDrawerButtonTestIds.button}
            isDisabled={!unit}
            onPress={openDrawer}
            buttonType={'secondary'}
            iconName={IconName.plus}
          >
            {ut(Slugs.createTicket)}
          </Button>
        </St.ButtonContainer>
      ) : (
        <St.Alert
          alertType="warning"
          dataTestId={CreateTicketDrawerButtonTestIds.alert}
        >
          {ut(Slugs.cannotCreateTicket)}
        </St.Alert>
      )}

      {!!unit && (
        <St.Drawer
          afterExit={completeDrawerClose}
          forceClose={drawerState.forceClose}
          id={CREATE_TICKET_DRAWER_ID}
          isOpen={drawerState.isOpen}
        >
          <CreateTicketFormContainer closeForm={closeDrawer} unit={unit} />
        </St.Drawer>
      )}
    </>
  )
}
