import { createSelector } from 'reselect'

import { type ApplicationState } from 'app/store/store'
import {
  hydrateRawUser,
  type JSONApiUsersMap,
  type User,
} from 'app/store/users'

const getUsersSearchResultsState = (state: ApplicationState): JSONApiUsersMap =>
  state.users.searchResults

export const getUsersSearchResults = createSelector(
  getUsersSearchResultsState,
  (searchResults): User[] => {
    return Object.values(searchResults).map(hydrateRawUser)
  },
)
