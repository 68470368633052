import styled from '@emotion/styled'
import React from 'react'

import { colors } from 'packages/styles'

import { Slugs, useI18n } from 'app/i18n'
import { gwText } from 'app/styles'

import { useUnitPickerModal } from '../../hooks/useUnitPickerModal/useUnitPickerModal'
import { UnitSelectButton } from '../UnitSelectButton'

const St = {
  Header: styled.header`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 32px 24px 24px 24px;
  `,
  Title: styled.h1`
    ${gwText.headingLarge};
    color: ${colors.midnight};
    margin-bottom: 0;
  `,
}

const useTranslations = (title: string) => {
  const { t } = useI18n()

  return {
    title: t(Slugs[title]),
  }
}

export enum HeaderTestIds {
  container = 'Header__container',
}

export type TitleType = 'schedule' | 'myTickets' | 'settings'

export interface HeaderProps {
  title: TitleType
}

export const Header: React.FC<HeaderProps> = React.memo(({ title }) => {
  const strings = useTranslations(title)
  const { showUnitPickerModal } = useUnitPickerModal()

  return (
    <St.Header data-testid={HeaderTestIds.container}>
      <St.Title>{strings.title}</St.Title>
      <div>
        <UnitSelectButton onPress={showUnitPickerModal}></UnitSelectButton>
      </div>
    </St.Header>
  )
})
