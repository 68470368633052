import { transformNormalizedToTyped } from 'packages/utils/store'

import {
  type RawGwUnitStaffer,
  type GwUnitStaffer,
  type GwUnitStaffersResponse,
  type NormalizedGwUnitStaffersApiResponse,
  GwUnitStafferAttributeNames,
} from './gwUnitStaffers.types'

export const hydrateRawGwUnitStaffer = (
  rawGwUnitStaffer: RawGwUnitStaffer,
): GwUnitStaffer => {
  const unitId = rawGwUnitStaffer.relationships?.unit?.data?.id || ''

  const gwUnitStaffer = transformNormalizedToTyped<GwUnitStaffer>(
    rawGwUnitStaffer,
    GwUnitStafferAttributeNames,
  )

  return {
    ...gwUnitStaffer,
    unitId,
  }
}

export const emptyNormalizedGwUnitStaffersData: GwUnitStaffersResponse =
  Object.freeze({
    gwUnitStaffer: {},
    unit: {},
  })

export const emptyGwUnitStaffersResponse: NormalizedGwUnitStaffersApiResponse =
  Object.freeze({
    normalized: emptyNormalizedGwUnitStaffersData,
    raw: { data: [] },
  })
