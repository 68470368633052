import { curry, includes } from 'lodash/fp'

import { type CleanJobType } from 'packages/grimoire'

import { CleanRealtimeStatus, CleanServiceType } from './clean.types'

/**************************************************
 * CleanRealtimeStatus Helpers
 **************************************************/

const hasStatus = (
  statuses: CleanRealtimeStatus[],
  status: CleanRealtimeStatus,
) => includes(status, statuses)

export const isNotReadyStatus = curry(hasStatus)([
  CleanRealtimeStatus.NOT_READY,
])

export const isInProgressStatus = curry(hasStatus)([
  CleanRealtimeStatus.IN_PROGRESS,
  CleanRealtimeStatus.IN_PROGRESS_OVERDUE,
])

export const isCompleteStatus = curry(hasStatus)([
  CleanRealtimeStatus.COMPLETED,
])

/**************************************************
 * CleanServiceType Helpers
 **************************************************/

interface WithServiceType {
  serviceType: CleanServiceType
}

export const cleanIsStandardService = (clean: WithServiceType): boolean =>
  clean.serviceType === CleanServiceType.standard

export const cleanIsMidstay = (clean: WithServiceType): boolean =>
  clean.serviceType === CleanServiceType.midStay

export const cleanIsB2b = (clean: WithServiceType): boolean =>
  clean.serviceType === CleanServiceType.b2b

export const cleanIsPotentialB2b = (clean: WithServiceType): boolean => {
  return clean.serviceType === CleanServiceType.potentialB2B
}

export const cleanIsLateCheckout = (clean: WithServiceType): boolean =>
  clean.serviceType === CleanServiceType.lateCheckout

/**************************************************
 * CleanJobType Helpers
 **************************************************/

interface CleanWithJobs {
  jobType: CleanJobType[]
}

export const cleanIsDeepClean = (clean: CleanWithJobs): boolean =>
  (clean?.jobType || []).includes('deep_clean')

interface CleanWithPredictedCleanTime {
  predictedCleanTime: number
}
export const getDeepCleanPredictedTime = (
  clean: CleanWithPredictedCleanTime,
): number => Math.round(clean.predictedCleanTime * 2.5)
