import { deprecated } from 'typesafe-actions'

import { AppActionTypes, type AppInitState } from '../app.types'
const { createStandardAction } = deprecated

export const setAppInitStateAction = createStandardAction(
  AppActionTypes.SET_APP_INIT_STATE,
)<AppInitState>()

export const setAppInitState = (nextState: AppInitState) => dispatch =>
  dispatch(setAppInitStateAction(nextState))
