export const SegmentEvents = {
  guestworksCleanCompleted: 'guestworksCleanCompleted',
  guestworksCleanShared: 'guestworksCleanShared',
  guestworksLocalHostCreated: 'guestworksLocalHostCreated',
  guestworksLocalHostUpdated: 'guestworksLocalHostUpdated',
  guestworksPrimaryHousekeeperCreated: 'guestworksPrimaryHousekeeperCreated',
  guestworksPrimaryHousekeeperDeleted: 'guestworksPrimaryHousekeeperDeleted',
  guestworksPrimaryHousekeeperUpdated: 'guestworksPrimaryHousekeeperUpdated',
  guestworksTicketCompleted: 'guestworksTicketCompleted',
  guestworksTicketCreated: 'guestworksTicketCreated',
  guestworksTicketShared: 'guestworksTicketShared',
  goOffline: 'goOffline',
}

// all events bundled together in a single platform-agnostic collection
export const Events = {
  ...SegmentEvents,
}
