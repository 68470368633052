import styled from '@emotion/styled'

import { SvgIcon } from 'packages/iconic'
import { colors, centerWithFlex } from 'packages/styles'

import { gwText } from 'app/styles'

export const Heading = styled.h1`
  ${gwText.headingMedium};
  margin: 0 0 0 14px;
`

export const HeadingContainer = styled.div`
  align-items: center;
  display: flex;
  margin: 18px 24px 0 12px;
`

export const HeadingIcon = styled(SvgIcon)`
  color: ${colors.dusk};
`

export const HeadingIconContainer = styled.div`
  ${centerWithFlex};
  cursor: pointer;
  height: 40px;
  width: 40px;
`

export const ButtonWrapper = styled.div`
  max-width: 200px;
  margin: 24px 24px 40px 24px;
`
