import { createAsyncAction } from 'typesafe-actions'

import {
  type RequestConfig,
  type RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { UnitApiFields } from '../../units'
import { gwUnitStaffersService } from '../gwUnitStaffers.service'
import {
  GwUnitStafferApiFields,
  GwUnitStaffersActionTypes,
  type NormalizedGwUnitStaffersApiResponse,
} from '../gwUnitStaffers.types'

export const fetchGwUnitStafferByIdAction = createAsyncAction(
  GwUnitStaffersActionTypes.FETCH_GW_UNIT_STAFFER,
  GwUnitStaffersActionTypes.FETCH_GW_UNIT_STAFFER_SUCCESS,
  GwUnitStaffersActionTypes.FETCH_GW_UNIT_STAFFER_FAILURE,
)<
  RequestConfig<NormalizedGwUnitStaffersApiResponse>,
  NormalizedGwUnitStaffersApiResponse,
  Error
>()

export const getParams = (): RequestOptions => ({
  fields: {
    gw_unit_staffer: GwUnitStafferApiFields,
    unit: UnitApiFields,
  },
  include: ['unit'],
})

export const fetchGwUnitStafferById = (stafferId: string) => async dispatch => {
  try {
    const params = getParams()
    const request = gwUnitStaffersService.fetchGwUnitStafferById.bind(
      null,
      stafferId,
      params,
    )
    const result = await dispatch(
      fetchGwUnitStafferByIdAction.request({ request }),
    )
    dispatch(fetchGwUnitStafferByIdAction.success(result))
    return result.normalized
  } catch (error) {
    dispatch(fetchGwUnitStafferByIdAction.failure(error))
    throw error
  }
}
