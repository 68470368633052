import styled from '@emotion/styled'

import { colors } from 'packages/styles'

import { gwText, gwShadows } from 'app/styles'

interface WithError {
  hasError?: boolean
}

const BottomLabel = styled.div`
  ${gwText.bodySmallSemibold};
  padding-left: 1px;
  margin-top: 8px;
  transition: color 100ms;
`

export const St = {
  ButtonContainer: styled.div`
    align-items: center;
    display: flex;
    grid-gap: 12px;
    justify-content: flex-end;
    width: 100%;
  `,
  Description: styled(BottomLabel)`
    color: ${colors.dusk60};
  `,
  Form: styled.form`
    background-color: ${colors.midnight4};
    border-radius: 8px;
    padding: 24px 16px;
  `,
  Label: styled.label<WithError>`
    ${gwText.headingXsCaps};
    color: ${({ hasError }) => (hasError ? colors.alert : colors.dusk)};
    display: flex;
    padding-left: 1px;
    transition: color 200ms;
  `,
  Section: styled.section`
    margin: 24px 0px;

    .PhoneInput {
      // https://gitlab.com/catamphetamine/react-phone-number-input#css
      // match InputField.tsx styles
      margin-top: 8px;
      input {
        height: 50px;
        border-color: ${colors.midnight10};
        border-style: solid;
        border-radius: 8px;
        border-width: 1px;
        color: ${colors.dusk};
        padding: 16px;

        &::placeholder {
          color: ${colors.dusk60};
          opacity: 0.5;
        }

        &:focus,
        &:active {
          box-shadow: ${gwShadows.focus};
          outline: 4px solid ${colors.lake40};
        }
      }
    }
  `,
}
