import {
  addHours,
  createDateString,
  endOfDay,
  isAfter,
  isBefore,
  startOfDay,
} from 'packages/utils/dateHelpers'
import { logInfo } from 'packages/wiretap/logging'

import { type Clean } from 'app/store/cleans'
import { type Ticket } from 'app/store/tickets'

const LOCK_CODE_URL =
  process.env.REACT_APP_SERVICE_HOST + '/access_code/get_code_for_'

/**
 * Types of lock code requests. Values are expected to be the noun at the end
 * of the lock code API endpoints and the the first word of the endpoint
 * parameter name, as in
 *
 * `/access_code/get_code_for_<type>/?<type>_id=<id>`
 */
export enum LockCodeRequestType {
  clean = 'clean',
  ticket = 'ticket',
}

interface LockCodeArgs {
  accessToken: string
  /** {@link Clean} or {@link Ticket} ID */
  itemId: string
  type: LockCodeRequestType
}

export const fetchLockCode = async ({
  accessToken,
  itemId,
  type,
}: LockCodeArgs): Promise<string> => {
  const result = await fetch(LOCK_CODE_URL + type, {
    body: `${type}_id=${itemId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
  }).then(async res => {
    if (res.ok) {
      return await res.json()
    }

    // errors here will not throw from fetch, so we need to manually parse/await and re-throw
    res.json().then(json => {
      logInfo('Error fetching lock codes', {
        error: json?.error || 'unknown',
      })
    })

    throw new Error('Error fetching lock codes')
  })
  const { access_code: accessCode } = result
  return accessCode
}

/**
 * Whether generation of a new lock code is *disabled* for the given {@link Clean}
 */
export const isCleanLockCodeCreationDisabled = (clean: Clean): boolean => {
  const today = createDateString()
  const effectiveDate = startOfDay(clean.effectiveDate)
  const dueDate = addHours(endOfDay(clean.dueDate), 24)

  const isBeforeEffectiveDate = isBefore(today, effectiveDate)
  const isAfterDueDate = isAfter(today, dueDate)

  return isBeforeEffectiveDate || isAfterDueDate
}

/**
 * Whether generation of a new lock code is *disabled* for the given {@link Ticket}
 */
export const isTicketLockCodeCreationDisabled = (ticket: Ticket): boolean => {
  if (ticket.completedAt) {
    const today = createDateString()
    const completedAt = endOfDay(ticket.completedAt)
    return isAfter(today, completedAt)
  } else {
    return false
  }
}
