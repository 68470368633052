import { negate } from 'lodash/fp'

import { FREEZE_WINDOW_DAYS } from 'packages/utils/constants'
import {
  addDays,
  createDateObject,
  differenceInDays,
  startOfDay,
} from 'packages/utils/dateHelpers'

import {
  type AllTaskAttributes,
  type TaskJobType,
  TaskServiceType,
} from './task.types'

export const FREEZE_WINDOW_END = startOfDay(
  addDays(startOfDay(createDateObject()), FREEZE_WINDOW_DAYS),
)

/**
 * Calculates the offset in days between the provided date and the end of the freeze window.
 *
 * A non-negative number means the date is before (i.e. within) the freeze window, while
 * a negative numbers means it is outside the freeze window.
 *
 * Cleans within the freeze window will no longer be changed by the auto-scheduler,
 * so anything within this window can safely be user-edited.
 * @param date
 */
export const daysFromFreezeWindow = (date: string | Date): number =>
  differenceInDays(FREEZE_WINDOW_END, startOfDay(date))

/**
 * Calculates whether the provided date is within the clean "freeze window."
 * @param date
 */
export const isWithinFreezeWindow = (date: string | Date): boolean =>
  daysFromFreezeWindow(date) > 0

interface WithServiceType {
  serviceType: TaskServiceType
}

export const getServiceTypeClass = (clean: WithServiceType): string => {
  switch (clean.serviceType) {
    case TaskServiceType.b2b:
      return 'b2b'
    case TaskServiceType.lateCheckout:
      return 'lateCheckout'
    case TaskServiceType.midStay:
      return 'midstay'
    default:
      return 'standard'
  }
}

// ------------------------------------------------
// Clean/Visit Helpers
// ------------------------------------------------
export const taskIsClean = (task: { jobType: TaskJobType[] }): boolean => {
  return task.jobType.includes('standard')
}

export const taskIsVisit = negate(taskIsClean)

export const rawTaskIsClean = (task: {
  attributes: { jobType: TaskJobType[] }
}): boolean => {
  return task.attributes.jobType.includes('standard')
}

export const rawTaskIsVisit = negate(rawTaskIsClean)

// ------------------------------------------------
// Task Progress Helpers
// ------------------------------------------------
export type TaskProgressStatus = 'notStarted' | 'inProgress' | 'completed'

export const getTaskProgressStatus = (
  task: Pick<AllTaskAttributes, 'completedAt' | 'startedAt'>,
): TaskProgressStatus => {
  if (task.startedAt) {
    return task.completedAt ? 'completed' : 'inProgress'
  }

  return 'notStarted'
}

/**
 * Reusable filter for /tasks requests to omit visits from the response payload.
 * Just spread this object directly in the request's 'filter' config.
 */
export const apiFilterExcludeVisits = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  service_type: {
    in: ['standard', 'b2b', 'late_checkout', 'potential_b2b'],
  },
}

/**
 * Reusable filter for /tasks requests to omit cleans from the response payload.
 * Just spread this object directly in the request's 'filter' config.
 */
export const apiFilterExcludeCleans = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  service_type: {
    in: ['adhoc', 'scheduled'],
  },
}
