import { createAsyncAction } from 'typesafe-actions'

import {
  type RequestConfig,
  type RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { UnitApiFields } from '../../units'
import { gwLocalHostsService } from '../gwLocalhosts.service'
import {
  GwLocalHostApiFields,
  GwLocalHostsActionTypes,
  type NormalizedGwLocalHostsApiResponse,
} from '../gwLocalhosts.types'

export const fetchGwLocalHostByIdAction = createAsyncAction(
  GwLocalHostsActionTypes.FETCH_GW_LOCAL_HOST,
  GwLocalHostsActionTypes.FETCH_GW_LOCAL_HOST_SUCCESS,
  GwLocalHostsActionTypes.FETCH_GW_LOCAL_HOST_FAILURE,
)<
  RequestConfig<NormalizedGwLocalHostsApiResponse>,
  NormalizedGwLocalHostsApiResponse,
  Error
>()

export const getParams = (): RequestOptions => ({
  fields: {
    gw_local_host: GwLocalHostApiFields,
    unit: UnitApiFields,
  },
  include: ['unit'],
})

export const fetchGwLocalHostById =
  (gwLocalHostId: string) => async dispatch => {
    try {
      const params = getParams()
      const request = gwLocalHostsService.fetchGwLocalHostById.bind(
        null,
        gwLocalHostId,
        params,
      )
      const result = await dispatch(
        fetchGwLocalHostByIdAction.request({ request }),
      )
      dispatch(fetchGwLocalHostByIdAction.success(result))
      return result.normalized
    } catch (error) {
      dispatch(fetchGwLocalHostByIdAction.failure(error))
      throw error
    }
  }
