import styled from '@emotion/styled'
import React from 'react'
import type { AriaTextFieldOptions } from 'react-aria'
import { useTextField } from 'react-aria'

import { IconName, SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

import { gwShadows, gwText } from 'app/styles'

interface WithError {
  hasError?: boolean
}

type InputElProps = WithError & {
  hasLeftIcon?: boolean
  wrapperStyle: InputWrapperStyle
}

const BottomLabel = styled.div`
  ${gwText.bodySmallSemibold};
  padding-left: 1px;
  margin-top: 8px;
  transition: color 100ms;
`

const St = {
  ClearBtn: styled(SvgIcon)`
    background: ${colors.midnight30};
    border-radius: 50%;
    color: white;
    cursor: pointer;
    display: grid;
    height: 22px;
    place-items: center !important; // override placement in base SvgIcon component
    position: absolute;
    right: 16px;
    top: 14px;
    width: 22px;
  `,
  Description: styled(BottomLabel)`
    color: ${colors.dusk60};
  `,
  Error: styled(BottomLabel)`
    color: ${colors.alert};
  `,
  Input: styled.input<InputElProps>`
    ${gwText.bodyDefault};
    border-color: ${({ hasError }) =>
      hasError ? colors.alert : colors.midnight10};
    border-style: solid;
    border-radius: ${({ wrapperStyle }) =>
      wrapperStyle === 'rect' ? 8 : 100}px;
    border-width: ${({ hasError }) => (hasError ? 2 : 1)}px;
    color: ${colors.dusk};
    padding: 0 ${({ hasLeftIcon }) => (hasLeftIcon ? 48 : 16)}px;
    padding-top: 1px; // slight visual alignment adjustment
    transition: outline 50ms;
    width: 100%;

    &::placeholder {
      color: ${colors.dusk60};
      opacity: 0.5;
    }

    &:focus,
    &:active {
      box-shadow: ${({ hasError }) =>
        hasError ? gwShadows.alert : gwShadows.focus};
      outline: ${({ hasError }) =>
        hasError ? 0 : `4px solid ${colors.lake40}`};
    }
  `,
  InputWrapper: styled.div`
    border: 0;
    display: flex;
    height: 50px;
    position: relative;
    width: 100%;

    label + & {
      margin-top: 8px;
    }
  `,
  Label: styled.label<WithError>`
    ${gwText.headingXsCaps};
    color: ${({ hasError }) => (hasError ? colors.alert : colors.dusk)};
    display: flex;
    padding-left: 1px;
    transition: color 200ms;
  `,
  LeftIcon: styled(SvgIcon)`
    color: ${colors.dusk60};
    left: 16px;
    position: absolute;
    top: 15px;
  `,
}

export enum InputFieldTestIds {
  clearBtn = 'InputField__clearBtn',
  description = 'InputField__description',
  error = 'InputField__error',
}

type RequiredAriaProps = Required<
  Pick<AriaTextFieldOptions<'input'>, 'label' | 'onChange'>
>

type InputWrapperStyle = 'rect' | 'round'

export type InputFieldProps = AriaTextFieldOptions<'input'> &
  RequiredAriaProps & {
    isClearable?: boolean
    leftIcon?: IconName
    wrapperStyle?: InputWrapperStyle
  }

/**
 * InputField based primarily around react-aria's useTextField().
 * If you have questions, take a peek as these docs, as they cover a lot of implementation details:
 * https://react-spectrum.adobe.com/react-aria/useTextField.html
 */
export const InputField: React.FC<InputFieldProps> = React.memo(props => {
  const {
    description,
    errorMessage,
    isClearable,
    label,
    leftIcon,
    onChange,
    value,
    wrapperStyle = 'rect',
  } = props

  const inputRef = React.useRef<HTMLInputElement>(null)
  const { descriptionProps, errorMessageProps, inputProps, labelProps } =
    useTextField(
      { ...props, validationState: errorMessage ? 'invalid' : 'valid' },
      inputRef,
    )

  return (
    <div>
      <St.Label {...labelProps} hasError={!!errorMessage}>
        {label}
      </St.Label>

      <St.InputWrapper>
        {leftIcon != null && <St.LeftIcon icon={leftIcon} size={20} />}

        <St.Input
          {...inputProps}
          hasError={!!errorMessage}
          hasLeftIcon={!(leftIcon == null)}
          ref={inputRef}
          wrapperStyle={wrapperStyle}
        />

        {isClearable && !!value?.length && (
          <St.ClearBtn
            centerItems={false}
            dataTestId={InputFieldTestIds.clearBtn}
            icon={IconName.x}
            onClick={() => {
              onChange('')
            }}
            size={16}
          />
        )}
      </St.InputWrapper>

      {!errorMessage && !!description && (
        <St.Description
          {...descriptionProps}
          data-testid={InputFieldTestIds.description}
        >
          {description}
        </St.Description>
      )}

      {!!errorMessage && (
        <St.Error {...errorMessageProps} data-testid={InputFieldTestIds.error}>
          {errorMessage as any}
        </St.Error>
      )}
    </div>
  )
})
