import { produce } from 'immer'
import { type ActionType, getType } from 'typesafe-actions'

import {
  fetchCleansAction,
  fetchCleanByIdAction,
  fetchPreviousCleansAction,
} from '../cleans/actions'
import { type AssignmentsState } from './assignments.types'
import { emptyNormalizedAssignmentsData } from './assignments.utils'

export const initialAssignmentsState: AssignmentsState = {
  data: {},
}

const actions = {
  fetchCleanByIdAction,
  fetchCleansAction,
  fetchPreviousCleansAction,
}

type Actions = ActionType<typeof actions>

export const assignmentsReducer = (
  state = initialAssignmentsState,
  action: Actions,
): AssignmentsState =>
  produce(state, (draft: AssignmentsState) => {
    switch (action.type) {
      case getType(fetchCleanByIdAction.success):
      case getType(fetchPreviousCleansAction.success):
      case getType(fetchCleansAction.success): {
        const normalized =
          action?.payload?.normalized || emptyNormalizedAssignmentsData

        draft.data = {
          ...state.data,
          ...normalized.assignment,
        }
      }
    }
  })
