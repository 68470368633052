import React from 'react'

import { IconName } from 'packages/iconic'

import * as St from './UnitImageThumbnail.styles'

export enum UnitImageThumbnailTestIds {
  icon = 'UnitImageThumbnail__icon',
}

export interface UnitImageThumbnailProps {
  imageUrl?: string
}

type ThumbnailState = 'noPhoto' | 'loading' | 'error'

export const UnitImageThumbnail: React.FC<UnitImageThumbnailProps> = React.memo(
  ({ imageUrl }) => {
    const [thumbnailState, setThumbnailState] = React.useState<ThumbnailState>(
      imageUrl ? 'loading' : 'noPhoto',
    )

    const hasError = thumbnailState === 'error'

    return (
      <>
        {imageUrl && !hasError ? (
          <St.ImageContainer>
            <St.Image
              alt="vacation home"
              src={imageUrl}
              onError={() => {
                setThumbnailState('error')
              }}
            />
          </St.ImageContainer>
        ) : (
          <St.IconBackground>
            <St.Icon
              icon={IconName.home}
              size={12}
              dataTestId={UnitImageThumbnailTestIds.icon}
            />
          </St.IconBackground>
        )}
      </>
    )
  },
)
