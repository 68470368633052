import styled from '@emotion/styled'
import { upperFirst } from 'lodash/fp'
import React from 'react'

import { IconName, SvgIcon } from 'packages/iconic'
import { centerWithFlex, colors } from 'packages/styles'

import { useI18n, Slugs } from 'app/i18n'
import { type GwLocalHost } from 'app/store/gwLocalhosts'

import { GwLocalHostsList } from '../../components/GwLocalHostsList'
import { AccessFormContainer } from '../AccessForm'
import {
  IconButton,
  SectionBody,
  SectionHeading,
  SectionSubHeading,
  StafferNameHeader,
} from '../MyUnitsPage.styles'

const scrollToTop = () => {
  window.scrollTo({
    behavior: 'smooth',
    top: 0,
  })
}

const St = {
  AccessSection: styled.section`
    margin: 24px 24px 0 24px;
  `,
  Buttons: styled.div`
    ${centerWithFlex};
    grid-gap: 8px;
  `,
  EditIcon: styled(SvgIcon)`
    color: ${colors.midnight90};
    padding-left: 1px;
  `,
  IconButton,
  PlusIcon: styled(SvgIcon)`
    bottom: 0;
    color: ${colors.midnight90};
    left: 0;
    position: relative;
  `,
  SectionBody,
  SectionHeading,
  SectionSubHeading,
  StafferNameHeader,
}

const useTranslations = () => {
  const { t } = useI18n()

  return {
    access: t(Slugs.access),
    accessBody: t(Slugs.accessBody),
    addAccess: t(Slugs.addAccess),
    addAccessBody: t(Slugs.addAccessBody),
  }
}

export enum AccessSectionTestIds {
  addButton = 'AccessSection__addButton',
}

export interface AccessSectionProps {
  gwLocalHosts: GwLocalHost[]
  loading: boolean
}

export const AccessSection: React.FC<AccessSectionProps> = React.memo(
  ({ gwLocalHosts }) => {
    const strings = useTranslations()

    const [isAddingLocalHost, setIsAddingLocalHost] = React.useState(false)
    const [isEditingLocalHost, setIsEditingLocalHost] = React.useState(false)

    const accessFormHeaderRef = React.useRef<HTMLHeadingElement>(null)
    const scrollToForm = () => {
      if (accessFormHeaderRef.current?.scrollIntoView) {
        accessFormHeaderRef.current.scrollIntoView({
          behavior: 'smooth',
        })
      }
    }

    React.useEffect(() => {
      scrollToForm()
    }, [isAddingLocalHost])

    return (
      <St.AccessSection>
        <St.StafferNameHeader>
          <St.SectionHeading>{upperFirst(strings.access)}</St.SectionHeading>
          <St.Buttons>
            <St.IconButton
              onClick={() => {
                setIsEditingLocalHost(prev => !prev)
              }}
            >
              <St.EditIcon icon={IconName.edit} size={16} />
            </St.IconButton>

            <St.IconButton
              data-testid={AccessSectionTestIds.addButton}
              onClick={() => {
                setIsAddingLocalHost(true)
                scrollToForm()
              }}
            >
              <St.PlusIcon icon={IconName.plus} size={10} />
            </St.IconButton>
          </St.Buttons>
        </St.StafferNameHeader>

        <St.SectionBody>{strings.accessBody}</St.SectionBody>
        <GwLocalHostsList
          editing={isEditingLocalHost}
          gwLocalHosts={gwLocalHosts}
        />

        {isAddingLocalHost ? (
          <>
            <St.SectionSubHeading ref={accessFormHeaderRef}>
              {strings.addAccess}
            </St.SectionSubHeading>
            <St.SectionBody>{strings.addAccessBody}</St.SectionBody>
            <AccessFormContainer
              onCancel={() => {
                setIsAddingLocalHost(false)
                scrollToTop()
              }}
              onSuccess={() => {
                setIsAddingLocalHost(false)
                scrollToTop()
              }}
            />
          </>
        ) : null}
      </St.AccessSection>
    )
  },
)
