import styled from '@emotion/styled'
import React from 'react'

import { IconName, SvgIcon } from 'packages/iconic'

import { Slugs, useI18n } from 'app/i18n'
import { type Lockbox } from 'app/store/lockboxes'

import { Section } from '../TicketDetail.styles'

const Row = styled.div`
  display: flex;

  :not(:last-child) {
    margin-bottom: 20px;
  }
`

const Content = styled.div`
  flex: 1;
`

const StyledSvgIcon = styled(SvgIcon)`
  flex: 0;
  margin-top: 1px;
  padding-right: 16px;
`

const Header = styled.h3`
  font-size: 14px;
  font-weight: bold;
  margin: 0;
`

const InlineHeader = styled.span`
  font-weight: 600;
`

interface AccessSectionProps {
  lockbox: Lockbox
}

export const AccessSection: React.FC<AccessSectionProps> = ({
  lockbox: { primaryLock, backupLock, notes },
}) => {
  const { ut, t } = useI18n()

  return (
    <Section>
      <Row>
        <StyledSvgIcon icon={IconName.lock} size={16} />
        <Content>
          <Header>{ut(Slugs.access)}</Header>
          <div>
            <InlineHeader>
              {ut(Slugs.primary)} {t(Slugs.lockbox)}:
            </InlineHeader>{' '}
            {primaryLock}
          </div>

          <div>
            <InlineHeader>
              {ut(Slugs.backup)} {t(Slugs.lockbox)}:
            </InlineHeader>{' '}
            {backupLock}
          </div>

          <div>
            <InlineHeader>{ut(Slugs.notes)}:</InlineHeader> {notes}
          </div>
        </Content>
      </Row>
    </Section>
  )
}
