import { connect } from 'react-redux'

import { setAppInitState } from 'app/store/app/actions'
import { type AppInitState } from 'app/store/app/app.types'
import { type ApplicationState } from 'app/store/store'
import { type User } from 'app/store/users'
import { searchUsers, setActiveUser } from 'app/store/users/actions'
import { getActiveUser, getUsersSearchResults } from 'app/store/users/selectors'

import {
  AdminPanel,
  type AdminPanelActions,
  type AdminPanelSelectors,
} from '../components/AdminPanel/AdminPanel'

const mapStateToProps = (state: ApplicationState) => {
  const selectors: AdminPanelSelectors = {
    getActiveUser: () => getActiveUser(state),
    getUsersSearchResults: () => getUsersSearchResults(state),
  }
  return selectors
}

const mapDispatchToProps = dispatch => {
  const actions: AdminPanelActions = {
    searchUsers: (search: string) => dispatch(searchUsers(search)),
    setActiveUser: (user: User) => dispatch(setActiveUser(user)),
    setAppInitState: (nextState: AppInitState) =>
      dispatch(setAppInitState(nextState)),
  }
  return actions
}

export const ConnectedAdminPanel = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminPanel)
