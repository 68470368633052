import { createAsyncAction } from 'typesafe-actions'

import {
  type RequestConfig,
  type RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { LockboxApiFields } from '../../lockboxes'
import { UnitApiFields } from '../../units'
import * as ticketsService from '../tickets.service'
import {
  type NormalizedTicketsApiResponse,
  TicketApiFields,
  TicketsActionTypes,
} from '../tickets.types'

export const fetchTicketByIdAction = createAsyncAction(
  TicketsActionTypes.FETCH_TICKET,
  TicketsActionTypes.FETCH_TICKET_SUCCESS,
  TicketsActionTypes.FETCH_TICKET_FAILURE,
)<
  RequestConfig<NormalizedTicketsApiResponse>,
  NormalizedTicketsApiResponse,
  Error
>()

export const getTicketByIdParams = (): RequestOptions => ({
  fields: {
    lock_box: LockboxApiFields,
    ticket: TicketApiFields,
    unit: UnitApiFields,
  },
  include: ['unit', 'lock_box'],
  page: { size: 100 },
})

export const fetchTicketById = (ticketId: string) => async dispatch => {
  try {
    const params = getTicketByIdParams()
    const request = ticketsService.fetchTicketById.bind(null, ticketId, params)
    const result = await dispatch(fetchTicketByIdAction.request({ request }))
    dispatch(fetchTicketByIdAction.success(result))
    return result.normalized
  } catch (error) {
    dispatch(fetchTicketByIdAction.failure(error))
    throw error
  }
}
