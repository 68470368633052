// ------------------------------------------------
// REDUX TYPES
// ------------------------------------------------
export enum AppActionTypes {
  SET_APP_INIT_STATE = 'APP/SET_APP_INIT_STATE',
}

export type AppInitState =
  | 'initial'
  | 'authenticating'
  | 'authenticated'
  | 'bootstrapping'
  | 'ready'
  | 'error'

export interface AppGlobalState {
  initState: AppInitState
}
