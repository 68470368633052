/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// TODO fix ESLint ignore after we upgrade react-select
import { type CSSProperties } from 'react'

import { colors } from 'packages/styles'

import { gwShadows } from './gwShadows'

const customControlStyles: CSSProperties = {
  borderColor: colors.midnight10,
  borderRadius: '8px',
  borderStyle: 'solid',
  borderWidth: '1px',
  color: colors.dusk,
  cursor: 'pointer',
  fontWeight: 500,
  height: '50px',
  paddingTop: '1px',
}

export const gwSelect = {
  container: (provided, state) => {
    const boxShadow = state.isFocused && gwShadows.focus

    return { ...provided, boxShadow }
  },
  control: (provided, state) => {
    const outline = state.isFocused ? `4px solid ${colors.lake40}` : 'none'

    return {
      ...provided,
      ...customControlStyles,
      '&:hover': {
        borderColor: colors.midnight10,
      },
      outline,
    }
  },
  option: provided => {
    return { ...provided, cursor: 'pointer' }
  },
}
