import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Loader } from 'packages/common'

import { getAppInitState } from 'app/store/app/selectors'

import { SettingsPage } from './SettingsPage'

export const SettingsPageContainer: React.FC = () => {
  const appInitState = useSelector(getAppInitState)
  const appIsLoading = !['ready', 'error'].includes(appInitState)

  const { REACT_APP_IDP_BASE_URL: authRoot = '' } = process.env
  const logoutUrl = authRoot + '/logout'

  const navigate = useNavigate()

  const handleLinkClick = React.useCallback(
    route => {
      navigate(route)
    },
    [navigate],
  )

  const handleLogout = () => {
    window.location.replace(logoutUrl)
  }

  return appIsLoading ? (
    <Loader />
  ) : (
    <SettingsPage onLinkClick={handleLinkClick} onLogout={handleLogout} />
  )
}
