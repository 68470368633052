import { type RequestOptions } from 'packages/utils/store/jsonapi.types'

import { api } from '../axiosInstance'
import { emptyGwLocalHostsResponse } from '../gwLocalhosts'
import {
  type GwLocalHostsApiResponse,
  type GwLocalHostsServiceResponse,
} from './gwLocalhosts.types'

const URL = '/gw_local_hosts'

export const gwLocalHostsService = {
  async createGwLocalHost(
    data: RequestOptions = {},
  ): GwLocalHostsServiceResponse {
    const response: GwLocalHostsApiResponse = await api.post(URL, data)
    return response?.data || emptyGwLocalHostsResponse
  },

  async fetchGwLocalHostById(
    gwLocalHostId: string,
    params: RequestOptions = {},
  ): GwLocalHostsServiceResponse {
    const response: GwLocalHostsApiResponse = await api.get(
      `${URL}/${gwLocalHostId}`,
      {
        params,
      },
    )
    return response?.data || emptyGwLocalHostsResponse
  },

  async updateGwLocalHost(
    id: string,
    requestOptions: RequestOptions,
  ): GwLocalHostsServiceResponse {
    const response: GwLocalHostsApiResponse = await api.patch(
      `${URL}/${id}`,
      requestOptions,
    )
    return response?.data || emptyGwLocalHostsResponse
  },

  async deleteGwLocalHost(id: string): GwLocalHostsServiceResponse {
    const response: GwLocalHostsApiResponse = await api.delete(`${URL}/${id}`)
    return response?.data || emptyGwLocalHostsResponse
  },
}
