import styled from '@emotion/styled'
import React from 'react'

import { type ModalChildrenProps, useModalContext } from 'packages/common'

import { gwText } from 'app/styles'

import { RadioButtons, type RadioButtonsProps } from './RadioButtons'

const St = {
  ModalWrapper: styled.div`
    max-width: 280px;
    max-height: 280px;
    overflow-y: auto;
  `,
  Text: styled.p`
    ${gwText.bodySmallBold};
    margin-bottom: 0;
  `,
}

export type RadioButtonsModalProps = RadioButtonsProps & {
  noOptionsText?: string
}

type UseRadioButtonsModal = {
  showRadioButtonsModal: () => void
} & Pick<RadioButtonsProps, 'getOptionLabel'>

export const useRadioButtonsModal = (
  props: RadioButtonsModalProps,
): UseRadioButtonsModal => {
  const { showModal } = useModalContext()
  const { onChange } = props

  const handleRadioChange = React.useCallback(
    (id: string, beginClose: ModalChildrenProps['beginClose']) => {
      onChange(id)
      beginClose()
    },
    [onChange],
  )

  const showRadioButtonsModal = React.useCallback(() => {
    showModal({
      childRenderer: ({ beginClose }) => (
        <St.ModalWrapper>
          {props.options.length > 0 ? (
            <RadioButtons
              {...props}
              getOptionLabel={props.getOptionLabel}
              onChange={option => {
                handleRadioChange(option, beginClose)
              }}
            />
          ) : (
            <St.Text>{props.noOptionsText}</St.Text>
          )}
        </St.ModalWrapper>
      ),
      modalProps: {
        hideCloseButton: true,
      },
    })
  }, [handleRadioChange, props, showModal])

  return {
    showRadioButtonsModal,
  }
}
