import { deprecated } from 'typesafe-actions'

import { type User, UsersActionTypes } from 'app/store/users'
const { createStandardAction } = deprecated

export const setActiveUserAction = createStandardAction(
  UsersActionTypes.SET_ACTIVE_USER,
)<User>()

export const setActiveUser = (user: User) => async dispatch => {
  dispatch(setActiveUserAction(user))
}
