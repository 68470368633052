import { deprecated } from 'typesafe-actions'

import { AuthActionTypes } from '../auth.types'
const { createStandardAction } = deprecated

export const setNeedsFullAuthRedirectAction = createStandardAction(
  AuthActionTypes.SET_NEEDS_FULL_AUTH_REDIRECT,
)<boolean>()

export const setNeedsFullAuthRedirect =
  (needsFullRedirect: boolean) => dispatch =>
    dispatch(setNeedsFullAuthRedirectAction(needsFullRedirect))
