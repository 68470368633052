import styled from '@emotion/styled'

import { gwText } from 'app/styles'

export {
  ChevronIcon,
  Container,
  Date,
  DetailsContainer,
  Month,
  Day,
} from '../NewCleanCard/NewCleanCard.styles'

export const ResHeader = styled.div`
  ${gwText.headingSmallCaps};
`

export const Nights = styled.div`
  ${gwText.bodySmall};
  margin-top: 4px;
`
