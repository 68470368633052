import { noop } from 'lodash/fp'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useFormFns } from 'packages/common'
import { useAsyncFnWithReset } from 'packages/utils/hooks'

import { useGwToast } from 'app/components/core/hooks'
import { Slugs, useI18n } from 'app/i18n'
import {
  type GwUnitStaffer,
  type GwUnitStafferTypes,
} from 'app/store/gwUnitStaffers'
import {
  createGwUnitStaffer,
  updateGwUnitStaffer,
} from 'app/store/gwUnitStaffers/actions'
import { getSelectedUnit } from 'app/store/units/selectors/getSelectedUnit'

import {
  NotificationForm,
  type NotificationFormProps,
  type NotificationFormValues,
} from './NotificationForm'
import { type UnknownAction } from '@reduxjs/toolkit'

interface NotificationFormContainerProps {
  gwUnitStaffer: GwUnitStaffer | undefined
  onCancel?: () => void
  onSuccess?: () => void
  stafferType: GwUnitStafferTypes
}

const useTranslations = () => {
  const { t } = useI18n()

  return {
    pleaseVerify: t(Slugs.pleaseVerify),
    primaryHkUpdated: t(Slugs.primaryHkUpdated),
    required: t(Slugs.required),
  }
}

export const NotificationFormContainer: React.FC<NotificationFormContainerProps> =
  React.memo(
    ({ onCancel = noop, onSuccess = noop, gwUnitStaffer, stafferType }) => {
      const { showGwToast } = useGwToast()
      const strings = useTranslations()

      const unitId = useSelector(getSelectedUnit)?.id || ''

      const dispatch = useDispatch()

      const initialFormValues: NotificationFormValues = {
        email: gwUnitStaffer?.email || '',
        name: gwUnitStaffer?.name || '',
        phone: gwUnitStaffer?.phone || '',
      }

      const { formValues, setValueByKey } =
        useFormFns<NotificationFormValues>(initialFormValues)

      // CREATE
      const [createGwUnitStafferState, createGwUnitStafferFn] =
        useAsyncFnWithReset(async () => {
          return dispatch(
            createGwUnitStaffer(
              {
                email: formValues.email,
                name: formValues.name,
                phone: formValues.phone,
                stafferType,
                unitId,
              },
              {
                onError: () => {
                  showGwToast({
                    message: strings.pleaseVerify,
                    toastType: 'danger',
                  })
                },

                onSuccess: () => {
                  showGwToast({ message: strings.primaryHkUpdated })
                  onSuccess()
                },
              },
            ) as unknown as UnknownAction,
          )
        }, [
          dispatch,
          formValues,
          unitId,
          onSuccess,
          showGwToast,
          stafferType,
          strings.pleaseVerify,
          strings.primaryHkUpdated,
        ])

      // UPDATE
      const [updateGwUnitStafferState, updateGwUnitStafferFn] =
        useAsyncFnWithReset(async () => {
          if (gwUnitStaffer) {
            return dispatch(
              updateGwUnitStaffer(
                {
                  email: formValues.email,
                  id: gwUnitStaffer.id,
                  name: formValues.name,
                  phone: formValues.phone,
                  stafferType,
                },
                {
                  onError: () => {
                    showGwToast({
                      message: strings.pleaseVerify,
                      toastType: 'danger',
                    })
                  },
                  onSuccess: () => {
                    showGwToast({ message: strings.primaryHkUpdated })
                    onSuccess()
                  },
                },
              ) as unknown as UnknownAction,
            )
          }
        }, [
          dispatch,
          formValues.email,
          formValues.name,
          formValues.phone,
          gwUnitStaffer,
          onSuccess,
          showGwToast,
          stafferType,
          strings.pleaseVerify,
          strings.primaryHkUpdated,
        ])

      const formState: NotificationFormProps['formState'] = React.useMemo(
        () => ({
          canSubmit: true,
          values: formValues,
        }),
        [formValues],
      )

      const handleSubmit: React.FormEventHandler = React.useCallback(
        event => {
          event.preventDefault()
          gwUnitStaffer ? updateGwUnitStafferFn() : createGwUnitStafferFn()
        },
        [createGwUnitStafferFn, gwUnitStaffer, updateGwUnitStafferFn],
      )

      const handlers: NotificationFormProps['handlers'] = React.useMemo(
        () => ({
          cancel: onCancel,
          setValueByKey,
          submit: handleSubmit,
        }),
        [handleSubmit, onCancel, setValueByKey],
      )

      return (
        <>
          <NotificationForm
            formState={formState}
            handlers={handlers}
            requestState={
              gwUnitStaffer
                ? updateGwUnitStafferState
                : createGwUnitStafferState
            }
          />
        </>
      )
    },
  )
