import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { sendErrorToSentry } from 'packages/common'
import { type JsonApiErrorResponse } from 'packages/grimoire/src/utils'
import { getErrorDetail } from 'packages/grimoire/src/utils/jsonapi.utils'
import { useAsyncFnWithReset } from 'packages/utils/hooks'
import { logError } from 'packages/wiretap/logging'

import { useGwToast } from 'app/components/core/hooks'
import {
  isViewingSharedClean,
  isViewingSharedTicket,
} from 'app/components/schedule/schedule.utils'
import { Slugs, useI18n } from 'app/i18n'
import { setAppInitState } from 'app/store/app/actions'
import { getAppInitState } from 'app/store/app/selectors'
import { fetchCleans } from 'app/store/cleans/actions'
import { fetchReservations } from 'app/store/reservations/actions'
import { fetchTickets } from 'app/store/tickets/actions'
import { fetchUnits } from 'app/store/units/actions/fetchUnits'
import { fetchCurrentUser } from 'app/store/users/actions'
import { useActiveUser } from 'app/utils/hooks/useActiveUser'
import { getUpcomingTimelineDateRange } from 'app/utils/timelineHelpers'

import { usePrivacyDisclosureModal } from '../../components'
import { type UnknownAction } from '@reduxjs/toolkit'

const logInitError = (error: Error | string) => {
  const detail =
    getErrorDetail(error as unknown as JsonApiErrorResponse) || 'unknown'
  const err = error instanceof Error ? error : new Error()
  err.name = 'Guestworks Initialization Error'

  logError(err, {
    error: { detail, fullError: error.toString() },
    name: err.name,
    severity: 'error',
  })

  // TODO: When we set up DD monitoring, we can get rid of the line below
  sendErrorToSentry(err, {
    customData: {
      error: { detail, fullError: error },
    },
    severity: 'error',
  })
}

export const useInitializeGuestworks = (): void => {
  usePrivacyDisclosureModal()

  const dispatch = useDispatch()
  const appInitState = useSelector(getAppInitState)

  const { t } = useI18n()
  const { user } = useActiveUser()
  const { showGwToast } = useGwToast()

  const isSharedTask = isViewingSharedClean() || isViewingSharedTicket()

  const handleInitError = React.useCallback(() => {
    showGwToast({
      autoClose: false,
      message: t(Slugs.anErrorOccurredLong),
      toastType: 'danger',
    })
  }, [showGwToast, t])

  // ------------------------------------------------
  // User initialization
  // ------------------------------------------------
  const [fetchUserState, fetchUserFn] = useAsyncFnWithReset(
    async () => dispatch(fetchCurrentUser() as unknown as UnknownAction),
    [dispatch],
  )

  React.useEffect(() => {
    const { error, loading, value } = fetchUserState
    if (loading) return

    if (error) {
      dispatch(setAppInitState('error') as unknown as UnknownAction)
      logInitError(error)
    } else if (value) {
      dispatch(setAppInitState('authenticated') as unknown as UnknownAction)
    }
  }, [dispatch, fetchUserState])

  // ------------------------------------------------
  // Data initialization
  // ------------------------------------------------
  const [fetchAllState, fetchAllFn] = useAsyncFnWithReset(async () => {
    const [startDate, endDate] = getUpcomingTimelineDateRange()

    // Awaiting units before cleans fixes a race condition where the cleans can have an undefined relationship to units
    await dispatch(fetchUnits() as unknown as UnknownAction)

    return await Promise.all([
      dispatch(fetchCleans(startDate, endDate) as unknown as UnknownAction),
      dispatch(
        fetchReservations(startDate, endDate) as unknown as UnknownAction,
      ),
      dispatch(fetchTickets() as unknown as UnknownAction),
    ])
  }, [dispatch])

  React.useEffect(() => {
    const { error, loading, value } = fetchAllState
    if (loading) return

    if (error) {
      dispatch(setAppInitState('error') as unknown as UnknownAction)
      logInitError(error)
    } else if (value) {
      dispatch(setAppInitState('ready') as unknown as UnknownAction)
    }
  }, [dispatch, fetchAllState])

  // ------------------------------------------------
  // State management
  // ------------------------------------------------
  React.useEffect(() => {
    switch (appInitState) {
      case 'initial':
        // skip authentication for shared cleans/tickets
        if (isSharedTask) {
          dispatch(setAppInitState('authenticated') as unknown as UnknownAction)
        } else
          dispatch(
            setAppInitState('authenticating') as unknown as UnknownAction,
          )
        break

      case 'authenticating':
        if (!user?.id) fetchUserFn()
        break

      case 'authenticated':
        if (user?.isSuperuser)
          dispatch(setAppInitState('ready') as unknown as UnknownAction)
        else
          dispatch(setAppInitState('bootstrapping') as unknown as UnknownAction)
        break

      case 'bootstrapping':
        fetchAllFn()
        break

      case 'error':
        handleInitError()
        break

      case 'ready':
      default:
        break
    }
  }, [
    appInitState,
    dispatch,
    fetchAllFn,
    fetchUserFn,
    handleInitError,
    isSharedTask,
    user?.id,
    user?.isSuperuser,
  ])
}
