import { createAsyncAction } from 'typesafe-actions'

import {
  type RequestConfig,
  type RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { UserApiFieldsWithoutPII } from 'app/store/users'

import { smartLocksService } from '../smartLocks.service'
import {
  type NormalizedSmartLocksApiResponse,
  SmartLockApiFields,
  SmartLocksActionTypes,
} from '../smartLocks.types'

export const fetchSmartLocksByUnitIdAction = createAsyncAction(
  SmartLocksActionTypes.FETCH_SMART_LOCKS_BY_UNIT_ID,
  SmartLocksActionTypes.FETCH_SMART_LOCKS_BY_UNIT_ID_SUCCESS,
  SmartLocksActionTypes.FETCH_SMART_LOCKS_BY_UNIT_ID_FAILURE,
)<
  RequestConfig<NormalizedSmartLocksApiResponse>,
  NormalizedSmartLocksApiResponse,
  Error
>()

export const getParams = (unitId: string): RequestOptions => ({
  fields: {
    smart_lock: SmartLockApiFields,
    unit: [],
    user: UserApiFieldsWithoutPII,
  },
  filter: {
    unit: unitId,
  },
  include: ['generated_by', 'unit'],
})

export const fetchSmartLocksByUnitId = (unitId: string) => async dispatch => {
  try {
    const params = getParams(unitId)
    const request = smartLocksService.fetchSmartLocks.bind(null, params)
    const result = await dispatch(
      fetchSmartLocksByUnitIdAction.request({ request }),
    )
    dispatch(fetchSmartLocksByUnitIdAction.success(result))
    return result.normalized
  } catch (error) {
    dispatch(fetchSmartLocksByUnitIdAction.failure(error))
    throw error
  }
}
