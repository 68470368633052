import styled from '@emotion/styled'
import React from 'react'

import { St as UnitLinkStyles } from 'packages/common/src/UnitLinks/UnitLinks.styles'
import { useI18n } from 'packages/i18n'
import { IconName } from 'packages/iconic'

import { Slugs } from 'app/i18n'
import { type Unit } from 'app/store/units'

import { CreateTicketDrawerButton } from './CreateTicketDrawerButton'

const St = {
  Row: styled(UnitLinkStyles.Row)`
    margin-top: 20px;
  `,
}

export interface CreateTicketDrawerProps {
  className?: string
  unit?: Unit
}

export const CreateTicketDrawer: React.FC<CreateTicketDrawerProps> = ({
  className,
  unit,
}) => {
  const { ut } = useI18n()

  return (
    <>
      <St.Row className={className}>
        <UnitLinkStyles.StyledSvgIcon icon={IconName.eye} size={16} />
        <div>
          <UnitLinkStyles.Header>{ut(Slugs.seeAnIssue)}</UnitLinkStyles.Header>
          <CreateTicketDrawerButton unit={unit} />
        </div>
      </St.Row>
    </>
  )
}
