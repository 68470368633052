import { produce } from 'immer'
import { isNil, keys, negate } from 'lodash/fp'

/** Returns whether the value is not nullish (null or undefined */
export const isDefined = negate(isNil)

type Obj = Record<string, unknown>
/**
 * Returns a new copy of the object with any undefined values removed.
 * Note that this DOES NOT remove "null" or other "nullish" values—only undefined;
 * and it only checks top-level properties—no recursion.
 */
export const removeUndefined = (obj: Obj): Obj =>
  produce(obj, draft => {
    keys(obj).forEach(key => {
      if (draft[key] === undefined) {
        delete draft[key]
      }
    })
  })
