import { combineReducers } from 'redux'

import { appReducer } from './app/app.reducer'
import { assignmentsReducer } from './assignments/assignments.reducer'
import { authReducer } from './auth/auth.reducer'
import { cleansReducer } from './cleans/cleans.reducer'
import { gwLocalHostsReducer } from './gwLocalhosts/gwLocalhosts.reducer'
import { gwUnitStaffersReducer } from './gwUnitStaffers/gwUnitStaffers.reducer'
import { housekeepersReducer } from './housekeepers/housekeepers.reducer'
import { lockboxesReducer } from './lockboxes/lockboxes.reducer'
import { reservationsReducer } from './reservations/reservations.reducer'
import { smartLocksReducer } from './smartLocks/smartLocks.reducer'
import { ticketsReducer } from './tickets/tickets.reducer'
import { unitsReducer } from './units/units.reducer'
import { usersReducer } from './users/users.reducer'

const reducers = {
  app: appReducer,
  assignments: assignmentsReducer,
  auth: authReducer,
  cleans: cleansReducer,
  gwLocalHosts: gwLocalHostsReducer,
  gwUnitStaffers: gwUnitStaffersReducer,
  housekeepers: housekeepersReducer,
  lockboxes: lockboxesReducer,
  reservations: reservationsReducer,
  smartLocks: smartLocksReducer,
  tickets: ticketsReducer,
  units: unitsReducer,
  users: usersReducer,
}

export const rootReducer = combineReducers(reducers)
