import React from 'react'

import { colors } from 'packages/styles'

import { Loader, LoaderType } from '../Loader'
import { St } from './Button.styles'

export const lightBgButtonTypeValues = [
  'primary',
  'secondary',
  'utility',
  'danger',
  'text',
] as const

export const darkBgButtonTypeValues = [
  'utilityInverted',
  'textInverted',
] as const

export type ButtonSize = 'default' | 'small'

const allButtonTypes = [...lightBgButtonTypeValues, ...darkBgButtonTypeValues]
export type ButtonType = (typeof allButtonTypes)[number]
export type ButtonShape = 'circle' | 'rect'

const loaderColorMap: Record<ButtonType, string> = {
  danger: 'white',
  primary: 'white',
  secondary: colors.lake,
  text: colors.lake,
  textInverted: 'white',
  utility: colors.midnight70,
  utilityInverted: 'white',
}

const loaderConfigMap: Record<ButtonShape, { size: number; type: LoaderType }> =
  {
    circle: {
      size: 18,
      type: LoaderType.Clip,
    },
    rect: {
      size: 12,
      type: LoaderType.Beat,
    },
  }

export interface ButtonProps {
  /**
   * (Optional) String for a11y purposes, which will apply an aria-label attribute to the button.
   * Generally speaking, this should be used when button does not contain text that clearly explains what it does (e.g. an icon button).
   */
  ariaLabel?: string
  /** When true, button will expand to fill all horizontal space */
  block?: boolean
  buttonShape?: ButtonShape
  buttonSize?: ButtonSize
  buttonType?: ButtonType
  children: React.ReactNode
  className?: string
  dataTestId?: string
  disabled?: boolean
  isFormSubmit?: boolean
  isLoading?: boolean
  onClick?: React.MouseEventHandler
}

export const Button: React.FC<ButtonProps> = React.memo(
  ({
    ariaLabel,
    block = false,
    buttonShape = 'rect',
    buttonSize = 'default',
    buttonType = 'primary',
    children,
    className,
    dataTestId,
    disabled = false,
    isFormSubmit = false,
    isLoading = false,
    onClick,
  }) => (
    <St.Button
      aria-label={ariaLabel}
      block={block}
      buttonShape={buttonShape}
      buttonSize={buttonSize}
      buttonType={buttonType}
      className={className}
      data-testid={dataTestId}
      disabled={disabled}
      isLoading={isLoading}
      onClick={onClick}
      type={isFormSubmit ? 'submit' : 'button'}
    >
      <St.Children isLoading={isLoading}>{children}</St.Children>
      {isLoading && (
        <Loader
          color={loaderColorMap[buttonType]}
          size={loaderConfigMap[buttonShape].size}
          transparent={true}
          type={loaderConfigMap[buttonShape].type}
        />
      )}
    </St.Button>
  ),
)
