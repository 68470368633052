import { noop } from 'lodash/fp'
import { createAsyncAction } from 'typesafe-actions'

import { type ReduxActionCallbacks } from 'packages/grimoire/src/utils'
import {
  makeRelationship,
  type RequestConfig,
  type RequestOptions,
} from 'packages/utils/store'

import { fetchUnitById } from '../../units/actions/fetchUnitById'
import { gwLocalHostsService } from '../gwLocalhosts.service'
import {
  type GwLocalHostPostApiAttributes,
  type GwLocalHostPostApiRelationships,
  type GwLocalHostPostAttributes,
  GwLocalHostsActionTypes,
  type NormalizedGwLocalHostsApiResponse,
} from '../gwLocalhosts.types'

export const createGwLocalHostAction = createAsyncAction(
  GwLocalHostsActionTypes.CREATE_GW_LOCAL_HOST,
  GwLocalHostsActionTypes.CREATE_GW_LOCAL_HOST_SUCCESS,
  GwLocalHostsActionTypes.CREATE_GW_LOCAL_HOST_FAILURE,
)<
  RequestConfig<NormalizedGwLocalHostsApiResponse>,
  NormalizedGwLocalHostsApiResponse,
  Error
>()

export const buildRequestData = (
  postData: GwLocalHostPostAttributes,
): RequestOptions => {
  const { email, firstName, lastName, unitId } = postData

  const attributes: GwLocalHostPostApiAttributes = {
    email,
    first_name: firstName,
    last_name: lastName,
  }

  const relationships: GwLocalHostPostApiRelationships = {
    unit: makeRelationship('unit', unitId),
  }

  return {
    data: {
      attributes,
      relationships,
      type: 'gw_local_host',
    },
  }
}

export const createGwLocalHost =
  (postData: GwLocalHostPostAttributes, callbacks: ReduxActionCallbacks = {}) =>
  async dispatch => {
    const { onError = noop, onSuccess = noop } = callbacks

    try {
      const requestData = buildRequestData(postData)
      const request = gwLocalHostsService.createGwLocalHost.bind(
        null,
        requestData,
      )
      const result: NormalizedGwLocalHostsApiResponse = await dispatch(
        createGwLocalHostAction.request({ request }),
      )
      dispatch(createGwLocalHostAction.success(result))

      await dispatch(fetchUnitById(postData.unitId))

      onSuccess()
      return result.normalized
    } catch (error) {
      dispatch(createGwLocalHostAction.failure(error))
      onError()
      throw error
    }
  }
