import { transformNormalizedToTyped } from 'packages/utils/store'

import {
  type RawGwLocalHost,
  type GwLocalHost,
  type GwLocalHostsResponse,
  type NormalizedGwLocalHostsApiResponse,
  GwLocalHostAttributeNames,
} from './gwLocalhosts.types'

export const hydrateRawGwLocalHost = (
  rawLocalHost: RawGwLocalHost,
): GwLocalHost => {
  const unitId = rawLocalHost.relationships?.unit?.data?.id || ''

  const gwLocalHost = transformNormalizedToTyped<GwLocalHost>(
    rawLocalHost,
    GwLocalHostAttributeNames,
  )

  return {
    ...gwLocalHost,
    unitId,
  }
}

export const emptyNormalizedGwLocalHostsData: GwLocalHostsResponse =
  Object.freeze({
    gwLocalHost: {},
    unit: {},
  })

export const emptyGwLocalHostsResponse: NormalizedGwLocalHostsApiResponse =
  Object.freeze({
    normalized: emptyNormalizedGwLocalHostsData,
    raw: { data: [] },
  })
