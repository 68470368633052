import styled from '@emotion/styled'
import React from 'react'

import { IconName, SvgIcon } from 'packages/iconic'
import { centerWithFlex, colors } from 'packages/styles'

import { Button } from '../Button'

export const alertTypeValues = ['danger', 'info', 'success', 'warning'] as const
export type AlertType = (typeof alertTypeValues)[number]
export type AlertTypeMap<T> = { [k in AlertType]: T }

const alertColorMap: AlertTypeMap<{ bg: string; border: string }> = {
  danger: {
    bg: colors.alert10,
    border: colors.alert,
  },
  info: {
    bg: colors.lake10,
    border: colors.midnight70,
  },
  success: {
    bg: colors.success10,
    border: colors.success,
  },
  warning: {
    bg: colors.awareness10,
    border: colors.awareness,
  },
}

const St = {
  ActionButton: styled(SvgIcon)`
    align-self: center;
    color: ${colors.dusk};
    margin: 0;
    padding: 0;
  `,
  Alert: styled.div<{ alertType: AlertType; isActionable: boolean }>`
    ${centerWithFlex};
    align-items: center;
    color: ${colors.dusk};
    grid-gap: 16px;
    justify-content: flex-start;
    margin: 0;
    margin-bottom: 1rem;
    min-height: 56px;
    padding: 16px 12px;

    background: ${({ alertType }) => alertColorMap[alertType].bg};
    box-shadow: ${({ isActionable }) =>
      isActionable ? '0px 2px 10px rgba(44, 52, 57, 0.15);' : 'none'};

    // be careful with changing the order of these styles!
    border: ${({ isActionable }) =>
      isActionable ? `1px solid ${colors.midnight20}` : 'none'};
    border-left: 4px solid;
    border-left-color: ${({ alertType }) => alertColorMap[alertType].border};
    border-radius: 4px;
  `,
  Children: styled.div`
    flex-grow: 1;
  `,
  CloseButton: styled(Button)`
    align-self: center;
    color: ${colors.dusk};
    height: auto;
    margin: 0;
    padding: 0;
  `,
}

export interface ActionConfig {
  icon: IconName
  onClick: () => void
}

export enum AlertTestIds {
  actionButton = 'alert__actionButton',
  closeButton = 'alert__closeButton',
  container = 'alert__container',
}

const alertIconMap: AlertTypeMap<IconName> = {
  danger: IconName.alertOctagon,
  info: IconName.info,
  success: IconName.thumbsUp,
  warning: IconName.alertTriangle,
}

export interface AlertProps {
  action?: ActionConfig
  alertType: AlertType
  children: React.ReactNode
  className?: string
  dataTestId?: string
  iconName?: IconName
  /**
   * Optional callback to handle closing the Alert
   * If a function is provided here, the Alert will have a "close" icon on it that will trigger this callback
   */
  onClose?: () => void
}

export const Alert: React.FC<AlertProps> = React.memo(
  ({
    action,
    alertType,
    children,
    className,
    dataTestId,
    iconName,
    onClose,
  }: AlertProps) => {
    return (
      <St.Alert
        alertType={alertType}
        className={className}
        data-testid={dataTestId || AlertTestIds.container}
        isActionable={!!onClose || !!action}
        role="alert"
      >
        <SvgIcon icon={iconName ?? alertIconMap[alertType]} size={24} />
        <St.Children>{children}</St.Children>

        {onClose && (
          <St.CloseButton
            buttonType={'text'}
            dataTestId={AlertTestIds.closeButton}
            onClick={onClose}
          >
            <SvgIcon icon={IconName.x} size={24} />
          </St.CloseButton>
        )}

        {/* if there is an "onClose" handler, action icons do not get rendered */}
        {action && !onClose && (
          <St.ActionButton
            dataTestId={AlertTestIds.actionButton}
            icon={action?.icon}
            onClick={action?.onClick}
            size={24}
          />
        )}
      </St.Alert>
    )
  },
)
