/* eslint-disable sort-keys */
import { css } from '@emotion/react'

export const gwText = {
  bodyDefault: css`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  `,
  bodySmall: css`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  `,
  bodySmallBold: css`
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  `,
  bodySmallSemibold: css`
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  `,
  bodyXs: css`
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  `,
  headingLarge: css`
    font-size: 38px;
    font-weight: 800;
    letter-spacing: 1px;
    line-height: 48px;
  `,
  headingMedium: css`
    font-size: 21px;
    font-weight: 800;
    line-height: 28px;
  `,
  headingMediumLight: css`
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
  `,
  headingSmall: css`
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
  `,
  headingSmallCaps: css`
    font-size: 14px;
    font-weight: 800;
    letter-spacing: 2px;
    line-height: 16px;
    text-transform: uppercase;
  `,
  headingTinyCaps: css`
    font-size: 10px;
    font-weight: 800;
    letter-spacing: 1.5px;
    line-height: 16px;
    text-transform: uppercase;
  `,
  headingMicroCaps: css`
    font-size: 8px;
    font-weight: 800;
    letter-spacing: 1.5px;
    line-height: 12px;
    text-transform: uppercase;
  `,
  headingXsCaps: css`
    font-size: 12px;
    font-weight: 800;
    letter-spacing: 2px;
    line-height: 14px;
    text-transform: uppercase;
  `,
  headingXs: css`
    font-size: 14px;
    font-weight: 800;
    letter-spacing: 1px;
    line-height: 16px;
  `,
}
