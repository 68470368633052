import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'

import {
  Loader,
  ModalProvider,
  ServiceWorkerWrapper,
  ToastCustomContainer,
} from 'packages/common'
import { colors, equalOrLarger } from 'packages/styles'

import { register as registerSW } from '../serviceWorker'
import { GuestworksRoot } from './components/GuestworksRoot'
import { useGuestworksAuth } from './useGuestworksAuth'
import { UpdateNotifier } from './components/core/components/UpdateNotifier'

const MAX_WIDTH = 768

const St = {
  Guestworks: styled.div`
    background: ${colors.white};
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: ${MAX_WIDTH}px;
    min-height: 100vh;

    ${equalOrLarger(
      MAX_WIDTH + 1,

      css`
        border: 1px solid ${colors.midnight30};
        box-shadow: 0 1px 4px 0 rgba(216, 216, 216, 0.5);
      `,
    )};
  `,
}

export const Guestworks: React.FC = React.memo(() => {
  const { authState } = useGuestworksAuth()

  return (
    <ModalProvider>
      <ServiceWorkerWrapper
        registerSW={registerSW}
        updateNotifier={<UpdateNotifier />}
      />
      <St.Guestworks>
        {authState.loaded ? (
          <>
            <ToastCustomContainer />
            <GuestworksRoot initialUrl={authState.initialUrl} />
          </>
        ) : (
          <Loader />
        )}
      </St.Guestworks>
    </ModalProvider>
  )
})
