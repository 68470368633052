import { filter, map, orderBy, pipe, values } from 'lodash/fp'
import { createSelector } from 'reselect'

import { type ApplicationState } from '../../store'
import { type SmartLock } from '../smartLocks.types'
import { transformRawSmartLock } from '../smartLocks.utils'

export const getSmartLocksByUnitId = createSelector(
  (state: ApplicationState) => state.smartLocks.data,
  (_state, unitId: string) => unitId,
  (smartLocksData, unitId): SmartLock[] =>
    pipe(
      values,
      map(transformRawSmartLock),
      filter(sl => sl.unitId === unitId),
      orderBy('expiredAt', ['desc']),
    )(smartLocksData),
)
