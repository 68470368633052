import styled from '@emotion/styled'

import { colors } from 'packages/styles'

interface StyleProps {
  isSelected: boolean
}

export const TimeListItem = styled.li<StyleProps>`
  align-items: center;
  background-color: ${({ isSelected }) =>
    isSelected ? `${colors.lake}` : 'inherit'};
  color: ${({ isSelected }) => (isSelected ? `${colors.white}` : 'inherit')};
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;

  &:hover {
    background-color: ${colors.lake};
    color: ${colors.white};
  }
`

export const TimeList = styled.ul`
  height: 250px;
  list-style: none;
  overflow-y: scroll;
  padding: 0;
  width: 300px;
`
