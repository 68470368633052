import React from 'react'

import { Alert } from 'packages/common'
import { useAsyncFnWithReset } from 'packages/utils/hooks'

import { Button } from 'app/components/core'
import { Slugs, useI18n } from 'app/i18n'

import {
  type LockCodeRequestType,
  fetchLockCode,
} from './LockCodeButton.helpers'

export enum LockCodeButtonTestIds {
  container = 'LockCodeButton__container',
}

export interface LockCodeButtonProps {
  accessToken: string
  /** Method to indicate whether the creation of new lock codes is disabled */
  isCreationDisabled: () => boolean
  itemId: string
  /** Method called after lock code has been generated */
  onSetLockCode: (lockCode: string) => void
  type: LockCodeRequestType
}

export const LockCodeButton: React.FC<LockCodeButtonProps> = React.memo(
  ({ itemId, type, isCreationDisabled, onSetLockCode, accessToken }) => {
    const { t } = useI18n()

    const [getLockCodeState, getLockCodeFn] = useAsyncFnWithReset(
      async () => await fetchLockCode({ accessToken, itemId, type }),
      [accessToken, itemId, type],
    )

    const handleButtonPress = async () => {
      const lockCode = await getLockCodeFn()
      onSetLockCode(lockCode)
    }

    return (
      <>
        {getLockCodeState.error && (
          <Alert alertType="danger">{t(Slugs.anErrorOccurred)}</Alert>
        )}
        <Button
          buttonType={'secondary'}
          block={true}
          dataTestId={LockCodeButtonTestIds.container}
          isDisabled={getLockCodeState.loading || isCreationDisabled()}
          isLoading={getLockCodeState.loading}
          onPress={handleButtonPress}
        >
          {t(Slugs.generateLockCode)}
        </Button>
      </>
    )
  },
)
