import React from 'react'

import {
  type AsyncState,
  useAsyncFnWithReset,
  useOnlineStatus,
  useShareApi,
} from 'packages/utils/hooks'
import { Events, track } from 'packages/wiretap'

import { useGwToast } from 'app/components/core/hooks'
import { Slugs, useI18n } from 'app/i18n'
import { useActiveUser } from 'app/utils/hooks'

import { fetchDelegateToken } from './useDelegateToken.helpers'

export const CLIPBOARD_RESET_TIMEOUT = 1500

export type PathType = 'cleans' | 'tickets'

enum CopyState {
  Idle,
  Copied,
  Failed,
}

export interface UseDelegateToken {
  buttonText: string
  canShare: boolean
  copyLinkText: string
  disabled: boolean
  fetchTokenState: AsyncState<unknown>
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const slugsByCopyState: Record<CopyState, string> = {
  [CopyState.Idle]: Slugs.copyLink,
  [CopyState.Copied]: Slugs.copied,
  [CopyState.Failed]: Slugs.copyFailed,
}

const useTranslations = (copyState: CopyState) => {
  const { ut } = useI18n()

  return {
    buttonText: ut(Slugs.shareButton),
    copyLinkText: ut(slugsByCopyState[copyState]),
    shareText: ut(Slugs.shareBody),
    tokenError: ut(Slugs.errorTryAgainLater),
  }
}

export const useDelegateToken = (
  accessToken: string,
  id: string,
  pathType: PathType,
  unitAddress: string,
): UseDelegateToken => {
  const { share, canShare } = useShareApi()
  const disabled = !useOnlineStatus()?.isOnline()
  const { user } = useActiveUser()

  const { showGwToast } = useGwToast()

  const [copyState, setCopyState] = React.useState<CopyState>(CopyState.Idle)
  const strings = useTranslations(copyState)

  const handleCopyLink = React.useCallback(async text => {
    try {
      await navigator.clipboard.writeText(text)
      setCopyState(CopyState.Copied)
      setTimeout(() => {
        setCopyState(CopyState.Idle)
      }, CLIPBOARD_RESET_TIMEOUT)
    } catch (err) {
      setCopyState(CopyState.Failed)
    }
  }, [])

  const trackShareClean = React.useCallback(() => {
    /* eslint-disable @typescript-eslint/naming-convention */
    track(Events.guestworksCleanShared, {
      clean_id: id,
      user_id: user?.id || 'unknown',
    })
    /* eslint-enable @typescript-eslint/naming-convention */
  }, [id, user?.id])

  const trackShareTicket = React.useCallback(() => {
    /* eslint-disable @typescript-eslint/naming-convention */
    track(Events.guestworksTicketShared, {
      ticket_id: id,
      user_id: user?.id || 'unknown',
    })
    /* eslint-enable @typescript-eslint/naming-convention */
  }, [id, user?.id])

  const [fetchTokenState, fetchToken] = useAsyncFnWithReset(async () => {
    if (pathType === 'cleans') {
      trackShareClean()
    }

    if (pathType === 'tickets') {
      trackShareTicket()
    }

    const selectedType =
      pathType === 'cleans' ? 'selectedClean' : 'selectedTicket'

    const resourcePath = `${pathType}/${id}`
    const delegateToken = await fetchDelegateToken({
      accessToken,
      resourcePath,
    })

    // due to URL parsing issue, we need to split the token "pieces" into query params
    // this is because attempting to include the full token (with periods) will cause apps/SMS not to read the URL correctly
    const [header, body, sig] = delegateToken.split('.')
    const tokenParams = `h=${header}&b=${body}&s=${sig}`
    const delegateUrl = `shared/${pathType}?${selectedType}=${id}&${tokenParams}`

    const fullUrl = `${window.location.origin}/${delegateUrl}`

    // NOTE: be very careful if tweaking the formatting/layout here, as it will affect the final "share" text
    const text = `
${strings.shareText}

${unitAddress}

${fullUrl}`

    if (canShare) share({ text })
    else handleCopyLink(text)
    return {
      onError: () => {
        showGwToast({
          message: strings.tokenError,
          toastType: 'danger',
        })
      },
    }
  }, [
    accessToken,
    canShare,
    handleCopyLink,
    id,
    pathType,
    share,
    showGwToast,
    strings.shareText,
    strings.tokenError,
    unitAddress,
  ])

  return {
    buttonText: strings.buttonText,
    canShare,
    copyLinkText: strings.copyLinkText,
    disabled,
    fetchTokenState,
    onClick: fetchToken,
  }
}
