import styled from '@emotion/styled'
import { Link as ReactRouterLink } from 'react-router-dom'

import { SvgIcon } from 'packages/iconic'
import { centerWithFlex, colors } from 'packages/styles'

import { gwText } from 'app/styles'

import { Card } from '../../core'

export {
  ButtonWrapper,
  HeadingContainer,
  HeadingIconContainer,
  HeadingIcon,
  Heading,
} from '../Settings.styles'

export const UnitName = styled.p`
  ${gwText.bodySmallBold};
  color: ${colors.dusk60};
  margin: 0;
`

export const UnitAddress = styled.p`
  ${gwText.bodySmallBold};
  color: ${colors.dusk};
  margin: 0;
`

export const LinkWrapper = styled.div`
  ${centerWithFlex};
`

export const Link = styled(ReactRouterLink)`
  color: ${colors.lake};
  font-size: 14px;
  font-weight: 800;
  letter-spacing: 0.75px;
  line-height: 24px;
`

export const LockCodeFormContainer = styled.div`
  margin: 32px 24px;
`

export const NoSmartLockCard = styled(Card)`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 24px;
  padding: 24px 46px;
`

export const NoSmartLockCardText = styled.p`
  ${gwText.bodyDefault};
  color: ${colors.midnight};
  margin: 24px 0 0 0;
  text-align: center;
`

export const AlertTriangleIcon = styled(SvgIcon)`
  color: ${colors.midnight70};
`
