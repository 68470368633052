import styled from '@emotion/styled'
import React from 'react'

import {
  ModalContent,
  ModalTitle,
  StyledModal,
} from 'packages/common/src/modals/Modal/Modal.styles'
import { colors } from 'packages/styles'

import { Slugs, useI18n } from 'app/i18n'

const St = {
  Definition: styled.div``,
  ModalContent,
  ModalFooter: styled.footer`
    border-top: 1px solid ${colors.dusk40};
    margin-top: 1em;
    padding-top: 1em;
    font-style: italic;
  `,
  ModalTitle: styled(ModalTitle)`
    color: ${colors.midnight};
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 2px;
    margin: 0 0 0.5em;
    text-align: left;
    text-transform: uppercase;
  `,
  StyledModal: styled(StyledModal)`
    padding: 0;
  `,
  Term: styled.span`
    font-weight: bold;
    margin: 5px 5px 0 0;
    white-space: nowrap;
  `,
}

const useTranslations = () => {
  const { ut } = useI18n()

  return {
    medium: {
      example: ut(Slugs.severityExamplesMediumExample),
      label: ut(Slugs.severityExamplesMediumLabel),
    },
    note: ut(Slugs.severityExamplesNote),
    priorToGuest: {
      example: ut(Slugs.severityExamplesPriorToNextGuestExample),
      label: ut(Slugs.severityExamplesPriorToNextGuestLabel),
    },
    title: ut(Slugs.severityExamplesTitle),
    urgent: {
      example: ut(Slugs.severityExamplesUrgentExample),
      label: ut(Slugs.severityExamplesUrgentLabel),
    },
  }
}

export const TicketSeverityExamplesModal: React.FC = React.memo(() => {
  const strings = useTranslations()

  return (
    <>
      <St.ModalTitle>{strings.title}</St.ModalTitle>
      <St.ModalContent>
        <St.Definition>
          <St.Term>{strings.urgent.label}:</St.Term>
          {strings.urgent.example}
        </St.Definition>
        <St.Definition>
          <St.Term>{strings.priorToGuest.label}:</St.Term>
          {strings.priorToGuest.example}
        </St.Definition>
        <St.Definition>
          <St.Term>{strings.medium.label}:</St.Term>
          {strings.medium.example}
        </St.Definition>
        <St.ModalFooter>{strings.note}</St.ModalFooter>
      </St.ModalContent>
    </>
  )
})
