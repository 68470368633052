import { datadogLogs } from '@datadog/browser-logs'

const envShorthand = {
  development: 'dev',
  production: 'prod',
  staging: 'stage',
}

let initialized = false

export function initializeDatadog(): void {
  if (initialized) return

  const clientToken = process.env.REACT_APP_DATADOG_CLIENT_TOKEN ?? ''

  if (clientToken) {
    const environment = process.env.REACT_APP_ENV ?? 'development'
    const env = envShorthand[environment]
    const service = 'guestworks'

    initialized = true
    datadogLogs.init({
      beforeSend: log => {
        if (
          // discard google analytics logs
          log.message.includes('https://www.google-analytics.com') ??
          // discard doubleclick.net logs
          log.message.includes('https://stats.g.doubleclick.net') ??
          // discard hotjar logs
          log.message.includes('https://vc.hotjar.io')
        ) {
          return false
        }

        return true
      },
      clientToken,
      env,
      service,
      site: 'datadoghq.com',
      version: `v${process.env.REACT_APP_RELEASE}`,
    })

    datadogLogs.setGlobalContextProperty('tags', {
      env,
      environment,
      product: 'guestworks',
      repository: 'guestworks',
      team: 'pluckier-donuts',
    })
  } else {
    // eslint-disable-next-line no-console
    console.warn(
      'Datadog could not be initialized because no client token was provided.',
    )
  }
}
