import * as React from 'react'

import { type ModalConfig, useModalContext } from '../../contexts'
import { type ModalChildrenProps } from '../../ModalPortal'
import { ConfirmModal, type ConfirmModalProps } from './ConfirmModal'

export type ConfirmModalHookProps = Omit<ConfirmModalProps, 'beginClose'> &
  Pick<ModalConfig, 'modalProps'>

export interface UseConfirmModalValue {
  showModal: () => void
}

export const useConfirmModal = ({
  extraChildren,
  modalProps,
  onConfirm,
  slugs,
}: ConfirmModalHookProps): UseConfirmModalValue => {
  const { showModal } = useModalContext()

  const showCompleteModal = React.useCallback(() => {
    showModal({
      childRenderer: (props: ModalChildrenProps) => (
        <ConfirmModal
          {...props}
          extraChildren={extraChildren}
          onConfirm={onConfirm}
          slugs={slugs}
        />
      ),
      modalProps,
    })
  }, [extraChildren, modalProps, onConfirm, showModal, slugs])

  return {
    showModal: showCompleteModal,
  }
}
