import { MissingDataError } from './errors'

// according to TS compiler, "any" type is required for "instanceof" checks
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function includesError(error: Error, errors: any[]): boolean {
  return !!errors.find(e => error instanceof e)
}

export function applyErrorSuppression<T>(
  func: () => T,
  errors: any[], // eslint-disable-line
  defaultValue?: T,
): T | undefined {
  try {
    return func()
  } catch (err) {
    if (includesError(err, errors)) return defaultValue
    throw err
  }
}

export function suppressMissingDataError<T>(func: () => T): T | undefined {
  return applyErrorSuppression<T>(func, [MissingDataError])
}
