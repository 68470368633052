import { pipe, reduce, values } from 'lodash/fp'
import { createSelector } from 'reselect'

import { MissingDataError } from 'packages/utils/errors'
import { findAllRelationshipIds } from 'packages/utils/store'
import { logInfo } from 'packages/wiretap/logging'

import { type ApplicationState } from '../../store'
import { type Clean, type RawClean } from '../cleans.types'
import { hydrateRawClean } from '../cleans.utils'

function captureErrorOnDatadog(err: Error, rawClean: RawClean) {
  logInfo('Cleans Selector Error: Missing Data', {
    selectorData: {
      assignmentIds: findAllRelationshipIds(rawClean, 'activeAssignments'),
      cleanId: rawClean.id,
      msg: err.message,
      name: 'getCleans',
    },
  })
}

const getFullState = (state: ApplicationState): ApplicationState => state

export const getCleans = createSelector(getFullState, (state): Clean[] => {
  const hydrator = hydrateRawClean(state)

  // find cleans in store, but silently ignore ones without local assignments
  const getValidClean = (acc: Clean[], rawClean: RawClean) => {
    try {
      const clean = hydrator(rawClean)
      acc.push(clean)
    } catch (err) {
      if (err instanceof MissingDataError) {
        captureErrorOnDatadog(err, rawClean)
      } else {
        throw err
      }
    }

    return acc
  }

  return pipe(values, reduce(getValidClean, []))(state.cleans.data)
})
