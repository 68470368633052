export const ERR_NO_VALID_TOKEN = 'No valid token found'
export const ERR_TOO_MANY_RETRIES = 'Too many re-authentication attempts'

export const AUTH_RETRY_TIMEOUT = 1000
export const AUTH_MAX_RETRIES = 15

export interface WaitForAuthRefreshArgs {
  getAuthToken: (state) => string | undefined
  getNeedsSilentRefresh: (state) => boolean
  // Redux state, but we can't type it properly because it needs to apply to both apps
  getState: () => Record<string, unknown>
}

export const waitForAuthRefresh = async ({
  getAuthToken,
  getNeedsSilentRefresh,
  getState,
}: WaitForAuthRefreshArgs): Promise<string> =>
  await new Promise((resolve, reject) => {
    const prevToken = getAuthToken(getState())
    let attempts = 0

    const tryAuthRefresh = () => {
      const stillAwaitingAuth = getNeedsSilentRefresh(getState())
      if (stillAwaitingAuth) {
        attempts += 1
        if (attempts > AUTH_MAX_RETRIES) {
          reject(ERR_TOO_MANY_RETRIES)
        } else {
          setTimeout(tryAuthRefresh, AUTH_RETRY_TIMEOUT)
        }
      } else {
        const token = getAuthToken(getState())
        if (token && token !== prevToken) {
          resolve(token)
        } else {
          reject(ERR_NO_VALID_TOKEN)
        }
      }
    }

    tryAuthRefresh()
  })
