import styled from '@emotion/styled'
import React from 'react'

import { colors } from 'packages/styles'

import { type UpcomingPreviousType } from 'app/components/schedule'
import { Slugs, useI18n } from 'app/i18n'
import { gwText } from 'app/styles'

interface SwitchProps {
  isLoading: boolean
  selected: 'upcoming' | 'previous'
}

type ContainerProps = Pick<SwitchProps, 'isLoading'>

const BaseSwitch = styled.div<SwitchProps>`
  ${gwText.headingXsCaps};
  padding: 8px;
  pointer-events: ${({ isLoading }) => (isLoading ? 'none' : 'auto')};
  text-align: center;
  width: 50%;
`

const St = {
  Container: styled.div<ContainerProps>`
    background-color: ${colors.midnight4};
    border-radius: 100px;
    cursor: ${({ isLoading }) => (isLoading ? 'default' : 'pointer')};
    display: flex;
    justify-content: space-between;
  `,
  Previous: styled(BaseSwitch)`
    background-color: ${({ selected }) =>
      selected === 'previous' ? `${colors.midnight10}` : 'inherit'};
    border-radius: ${({ selected }) =>
      selected ? '100px' : '0 100px 100px 0'};
    color: ${({ selected }) =>
      selected === 'previous' ? `${colors.midnight}` : `${colors.midnight70}`};
  `,
  Upcoming: styled(BaseSwitch)`
    background-color: ${({ selected }) =>
      selected === 'upcoming' ? `${colors.midnight10}` : 'inherit'};
    border-radius: ${({ selected }) =>
      selected ? '100px' : '100px 0 0 100px'};
    color: ${({ selected }) =>
      selected === 'upcoming' ? `${colors.midnight}` : `${colors.midnight70}`};
  `,
}

export enum UpcomingPreviousSwitchTestIds {
  container = 'UpcomingPreviousSwitch__container',
  previous = 'UpcomingPreviousSwitch__previous',
  upcoming = 'UpcomingPreviousSwitch__upcoming',
}

export interface UpcomingPreviousSwitchProps {
  isLoading?: boolean
  onSelectedChange: (selected: UpcomingPreviousType) => void
  selected: UpcomingPreviousType
}

export const UpcomingPreviousSwitch: React.FC<UpcomingPreviousSwitchProps> =
  React.memo(({ isLoading = false, onSelectedChange, selected }) => {
    const { t } = useI18n()

    const handleClick = (newSelected: UpcomingPreviousType) => {
      if (newSelected === selected) return
      onSelectedChange(newSelected)
    }

    return (
      <St.Container
        data-testid={UpcomingPreviousSwitchTestIds.container}
        isLoading={isLoading}
      >
        <St.Upcoming
          data-testid={UpcomingPreviousSwitchTestIds.upcoming}
          isLoading={isLoading}
          onClick={() => {
            handleClick('upcoming')
          }}
          selected={selected}
        >
          {t(Slugs.upcoming)}
        </St.Upcoming>
        <St.Previous
          data-testid={UpcomingPreviousSwitchTestIds.previous}
          isLoading={isLoading}
          onClick={() => {
            handleClick('previous')
          }}
          selected={selected}
        >
          {t(Slugs.previous)}
        </St.Previous>
      </St.Container>
    )
  })
