import React from 'react'
import { useNavigate } from 'react-router-dom'

import { type Clean } from 'app/store/cleans'

import { NewCleanCard } from './NewCleanCard'

export interface NewCleanCardContainerProps {
  clean: Clean
}

export const NewCleanCardContainer: React.FC<NewCleanCardContainerProps> =
  React.memo(({ clean }) => {
    const navigate = useNavigate()

    const handleCleanClick = React.useCallback(
      (cleanId: string): void => {
        navigate(`/cleans?selectedClean=${cleanId}`)
      },
      [navigate],
    )

    return <NewCleanCard clean={clean} onClick={handleCleanClick} />
  })
