import React from 'react'
import { useDispatch } from 'react-redux'

import { useConfirmModal } from 'packages/common'
import { useAsyncFnWithReset } from 'packages/utils/hooks'

import { useGwToast } from 'app/components/core/hooks'
import { Slugs, useI18n } from 'app/i18n'
import type { GwLocalHost, GwLocalHostPatchData } from 'app/store/gwLocalhosts'
import {
  deleteGwLocalHost,
  updateGwLocalHost,
} from 'app/store/gwLocalhosts/actions'

import { GwLocalHostRow } from './GwLocalHostRow'
import { type UnknownAction } from '@reduxjs/toolkit'

const useTranslations = () => {
  const { t } = useI18n()
  return {
    deleteError: t(Slugs.gwLocalHostDeleteError),
    deleteModalBody: t(Slugs.gwLocalHostDeleteModalBody),
    deleteModalTitle: t(Slugs.gwLocalHostDeleteModalTitle),
    deleteSuccess: t(Slugs.gwLocalHostDeleteSuccess),
    updateError: t(Slugs.gwLocalHostUpdateError),
    updateSuccess: t(Slugs.gwLocalHostUpdateSuccess),
  }
}

export interface GwLocalHostRowContainerProps {
  editing: boolean
  gwLocalHost: GwLocalHost
}

export const GwLocalHostRowContainer: React.FC<GwLocalHostRowContainerProps> =
  React.memo(({ editing, gwLocalHost }) => {
    const strings = useTranslations()
    const dispatch = useDispatch()
    const { showGwToast } = useGwToast()

    const [updateGwLocalHostState, updateGwLocalHostFn] = useAsyncFnWithReset(
      async (patchData: GwLocalHostPatchData) =>
        dispatch(
          updateGwLocalHost(patchData, {
            onError: () => {
              showGwToast({
                message: strings.updateError,
                toastType: 'danger',
              })
            },
            onSuccess: () => {
              showGwToast({
                message: strings.updateSuccess,
              })
            },
          }) as unknown as UnknownAction,
        ),

      [dispatch, showGwToast, strings.updateError, strings.updateSuccess],
    )

    const [deleteGwLocalHostState, deleteGwLocalHostFn] = useAsyncFnWithReset(
      async () =>
        dispatch(
          deleteGwLocalHost(gwLocalHost.id, {
            onError: () => {
              showGwToast({
                message: strings.deleteError,
                toastType: 'danger',
              })
            },
            onSuccess: () => {
              showGwToast({
                message: strings.deleteSuccess,
              })
            },
          }) as unknown as UnknownAction,
        ),

      [
        dispatch,
        gwLocalHost.id,
        showGwToast,
        strings.deleteError,
        strings.deleteSuccess,
      ],
    )

    const { showModal: showDeleteModal } = useConfirmModal({
      onConfirm: deleteGwLocalHostFn,
      slugs: {
        message: strings.deleteModalBody,
        title: strings.deleteModalTitle,
      },
    })

    const handleToggle = React.useCallback(
      (isActive: boolean) => {
        updateGwLocalHostFn({
          id: gwLocalHost.id,
          isActive,
        })
      },
      [gwLocalHost.id, updateGwLocalHostFn],
    )

    return (
      <GwLocalHostRow
        deleteRequestState={deleteGwLocalHostState}
        editing={editing}
        gwLocalHost={gwLocalHost}
        onDeleteClick={showDeleteModal}
        onToggle={handleToggle}
        updateRequestState={updateGwLocalHostState}
      />
    )
  })
