import React from 'react'

import { Alert, type FormProps } from 'packages/common'
import { IconName } from 'packages/iconic'
import {
  addDays,
  createDateObject,
  DateFormat,
  format,
  startOfDay,
} from 'packages/utils/dateHelpers'

import { Button, DateTimeButton } from 'app/components/core'
import { useDatePickerModal } from 'app/components/core/components/DatePicker/useDatePickerModal'
import { timePickerTimesArr } from 'app/components/core/components/TimePicker'
import { useTimePickerModal } from 'app/components/core/components/TimePicker/useTimePickerModal'
import { Slugs, useI18n } from 'app/i18n'

import * as St from './LockCodeForm.styles'

const getTimeText = time =>
  timePickerTimesArr.find(timeObj => timeObj.value === time)?.key

const useTranslations = () => {
  const { t } = useI18n()

  return {
    anErrorOccurred: t(Slugs.anErrorOccurred),
    date: t(Slugs.date),
    end: t(Slugs.end),
    generateLockCode: t(Slugs.generateLockCode),
    lockCodeFormBody: t(Slugs.lockCodeFormBody),
    lockCodeGenerator: t(Slugs.lockCodeGenerator),
    start: t(Slugs.start),
    time: t(Slugs.time),
  }
}

export enum LockCodeFormTestIds {
  alert = 'LockCodeForm__alert',
  container = 'LockCodeForm__container',
  endDateButton = 'LockCodeForm__endDateButton',
  endTimeButton = 'LockCodeForm__endTimeButton',
  startDateButton = 'LockCodeForm__startDateButton',
  startTimeButton = 'LockCodeForm__startTimeButton',
  submitButton = 'LockCodeForm__submitButton',
}

export interface LockCodeFormValues {
  endDate: Date | undefined
  endTime: number | undefined
  startDate: Date | undefined
  startTime: number | undefined
}

// @ts-expect-error
export type LockCodeFormProps = FormProps<LockCodeFormValues> & {
  handlers: {
    onDateChange: (key: string) => (date: Date | undefined) => void
    onTimeChange: (key: string) => (time: number) => void
  }
}

export const LockCodeForm: React.FC<LockCodeFormProps> = React.memo(
  ({ formState, handlers, requestState }) => {
    const strings = useTranslations()
    const todaysDate = startOfDay(createDateObject())

    const { endDate, endTime, startDate, startTime } = formState.values

    // BUTTON TEXT
    const startDateButtonText = startDate
      ? format(startDate, DateFormat.ApiUtcShort)
      : strings.date
    const endDateButtonText = endDate
      ? format(endDate, DateFormat.ApiUtcShort)
      : strings.date
    const startTimeButtonText = Number.isFinite(startTime)
      ? getTimeText(startTime)
      : strings.time
    const endTimeButtonText = Number.isFinite(endTime)
      ? getTimeText(endTime)
      : strings.time

    // MODALS
    const { showDatePickerModal: showStartDatePickerModal } =
      useDatePickerModal({
        disableBeforeDate: todaysDate,
        onDateChange: handlers.onDateChange('startDate'),
        selectedDate: startDate,
      })
    const { showDatePickerModal: showEndDatePickerModal } = useDatePickerModal({
      disableAfterDate: addDays(startDate || todaysDate, 90),
      disableBeforeDate: startDate,
      onDateChange: handlers.onDateChange('endDate'),
      selectedDate: endDate,
    })

    const { showTimePickerModal: showStartTimePickerModal } =
      useTimePickerModal({
        onTimeChange: handlers.onTimeChange('startTime'),
        selectedTime: startTime,
      })
    const { showTimePickerModal: showEndTimePickerModal } = useTimePickerModal({
      onTimeChange: handlers.onTimeChange('endTime'),
      selectedTime: endTime,
    })

    const isButtonDisabled = React.useMemo(() => {
      return (
        !(
          startDate &&
          Number.isFinite(startTime) &&
          endDate &&
          Number.isFinite(endTime)
        ) || requestState.loading
      )
    }, [endDate, endTime, requestState.loading, startDate, startTime])

    return (
      <form
        data-testid={LockCodeFormTestIds.container}
        onSubmit={handlers.submit}
      >
        <St.LockCodeFormSection>
          <St.FormLabelText>
            {strings.start}
            <St.RequiredAsterisk>*</St.RequiredAsterisk>
          </St.FormLabelText>
          <St.DateTimeButtonsContainer>
            <St.ButtonSubContainer>
              <DateTimeButton
                dataTestId={LockCodeFormTestIds.startDateButton}
                onPress={showStartDatePickerModal}
                iconName={IconName.calendar}
              >
                {startDateButtonText}
              </DateTimeButton>
            </St.ButtonSubContainer>
            <St.ButtonSubContainer>
              <DateTimeButton
                dataTestId={LockCodeFormTestIds.startTimeButton}
                onPress={showStartTimePickerModal}
                iconName={IconName.clock}
              >
                {startTimeButtonText}
              </DateTimeButton>
            </St.ButtonSubContainer>
          </St.DateTimeButtonsContainer>
        </St.LockCodeFormSection>

        <St.LockCodeFormSection>
          <St.FormLabelText>
            {strings.end}
            <St.RequiredAsterisk>*</St.RequiredAsterisk>
          </St.FormLabelText>
          <St.DateTimeButtonsContainer>
            <St.ButtonSubContainer>
              <DateTimeButton
                dataTestId={LockCodeFormTestIds.endDateButton}
                onPress={showEndDatePickerModal}
                iconName={IconName.calendar}
              >
                {endDateButtonText}
              </DateTimeButton>
            </St.ButtonSubContainer>
            <St.ButtonSubContainer>
              <DateTimeButton
                dataTestId={LockCodeFormTestIds.endTimeButton}
                onPress={showEndTimePickerModal}
                iconName={IconName.clock}
              >
                {endTimeButtonText}
              </DateTimeButton>
            </St.ButtonSubContainer>
          </St.DateTimeButtonsContainer>
        </St.LockCodeFormSection>

        <St.LockCodeFormSection>
          <Button
            dataTestId={LockCodeFormTestIds.submitButton}
            type="submit"
            block={true}
            isDisabled={isButtonDisabled}
            isLoading={requestState.loading}
          >
            {strings.generateLockCode}
          </Button>
        </St.LockCodeFormSection>

        {requestState.error && (
          <Alert dataTestId={LockCodeFormTestIds.alert} alertType="danger">
            {strings.anErrorOccurred}
          </Alert>
        )}
      </form>
    )
  },
)
