import styled from '@emotion/styled'
import DOMPurify from 'dompurify'
import React from 'react'

import { colors } from 'packages/styles'

import { openLinksInNewWindow } from './hooks'

DOMPurify.addHook('afterSanitizeAttributes', openLinksInNewWindow)

export const Container = styled.div`
  a {
    color: ${colors.lake};
  }
`

interface SanitizedHTMLProps {
  className?: string
  html: string
}

export const SanitizedHTML: React.FC<SanitizedHTMLProps> = ({
  className,
  html,
}) => (
  <Container
    className={className}
    dangerouslySetInnerHTML={{
      // eslint-disable-next-line @typescript-eslint/naming-convention
      __html: DOMPurify.sanitize(html),
    }}
  />
)
