import React from 'react'
import { useDispatch } from 'react-redux'

import { useI18n } from 'packages/i18n'

import { useGwToast } from 'app/components/core/hooks'
import { Slugs } from 'app/i18n'
import {
  type Disposition,
  type SeverityAttributes,
  type TicketPostData,
} from 'app/store/tickets'
import { createTicket } from 'app/store/tickets/actions/createTicket'
import { type Unit } from 'app/store/units'
import { useActiveUser } from 'app/utils/hooks/useActiveUser'

import {
  createTicketReducer,
  type CreateTicketValuesState,
  initialCreateTicketState,
} from '../createTicketState/createTicket.reducer'
import { useMaintenanceDispositions } from '../useMaintenanceDispositions'
import { CreateTicketForm } from './CreateTicketForm'
import { type UnknownAction } from '@reduxjs/toolkit'

export const DEFAULT_DISPOSITION_DATA: Partial<Disposition> = {
  id: '656a1006-38eb-4c3e-ba65-ee13a78d8a3e',
  tier3Display: 'Others',
}

const useTranslations = () => {
  const { t, ut } = useI18n()

  return {
    additionLabelsToggle: ut(Slugs.addAdditionalDetails),
    cancel: t(Slugs.cancel),
    createTicketBody: t(Slugs.createTicketBody),
    defaultDispositionDisplayValue: t(
      Slugs.createATicketDefaultDispositionDisplayValue,
    ),
    descriptionLabel: t(Slugs.description),
    dispositionIdLabel: `${t(Slugs.whatNeedsAttention)}`,
    dispositionsFailToLoadMessage: ut(
      Slugs.createATicketDispositionErrorMessage,
    ),
    failureMessage: ut(Slugs.createTicketMessageFailure),
    headerText: `${ut(Slugs.reportMaintenanceIssue)}`,
    required: `(${t(Slugs.required)})`,
    seeExamples: `${t(Slugs.seeExamples)}`,
    severityLabel: `${t(Slugs.severity)}`,
    severityLevels: [
      {
        id: 'Medium',
        label: ut(Slugs.ticketSeverityMedium),
        note: ut(Slugs.severityMediumNote),
      },
      {
        id: 'Prior to Next Guest',
        label: ut(Slugs.ticketSeverityPriorNextGuest),
        note: ut(Slugs.severityPriorNextGuestNote),
      },
      {
        id: 'Urgent',
        label: ut(Slugs.severityUrgent),
        note: ut(Slugs.severityUrgentNote),
      },
    ] as SeverityAttributes[],
    submit: t(Slugs.submit),
    titleLabel: `${t(Slugs.title)}`,
    unit: Slugs.unit,
  }
}

export type CreateTicketFormTranslations = ReturnType<typeof useTranslations>

interface CreateTicketFormContainerProps {
  closeForm: () => void
  unit: Unit
}

export const CreateTicketFormContainer: React.FC<
  CreateTicketFormContainerProps
> = ({ closeForm, unit }) => {
  const strings = useTranslations()
  const [state, dispatch] = React.useReducer(
    createTicketReducer,
    initialCreateTicketState,
  )

  const dispositions = useMaintenanceDispositions()

  React.useEffect(() => {
    if (dispositions === 'error') {
      dispatch({
        // if we're using this default, we only need data with an id
        payload: { disposition: DEFAULT_DISPOSITION_DATA as Disposition },
        type: 'setValue',
      })
    }
  }, [dispositions])

  const onInputChange = React.useCallback(
    (section: keyof CreateTicketValuesState) => (value: string) => {
      dispatch({ payload: { [section]: value }, type: 'setValue' })
    },
    [],
  )

  const toggleDetails = React.useCallback(() => {
    dispatch({ type: 'toggleDetailsOpen' })
  }, [])

  const setError = React.useCallback((value: boolean) => {
    dispatch({ payload: value, type: 'setError' })
  }, [])

  const setIsLoading = React.useCallback((value: boolean) => {
    dispatch({ payload: value, type: 'setLoading' })
  }, [])

  const { showGwToast } = useGwToast()
  const { ut } = useI18n()

  const reduxDispatch = useDispatch()

  const { delegateUserId, user } = useActiveUser()

  const appliedUserId = delegateUserId || user?.id

  const submit = React.useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      setIsLoading(true)
      setError(false)
      try {
        const { description, disposition, severity, title } = state.values

        if (!(disposition && severity && appliedUserId)) {
          /* This should never happen due to validation in the reducer but this makes
           * TS happy and adds in an extra layer of protection */
          throw new Error('Trying to submit invalid data')
        }

        const data: TicketPostData = {
          description,
          dispositionId: disposition?.id,
          severity: severity?.id,
          title,
          unitId: unit.id,
          userId: appliedUserId,
        }

        await reduxDispatch(createTicket(data) as unknown as UnknownAction)

        showGwToast({ message: ut(Slugs.createTicketSuccessMessage) })
        closeForm()
      } catch {
        setError(true)
      } finally {
        setIsLoading(false)
      }
    },
    [
      appliedUserId,
      closeForm,
      reduxDispatch,
      setError,
      setIsLoading,
      showGwToast,
      state.values,
      unit,
      ut,
    ],
  )

  const formState = React.useMemo(() => {
    const { severity, disposition } = state.values

    const { descriptionOpen, error, loading } = state.uiState

    return {
      canSubmit: state.uiState.readyToSubmit,
      disposition,
      dispositions,
      error,
      isDetailsOpen: descriptionOpen,
      isLoading: loading,
      severity,
    }
  }, [dispositions, state])

  const formEventHandlers = React.useMemo(
    () => ({
      cancel: closeForm,
      clearError: () => {
        setError(false)
      },
      onInputChange,
      submit,
      toggleDetails,
    }),
    [closeForm, onInputChange, setError, submit, toggleDetails],
  )

  return (
    <CreateTicketForm
      eventHandlers={formEventHandlers}
      state={formState}
      strings={strings}
      unit={unit}
    />
  )
}
