import React from 'react'
import { useLocation } from 'react-router-dom'

import { IconName } from 'packages/iconic'
import { splitSearchQuery } from 'packages/utils/misc'

import { Slugs, useI18n } from 'app/i18n'
import { type Ticket } from 'app/store/tickets'
import type { Unit } from 'app/store/units'

import { Header, LastFetchBanner } from '../../core'
import { CreateTicketDrawerButton } from '../../schedule/components/CleanDetail/CreateTicket/CreateTicketDrawer'
import { TicketCard } from '../TicketCard'
import { TicketDetailDrawerContainer as TicketDetailDrawer } from '../TicketDetail/TicketDetailDrawer'
import * as St from './TicketsPage.styles'

export interface TicketsPageProps {
  lastFetch: number
  onRefetch: () => void
  tickets: Ticket[]
  unit?: Unit
}

export const TicketsPage: React.FC<TicketsPageProps> = React.memo(
  ({ lastFetch, onRefetch, tickets, unit }) => {
    const { ut } = useI18n()
    const location = useLocation()
    const { selectedTicket = '' } = splitSearchQuery(location.search)

    return (
      <section>
        <Header title={'myTickets'} />
        <St.TicketsListControls>
          <LastFetchBanner lastFetch={lastFetch} onRefetch={onRefetch} />
          <CreateTicketDrawerButton unit={unit} />
        </St.TicketsListControls>

        <St.TicketsList>
          {tickets.length === 0 && (
            <St.NoResults>
              <St.NoResultsIcon icon={IconName.tag} size={82} />
              {ut(Slugs.noAssignedTickets)}
            </St.NoResults>
          )}

          {tickets.map(ticket => (
            <TicketCard key={ticket.id} ticket={ticket} />
          ))}

          <TicketDetailDrawer ticketId={selectedTicket} />
        </St.TicketsList>
      </section>
    )
  },
)
