import { createSelector } from 'reselect'

import { isWithinFreezeWindow } from 'packages/grimoire'

import { type ApplicationState } from '../../store'
import { type Clean } from '../cleans.types'
import { getCleans } from './getCleans'

const cleanIsFrozen = (clean: Clean) =>
  isWithinFreezeWindow(clean.effectiveDate)

const getCleansData = (state: ApplicationState) => getCleans(state)

export const getFrozenCleans = createSelector(
  getCleansData,
  (cleans): Clean[] => {
    return cleans.filter(cleanIsFrozen)
  },
)
