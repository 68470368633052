import { type AxiosResponse } from 'axios'
import { snakeCase } from 'lodash/fp'

import {
  type AllTicketAttributes,
  AllTicketAttributeNames,
  TicketStatus,
} from 'packages/grimoire/src/ticket/ticket.types'
import {
  type JSONApiObject,
  type JSONApiObjectMap,
  type JSONApiObjectWithRelationships,
  type JSONApiObjectWithRelationshipsMap,
  type NormalizedJSONApiResponse,
  type ToOneRelationship,
} from 'packages/grimoire/src/utils'

import { type JSONApiLockboxesMap, type Lockbox } from 'app/store/lockboxes'
import { type JSONApiUnitsMap, type Unit } from 'app/store/units'

export { TicketSeverity } from 'packages/grimoire/src/ticket/ticket.types'
export { TicketStatus }

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export enum TicketsActionTypes {
  CREATE_TICKET = 'TICKETS/API/CREATE_TICKET',
  CREATE_TICKET_FAILURE = 'TICKETS/CREATE_TICKET_FAILURE',
  CREATE_TICKET_SUCCESS = 'TICKETS/CREATE_TICKET_SUCCESS',

  FETCH_ALL_DISPOSITIONS = 'TICKETS/API/FETCH_ALL_DISPOSITIONS',
  FETCH_ALL_DISPOSITIONS_FAILURE = 'TICKETS/FETCH_ALL_DISPOSITIONS_FAILURE',
  FETCH_ALL_DISPOSITIONS_SUCCESS = 'TICKETS/FETCH_ALL_DISPOSITIONS_SUCCESS',

  FETCH_TICKET = 'TICKETS/API/FETCH_TICKET',
  FETCH_TICKETS = 'TICKETS/API/FETCH_TICKETS',
  FETCH_TICKETS_FAILURE = 'TICKETS/FETCH_TICKETS_FAILURE',

  FETCH_TICKETS_SUCCESS = 'TICKETS/FETCH_TICKETS_SUCCESS',
  FETCH_TICKET_FAILURE = 'TICKETS/FETCH_TICKET_FAILURE',
  FETCH_TICKET_SUCCESS = 'TICKETS/FETCH_TICKET_SUCCESS',

  UPDATE_TICKET = 'TICKETS/API/UPDATE_TICKET',
  UPDATE_TICKET_FAILURE = 'TICKETS/UPDATE_TICKET_FAILURE',
  UPDATE_TICKET_SUCCESS = 'TICKETS/UPDATE_TICKET_SUCCESS',
}

export interface TicketsState {
  data: JSONApiTicketsMap
  dispositions: JSONApiDispositionMap
  lastFetch: number
  loading: boolean
}

/**********************************************************
 * SERVICE TYPES
 *********************************************************/
export interface TicketsResponse {
  lockBox: JSONApiLockboxesMap
  ticket: JSONApiTicketsMap
  unit: JSONApiUnitsMap
}

export interface TicketPatchAttributes {
  status: TicketStatus
}

export type TicketPatchData = {
  id: string
} & Partial<TicketPatchAttributes>

export type NormalizedTicketsApiResponse =
  NormalizedJSONApiResponse<TicketsResponse>

export type TicketsServiceResponse = Promise<NormalizedTicketsApiResponse>
export type TicketsApiResponse = AxiosResponse<NormalizedTicketsApiResponse>

/**********************************************************
 * DISPOSITIONS
 *********************************************************/
export interface DispositionAttributes {
  tier3Display: string
  tierOne: string
  tierThree: string
  tierTwo: string
}

export const DispositionAttributeNames = [
  'tier3Display',
  'tierOne',
  'tierThree',
  'tierTwo',
]

export const DispositionApiFields = DispositionAttributeNames.map(snakeCase)

export interface DispositionResponse {
  disposition: JSONApiObjectMap<DispositionAttributes>
}

export type NormalizedDispositionResponse =
  NormalizedJSONApiResponse<DispositionResponse>

export type DispositionServiceResponse = Promise<NormalizedDispositionResponse>
export type DispositionApiResponse =
  AxiosResponse<NormalizedDispositionResponse>

export type NormalizedDispositionApiResponse =
  NormalizedJSONApiResponse<DispositionResponse>

export type JSONApiDispositionMap = JSONApiObjectMap<DispositionAttributes>

export type RawDisposition = JSONApiObject<DispositionAttributes>

export type Disposition = {
  id: string
} & DispositionAttributes

/**********************************************************
 * TICKET
 *********************************************************/
export type Ticket = {
  id: string
  lockbox: Lockbox
  unit: Unit
} & TicketAttributes

export type TicketAttributes = AllTicketAttributes
export const TicketAttributeNames = AllTicketAttributeNames

export const TicketApiFields: string[] = TicketAttributeNames.map(snakeCase)

export interface TicketRelationships {
  lockBox: ToOneRelationship
  unit: ToOneRelationship
}

export type JSONApiTicketsMap = JSONApiObjectWithRelationshipsMap<
  TicketAttributes,
  TicketRelationships
>

export type RawTicket = JSONApiObjectWithRelationships<
  TicketAttributes,
  TicketRelationships
>
export const severityList = ['Urgent', 'Prior to Next Guest', 'Medium'] as const

export type SeverityType = (typeof severityList)[number]

export interface SeverityAttributes {
  id: SeverityType
  label: string
  note?: string
}

export interface TicketPostData {
  description?: string
  dispositionId: string
  severity: SeverityType
  title: string
  unitId: string
  userId: string
}

export type TicketPostAPIAttributes = Pick<
  TicketPostData,
  'description' | 'title' | 'severity'
>

export interface TicketPostAPIRelationships {
  assignee?: ToOneRelationship
  creator?: ToOneRelationship
  disposition: ToOneRelationship
  unit: ToOneRelationship
}
