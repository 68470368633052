import React from 'react'

import { type AriaFormProps } from 'packages/common'

import { Button, InputField } from 'app/components/core'
import { Slugs, useI18n } from 'app/i18n'

import { St } from './AccessForm.styles'

export enum AccessFormTestIds {
  container = 'AccessForm__container',
}

export interface AccessFormValues {
  email: string
  firstName: string
  lastName: string
}

// @ts-expect-error
export type AccessFormProps = AriaFormProps<AccessFormValues>

const useTranslations = () => {
  const { t } = useI18n()

  return {
    accessEmailDescription: t(Slugs.accessEmailDescription),
    cancel: t(Slugs.cancel),
    emailAddress: t(Slugs.emailAddress),
    firstName: t(Slugs.firstName),
    lastName: t(Slugs.lastName),
    send: t(Slugs.send),
  }
}

export const AccessForm: React.FC<AccessFormProps> = React.memo(
  ({ formState, handlers, requestState }) => {
    const strings = useTranslations()

    return (
      <St.Form
        data-testid={AccessFormTestIds.container}
        onSubmit={handlers.submit}
      >
        <InputField
          errorMessage={formState.errors?.firstName}
          id="firstName"
          isClearable={true}
          placeholder={strings.firstName}
          label={strings.firstName}
          name="firstName"
          onChange={handlers.setValueByKey('firstName')}
          value={formState.values.firstName}
        />

        <St.Section>
          <InputField
            errorMessage={formState.errors?.lastName}
            id="lastName"
            isClearable={true}
            placeholder={strings.lastName}
            label={strings.lastName}
            name="lastName"
            onChange={handlers.setValueByKey('lastName')}
            value={formState.values.lastName}
          />
        </St.Section>

        <St.Section>
          <InputField
            description={strings.accessEmailDescription}
            errorMessage={formState.errors?.email}
            id="email"
            isClearable={true}
            placeholder={'Email Address'}
            label={strings.emailAddress}
            name="email"
            onChange={handlers.setValueByKey('email')}
            value={formState.values.email}
          />
        </St.Section>

        <St.ButtonContainer>
          <Button
            buttonSize={'small'}
            buttonType={'text'}
            onPress={handlers.cancel}
            type={'button'}
          >
            {strings.cancel}
          </Button>
          <Button
            buttonSize={'small'}
            buttonType={'primary'}
            isLoading={requestState.loading}
            isDisabled={!formState.canSubmit || !!requestState.loading}
            type="submit"
          >
            {strings.send}
          </Button>
        </St.ButtonContainer>
      </St.Form>
    )
  },
)
