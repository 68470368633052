import { produce } from 'immer'
import { type ActionType, getType } from 'typesafe-actions'

import {
  getIdAndDataTypeFromAction,
  type JSONApiObjectWithRelationships,
} from 'packages/utils/store'

import { getLastFetchTimestamp } from 'app/utils/cacheHelpers'

import {
  fetchTicketsAction,
  fetchTicketByIdAction,
  fetchTicketingMaintenanceDispositionsAction,
  updateTicketAction,
} from './actions'
import {
  type RawTicket,
  type TicketAttributes,
  type TicketRelationships,
  type TicketsState,
} from './tickets.types'

const actions = {
  fetchTicketByIdAction,
  fetchTicketingMaintenanceDispositionsAction,
  fetchTicketsAction,
  updateTicketAction,
}

type TicketsActionsTypes = ActionType<typeof actions>

export const initialState: TicketsState = {
  data: {},
  dispositions: {},
  lastFetch: 0,
  loading: false,
}

export const ticketsReducer = (
  state = initialState,
  action: TicketsActionsTypes,
): TicketsState =>
  produce(state, (draft: TicketsState) => {
    switch (action.type) {
      case getType(fetchTicketsAction.request): {
        draft.loading = true
        return
      }

      case getType(fetchTicketsAction.success): {
        draft.data = action.payload.normalized?.ticket || {}
        draft.lastFetch = getLastFetchTimestamp('tickets')
        draft.loading = false
        return
      }

      case getType(fetchTicketByIdAction.success): {
        const [id, ticket] = getIdAndDataTypeFromAction<RawTicket>(
          action,
          'ticket',
        )

        draft.data[id] = ticket as JSONApiObjectWithRelationships<
          TicketAttributes,
          TicketRelationships
        >

        return
      }

      case getType(fetchTicketingMaintenanceDispositionsAction.success): {
        draft.dispositions = action.payload.normalized?.disposition || {}
        draft.loading = false
        return
      }

      case getType(updateTicketAction.success): {
        const data = action.payload.normalized?.ticket || {}
        const { attributes, id } = Object.values(data)[0] || {}

        if (!id || !draft.data[id]) return

        draft.data[id].attributes = {
          ...draft.data[id].attributes,
          ...attributes,
        }

        return
      }

      case getType(fetchTicketsAction.failure): {
        draft.data = initialState.data
        draft.loading = false
      }
    }
  })
