/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { useMountedState } from 'react-use'

type PromiseType<P extends Promise<any>> =
  P extends Promise<infer T> ? T : never

type FunctionReturningPromise = (...args: any[]) => Promise<any>

export interface AsyncState<T> {
  error?: Error | string
  loading: boolean
  value?: T
}

type StateFromFunctionReturningPromise<T extends FunctionReturningPromise> =
  AsyncState<PromiseType<ReturnType<T>>>

// CHANGE: Return type updates
// - [1] fixes the return type based on TS error
// - [2] adds the type for the "reset" callback
type AsyncFnReturn<
  T extends FunctionReturningPromise = FunctionReturningPromise,
> = [
  StateFromFunctionReturningPromise<T>,
  (...args: Parameters<T>) => ReturnType<T>,
  () => void,
]

const DEFAULT_INITIAL_STATE = { loading: false }

// This hook is an extension of the `useAsyncFn` hook from react-hook.
// The original hook had a few limitations that made it difficult to work for our use cases,
// so this version just adds a few minor tweaks. Most notably:
// - A 'reset' callback to clear the internal state; the original hook does not have any way to clear
//      its state, so e.g. the 'error' state will generally persist longer than is necessary and useful.
//
// The original hook can be found here. This is mostly a copy/paste of the original,
// with any changes marked with a comment beginning with "CHANGE":
// https://github.com/streamich/react-use/blob/master/src/useAsyncFn.ts
export function useAsyncFnWithReset<T extends FunctionReturningPromise>(
  fn: T,
  deps: unknown[],
  initialState: StateFromFunctionReturningPromise<T> = DEFAULT_INITIAL_STATE,
): AsyncFnReturn<T> {
  const lastCallId = React.useRef(0)
  const isMounted = useMountedState()

  const [state, setState] =
    React.useState<StateFromFunctionReturningPromise<T>>(initialState)

  // CHANGE: expose a callback to clear internal state
  const reset = React.useCallback(() => {
    setState(initialState)
  }, [initialState])

  const callback = React.useCallback(
    (...args: Parameters<T>): ReturnType<T> => {
      const callId = ++lastCallId.current // eslint-disable-line no-plusplus

      if (!state.loading) {
        // CHANGE: clear any previous errors when sending a request
        setState(prev => ({ ...prev, error: undefined, loading: true }))
      }

      return fn(...args).then(
        value => {
          isMounted() &&
            callId === lastCallId.current &&
            setState({ loading: false, value })

          return value
        },
        error => {
          isMounted() &&
            callId === lastCallId.current &&
            setState({ error, loading: false })

          return error
        },
      ) as ReturnType<T>
    },
    deps, // eslint-disable-line react-hooks/exhaustive-deps
  )

  return [state, callback, reset]
}
