import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

import { gwColors, gwText } from 'app/styles'

import { CleanHeader } from '../CleanHeader'
import { CreateTicketDrawer } from './CreateTicket/CreateTicketDrawer'

// ------------------------------------------------
// Main container/wrapper styles
// ------------------------------------------------
export const CleanDetailContainer = styled.div`
  background: white;
  font-size: 14px;
  overflow-y: auto;
  padding-top: 16px;
  padding-bottom: 64px;
  position: relative;
`

export const CleanDetailMainSection = styled.section`
  padding: 16px 24px;

  section + & {
    border-top: 1px solid ${gwColors.uiDivider};
  }
`

// ------------------------------------------------
// Sections/styles for the lower "details" items
// ------------------------------------------------
export const CleanDetailSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
`

export const CleanDetailHeader = styled(CleanHeader)`
  padding: 0 16px 0 24px;

  /* remove right pad when owner badge is present */
  padding-right: ${({ clean }) => (clean.isNextStayOwner ? 0 : '16px')};
`

export const CleanDetailIcon = styled(SvgIcon)`
  padding-top: 6px;
`

export const CleanDetailBody = styled.div`
  padding-left: 16px;
  width: 100%;
`

export const CleanDetailSubheader = styled.span`
  font-weight: 600;
`

const sectionBorder = css`
  border-top: 1px solid ${colors.midnight20};
  margin: 0 -24px;
  padding: 24px;
  padding-bottom: 0;
`

export const CleanDetailCreateTicketDrawer = styled(CreateTicketDrawer)`
  ${sectionBorder};
`

export const Divider = styled.hr`
  background-color: ${colors.midnight10};
  border: 0;
  height: 8px;
  margin-left: -16px;
  margin-right: -16px;
  margin-top: 24px;
  margin-bottom: 24px;
`

export const HkNote = styled.p`
  &:last-of-type {
    margin-bottom: 0;
  }
`

export const PrivacyNoticeSection = styled.div`
  ${sectionBorder};
`

export const EcdButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;

  button {
    ${gwText.bodySmall};
    color: ${colors.dusk};
    padding: 0;

    &:focus {
      background-color: inherit;
    }
  }
`

export const LockCodeBody = styled.p`
  margin: 8px 0 8px 0;
`
