import { produce } from 'immer'
import { type ActionType, getType } from 'typesafe-actions'

import { setAppInitStateAction } from './actions'
import { type AppGlobalState } from './app.types'

export const initialState: AppGlobalState = {
  initState: 'initial',
}

const actions = {
  setAppInitStateAction,
}

export const appReducer = (
  state = initialState,
  action: ActionType<typeof actions>,
): AppGlobalState =>
  produce(state, (draft: AppGlobalState) => {
    switch (action.type) {
      case getType(setAppInitStateAction): {
        draft.initState = action.payload
      }
    }
  })
