/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { logInfo } from 'packages/wiretap/logging'

import { waitForAuthRefresh } from './waitForAuthRefresh'

const ERROR_NEEDS_FULL_AUTH = 'Full authentication redirect required.'
const ERROR_REQUEST_FAILED = 'Api request failed'

export interface IdpAuthMiddlewareConfig {
  getAccessToken: (state) => string | undefined
  getNeedsSilentRefresh: (state) => boolean
  setNeedsFullAuthRedirect: (needsFullRedirect: boolean) => void
  setNeedsSilentRefresh: (needsSilentRefresh: boolean) => void
  setRequestHeader: (name: string, value: string) => void
}

/**
 * Redux middleware for dealing with many aspects of the "Auth Code w/ PKCE" auth flow.
 *
 * Important note: any action that does not contain "/API/" in its name will be assumed
 * to be a non-API action, and will be completely ignored by this middleware.
 *
 * Otherwise, this middleware will serve the following functions for API actions:
 *   - Insert the current auth token as an "Authorization" header
 *   - Watch for 401 errors, and trigger a silent auth refresh when they occur
 *       - In this case, it will wait for the silent refresh to complete, and then
 *          automatically re-trigger the original action, completely transparently to the user
 *   - Watch for 403 errors, or failed attempts at silent refreshes
 *       - In this case, it will trigger a full redirect to the auth/login page
 *
 * @param config
 */
export const getIdpAuthMiddleware =
  ({
    getAccessToken,
    getNeedsSilentRefresh,
    setRequestHeader,
    setNeedsFullAuthRedirect,
    setNeedsSilentRefresh,
  }: IdpAuthMiddlewareConfig) =>
  ({ dispatch, getState }) =>
  next =>
  async action => {
    const isApiRequest = action?.type?.includes('/API/')
    if (!isApiRequest) {
      return next(action)
    }

    try {
      next(action)

      const accessToken = getAccessToken(getState())
      setRequestHeader('Authorization', `Bearer ${accessToken}`)

      const { request } = action.payload
      return await request()
    } catch (err) {
      const status = err?.response?.status

      // 401 means our JWT has expired, so we will attempt a silent refresh
      if (status === 401) {
        dispatch(setNeedsSilentRefresh(true))
        try {
          await waitForAuthRefresh({
            getAuthToken: getAccessToken,
            getNeedsSilentRefresh,
            getState,
          })

          return dispatch(action)
        } catch {
          dispatch(setNeedsFullAuthRedirect(true))
          // this error will be suppressed from going to DataDog, but we need to throw it
          // so Redux does not attempt to dispatch any further actions while the redirect is happening
          throw new Error(ERROR_NEEDS_FULL_AUTH)
        }
      }

      // 403 means our session has completely expired, and we need to fully re-authenticate
      if (status === 403) {
        dispatch(setNeedsFullAuthRedirect(true))
        // this error will be suppressed from going to DataDog, but we need to throw it
        // so Redux does not attempt to dispatch any further actions while the redirect is happening
        throw new Error(ERROR_NEEDS_FULL_AUTH)
      }

      if (!status) {
        // if we reach this point, we probably don't know what the error is,
        // so we can just re-throw it and let it get reported to DataDog
        logInfo(err.message, {
          boundary: 'idp-middleware',
          severity: 'warning',
        })

        err.message = ERROR_REQUEST_FAILED
      }

      throw err
    }
  }
