import styled from '@emotion/styled'
import React from 'react'

import { text } from 'packages/styles'
import { DateFormat, formatLocalized } from 'packages/utils/dateHelpers'

import { Slugs, useI18n } from 'app/i18n'
import type { Reservation } from 'app/store/reservations'
import { gwColors, gwText } from 'app/styles'

const St = {
  Cell: styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;

    &:not(:first-of-type) {
      border-left: 1px solid ${gwColors.uiDivider};
      padding-left: 16px;
    }
  `,
  ResDateInfo: styled.div`
    border-bottom: 1px solid ${gwColors.uiDivider};
    border-top: 1px solid ${gwColors.uiDivider};
    display: flex;
    flex-direction: row;
    padding: 16px 0;
    margin: 16px 0;
  `,
  SectionTitle: styled.div`
    ${gwText.bodySmallBold};
  `,
  Text: styled.p`
    ${text.bodyRegularSmall};
    margin: 0;
  `,
}

const useTranslations = () => {
  const { t } = useI18n()

  return {
    checkIn: t(Slugs.checkIn),
    checkOut: t(Slugs.checkOut),
  }
}

export interface ResDateInfoProps {
  res: Reservation
}

export const ResDateInfo: React.FC<ResDateInfoProps> = React.memo(({ res }) => {
  const strings = useTranslations()
  const checkInText = formatLocalized(res.checkIn, DateFormat.Full)
  const checkOutText = formatLocalized(res.checkOut, DateFormat.Full)

  return (
    <St.ResDateInfo>
      <St.Cell>
        <St.SectionTitle>{strings.checkIn}</St.SectionTitle>
        <St.Text>{checkInText}</St.Text>
      </St.Cell>

      <St.Cell>
        <St.SectionTitle>{strings.checkOut}</St.SectionTitle>
        <St.Text>{checkOutText}</St.Text>
      </St.Cell>
    </St.ResDateInfo>
  )
})
