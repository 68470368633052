import { noop } from 'lodash/fp'
import { createAsyncAction } from 'typesafe-actions'

import { type ReduxActionCallbacks } from 'packages/grimoire/src/utils'
import { type RequestConfig } from 'packages/utils/store'

import { gwLocalHostsService } from '../gwLocalhosts.service'
import {
  GwLocalHostsActionTypes,
  type NormalizedGwLocalHostsApiResponse,
} from '../gwLocalhosts.types'

export const deleteGwLocalHostAction = createAsyncAction(
  GwLocalHostsActionTypes.DELETE_GW_LOCAL_HOST,
  GwLocalHostsActionTypes.DELETE_GW_LOCAL_HOST_SUCCESS,
  GwLocalHostsActionTypes.DELETE_GW_LOCAL_HOST_FAILURE,
)<RequestConfig<NormalizedGwLocalHostsApiResponse>, { id: string }, Error>()

export const deleteGwLocalHost =
  (id: string, callbacks: ReduxActionCallbacks = {}) =>
  async dispatch => {
    const { onError = noop, onSuccess = noop } = callbacks
    try {
      const request = gwLocalHostsService.deleteGwLocalHost.bind(null, id)
      const result = await dispatch(
        deleteGwLocalHostAction.request({ request }),
      )

      onSuccess()
      dispatch(deleteGwLocalHostAction.success({ id }))
      return result.normalized
    } catch (error) {
      onError()
      dispatch(deleteGwLocalHostAction.failure(error))
      throw error
    }
  }
