import { createSelector } from 'reselect'

import { type ApplicationState } from '../../store'
import { type Ticket } from '../tickets.types'
import { hydrateRawTicket } from '../tickets.utils'

const getFullState = (state: ApplicationState): ApplicationState => state
const getId = (_, id: string): string => id

export const getTicketById = createSelector(
  getFullState,
  getId,
  (fullState, id): Ticket | undefined => {
    const ticket = fullState?.tickets?.data[id]

    return ticket && hydrateRawTicket(fullState)(ticket)
  },
)
