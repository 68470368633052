import React from 'react'

import type { BadgeType } from 'app/components/core'
import { Badge } from 'app/components/core'
import { Slugs, useI18n } from 'app/i18n'
import type { Clean } from 'app/store/cleans'

type StatusBadgeType = Extract<BadgeType, 'aspen' | 'yellow'>

const badgeChildrenMap: Record<StatusBadgeType, string> = {
  aspen: Slugs.bookingStatusCompleted,
  yellow: Slugs.bookingStatusNotFinished,
}

const getBadgeType = (clean: Clean): StatusBadgeType => {
  if (clean.completedAt) return 'aspen'
  return 'yellow'
}

export interface CompletionStatusBadgeProps {
  clean: Clean
}

export const CompletionStatusBadge: React.FC<CompletionStatusBadgeProps> =
  React.memo(({ clean }) => {
    const { t } = useI18n()
    const badgeType: StatusBadgeType = getBadgeType(clean)

    return <Badge badgeType={badgeType}>{t(badgeChildrenMap[badgeType])}</Badge>
  })
