import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { centerWithFlex, colors } from 'packages/styles'

import { type ButtonProps, type ButtonType } from './Button'

type PickedButtonProps = Required<
  Pick<
    ButtonProps,
    | 'block'
    | 'buttonShape'
    | 'buttonSize'
    | 'buttonType'
    | 'disabled'
    | 'isLoading'
  >
>

const buttonStylesByType: Record<ButtonType, ReturnType<typeof css>> = {
  danger: css`
    background-color: ${colors.alert};
    color: white;

    &:active,
    &:hover {
      background-color: ${colors.alert70};
    }

    &:focus {
      outline: 4px solid ${colors.alert};
      outline-offset: 2px;
    }

    &:disabled {
      background-color: ${colors.dusk60};
    }
  `,

  primary: css`
    background-color: ${colors.lake};
    color: white;

    &:active,
    &:hover {
      background-color: ${colors.lake70};
    }

    &:focus {
      outline: 4px solid ${colors.lake};
      outline-offset: 2px;
    }

    &:disabled {
      background-color: ${colors.dusk60};
    }
  `,

  secondary: css`
    background-color: white;
    border: 1px solid ${colors.lake};
    color: ${colors.lake};

    &:active,
    &:hover {
      background-color: ${colors.lake10};
    }

    &:focus {
      outline: 2px solid ${colors.lake};
    }

    &:disabled {
      // "lake" at 50% opacity; this lets us fade the colors but keep a solid BG
      border-color: rgb(0 120 171 / 50%);
      color: rgb(0 120 171 / 50%);
    }
  `,

  text: css`
    background-color: transparent;
    border: none;
    color: ${colors.lake};
    font-weight: bold;
    padding: 2px;

    &:active,
    &:hover {
      color: ${colors.midnight80};
    }

    &:focus {
      outline: 2px solid ${colors.lake};
    }

    &:disabled {
      color: ${colors.dusk60};
    }
  `,

  textInverted: css`
    background-color: transparent;
    border: none;
    color: white;
    font-weight: bold;
    padding: 2px;

    &:active,
    &:hover {
      color: rgb(255 255 255 / 80%);
    }

    &:focus {
      outline: 2px solid white;
    }

    &:disabled {
      color: rgb(255 255 255 / 60%);
    }
  `,

  utility: css`
    background-color: white;
    border: 1px solid ${colors.midnight70};
    color: ${colors.midnight};

    &:active,
    &:hover {
      background-color: ${colors.midnight10};
    }

    &:focus {
      outline: 2px solid ${colors.lake};
    }

    &:disabled {
      // "midnight" values at 50% opacity; this lets us fade the colors but keep a solid BG
      border-color: rgb(77 112 128 / 50%);
      color: rgb(0 51 73 / 50%);
    }
  `,

  utilityInverted: css`
    background-color: transparent;
    border: 1px solid white;
    color: white;

    &:active,
    &:hover {
      background-color: rgb(255 255 255 / 20%);
    }

    &:focus {
      outline: 3px solid rgb(255 255 255 / 50%);
    }

    &:disabled {
      // "white" values at 50% opacity; this lets us fade the colors but keep a solid BG
      border-color: rgb(255 255 255 / 60%);
      color: rgb(255 255 255 / 60%);
    }
  `,
}

const baseStyles = (props: PickedButtonProps) =>
  buttonStylesByType[props.buttonType || 'primary']

const cursorStyles = (props: PickedButtonProps) => {
  const disableCursor = props.disabled || props.isLoading
  return css`
    cursor: ${disableCursor ? 'default' : 'pointer'};
    pointer-events: ${disableCursor ? 'none' : 'all'};
  `
}

const shapeStyles = (props: PickedButtonProps) => {
  if (props.buttonShape === 'circle') {
    return css`
      border-radius: 50%;
      width: 40px;
    `
  }

  return css`
    border-radius: 4px;
    width: ${props.block ? '100%' : 'auto'};
  `
}

export const St = {
  Button: styled.button<PickedButtonProps>`
    border: none;
    display: inline-block;
    font-size: ${({ buttonSize }) => (buttonSize === 'small' ? 14 : 16)}px;
    font-weight: 600;
    height: ${({ buttonSize }) => (buttonSize === 'small' ? 36 : 40)}px;
    line-height: 24px;
    padding: 0 ${({ buttonSize }) => (buttonSize === 'small' ? 16 : 20)}px;
    position: relative;
    text-align: center;
    text-transform: none;
    transition: background-color 0.2s;
    vertical-align: middle;
    white-space: nowrap;

    ${baseStyles};
    ${cursorStyles};
    ${shapeStyles};

    & + button {
      margin-left: 8px;
    }
  `,

  Children: styled.span<Pick<ButtonProps, 'isLoading'>>`
    ${centerWithFlex};
    pointer-events: none;
    visibility: ${({ isLoading }) => (isLoading ? 'hidden' : 'inherit')};
  `,
}
