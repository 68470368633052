import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { useSafeGetTicketById } from 'app/components/tickets/hooks'
import { getAuthUser } from 'app/store/users/selectors'

import { TicketDetailDrawer } from './TicketDetailDrawer'

export interface TicketDetailDrawerContainerProps {
  ticketId: string | undefined
}

export const TicketDetailDrawerContainer: React.FC<
  TicketDetailDrawerContainerProps
> = ({ ticketId = '' }) => {
  const navigate = useNavigate()

  const authUser = useSelector(getAuthUser)

  const ticket = useSafeGetTicketById(ticketId)

  const afterExit = React.useCallback(() => {
    navigate('/tickets')
  }, [navigate])

  // if we have a ticket ID but no ticket is found, we are here by mistake; go back to main tickets page
  React.useEffect(() => {
    if (ticketId && !ticket) {
      navigate('/tickets')
    }
  }, [ticket, ticketId, navigate])

  return (
    <TicketDetailDrawer
      afterExit={afterExit}
      isOpen={!!ticket}
      ticket={ticket}
      userId={authUser?.id || 'unknown'}
    />
  )
}
