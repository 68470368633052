import { noop } from 'lodash/fp'
import { createAsyncAction } from 'typesafe-actions'

import { type ReduxActionCallbacks } from 'packages/grimoire/src/utils'
import {
  makeRelationship,
  type RequestConfig,
  type RequestOptions,
} from 'packages/utils/store'

import { fetchUnitById } from '../../units/actions/fetchUnitById'
import { gwUnitStaffersService } from '../gwUnitStaffers.service'
import { trackGwUnitStafferCreated } from '../gwUnitStaffers.trackers'
import {
  type GwUnitStafferPostApiAttributes,
  type GwUnitStafferPostApiRelationships,
  type GwUnitStafferPostAttributes,
  GwUnitStaffersActionTypes,
  type NormalizedGwUnitStaffersApiResponse,
} from '../gwUnitStaffers.types'

export const createGwUnitStaffersAction = createAsyncAction(
  GwUnitStaffersActionTypes.CREATE_GW_UNIT_STAFFER,
  GwUnitStaffersActionTypes.CREATE_GW_UNIT_STAFFER_SUCCESS,
  GwUnitStaffersActionTypes.CREATE_GW_UNIT_STAFFER_FAILURE,
)<
  RequestConfig<NormalizedGwUnitStaffersApiResponse>,
  NormalizedGwUnitStaffersApiResponse,
  Error
>()

export const buildRequestData = (
  postData: GwUnitStafferPostAttributes,
): RequestOptions => {
  const { email, name, phone, unitId, stafferType } = postData

  const attributes: GwUnitStafferPostApiAttributes = {
    email,
    name,
    phone,
    staffer_type: stafferType,
  }

  const relationships: GwUnitStafferPostApiRelationships = {
    unit: makeRelationship('unit', unitId),
  }

  return {
    data: {
      attributes,
      relationships,
      type: 'gw_unit_staffer',
    },
  }
}

export const createGwUnitStaffer =
  (
    postData: GwUnitStafferPostAttributes,
    callbacks: ReduxActionCallbacks = {},
  ) =>
  async (dispatch, getState) => {
    const { onError = noop, onSuccess = noop } = callbacks

    try {
      const requestData = buildRequestData(postData)
      const request = gwUnitStaffersService.createGwUnitStaffer.bind(
        null,
        requestData,
      )
      const result: NormalizedGwUnitStaffersApiResponse = await dispatch(
        createGwUnitStaffersAction.request({ request }),
      )
      dispatch(createGwUnitStaffersAction.success(result))
      trackGwUnitStafferCreated(postData, getState)

      await dispatch(fetchUnitById(postData.unitId))

      onSuccess()
      return result.normalized
    } catch (error) {
      dispatch(createGwUnitStaffersAction.failure(error))
      onError()
      throw error
    }
  }
