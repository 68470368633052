import styled from '@emotion/styled'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Loader, Switch, VersionDisplay } from 'packages/common'
import { addDays, subDays } from 'packages/utils/dateHelpers'
import { startOfToday } from 'date-fns'
import { useAsyncFnWithReset } from 'packages/utils/hooks'
import { splitSearchQuery } from 'packages/utils/misc'

import { useGwToast } from 'app/components/core/hooks'
import {
  CLEANS_TIMELINE_DATE_RANGE,
  CLEANS_TIMELINE_PREV_DAYS,
} from 'app/Guestworks.constants'
import { Slugs, useI18n } from 'app/i18n'
import { getAppInitState } from 'app/store/app/selectors'
import { fetchCleans, fetchPreviousCleans } from 'app/store/cleans/actions'
import { getLastFetch } from 'app/store/cleans/selectors'
import { getCleansBuckets } from 'app/store/cleans/selectors/getCleansBuckets'
import { getLastFetchPrevious } from 'app/store/cleans/selectors/getLastFetchPrevious'
import {
  fetchPreviousReservations,
  fetchReservations,
} from 'app/store/reservations/actions'
import { getReservationsBuckets } from 'app/store/reservations/selectors/getReservationsBuckets'
import { type ApplicationState } from 'app/store/store'
import { fetchUnits } from 'app/store/units/actions'
import { getSelectedUnit } from 'app/store/units/selectors'
import { gwColors, gwText } from 'app/styles'
import { useActiveUser } from 'app/utils/hooks'
import {
  getPreviousTimelineDateRange,
  getUpcomingTimelineDateRange,
} from 'app/utils/timelineHelpers'

import { Header } from '../../core/components'
import { UpcomingPreviousSwitch } from '../../core/components/UpcomingPreviousSwitch'
import { CleanDetailDrawerContainer } from '../components/CleanDetail'
import { CleansTimeline } from '../components/CleansTimeline'
import { ResDrawerContainer } from '../components/ResDrawer'
import { ScheduleColorKey } from '../components/ScheduleColorKey'
import { type UnknownAction } from '@reduxjs/toolkit'

const TODAY = startOfToday()

const St = {
  ScheduleColorKeyContainer: styled.div`
    margin: 0 24px;
  `,
  ShowCleansContainer: styled.div`
    display: flex;
    justify-content: space-between;
    margin: 8px 24px 0 24px;
  `,
  ShowCleansText: styled.p`
    ${gwText.headingXs};
    color: ${gwColors.black};
    margin: 0;
    text-align: center;
  `,
  ShowCleansTextContainer: styled.div`
    align-items: center;
    display: flex;
  `,
  UpcomingPreviousContainer: styled.div`
    margin: 20px 24px;
  `,
  VersionDisplay: styled(VersionDisplay)`
    padding-right: 8px;
  `,
}

export type UpcomingPreviousType = 'upcoming' | 'previous'

export const SchedulePage: React.FC = React.memo(() => {
  const { t } = useI18n()
  const dispatch = useDispatch()
  const { showGwToast } = useGwToast()
  const { user } = useActiveUser()

  const [showCleans, setShowCleans] = React.useState(true)

  const selectedUnitId = useSelector(getSelectedUnit)?.id || ''

  const [selected, setSelected] =
    React.useState<UpcomingPreviousType>('upcoming')

  const appInitState = useSelector(getAppInitState)
  const appIsLoading = !['ready', 'error'].includes(appInitState)

  const location = useLocation()
  const { selectedClean = '', selectedRes = '' } = splitSearchQuery(
    location.search,
  )

  const lastFetchTimestamp = useSelector(getLastFetch)
  const lastFetchPreviousTimestamp = useSelector(getLastFetchPrevious)

  const upcomingCleanBuckets = useSelector((state: ApplicationState) =>
    getCleansBuckets(
      state,
      TODAY,
      addDays(TODAY, CLEANS_TIMELINE_DATE_RANGE),
      selectedUnitId,
    ),
  )
  const upcomingResBuckets = useSelector((state: ApplicationState) =>
    getReservationsBuckets(
      state,
      TODAY,
      addDays(TODAY, CLEANS_TIMELINE_DATE_RANGE),
      selectedUnitId,
    ),
  )
  const previousCleanBuckets = useSelector((state: ApplicationState) =>
    getCleansBuckets(
      state,
      subDays(TODAY, CLEANS_TIMELINE_PREV_DAYS),
      subDays(TODAY, 1),
      selectedUnitId,
    ),
  )
  const previousResBuckets = useSelector((state: ApplicationState) =>
    getReservationsBuckets(
      state,
      subDays(TODAY, CLEANS_TIMELINE_PREV_DAYS),
      subDays(TODAY, 1),
      selectedUnitId,
    ),
  )

  const [fetchPreviousCleansResState, fetchPreviousCleansResFn] =
    useAsyncFnWithReset(async () => {
      const [prevStartDate, prevEndDate] = getPreviousTimelineDateRange()

      await dispatch(
        fetchPreviousReservations(
          prevStartDate,
          prevEndDate,
        ) as unknown as UnknownAction,
      )
      await dispatch(
        fetchPreviousCleans(
          prevStartDate,
          prevEndDate,
        ) as unknown as UnknownAction,
      )
    }, [dispatch])

  const handleSelectedChange = (selected: UpcomingPreviousType) => {
    setSelected(selected)
    if (
      selected === 'previous' &&
      !lastFetchPreviousTimestamp &&
      !user?.isSuperuser
    ) {
      fetchPreviousCleansResFn()
    }
  }

  const [refetchPreviousCleanResState, refetchPreviousCleanResFn] =
    useAsyncFnWithReset(async () => {
      const [prevStartDate, prevEndDate] = getPreviousTimelineDateRange()

      await dispatch(
        fetchPreviousReservations(
          prevStartDate,
          prevEndDate,
        ) as unknown as UnknownAction,
      )
      await dispatch(
        fetchPreviousCleans(
          prevStartDate,
          prevEndDate,
        ) as unknown as UnknownAction,
      )
    }, [dispatch])

  const [refetchUpcomingCleansResState, refetchUpcomingCleansResFn] =
    useAsyncFnWithReset(async () => {
      const [startDate, endDate] = getUpcomingTimelineDateRange()

      // Awaiting units before cleans fixes a race condition where the cleans can have an undefined relationship to units
      await dispatch(fetchUnits() as unknown as UnknownAction)
      await dispatch(
        fetchReservations(startDate, endDate) as unknown as UnknownAction,
      )
      return dispatch(
        fetchCleans(startDate, endDate) as unknown as UnknownAction,
      )
    }, [dispatch])

  // show an error toast for any errors from re-fetching cleans
  React.useEffect(() => {
    if (
      refetchUpcomingCleansResState.error ||
      refetchPreviousCleanResState.error
    ) {
      showGwToast({
        message: t(Slugs.anErrorOccurredLong),
        toastType: 'danger',
      })
    }
  }, [
    refetchUpcomingCleansResState.error,
    refetchPreviousCleanResState.error,
    showGwToast,
    t,
  ])

  const isPreviousLoading = fetchPreviousCleansResState.loading
  const isSelectedUpcoming = selected === 'upcoming'

  const showUpcomingPreviousCleansBucket = !showCleans
    ? {}
    : isSelectedUpcoming
      ? upcomingCleanBuckets
      : previousCleanBuckets

  return refetchUpcomingCleansResState.loading ||
    refetchPreviousCleanResState.loading ||
    appIsLoading ? (
    <Loader />
  ) : (
    <section>
      <Header title={'schedule'} />

      <St.ShowCleansContainer>
        <St.ShowCleansTextContainer>
          <St.ShowCleansText>{t(Slugs.showCleans)}</St.ShowCleansText>
        </St.ShowCleansTextContainer>
        <Switch checked={showCleans} onToggle={setShowCleans} />
      </St.ShowCleansContainer>

      <St.UpcomingPreviousContainer>
        <UpcomingPreviousSwitch
          onSelectedChange={handleSelectedChange}
          selected={selected}
        />
      </St.UpcomingPreviousContainer>

      <St.ScheduleColorKeyContainer>
        <ScheduleColorKey />
      </St.ScheduleColorKeyContainer>

      <CleansTimeline
        cleanBuckets={showUpcomingPreviousCleansBucket}
        isPreviousLoading={isPreviousLoading}
        lastFetchTimestamp={
          isSelectedUpcoming ? lastFetchTimestamp : lastFetchPreviousTimestamp
        }
        refetchCleans={
          isSelectedUpcoming
            ? refetchUpcomingCleansResFn
            : refetchPreviousCleanResFn
        }
        resBuckets={
          isSelectedUpcoming ? upcomingResBuckets : previousResBuckets
        }
        selected={selected}
      />
      <St.VersionDisplay />

      <CleanDetailDrawerContainer cleanId={selectedClean} />
      <ResDrawerContainer reservationId={selectedRes} />
    </section>
  )
})
