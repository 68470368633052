import styled from '@emotion/styled'

import { colors } from 'packages/styles'

import { gwText } from 'app/styles'

export const LockCodeFormSection = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
`

export const FormLabelText = styled.div`
  ${gwText.headingXsCaps};
  ${colors.dusk};
  margin-bottom: 8px;
`

export const RequiredAsterisk = styled.span`
  color: ${colors.alert};
`

export const DateTimeButtonsContainer = styled.div`
  display: flex;

  @media (max-width: 450px) {
    flex-direction: column;
  }
`

export const ButtonSubContainer = styled.div`
  width: 100%;

  &:not(:last-child) {
    margin-right: 10px;
  }

  @media (max-width: 450px) {
    &:not(:last-child) {
      margin: 0 0 10px 0;
    }
  }
`
