import { produce } from 'immer'
import { merge } from 'lodash/fp'
import get from 'lodash/get' // eslint-disable-line
import { type ActionType, getType } from 'typesafe-actions'

import { type JSONApiObjectWithRelationships } from 'packages/utils/store/jsonapi.types'
import { getIdAndDataTypeFromAction } from 'packages/utils/store/store.utils'

import {
  type CleansResponse,
  type RawClean,
} from 'app/store/cleans/cleans.types'
import { getLastFetchTimestamp } from 'app/utils/cacheHelpers'

import { fetchPreviousReservationsAction } from '../reservations/actions'
import {
  fetchCleansAction,
  fetchCleanByIdAction,
  fetchPreviousCleansAction,
  updateCleanAction,
} from './actions'
import {
  type CleansState,
  type CleanAttributes,
  type CleanRelationships,
} from './cleans.types'
import { emptyNormalizedCleansData } from './cleans.utils'

const DEFAULT_ERROR = new Error('Unknown Error in cleansReducer')

export const initialState: CleansState = Object.freeze({
  data: {},
  error: undefined,
  lastFetch: 0,
  lastFetchPrevious: 0,
  loading: true,
})

const actions = {
  fetchCleanByIdAction,
  fetchCleansAction,
  fetchPreviousCleansAction,
  fetchPreviousReservationsAction,
  updateCleanAction,
}

type CleansActionsTypes = ActionType<typeof actions>

export const cleansReducer = (
  state = initialState,
  action: CleansActionsTypes,
): CleansState =>
  produce(state, (draft: CleansState) => {
    switch (action.type) {
      case getType(fetchPreviousCleansAction.request):
      case getType(fetchCleansAction.request): {
        draft.error = undefined
        draft.loading = true
        return
      }

      case getType(fetchCleansAction.success): {
        const normalized: CleansResponse =
          action.payload?.normalized || emptyNormalizedCleansData

        Object.values(normalized?.clean || {}).forEach(incomingClean => {
          const existingClean = state.data[incomingClean.id] || {}
          const mergedClean = merge(existingClean, incomingClean)
          draft.data[incomingClean.id] = mergedClean
        })

        draft.error = undefined
        draft.loading = false
        draft.lastFetch = getLastFetchTimestamp('cleans')
        return
      }

      case getType(actions.fetchCleanByIdAction.success): {
        const [id, clean] = getIdAndDataTypeFromAction<RawClean>(
          action,
          'clean',
        )

        draft.data[id] = clean as JSONApiObjectWithRelationships<
          CleanAttributes,
          CleanRelationships
        >

        return
      }

      case getType(fetchPreviousCleansAction.success): {
        const normalized: CleansResponse =
          action.payload?.normalized || emptyNormalizedCleansData

        Object.values(normalized?.clean || {}).forEach(incomingClean => {
          const existingClean = state.data[incomingClean.id] || {}
          const mergedClean = merge(existingClean, incomingClean)
          draft.data[incomingClean.id] = mergedClean
        })

        draft.error = undefined
        draft.loading = false
        draft.lastFetchPrevious = getLastFetchTimestamp('previous-cleans')
        return
      }

      // ------------------------------------------------
      // Misc. failure states
      // ------------------------------------------------
      case getType(actions.fetchCleanByIdAction.failure):
      case getType(actions.fetchPreviousCleansAction.failure):
      case getType(actions.fetchCleansAction.failure):
      case getType(actions.updateCleanAction.failure): {
        draft.error = action.payload || DEFAULT_ERROR
        draft.loading = false
      }
    }
  })
