import { cleanIsMidstay } from 'packages/grimoire'
import { Slugs, type TranslatorFn } from 'packages/i18n'
import {
  MIDSTAY_CLEAN_START_TIME_STRING,
  formatLocalized,
  isSameDay,
  isSameMinute,
  isToday,
  DateFormat,
} from 'packages/utils/dateHelpers'

import { type CleanForCleanCheckOutDisplay } from './CleanCheckOutDisplay'

export const isCleanStartTimeOverridden = (
  clean: CleanForCleanCheckOutDisplay,
): boolean =>
  !!(
    clean.earliestCleanStartTime &&
    !isSameMinute(clean.reservation.checkOut, clean.earliestCleanStartTime)
  )

export const getReservationCheckOutDisplayString =
  (t: TranslatorFn) =>
  (clean: CleanForCleanCheckOutDisplay): string => {
    if (cleanIsMidstay(clean)) {
      return MIDSTAY_CLEAN_START_TIME_STRING
    }

    const resCheckOutDate = clean.reservation.checkOut

    const resCheckOutDateMatchesCleanDate = isSameDay(
      clean.effectiveDate,
      resCheckOutDate,
    )

    const resCheckOutIsToday = isToday(resCheckOutDate)

    const formatForCheckOut = (dateFormat: string) =>
      formatLocalized(resCheckOutDate, dateFormat, clean.unit.tz)

    if (resCheckOutIsToday) {
      return `${t(Slugs.today)}, ${formatForCheckOut(DateFormat.TimeWithAmPm)}`
    }

    if (resCheckOutDateMatchesCleanDate) {
      return formatForCheckOut(DateFormat.TimeWithAmPm)
    }

    return formatForCheckOut(DateFormat.Full)
  }

export const getReservationEarlyCheckOutDisplayString =
  (t: TranslatorFn) =>
  (clean: CleanForCleanCheckOutDisplay): string => {
    const earlyCheckOutTime = clean.earliestCleanStartTime

    if (!earlyCheckOutTime) return ''

    const earlyCheckOutTimeIsToday = isToday(earlyCheckOutTime)

    const { tz } = clean.unit

    const formatForCheckOut = (dateFormat: string) =>
      formatLocalized(earlyCheckOutTime, dateFormat, tz)

    if (earlyCheckOutTimeIsToday) {
      return `${t(Slugs.today)}, ${formatForCheckOut(DateFormat.TimeWithAmPm)}`
    }

    return formatForCheckOut(DateFormat.Full)
  }
