import * as React from 'react'

import {
  type AllCleanAttributes,
  type AllUnitAttributes,
  cleanIsLateCheckout,
  type Reservation,
} from 'packages/grimoire'
import { Slugs, useI18n } from 'packages/i18n'

import {
  isCleanStartTimeOverridden,
  getReservationCheckOutDisplayString,
  getReservationEarlyCheckOutDisplayString,
} from './CleanCheckOutDisplay.helpers'
import {
  ReservationCheckOutTime,
  Container,
  GuestCheckOutTime,
  EarlyText,
} from './CleanCheckOutDisplay.styles'

export type CleanForCleanCheckOutDisplay = Pick<
  AllCleanAttributes,
  'effectiveDate' | 'serviceType' | 'earliestCleanStartTime'
> & {
  reservation: Pick<Reservation, 'checkOut'>
  unit: Pick<AllUnitAttributes, 'tz'>
}

export interface CleanCheckOutDisplayProps {
  className?: string
  clean: CleanForCleanCheckOutDisplay
  dataTestId?: string
}

export const CleanCheckOutDisplay: React.FC<CleanCheckOutDisplayProps> = ({
  className,
  clean,
  dataTestId,
}) => {
  const { ut } = useI18n()

  const isLateCheckOut = cleanIsLateCheckout(clean)
  const isCheckOutOverridden = isCleanStartTimeOverridden(clean)

  const resCheckOutDateString = getReservationCheckOutDisplayString(ut)(clean)
  const earlyStateDateString =
    getReservationEarlyCheckOutDisplayString(ut)(clean)

  return (
    <Container className={className} data-testid={dataTestId}>
      <ReservationCheckOutTime
        isLateCheckOut={isLateCheckOut}
        isOverridden={isCheckOutOverridden}
      >
        {resCheckOutDateString}
      </ReservationCheckOutTime>

      {isCheckOutOverridden && (
        <GuestCheckOutTime>
          <EarlyText>{ut(Slugs.confirmed)}</EarlyText>
          <span>{earlyStateDateString}</span>
        </GuestCheckOutTime>
      )}
    </Container>
  )
}
