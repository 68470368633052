/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

export type I18nInitOptions = i18next.InitOptions

export enum I18nEventTypes {
  failedLoading = 'failedLoading',
  initialized = 'initialized',
  languageChanged = 'languageChanged',
  loaded = 'loaded',
  missingKey = 'missingKey',
}

const defaultOptions: I18nInitOptions = {
  detection: {
    lookupQueryString: 'lng',
    order: ['querystring', 'navigator'],
  } as {
    lookupQueryString: string
    order: string[]
  },
}

export default class I18n {
  i18next: i18next.i18n

  constructor() {
    this.i18next = i18next
      .createInstance()
      .use(LanguageDetector)
      .use(initReactI18next)
  }

  subscribe(event: I18nEventTypes, callback) {
    this.i18next.on(event, callback)
  }

  unsubscribe(event: I18nEventTypes, callback) {
    this.i18next.off(event, callback)
  }

  async init(options?: I18nInitOptions) {
    await this.i18next.init({ ...defaultOptions, ...options })
    return { locale: this.i18next.language }
  }
}
