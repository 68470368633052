import { noop } from 'lodash/fp'
import { createAsyncAction } from 'typesafe-actions'

import { type ReduxActionCallbacks } from 'packages/grimoire/src/utils'
import { apiDateStringWithSeconds } from 'packages/utils/dateHelpers'
import {
  type RequestConfig,
  type RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { cleansService } from '../cleans.service'
import { trackCleanCompleted } from '../cleans.trackers'
import {
  CleansActionTypes,
  type NormalizedCleansApiResponse,
  type CleanPatchData,
  type CleanPatchApiAttributes,
} from '../cleans.types'
import { fetchCleanById } from './fetchCleanById'

export const updateCleanAction = createAsyncAction(
  CleansActionTypes.UPDATE_CLEAN,
  CleansActionTypes.UPDATE_CLEAN_SUCCESS,
  CleansActionTypes.UPDATE_CLEAN_FAILURE,
)<
  RequestConfig<NormalizedCleansApiResponse>,
  NormalizedCleansApiResponse,
  Error
>()

export const buildRequestData = (patchData: CleanPatchData): RequestOptions => {
  const { completedAt, jobType, startedAt } = patchData
  const attributes: Partial<CleanPatchApiAttributes> = {
    completed_at: completedAt && apiDateStringWithSeconds(completedAt),
    job_type: jobType,
    started_at: startedAt && apiDateStringWithSeconds(startedAt),
  }

  return {
    data: {
      attributes,
      id: patchData.id,
      type: 'clean',
    },
  }
}

export const updateClean =
  (patchData: CleanPatchData, callbacks: ReduxActionCallbacks = {}) =>
  async (dispatch, getState) => {
    const { onSuccess = noop } = callbacks
    try {
      const cleanId = patchData.id
      const requestData = buildRequestData(patchData)
      const request = cleansService.updateClean.bind(null, cleanId, requestData)
      const result = await dispatch(updateCleanAction.request({ request }))
      dispatch(updateCleanAction.success(result))
      trackCleanCompleted(patchData, getState)

      // re-fetch the associated clean to ensure we have the latest version locally
      await dispatch(fetchCleanById(cleanId))

      onSuccess()
      return result.normalized
    } catch (error) {
      dispatch(updateCleanAction.failure(error))
      throw error
    }
  }
