import { type ApplicationState } from 'app/store/store'

/**
 * Returns either delegate token (when present for shared cleans) or the current
 * user's access token.
 */
export const getAccessToken = (state: ApplicationState): string | undefined =>
  state.auth.delegateToken ||
  state.auth.impersonationToken ||
  state.auth.accessToken
