import { createSelector } from 'reselect'

import { MissingDataError } from 'packages/utils/errors'

import { type ApplicationState } from 'app/store/store'

import { type Assignment } from '../assignments.types'
import { hydrateRawAssignment } from '../assignments.utils'

const getFullState = (state: ApplicationState): ApplicationState => state
const getAssignmentId = (_state: ApplicationState, id: string): string => id

export const getAssignmentById = createSelector(
  getFullState,
  getAssignmentId,
  (state, assignmentId): Assignment => {
    const hydrator = hydrateRawAssignment(state)

    const rawAssignment = state.assignments?.data?.[assignmentId]
    if (!rawAssignment) {
      throw new MissingDataError('assignment', assignmentId)
    }

    return hydrator(rawAssignment)
  },
)
