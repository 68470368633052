import styled from '@emotion/styled'
import React from 'react'

import { useModalContext } from 'packages/common'
import { IconName, SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

import { Slugs, useI18n } from 'app/i18n'
import { gwText } from 'app/styles'

const St = {
  Body: styled.div`
    ${gwText.bodySmall};
    max-width: 420px;
  `,
  Link: styled.div`
    ${gwText.bodySmall};
    color: ${colors.lake};
    margin-top: 16px;
  `,
  Title: styled.div`
    ${gwText.headingSmallCaps};
    align-items: center;
    display: flex;
    grid-gap: 8px;
    justify-content: flex-start;
    margin-bottom: 20px;
  `,
}

const useTranslations = () => {
  const { t } = useI18n()

  return {
    body: t(Slugs.rescheduleACleanBody),
    link: t(Slugs.goToHomeownerAccount),
    title: t(Slugs.needToRescheduleClean),
  }
}

export interface UseEcdModal {
  showEcdModal: () => void
}

/**
 * Triggers a modal with a simple explanation for owner about how to "reschedule a clean"
 * by adding extra cleaning days from Owner Portal.
 * (ECD = Extra Cleaning Days)
 */
export const useEcdModal = (): UseEcdModal => {
  const strings = useTranslations()
  const { showModal } = useModalContext()

  const showEcdModal = React.useCallback(() => {
    showModal({
      childRenderer: () => (
        <>
          <St.Title>
            <SvgIcon icon={IconName.info} size={16} />
            <span>{strings.title}</span>
          </St.Title>
          <St.Body>{strings.body}</St.Body>
          <St.Link>
            <a
              href="https://accounts.vacasa.io/login?next=/authorize%3Fdirectory_hint%3Demail%26owner_migration_needed%3Dtrue%26client_id%3DKOIkAJP9XW7ZpTXwRa0B7O4qMuXSQ3p4BKFfTPhr%26response_type%3Dtoken%2Bid_token%26redirect_uri%3Dhttps%3A//owners.vacasa.com%26scope%3Downers%253Aread%2520employees%253Aread%26audience%3Downer.vacasa.io%26state%3D1cbf17f3-52ec-4622-9543-e76450964712%26nonce%3D58c70035-b1f3-4f04-a2a1-d6ed538524df%26mode%3Downer"
              target={'_blank'}
              rel="noreferrer"
            >
              {strings.link}
            </a>
          </St.Link>
        </>
      ),
    })
  }, [showModal, strings])

  return {
    showEcdModal,
  }
}
