import {
  type IJsonApiReference,
  type IJsonApiResponse,
} from 'json-api-normalizer'

/**
 * Use when building an object to describe a JSON:API response in conjunction with
 * `JSONApiObjectWithRelationships<ObjectAttributes, ObjectRelationships>`
 *
 * @example
 * type HousekeeperRelationships = {
 *  user: ToOneRelationship
 *  tier: ToOneRelationship
 * }
 */
export interface ToOneRelationship {
  data: IJsonApiReference
}

export interface ToOneRelationshipOrNull {
  data: IJsonApiReference | null
}

/**
 * Use when building an object to describe a JSON:API response in conjunction with
 * `JSONApiObjectWithRelationships<ObjectAttributes, ObjectRelationships>`
 *
 * @example
 * type HousekeeperRelationships = {
 *  user: ToOneRelationship
 *  tier: ToOneRelationship
 * }
 */
export interface ToManyRelationship {
  data: IJsonApiReference[]
}

export enum JsonApiErrorCodes {
  MISSING_MONOLITH_USER_ID = '0E0602',
  REMOVE_BORROWED_HK_FROM_ZONE_WITH_UNITS = '060101',
  REMOVE_HK_FROM_ZONE_WITH_UNITS = '0A0103',
  UKG_CATCH_ALL = '000502',
}

export interface JsonApiError {
  code: JsonApiErrorCodes
  detail?: string
  title?: string
}

export interface JsonApiErrorResponse {
  response: {
    data: {
      errors: JsonApiError[]
    }
  }
}

/**
 * This represents a collection of typed JSON:API objects with attributes
 * as defined by `ObjectAttributes`
 *
 * @example
 * type ZoneResponse = {
 *   zone: JSONApiObjectMap<ZoneAttributes>
 * }
 */
export type JSONApiObjectMap<ObjectAttributes> = Record<
  string,
  JSONApiObject<ObjectAttributes>
>

/**
 * This represents a collection of typed JSON:API objects with attributes
 * as defined by `ObjectAttributes` and relationships as defined by
 * `ObjectRelationships`
 *
 * @example
 * type JSONApiHousekeeperMap = JSONApiObjectWithRelationshipsMap<
 *   HousekeeperAttributes,
 *   HousekeeperRelationships
 * >
 */
export type JSONApiObjectWithRelationshipsMap<
  ObjectAttributes,
  ObjectRelationships,
> = Record<
  string,
  JSONApiObjectWithRelationships<ObjectAttributes, ObjectRelationships>
>

export interface JSONApiObject<ObjectAttributes> {
  attributes: ObjectAttributes
  id: string
  type: string
}

export interface JSONApiObjectWithRelationships<
  ObjectAttributes,
  ObjectRelationships,
> {
  attributes: ObjectAttributes
  id: string
  relationships: ObjectRelationships
  type: string
}

export interface NormalizedJSONApiResponse<T> {
  normalized: T
  raw: IJsonApiResponse
}

export interface RequestOptions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: Partial<JSONApiObjectWithRelationships<any, any>>
  fields?: Record<string, string[]>
  filter?: Record<string, unknown>
  include?: string[]
  page?: Record<string, unknown>
  sort?: string[]
}

export interface RequestConfig<T> {
  request: (params: RequestOptions) => Promise<T>
}

interface OfflineDataConfig<T> {
  data: T
}

export type OfflineRequestConfig<RequestType, OfflineDataType> =
  RequestConfig<RequestType> & OfflineDataConfig<OfflineDataType>

/*
 * Data shape for making update requests against a given entity's relationships.
 * e.g. Adding/removing a Unit/HK from a Zone
 */
export interface RelationshipUpdate {
  ownerId: string
  relationshipIds: string[]
}
