import styled from '@emotion/styled'
import React from 'react'

import {
  unitCodeAndNameString,
  unitFullAddressString,
} from 'packages/utils/hkStringHelpers'

import { MapsLink } from 'app/components/core'
import { type Clean } from 'app/store/cleans'

import { CleanDetailMainSection } from '../../CleanDetail.styles'

const St = {
  Address: styled.div`
    margin-top: 8px;
  `,
  AddressInfo: styled(CleanDetailMainSection)`
    padding-top: 0;
    position: relative;
  `,
  UnitName: styled.div`
    font-size: 16px;
    font-weight: 600;
  `,
}

export enum AddressInfoTestIds {
  container = 'AddressInfo__container',
}

export interface AddressInfoProps {
  clean: Clean
}

export const AddressInfo: React.FC<AddressInfoProps> = React.memo(
  ({ clean }) => {
    const { unit } = clean
    const unitNameString = unitCodeAndNameString(unit)
    const addressString = unitFullAddressString(unit)

    return (
      <St.AddressInfo data-testid={AddressInfoTestIds.container}>
        <St.UnitName>{unitNameString}</St.UnitName>
        <St.Address>{addressString}</St.Address>
        <MapsLink {...unit} />
      </St.AddressInfo>
    )
  },
)
