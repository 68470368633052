import { deprecated } from 'typesafe-actions'

import { type AuthTokens } from 'packages/auth'

import { AuthActionTypes } from '../auth.types'
const { createStandardAction } = deprecated

type TokensWithDelegate = AuthTokens & {
  delegateToken?: string
}

export const setTokensAction = createStandardAction(
  AuthActionTypes.SET_TOKENS,
)<TokensWithDelegate>()

export const setTokens = (tokens: TokensWithDelegate) => dispatch =>
  dispatch(setTokensAction(tokens))
