import { type RequestOptions } from 'packages/utils/store/jsonapi.types'

import { api } from '../axiosInstance'
import { type UnitsApiResponse, type UnitsServiceResponse } from './units.types'
import { emptyUnitsResponse } from './units.utils'

const URL = '/units'

export const unitsService = {
  async fetchUnits(params: RequestOptions = {}): UnitsServiceResponse {
    const response: UnitsApiResponse = await api.get(`${URL}`, { params })
    return response?.data || emptyUnitsResponse
  },

  async fetchUnitById(
    unitId: string,
    params: RequestOptions = {},
  ): UnitsServiceResponse {
    const response: UnitsApiResponse = await api.get(`${URL}/${unitId}`, {
      params,
    })
    return response?.data || emptyUnitsResponse
  },
}
