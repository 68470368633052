import { produce } from 'immer'

import { UnitRealtimeStatus } from 'packages/grimoire'
import { transformNormalizedToTyped } from 'packages/utils/store'

import {
  type NormalizedUnitsApiResponse,
  type RawUnit,
  type Unit,
  UnitAttributeNames,
} from './units.types'

/**
 * Empty data in the shape of the Units service's `normalized` payload.
 * Use this as a fallback when there are issues with the API data being undefined or malformed.
 */
export const emptyNormalizedUnitsData = Object.freeze({
  gwLocalHost: {},
  gwUnitStaffer: {},
  unit: {},
})

export const emptyUnitsResponse: NormalizedUnitsApiResponse = Object.freeze({
  normalized: emptyNormalizedUnitsData,
  raw: { data: [] },
})

export const hydrateRawUnit = (rawUnit: RawUnit): Unit => {
  const baseUnit = transformNormalizedToTyped<Unit>(rawUnit, UnitAttributeNames)
  return produce(baseUnit, draft => {
    draft.gwUnitStaffersIds = (
      rawUnit.relationships?.gwUnitStaffers?.data || []
    ).map(({ id }) => id)

    draft.gwLocalHostsIds = (
      rawUnit.relationships?.gwLocalHosts?.data || []
    ).map(({ id }) => id)
  })
}

export const UnknownUnit: Unit = Object.freeze({
  address: 'Unknown',
  alarmCode: '',
  amenities: {},
  bathrooms: 2,
  bedrooms: 2,
  beds: 3,
  city: 'Unknown',
  currentResId: '',
  gateCode: '',
  gwLocalHostsIds: [],
  gwUnitStaffersIds: [],
  hasSmartLock: false,
  hottub: true,
  housekeeperNotes: '',
  id: 'unknown',
  isActive: '',
  lastDeepCleanedAt: '',
  maxOccupancy: 10,
  name: 'Unknown',
  nextDeepCleanDueAt: '',
  nextResId: '',
  previousResId: '',
  primaryImageId: '',
  realtimeStatus: UnitRealtimeStatus.UNKNOWN,
  state: 'Unknown',
  statistics: null,
  tz: '',
  unitCode: 'Unknown',
})
