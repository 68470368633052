export const SHARED_CLEAN_ROOT_URL = '/shared/cleans'
export const SHARED_TICKET_ROOT_URL = '/shared/tickets'
export const SHARED_CLEAN_INVALID_URL = `${SHARED_CLEAN_ROOT_URL}/invalid`
export const SHARED_TICKET_INVALID_URL = `${SHARED_TICKET_ROOT_URL}/invalid`

export const isViewingSharedClean = (): boolean =>
  window.location.href.includes(SHARED_CLEAN_ROOT_URL)

export const isViewingSharedTicket = (): boolean =>
  window.location.href.includes(SHARED_TICKET_ROOT_URL)
