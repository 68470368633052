import styled from '@emotion/styled'
import upperFirst from 'lodash/upperFirst'
import React from 'react'

import { CleanCheckOutDisplay } from 'packages/common'

import { Slugs, useI18n } from 'app/i18n'
import { type Clean } from 'app/store/cleans/cleans.types'
import { gwColors } from 'app/styles'
import { cleanDueDateString } from 'app/utils'

import { CleanDetailMainSection } from '../../CleanDetail.styles'

const St = {
  Cell: styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;

    &:not(:first-of-type) {
      border-left: 1px solid ${gwColors.uiDivider};
      padding-left: 16px;
    }
  `,
  DateInfo: styled(CleanDetailMainSection)`
    display: flex;
    flex-direction: row;
  `,
}

export enum DateInfoTestIds {
  cleanCheckOutDisplay = 'DateInfo__cleanCheckOutDisplay',
}

export interface DateInfoProps {
  clean: Clean
}

export const DateInfo: React.FC<DateInfoProps> = React.memo(({ clean }) => {
  const { t } = useI18n()
  const dueDateString = cleanDueDateString(clean)

  return (
    <St.DateInfo>
      <St.Cell>
        <div>
          <strong>{upperFirst(t(Slugs.startTime))}</strong>
        </div>
        <CleanCheckOutDisplay
          clean={clean}
          dataTestId={DateInfoTestIds.cleanCheckOutDisplay}
        />
      </St.Cell>

      <St.Cell>
        <div>
          <strong>{upperFirst(t(Slugs.due))}</strong>
        </div>
        <div>{dueDateString}</div>
      </St.Cell>
    </St.DateInfo>
  )
})
