import { createSelector } from 'reselect'

import { type ApplicationState } from '../../store'
import { type GwLocalHost } from '../gwLocalhosts.types'
import { hydrateRawGwLocalHost } from '../gwLocalhosts.utils'

/**
 * Returns an array of Local Hosts based on all ID provided.
 */

export const getGwLocalHostsByIds = createSelector(
  (state: ApplicationState) => state.gwLocalHosts,
  (_, gwLocalHostIds: string[]) => gwLocalHostIds,
  (gwLocalHostsState, gwLocalHostIds): GwLocalHost[] =>
    gwLocalHostIds?.reduce((acc, id) => {
      const rawGwLocalHost = gwLocalHostsState.data[id]
      if (rawGwLocalHost) {
        acc.push(hydrateRawGwLocalHost(rawGwLocalHost))
      }

      return acc
    }, [] as GwLocalHost[]),
)
