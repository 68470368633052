import { createSelector } from 'reselect'

import { type ApplicationState } from 'app/store/store'
import {
  hydrateRawUser,
  type JSONApiUsersMap,
  type User,
} from 'app/store/users'

/**
 * Data for all loaded users (e.g. *authorization* and *active*) keyed to their
 * ID
 */
const getUsersData = (state: ApplicationState): JSONApiUsersMap =>
  state.users.data

const getActiveUserId = (state: ApplicationState): string =>
  state.users.activeUserId

export const getActiveUser = createSelector(
  getUsersData,
  getActiveUserId,
  (usersData, activeUserId): User | undefined => {
    const rawUser = usersData[activeUserId]
    return rawUser && hydrateRawUser(rawUser)
  },
)
