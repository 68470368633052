import { noop } from 'lodash/fp'
import { createAsyncAction } from 'typesafe-actions'

import { type ReduxActionCallbacks } from 'packages/grimoire/src/utils'
import {
  type RequestConfig,
  type RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { gwLocalHostsService } from '../gwLocalhosts.service'
import {
  type GwLocalHostPatchApiAttributes,
  type GwLocalHostPatchData,
  GwLocalHostsActionTypes,
  type NormalizedGwLocalHostsApiResponse,
} from '../gwLocalhosts.types'
import { fetchGwLocalHostById } from './fetchGwLocalhostById'

export const updateGwLocalHostAction = createAsyncAction(
  GwLocalHostsActionTypes.UPDATE_GW_LOCAL_HOST,
  GwLocalHostsActionTypes.UPDATE_GW_LOCAL_HOST_SUCCESS,
  GwLocalHostsActionTypes.UPDATE_GW_LOCAL_HOST_FAILURE,
)<
  RequestConfig<NormalizedGwLocalHostsApiResponse>,
  NormalizedGwLocalHostsApiResponse,
  Error
>()

export const buildRequestData = (
  patchData: GwLocalHostPatchData,
): RequestOptions => {
  const { isActive } = patchData
  const attributes: Partial<GwLocalHostPatchApiAttributes> = {
    is_active: isActive,
  }

  return {
    data: {
      attributes,
      id: patchData.id,
      type: 'gw_local_host',
    },
  }
}

export const updateGwLocalHost =
  (patchData: GwLocalHostPatchData, callbacks: ReduxActionCallbacks = {}) =>
  async dispatch => {
    const { onError = noop, onSuccess = noop } = callbacks
    try {
      const gwLocalHostId = patchData.id
      const requestData = buildRequestData(patchData)
      const request = gwLocalHostsService.updateGwLocalHost.bind(
        null,
        gwLocalHostId,
        requestData,
      )
      const result = await dispatch(
        updateGwLocalHostAction.request({ request }),
      )
      dispatch(updateGwLocalHostAction.success(result))

      await dispatch(fetchGwLocalHostById(gwLocalHostId))

      onSuccess()
      return result.normalized
    } catch (error) {
      dispatch(updateGwLocalHostAction.failure(error))
      onError()
      throw error
    }
  }
