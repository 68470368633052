import { createAsyncAction } from 'typesafe-actions'

import {
  type RequestConfig,
  type RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { HousekeeperApiFields } from '../../housekeepers'
import { LockboxApiFields } from '../../lockboxes'
import { CleanReservationApiFields } from '../../reservations'
import { UnitApiFields } from '../../units'
import { UserApiFields } from '../../users'
import { cleansService } from '../cleans.service'
import {
  CleanApiFields,
  CleansActionTypes,
  type NormalizedCleansApiResponse,
} from '../cleans.types'

export const fetchCleanByIdAction = createAsyncAction(
  CleansActionTypes.FETCH_CLEAN,
  CleansActionTypes.FETCH_CLEAN_SUCCESS,
  CleansActionTypes.FETCH_CLEAN_FAILURE,
)<
  RequestConfig<NormalizedCleansApiResponse>,
  NormalizedCleansApiResponse,
  Error
>()

export const getParams = (): RequestOptions => ({
  fields: {
    assignment: [],
    clean: CleanApiFields,
    housekeeper: HousekeeperApiFields,
    lock_box: LockboxApiFields,
    reservation: CleanReservationApiFields,
    unit: UnitApiFields,
    user: UserApiFields,
  },
  include: [
    'active_assignments',
    'active_assignments.housekeeper',
    'active_assignments.housekeeper.user',
    'reservation',
    'reservation.unit',
    'unit',
    'unit.lock_box',
  ],
  page: { size: 100 },
})

export const fetchCleanById = (cleanId: string) => async dispatch => {
  try {
    const params = getParams()
    const request = cleansService.fetchCleanById.bind(null, cleanId, params)
    const result = await dispatch(fetchCleanByIdAction.request({ request }))
    dispatch(fetchCleanByIdAction.success(result))
    return result.normalized
  } catch (error) {
    dispatch(fetchCleanByIdAction.failure(error))
    throw error
  }
}
