import { type AuthTokens } from 'packages/auth'

/**********************************************************
 * REDUX TYPES
 *********************************************************/
export enum AuthActionTypes {
  SET_NEEDS_FULL_AUTH_REDIRECT = 'AUTH/SET_NEEDS_FULL_AUTH_REDIRECT',
  SET_NEEDS_SILENT_REFRESH = 'AUTH/SET_NEEDS_SILENT_REFRESH',
  SET_TOKENS = 'SET_TOKENS',
}

export type AuthState = AuthTokens & {
  delegateToken?: string
  delegateUserId?: string
  error?: Error
  /**
   * Whether current user must be redirected to sign in again
   */
  needsFullAuthRedirect: boolean
  needsSilentRefresh: boolean
}
