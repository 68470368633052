import { useSelector } from 'react-redux'

import { applyErrorSuppression } from '../applyErrorSuppression'

export function useSelectorWithErrorSuppression<T, ApplicationState>(
  selector: (state: ApplicationState) => T,
  /* We need to allow any here because we're passing an array of Error Classes, 
  not instances of the classes, so the `Error` type won't work */
  suppressedErrors: any /* eslint-disable-line */,
): T | undefined {
  return useSelector((state: ApplicationState) => {
    return applyErrorSuppression<T>(() => selector(state), suppressedErrors)
  })
}
