import styled from '@emotion/styled'

import { LoaderTestIds } from 'packages/common'
import { colors } from 'packages/styles'

import { type ButtonProps, type ButtonSize } from './Button'

// Some of the colors in the designs make "new" colors by altering opacity,
// which are not a core part of the color system.
// So these variables try to name them relative to their original colors.
const altColors = {
  midnight10opacity80: `rgb(229 235 237 / 80%)`,
  midnight20opacity80: `rgb(209 217 221 / 80%)`,
}

const buttonHeightMap: Record<ButtonSize, number> = {
  default: 40,
  small: 40,
  xSmall: 30,
}

const buttonPaddingMap: Record<ButtonSize, number> = {
  default: 32,
  small: 28,
  xSmall: 20,
}

const fontSizeMap: Record<ButtonSize, number> = {
  default: 16,
  small: 14,
  xSmall: 12,
}

const lineHeightMap: Record<ButtonSize, number> = {
  default: 24,
  small: 24,
  xSmall: 14,
}

type PickedProps = Required<
  Pick<ButtonProps, 'block' | 'buttonSize' | 'isLoading'>
>
const BaseButton = styled.button<PickedProps>`
  border: 0;
  border-radius: 40px;
  cursor: pointer;
  font-weight: 800;
  letter-spacing: 0.75px;
  position: relative;

  font-size: ${({ buttonSize }) => fontSizeMap[buttonSize]}px;
  height: ${({ buttonSize }) => buttonHeightMap[buttonSize]}px;
  line-height: ${({ buttonSize }) => lineHeightMap[buttonSize]}px;
  padding: 0 ${({ buttonSize }) => buttonPaddingMap[buttonSize]}px;
  pointer-events: ${({ isLoading }) => (isLoading ? 'none' : 'inherit')};
  width: ${({ block }) => (block ? '100%' : 'auto')};

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }
`

const IconButton = styled(BaseButton)`
  height: 32px;
  color: ${colors.midnight90};
  padding: 0;
  transition:
    background-color 150ms,
    border-color 150ms,
    color 150ms,
    opacity 150ms;
  width: 32px;

  &:hover {
    opacity: 0.8;
  }

  // This wonky selector targets the Loader within the button.
  // With icon buttons, we need to adjust the positioning of the loader to keep it centered.
  > div[data-testid='${LoaderTestIds.container}'] {
    bottom: 0;
    top: unset;
  }
`

export const StyledButton = {
  alertPrimary: styled(BaseButton)`
    background-color: ${colors.alert};
    color: white;
    transition:
      background-color 150ms,
      opacity 150ms;

    &:active,
    &:focus {
      outline: 6px solid ${altColors.midnight10opacity80};
    }

    &:hover {
      background-color: ${colors.alert70};
    }

    &:disabled {
      background-color: ${colors.alert70};
    }
  `,

  alertSecondary: styled(BaseButton)`
    background-color: white;
    border: 2px solid ${colors.alert};
    color: ${colors.alert};
    transition:
      border-color 150ms,
      color 150ms,
      opacity 150ms;

    &:active,
    &:focus {
      background-color: transparent;
      outline: 4px solid ${altColors.midnight10opacity80};
    }

    &:hover {
      border-color: ${colors.alert70};
      color: ${colors.alert70};
    }

    &:disabled {
      background-color: transparent;
    }
  `,

  iconPrimary: styled(IconButton)`
    background-color: ${colors.midnight20};

    &:active,
    &:focus {
      outline: 4px solid ${altColors.midnight10opacity80};
    }
  `,

  iconSecondary: styled(IconButton)`
    background-color: transparent;
    border: 2px solid ${colors.midnight90};

    &:active,
    &:focus {
      outline: 4px solid ${altColors.midnight20opacity80};
    }
  `,

  iconSubtle: styled(IconButton)`
    background-color: white;

    &:active,
    &:focus {
      outline: 4px solid ${altColors.midnight20opacity80};
    }
  `,

  primary: styled(BaseButton)`
    background-color: ${colors.midnight};
    color: white;
    transition:
      background-color 150ms,
      opacity 150ms;

    &:active,
    &:focus {
      outline: 4px solid ${altColors.midnight20opacity80};
    }

    &:hover {
      background-color: ${colors.midnight70};
    }

    &:disabled {
      background-color: ${colors.midnight90};
    }
  `,

  secondary: styled(BaseButton)`
    background-color: white;
    border: 2px solid ${colors.midnight};
    color: ${colors.midnight};
    transition:
      border-color 650ms,
      color 150ms,
      opacity 150ms;

    &:active,
    &:focus {
      background-color: transparent;
      outline: 4px solid ${altColors.midnight10opacity80};
    }

    &:hover {
      border-color: ${colors.midnight70};
      color: ${colors.midnight70};
    }

    &:disabled {
      background-color: transparent;
    }
  `,

  subtle: styled(BaseButton)`
    background-color: transparent;
    border: 2px solid ${colors.midnight20};
    color: ${colors.midnight};
    transition:
      border-color 150ms,
      color 150ms,
      opacity 150ms;

    &:active,
    &:focus {
      outline: 4px solid ${altColors.midnight10opacity80};
    }

    &:hover {
      color: ${colors.midnight70};
    }
  `,

  text: styled(BaseButton)`
    background-color: transparent;
    color: ${colors.midnight};
    transition:
      background-color 150ms,
      color 150ms,
      opacity 150ms;

    &:active,
    &:focus {
      background-color: ${altColors.midnight10opacity80};
    }

    &:hover {
      color: ${colors.midnight70};
    }
  `,

  white: styled(BaseButton)`
    background-color: white;
    color: ${colors.midnight};
    transition:
      color 150ms,
      opacity 150ms;

    &:active,
    &:focus {
      outline: 4px solid ${altColors.midnight10opacity80};
    }

    &:hover {
      color: ${colors.midnight70};
    }
  `,
}

export const St = {
  Children: styled.span<Pick<ButtonProps, 'isLoading'>>`
    display: inline-flex;
    grid-gap: 8px;
    height: 100%;
    place-items: center;
    visibility: ${({ isLoading }) => (isLoading ? 'hidden' : 'inherit')};
  `,
  LoadingText: styled.span`
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  `,
}
