import { createAsyncAction } from 'typesafe-actions'

import {
  type RequestConfig,
  type RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { GwLocalHostApiFields } from '../../gwLocalhosts'
import { GwUnitStafferApiFields } from '../../gwUnitStaffers'
import { unitsService } from '../units.service'
import {
  type NormalizedUnitsApiResponse,
  UnitApiFields,
  UnitsActionTypes,
} from '../units.types'

export const fetchUnitByIdAction = createAsyncAction(
  UnitsActionTypes.FETCH_UNIT,
  UnitsActionTypes.FETCH_UNIT_SUCCESS,
  UnitsActionTypes.FETCH_UNIT_FAILURE,
)<
  RequestConfig<NormalizedUnitsApiResponse>,
  NormalizedUnitsApiResponse,
  Error
>()

export const getParams = (): RequestOptions => ({
  fields: {
    gw_local_host: GwLocalHostApiFields,
    gw_unit_staffer: GwUnitStafferApiFields,
    unit: UnitApiFields,
  },
  include: ['gw_unit_staffers', 'gw_local_hosts'],
})

export const fetchUnitById = (unitId: string) => async dispatch => {
  try {
    const params = getParams()
    const request = unitsService.fetchUnitById.bind(null, unitId, params)
    const result = await dispatch(fetchUnitByIdAction.request({ request }))
    dispatch(fetchUnitByIdAction.success(result))
    return result.normalized
  } catch (error) {
    dispatch(fetchUnitByIdAction.failure(error))
    throw error
  }
}
