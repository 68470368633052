import { upperFirst } from 'lodash/fp'
import React from 'react'

import { IconName } from 'packages/iconic'

import { Slugs, useI18n } from 'app/i18n'

import { Button, Header } from '../../core'
import * as St from './SettingsPage.styles'

export enum SettingsPageTestIds {
  accessLink = 'SettingsPage__accessLink',
  container = 'SettingsPage__container',
}

export interface LinkConfigType {
  icon: IconName
  route: string
  testId?: string
  text: string
}

export interface SettingsPageProps {
  onLinkClick: (route: string) => void
  onLogout: () => void
}

export const SettingsPage: React.FC<SettingsPageProps> = ({
  onLinkClick,
  onLogout,
}) => {
  const { t } = useI18n()

  const linkConfigs = React.useMemo(
    () => [
      {
        icon: IconName.user2,
        route: '/settings/homes',
        text: 'My Homes',
      },
      {
        icon: IconName.lock,
        route: '/settings/access',
        testId: SettingsPageTestIds.accessLink,
        text: t(Slugs.homeAccess),
      },
      {
        icon: IconName.headset,
        route: '/settings/support',
        text: t(Slugs.support),
      },
    ],
    [t],
  ) as LinkConfigType[]

  return (
    <St.Container data-testid={SettingsPageTestIds.container}>
      <div>
        <Header title={'settings'} />
        <St.List>
          {linkConfigs.map(({ icon, route, text, testId }) => {
            return (
              <St.ListItem
                data-testid={testId}
                onClick={() => {
                  onLinkClick(route)
                }}
                key={text}
              >
                <St.TextIconContainer>
                  <St.LinkIcon icon={icon} size={24} />
                  <St.LinkText>{text}</St.LinkText>
                </St.TextIconContainer>
                <St.LinkChevron icon={IconName.chevronRight} size={20} />
              </St.ListItem>
            )
          })}
        </St.List>
      </div>

      <St.LogoutButtonWrapper>
        <Button block={true} buttonType={'secondary'} onPress={onLogout}>
          {upperFirst(t(Slugs.logout))}
        </Button>
      </St.LogoutButtonWrapper>
    </St.Container>
  )
}
