import styled from '@emotion/styled'

import { SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'

import { gwText } from 'app/styles'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
`

export const LogoutButtonWrapper = styled.div`
  margin: 0 14px 0 14px;
`

export const List = styled.ul`
  list-style-type: none;
  padding: 0px;
  margin: 16px 14px 16px 14px;
`

export const ListItem = styled.li`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 10px;

  &:hover {
    background-image: linear-gradient(rgb(0 0 0/5%) 0 0);
  }
`

export const TextIconContainer = styled.div`
  align-items: center;
  display: flex;
`

export const LinkChevron = styled(SvgIcon)`
  color: ${colors.midnight};
`

export const LinkIcon = styled(SvgIcon)`
  color: ${colors.midnight70};
`

export const LinkText = styled.p`
  ${gwText.headingSmallCaps};
  color: ${colors.midnight};
  margin: 0 0 0 20px;
`
