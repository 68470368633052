import styled from '@emotion/styled'
import React from 'react'

import type { Clean } from 'app/store/cleans'

import { CompletionStatusBadge } from './CompletionStatusBadge'
import { ServiceTypeBadge } from './ServiceTypeBadge'

const St = {
  CleanStatusBadges: styled.div`
    padding: 16px 24px;
  `,
}

export interface CleanStatusBadgesProps {
  className?: string
  clean: Clean
}

export const CleanStatusBadges: React.FC<CleanStatusBadgesProps> = React.memo(
  ({ className, clean }) => {
    return (
      <St.CleanStatusBadges className={className}>
        <CompletionStatusBadge clean={clean} />
        <ServiceTypeBadge clean={clean} />
      </St.CleanStatusBadges>
    )
  },
)
