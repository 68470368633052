import styled from '@emotion/styled'
import React from 'react'

import { IconName, SvgIcon } from 'packages/iconic'
import { centerWithFlex } from 'packages/styles'
import { DateFormat, formatLocalized } from 'packages/utils/dateHelpers'

import { Slugs, useI18n } from 'app/i18n'
import type { Reservation } from 'app/store/reservations'
import { gwText } from 'app/styles'

const St = {
  Block: styled.div`
    align-items: flex-start;
    display: flex;
    grid-gap: 16px;
    justify-content: flex-start;

    &:not(:first-of-type) {
      margin-top: 8px;
    }
  `,
  Details: styled.div`
    ${centerWithFlex};
    align-items: flex-start;
    flex-direction: column;
  `,
  Header: styled.div`
    ${gwText.headingSmall};
  `,
  Text: styled.p`
    ${gwText.bodyDefault};
    margin: 0;

    &:first-of-type {
      margin-top: 4px;
    }
  `,
}

function useTranslations(res: Reservation) {
  const { t, tp, ut } = useI18n()
  const guestFullName =
    `${res.guestFirstName || ''} ${res.guestLastName || ''}`.trim()

  return {
    bookedOnBody: res.creationDate
      ? formatLocalized(res.creationDate, DateFormat.SlashesWithShortYear)
      : ut(Slugs.unknown),
    bookedOnTitle: t(Slugs.bookedOn),
    bookingDetailsBody: `${tp(Slugs.guest, res.guests || 0)}, ${tp(Slugs.dog, res.dogs || 0)}`,
    bookingDetailsTitle: t(Slugs.bookingDetails),
    guestCode: t(Slugs.guestCode),
    guestContactEmail: res.guestEmail
      ? `${t(Slugs.email)}: ${res.guestEmail}`
      : '',
    guestContactName: `${t(Slugs.name)}: ${guestFullName || ut(Slugs.unknown)}`,
    guestContactPhone: res.guestPhone
      ? `${t(Slugs.phone)}: ${res.guestPhone}`
      : '',
    guestContactTitle: t(Slugs.guestContact),
    lockCode: t(Slugs.lockCode),
    noCodeToShow: t(Slugs.noCodeToShow),
  }
}

export interface ResDetailsProps {
  res: Reservation
}

export const ResDetails: React.FC<ResDetailsProps> = React.memo(({ res }) => {
  const strings = useTranslations(res)

  return (
    <div>
      <St.Block>
        <SvgIcon icon={IconName.user2} size={20} />
        <St.Details>
          <St.Header>{strings.guestContactTitle}</St.Header>
          {strings.guestContactName && (
            <St.Text>{strings.guestContactName}</St.Text>
          )}
          {strings.guestContactPhone && (
            <St.Text>{strings.guestContactPhone}</St.Text>
          )}
          {strings.guestContactEmail && (
            <St.Text>{strings.guestContactEmail}</St.Text>
          )}
        </St.Details>
      </St.Block>

      <St.Block>
        <SvgIcon icon={IconName.users} size={20} />
        <St.Details>
          <St.Header>{strings.bookingDetailsTitle}</St.Header>
          <St.Text>{strings.bookingDetailsBody}</St.Text>
        </St.Details>
      </St.Block>

      <St.Block>
        <SvgIcon icon={IconName.calendarCheck} size={20} />
        <St.Details>
          <St.Header>{strings.bookedOnTitle}</St.Header>
          <St.Text>{strings.bookedOnBody}</St.Text>
        </St.Details>
      </St.Block>

      <St.Block>
        <SvgIcon icon={IconName.lock} size={20} />
        <St.Details>
          <St.Header>{strings.lockCode}</St.Header>
          {res.guestLockCode ? (
            <St.Text>
              {strings.guestCode} {res.guestLockCode}
            </St.Text>
          ) : (
            <St.Text>{strings.noCodeToShow}</St.Text>
          )}
        </St.Details>
      </St.Block>
    </div>
  )
})
