import { createSelector } from 'reselect'

import { type ApplicationState } from '../../store'
import { type Ticket } from '../tickets.types'
import {
  hydrateRawTicket,
  compareCreationDate,
  compareCompletedDate,
  isCompleted,
} from '../tickets.utils'

const getFullState = (state: ApplicationState): ApplicationState => state

export const getCompletedTickets = createSelector(
  getFullState,
  (state): Ticket[] => {
    const hydrator = hydrateRawTicket(state)

    return Object.values(state.tickets.data)
      .map(hydrator)
      .filter(ticket => isCompleted(ticket))
      .sort(compareCompletedDate)
  },
)

export const getNonCompletedTickets = createSelector(
  getFullState,
  (state): Ticket[] => {
    const hydrator = hydrateRawTicket(state)

    return Object.values(state.tickets.data)
      .map(hydrator)
      .filter(ticket => !isCompleted(ticket))
      .sort(compareCreationDate)
  },
)
